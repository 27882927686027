import {
  member,
  slackMessage,
  somethingWrong,
  sheetyCalls,
  user,
  sites,
} from "../en.json";

import ApiService from "../utils/ApiService";
import NotificationStore from "../stores/NotificationStore";
import history from "../utils/history";
import { types } from "mobx-state-tree";
import { IS_STAGING, IS_NIDA } from "../utils/getEnvironment";
import { slackNotification } from "../utils/sendSlackNotification.js";
import moment from "moment";
import HPUser from "./HPUser";
import SecureLocalStorage from "../utils/SecureLS";
import Site from "./Site";
import { toJS } from "mobx";

var mixpanel = require("mixpanel-browser");
const { REACT_APP_SITE_SECRET_KEY: SITE_SECRET } = process.env;

const UserSignUp = types
  .model("UserSignUp", {
    fullName: "",
    username: "",
    password: "",
    email: "",
    DOB: "",
    link: false,
    loading: false,
    token: types.maybeNull(types.string),
    userSiteId: types.maybeNull(types.string),
    confirmPassword: "",
    HpUsers: types.array(HPUser),
    phoneNumber: types.maybeNull(types.string),
    notes: types.frozen({
      mrn: types.maybeNull(types.string),
      color: types.maybeNull(types.string),
      site: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
    }),
    providerId: types.maybeNull(types.number),
    recommendedBy: types.maybeNull(types.number),
    siteName: types.maybeNull(types.string),
    receivedSiteId: types.maybeNull(types.number),
    HpUsers: types.array(HPUser),
    allSites: types.array(Site),
  })
  .actions((self) => ({
    async createUser(checked) {
      const regExForSiteCheck = /^[A-z0-9]+$/g;
      const notesData =
        (self.notes.name ? `Real Name : ${self.notes.name}\n` : "") +
        (self.notes.site
          ? regExForSiteCheck.test(self.notes.site)
            ? `Site Pin : ${self.notes.site}\n`
            : `Site Name : ${self.notes.site}\n`
          : "") +
        (self.notes.mrn ? `MRN : ${self.notes.mrn}\n` : "") +
        (self.notes.color ? `Color : ${self.notes.color}\n` : "");
      if (!self.checkMissingFields || !checked) {
        NotificationStore.setNotification("error", user.allFieldError);
        return false;
      }
      if (!self.isFullNameValid) {
        NotificationStore.setNotification("error", user.fullNameError);
        return false;
      }
      if (!self.isEmailValid) {
        NotificationStore.setNotification("error", user.emailError);
        return false;
      }
      if (!self.isUsernameValid) {
        NotificationStore.setNotification("error", user.usernameError2);
        return false;
      }

      if (self.isUsernameContainsRealname) {
        NotificationStore.setNotification("error", user.usernameError3);
        return false;
      }
      if (self.isPasswordValid && self.isValid && self.isPasswordMatched) {
        self.setLoading(true);

        const dateOfBirth = moment(self.DOB, "YYYY-MM-DD").format("YYYY-MM-DD");
        const formattedPhone =
          "(" +
          self.phoneNumber.slice(0, 3) +
          ")" +
          " " +
          self.phoneNumber.slice(3, 6) +
          "-" +
          self.phoneNumber.slice(6);
        let params = {
          fullName: self.fullName,
          username: self.username,
          phoneNumber: formattedPhone,
          type: "user",
          email: self.email,
          dob: dateOfBirth,
          password: self.password,
          siteId: parseInt(self.userSiteId),
          notes: notesData,
        };
        if (self.recommendedBy) {
          params = {
            ...params,
            recommendedBy: self.recommendedBy,
            providerId: self.providerId,
          };
        } else {
          params = {
            ...params,
            recommendedBy: -1,
            providerId: -1,
          };
        }
        const checkUniqueEmail = await ApiService.getRequest(
          "unique-username-email",
          { email: self.email }
        );
        const checkUniqueUsername = await ApiService.getRequest(
          "unique-username-email",
          { username: self.username }
        );
        if (!checkUniqueUsername.data.present) {
          if (!checkUniqueEmail.data.present) {
            const response = await ApiService.postRequest("users", params);
            if (response.success) {
              const loginParams = {
                username: self.username,
                password: self.password,
                strategy: "local",
              };
              const loginResponse = await ApiService.postRequest(
                "authentication",
                loginParams
              );
              if (loginResponse.success) {
                await self.setToken(loginResponse.data.accessToken);
                const sendMessageEndPoint = "send-welcome-message";
                const sendMessageParams = {};
                await ApiService.postRequest(
                  sendMessageEndPoint,
                  sendMessageParams
                );
              }

              mixpanel.people.set({
                $email: self.email,
                $username: self.username,
              });
              mixpanel.alias(String(response.data.id));
              mixpanel.track("Sign Up Success", {
                through: "CSM",
                from: "WEB",
              });
              self.setToken("");
              history.push("/signup-success");
              // if (!IS_STAGING) {
              //   const site = self.siteName ? self.siteName : "";
              //   const message = `😬 ${member} ${self.username} ${slackMessage} ${site}.`;
              //   slackNotification({ message });
              // }
              // NotificationStore.setNotification(
              //   "success",
              //   "Congratulations! Account created succesful, Download app from app store | play store to start."
              // );
            } else {
              try {
                NotificationStore.setNotification(
                  "error",
                  response.data.errors[0].message
                );
              } catch (error) {
                NotificationStore.setNotification("error", somethingWrong);
              }
            }
          } else {
            NotificationStore.setNotification("error", user.emailError2);
            self.setLoading(false);
            return false;
          }
        } else {
          NotificationStore.setNotification("error", user.usernameError);
          self.setLoading(false);
          return false;
        }
        self.setLoading(false);
      }
    },
    async fetchAllSites() {
      const params = {
        secret: SITE_SECRET,
      };
      const path = "get-sites";
      self.setLoading(true);
      const response = await ApiService.postRequest(path, params);
      if (response.success) {
        self.setAllSites(response.data.data);
        self.setLoading(false);
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", sites.sitesFetchError);
        return;
      }
    },
    setAllSites(data) {
      self.allSites = data.map((site) => {
        return Site.create(site);
      });
    },
    async getHighPointUsers() {
      const option = {
        method: "GET",
      };
      const url = sheetyCalls.fetchHPUsers;
      const request = new Request(url, option);
      mixpanel.time_event(`Loading time on getRequest{${url}}`);
      const response = await fetch(request)
        .then((response) => {
          mixpanel.track(`Loading time on getRequest{${url}}`, { from: "WEB" });
          if (response.ok) {
            return response.json();
          }
        })
        .catch((err) => {});
      if (response) {
        self.addHPUsers(response.hpUser);
      }
    },
    addHPUsers(Hpusers) {
      self.HpUsers = Hpusers.map((item) => {
        return HPUser.create(item);
      });
    },
    updateProviderId(contactId) {
      if (contactId) {
        const selectedOption = self.HpUsers.filter(
          (item) => item.contactId == contactId
        );
        self.setHighPointContact(
          selectedOption[0].contactId,
          selectedOption[0].champId
        );
      } else {
        self.setHighPointContact(-1, -1);
      }
    },
    setHighPointContact(recommendedBy, providerId) {
      self.recommendedBy = recommendedBy;
      self.providerId = providerId;
    },
    fireMixpanelEventForIconClick(store) {
      mixpanel.track(`Open ${store}`, {
        through: "CSM",
        from: "WEB",
      });
    },
    setNotes(site, color, mrn, name) {
      self.notes = {
        mrn,
        color,
        site,
        name,
      };
    },
    setAttribute(prop, value) {
      self[prop] = value.trim();
    },
    setLoading(value) {
      self.loading = value;
    },
    async setToken(accessToken) {
      SecureLocalStorage.set("TOKEN", accessToken);
      self.token = accessToken;
    },
    // async checkSiteCode(siteCode, phone) {
    // const params = {
    //   sitePin: siteCode
    // };
    // const response = await ApiService.getRequest("sites", params);
    // if (response.data.length > 0) {
    //   self.setUserSiteId(response.data[0].id);
    // }
    // },
    setPhoneNumber(phone) {
      self.phoneNumber = phone;
    },
    setUserSiteId(id) {
      self.receivedSiteId = id;
    },
    setSiteName(name) {
      self.siteName = name;
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.username !== "" && self.password !== "";
    },
    get checkMissingFields() {
      return (
        self.fullName !== "" &&
        self.email !== "" &&
        self.confirmPassword !== "" &&
        self.userSiteId !== null &&
        self.username !== "" &&
        self.password !== ""
      );
    },
    get isUsernameContainsRealname() {
      return (
        self.username
          .toLowerCase()
          .includes(self.fullName.toLowerCase().split(" ")[0]) ||
        self.username
          .toLowerCase()
          .includes(self.fullName.toLowerCase().split(" ")[1]) ||
        self.username
          .toLowerCase()
          .includes(self.fullName.toLowerCase().split(" ")[2])
      );
    },
    get isUsernameValid() {
      let regEx = /^[a-zA-Z0-9_-]{2,30}$/;
      return regEx.test(self.username);
    },
    get isFullNameValid() {
      let regEx = /^[a-zA-Z'_.-]+(\s?[a-zA-z'_.-]+)(\s?[a-zA-z'_.-]+)\s*$/;
      return regEx.test(self.fullName);
    },
    get isEmailValid() {
      let regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return regEx.test(self.email.toLowerCase());
    },
    get isPasswordMatched() {
      if (self.password !== self.confirmPassword) {
        NotificationStore.setNotification("error", user.passwordMismatch);
      }
      return self.password === self.confirmPassword;
    },
    get isPasswordValid() {
      const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

      if (!strongRegex.test(self.password)) {
        NotificationStore.setNotification("error", user.passwordError);
        return false;
      }
      return true;
    },
  }));

export default UserSignUp;
