import { Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Chat from "../components/protected/Chat";
import SessionDetails from "../components/protected/SessionDetails";

// import GroupDetails from "../components/protected/GroupDetails";
// import GroupList from "../components/protected/GroupList";
import Layout from "../components/Layout";

import UserForms from "../components/protected/UserForms";
import AllForms from "../components/protected/AllForms";
import { unsubscribeAll } from "../utils/PubnubMethods";
import React from "react";
import { withAuth } from "../components/Authentication";

@withAuth
@withRouter
@inject("store")
@observer
class UserProtected extends React.Component {
  componentDidMount() {
    const {
      store: { MemberListStore, GroupStore, AuthStore, ReactionsStore },
    } = this.props;
    const userType = AuthStore.type;
    if (userType !== "moderator") {
      MemberListStore.fetchModerators();
      AuthStore.fetchGroupsAndDms();
      // GroupStore.fetchPendingUsers();
      // ReactionsStore.fetchAllReactions();
    }
  }

  async componentWillUnmount() {
    const {
      store: {
        AuthStore: { changeUserOnlineStatus },
      },
    } = this.props;
    changeUserOnlineStatus();
    unsubscribeAll();
  }

  getComponent = (view) => {
    switch (view) {
      case "chat":
        return (
          <div className="chat">
            <Chat />
          </div>
        );

      default:
        return <Redirect to="/chat" />;
    }
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        FormMessageStore: { userFormsVisible, formsVisible },
        MessagesStore: { sessionStarted },
      },
    } = this.props;

    return (
      <Layout title="dashboard" classname="dashboard">
        {this.getComponent(view)}
        {userFormsVisible ? (
          <div className="user-profile-sidebar-2">
            <UserForms />
          </div>
        ) : null}

        {sessionStarted ? (
          <div className="session-sidebar p-1">
            <SessionDetails />
          </div>
        ) : null}

        {formsVisible ? (
          <div className="user-profile-sidebar-2">
            <AllForms />
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default UserProtected;
