import { inject, observer } from "mobx-react";

import React from "react";
import { Spin, Radio, Checkbox, Button, Progress } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import close from "../../static/images/close.png";
import _ from "lodash";

@inject("store")
@observer
class UserForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleChoices: {},
      multipleChoicesRadio: {},
      descriptiveAnswer: {},
      rows: {},
      showWarnings: false,
      minRows: 1,
      maxRows: 10,
    };
  }

  getMultipleChoices = (id, checkedValue) => {
    if (!checkedValue.length) {
      this.setState({
        multipleChoices: _.omit(this.state.multipleChoices, id),
      });
    } else {
      this.setState((prevState) => {
        return {
          multipleChoices: {
            ...prevState.multipleChoices,
            ...{ [id]: checkedValue },
          },
        };
      });
    }
  };
  getMultipleChoicesRadio = (id, e) => {
    const enteredvalue = e.target.value;
    this.setState((prevState) => {
      return {
        multipleChoicesRadio: {
          ...prevState.multipleChoicesRadio,
          ...{ [id]: enteredvalue },
        },
      };
    });
  };

  getDescriptive = (id, e) => {
    const text = e.target.value;

    const afterTrim = text.trim();
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState((prevState) => {
      return {
        rows: {
          ...prevState.rows,
          [id]: currentRows < maxRows ? currentRows : maxRows,
        },
      };
    });
    if (!afterTrim) {
      this.setState({
        descriptiveAnswer: _.omit(this.state.descriptiveAnswer, id),
      });
    } else {
      this.setState((prevState) => {
        return {
          descriptiveAnswer: {
            ...prevState.descriptiveAnswer,
            ...{ [id]: text },
          },
        };
      });
    }
  };

  displayOptionBasedOnQuestionType = (id, type, options) => {
    if (type === "multiple_choices") {
      const myOptions = options.map((option) => {
        return { value: option.id, label: option.option };
      });
      return (
        <Checkbox.Group
          name={id}
          options={myOptions}
          onChange={(e) => {
            this.getMultipleChoices(id, e);
          }}
        />
      );
    } else if (type === "multiple_choices_radio") {
      return (
        <Radio.Group
          className="radio-group"
          onChange={(e) => {
            this.getMultipleChoicesRadio(id, e);
          }}
        >
          {options.map((option) => (
            <Radio value={option.id}>{option.option}</Radio>
          ))}
        </Radio.Group>
      );
    } else if (type === "descriptive") {
      return (
        <div>
          <textarea
            rows={this.state.rows[id] ? this.state.rows[id] : 1}
            className="descriptive-textbox"
            value={this.state.descriptiveAnswer[id]}
            onChange={(e) => this.getDescriptive(id, e)}
            onBlur={() => {
              this.setState({ showWarnings: true });
            }}
          />
          {!this.state.descriptiveAnswer[id] && this.state.showWarnings ? (
            <div className="warning">* field cannot be empty</div>
          ) : null}
        </div>
      );
    }
  };

  updateUserFormResponses = () => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
    } = this.state;
    const {
      store: {
        FormMessageStore: { updateUserFormResponses },
      },
    } = this.props;
    updateUserFormResponses(
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer
    );
  };

  isDisabled = () => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
    } = this.state;

    const multipleChoicesKeys = Object.keys(multipleChoices);
    const multipleChoicesRadioKeys = Object.keys(multipleChoicesRadio);
    const descriptiveAnswerKeys = Object.keys(descriptiveAnswer);
    const err1 = multipleChoicesKeys.filter(
      (key) => !multipleChoices[key].length
    );
    const err2 = multipleChoicesRadioKeys.filter(
      (key) => !multipleChoicesRadio[key]
    );
    const err3 = descriptiveAnswerKeys.filter(
      (key) => !descriptiveAnswer[key].trim().length
    );
    return err1.length || err2.length || err3.length;
  };

  render() {
    const {
      store: {
        FormMessageStore: {
          selectedForm,
          loading,
          formQuestions,
          changeUserformsVisible,
        },
        ProfileStore: { username, fullname },
      },
    } = this.props;
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
    } = this.state;
    const totalLength =
      Object.keys(multipleChoices).length +
      Object.keys(multipleChoicesRadio).length +
      Object.keys(descriptiveAnswer).length;
    let disabled = true;
    if (formQuestions.length !== totalLength) {
      disabled = true;
    } else {
      disabled = this.isDisabled();
    }

    return (
        <div className="form-response">
          <h1 className="form-heading">
            {loading && <Spin />}
            <div>
              <span className="fa fa-user fa-usericon" />
              <span className="username-label">{username}&nbsp;({fullname}):</span>
              <span>&nbsp;{selectedForm.name}</span>
            </div>
            <div className="user-form-progress-bar">
              <div className="form-progress-title">Progress</div>
              <Progress
                percent={Math.trunc((totalLength * 100) / formQuestions.length)}
                strokeColor="#FB9048"
                trailColor="#fff"
              />
            </div>
            <img
              src={close}
              onClick={() => { changeUserformsVisible(false) }}
              alt="Close"
            />
          </h1>
          <div className="forms-container">
            {formQuestions.map((question, index) => {
              return (
                <div className="form-responses" key={question.id}>
                  <span className="question-number">{index + 1}. </span>
                  <span className="subHeading">{question.questionTitle}</span>
                  {this.displayOptionBasedOnQuestionType(
                    question.id,
                    question.type,
                    question.options
                  )}
                </div>
              );
            })}
            <div className="submit-container">
              <button
                className="new-referral-button"
                onClick={this.updateUserFormResponses}
                disabled={disabled}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
    );
  }
}
export default UserForms;
