import LinkifyIt from "linkify-it";
import MemberListStore from "../../src/stores/MemberListStore";
import { toJS } from "mobx";
import GroupStore from "../../src/stores/Groups";

const linkify = new LinkifyIt();

linkify.add("#", {
  validate: function (text, pos, self) {
    const tail = text.slice(pos);
    const hashRegex = /^[A-Za-z0-9]+/;
    const match = tail.match(hashRegex);
    return match && match.length ? match[0].length : 0;
  },
});

linkify.add("@", {
  validate: function (text, pos, self) {
    var tail = text.slice(pos);
 
    if (!self.re.twitter) {
      self.re.twitter =  new RegExp(
        '^([a-zA-Z0-9_]){1,15}(?!_)(?=$|' + self.re.src_ZPCc + ')'
      );
    }
    if (self.re.twitter.test(tail)) {
      if (pos >= 2 && tail[pos - 2] === '@') {
        return false;
      }
      return tail.match(self.re.twitter)[0].length;
    }
    return 0;
  },
  normalize: function (match) {
    match.url = match.url.replace(/^@/, "");
  },
});

export default (text) => {
  return linkify.match(text);
};
