import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import _ from 'lodash';
import { Spin, Icon } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { chat } from '../../en.json';
import { IMG_CLOSE, IMG_SEND } from '../../utils/ImageUtils';

@inject("store")
@observer
class UserSmsSection extends React.Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.state = {
      messageText: ''
    }
  }

  componentDidMount() {
    const {
      store: {
        ProfileStore,
      },
    } = this.props;
    ProfileStore.fetchUserSmsHistory(ProfileStore.userId);
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      messageText: ''
    });
  }

  componentDidUpdate() {
    const {
      store: {
        ProfileStore: {
          userSmsData,
        },
      },
    } = this.props;
    if (toJS(userSmsData).length) {
      setTimeout(() => this.scrollToBottom(), 500);
    }
  }

  appendData = () => {
    const {
      store: {
        ProfileStore: {
          sendUserSms,
          userId
        }
      },
    } = this.props;
    const { messageText } = this.state;
    // Here we are checking if message text contains any value or not
    if (messageText.trim() !== '') {
      // If there is any message text available then we are sending message to the user's number in API call
      sendUserSms({ receiverId: userId, text: messageText });
      // Empty the textbox value after sending message text in API call
      this.setState({
        ...this.state,
        messageText: ''
      });
      // Here we are setting timeout for scrolling to bottom after fetching the data from API call
      setTimeout(() => this.scrollToBottom(), 500);
    }
  };

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleChange = (e) => {
    const messageText = e.target.value;
    // Here we are setting the data in state if admin types in input box
    this.setState({
      ...this.state,
      messageText
    });
  };

  handleKeyPress = (e) => {
    const {
      store: {
        ProfileStore: {
          sendUserSms,
          userId
        }
      },
    } = this.props;
    const { messageText } = this.state;
    // If admin press only enter then we need to initiate call for send sms api
    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (messageText.trim() !== '') {
        // Here we are making API call to send message to user's number
        sendUserSms({ receiverId: userId, text: messageText });
        // Empty the textbox value after sending message text in API call
        this.setState({
          ...this.state,
          messageText: ''
        });
      }
    }
  };

  handleClose = () => {
    const {
      store: {
        ProfileStore: {
          setUserSmsData
        },
        MessagesStore: {
          setIsSmsSectionVisible,
        },
      },
    } = this.props;
    // If admin clicks on close icon then we need to hide that modal section 
    setIsSmsSectionVisible(false);
    // Also we need to reset the data for user sms history
    setUserSmsData([]);
  };

  loadMore = async () => {
    const {
      store: {
        ProfileStore: {
          isSmsHistoryLoading,
          hasMore
        },
      },
    } = this.props;
    let prevScrollHeight = 1;
    if (!isSmsHistoryLoading && hasMore) {
      if (this.messagesEndRef.current && this.messagesEndRef.current.scrollHeight) {
        prevScrollHeight = this.messagesEndRef.current.scrollHeight;
      }
    }
  };

  renderSmsBubble = (message) => {
    const {
      store: {
        ProfileStore: { username }
      },
    } = this.props;

    const { body, direction, from, senderUserDetails, dateCreated } = message;
    const messageSendDate = moment(dateCreated).format('LT');
    const isDirectionInbound = direction === 'inbound';
    // Here by default we are taking "🤖 MarigoldBot" as a sender username
    let senderUsername = `🤖 MarigoldBot`;
    // If sender user details are present in api call then we are taking username from that response
    if (senderUserDetails) {
      senderUsername = senderUserDetails.username;
    } else if (isDirectionInbound) {
      // If direction is inbound then we need to set receiver's username or user's username with his phone number
      senderUsername = `${username} (${from})`;
    }

    return (<>
      <li className={isDirectionInbound ? "left-side" : "clearfix"}>
        <div className={`message-data ${isDirectionInbound ? '' : 'align-right'}`}>
          {isDirectionInbound ? (
            <>
              <span className="message-data-name">{senderUsername}</span>
              <span className="message-data-time">{messageSendDate}</span>
            </>)
            : (<>
              <span className="message-data-time" >{messageSendDate}</span> &nbsp; &nbsp;
              <span className="message-data-name" >{senderUsername}</span>
            </>)}

        </div>
        <div className={`message ${isDirectionInbound ? 'my-message' : 'other-message float-right'}`}>
          {body.trim()}
        </div>
      </li>
    </>)

  };

  reloadSmsHistory = () => {
    const {
      store: {
        ProfileStore,
      },
    } = this.props;
    ProfileStore.fetchUserSmsHistory(ProfileStore.userId);
  };

  render() {
    const {
      store: {
        ProfileStore: {
          username,
          isSmsHistoryLoading,
          isSmsSending,
          userSmsData,
          smsHistoryUpdatedTime
          // hasMore
        },
      },
    } = this.props;
    const { messageText } = this.state;
    let prevDate = "";

    return (
      <div className="threaded-sms-section">
        <div className="threaded-sms-header-wrapper clearfix">
          <div className="chat-about">
            <div className="chat-with">Sms Messages for {username}</div>
            {isSmsHistoryLoading ? <Spin /> :
              toJS(userSmsData).length ?
                <div className="chat-num-messages">({toJS(userSmsData).length} messages sent and received )</div>
                : <div className="chat-num-messages">has no messages</div>
            }
            {smsHistoryUpdatedTime ?
              (<div>
                Last updated at {moment(smsHistoryUpdatedTime).format('LTS')}

                <Icon
                  type="reload"
                  className="reload-icon"
                  onClick={() => this.reloadSmsHistory()}
                  spin={isSmsHistoryLoading}
                />
              </div>)
              : null}
          </div>
          <img className="threaded-sms-close-icon" src={IMG_CLOSE} onClick={() => this.handleClose()} alt="Close" />
        </div>

        <div className="threaded-sms-chat-history">
          {isSmsHistoryLoading ?
            (<div className='loading-spinner'>
              <Spin />
            </div>) :
            (<>
              <InfiniteScroll
                loadMore={this.loadMore}
                // hasMore={hasMore}
                isReverse
                useWindow={false}
              >
                <ul>
                  {_.uniqBy(userSmsData).map((message, index) => {
                    const currDate = moment(message.dateCreated).format('LL');
                    const messageBox = (
                      <React.Fragment key={index}>
                        {currDate !== prevDate &&
                          (
                            <div className="message-date">
                              <span>{currDate}</span>
                            </div>
                          )}

                        {this.renderSmsBubble(message)}
                      </React.Fragment>
                    );

                    prevDate = currDate;
                    return messageBox;
                  })}
                  {!userSmsData.length ? (
                    <div className="no-messages">{chat.noMessages}</div>
                  ) : null}
                </ul>
              </InfiniteScroll>
              <div ref={this.messagesEndRef} />
            </>)
          }

        </div>

        <div className="chat-message clearfix">
          <textarea
            value={messageText}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyPress}
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message..."
            rows="3" />

          {isSmsSending ? <Spin className='send-text-img ml-auto' /> :
            <img
              src={IMG_SEND}
              onClick={this.appendData}
              value="Append"
              className="send-text-img ml-auto"
              style={
                messageText.trim() !== '' ? { opacity: 1 } : { opacity: 0.4 }
              }
              alt="Send"
            />
          }

        </div>

      </div>
    );
  }
};

export default UserSmsSection;