import { Dropdown, Icon, Menu, Spin, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import React from "react";
import SVG from "react-inlinesvg";
import { sortBy } from "lodash";
import { withRouter } from "react-router-dom";

import alert from "../static/images/alert.svg";
import mix from "../static/images/mix.png";
import activity from "../static/images/activity.png";
import logo from "../static/images/logo.png";
import sites from "../static/sites.json";
import template from "../utils/templates";
import { IS_HP, IS_COMM, IS_STAGING, IS_ISLAND, IS_ILLINOIS, IS_NIDA } from "../utils/getEnvironment";
import { toolTips } from "../en.json";

@withRouter
@inject("store")
@observer
class Header extends React.Component {
  closeAll = () => {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: { reset },
        MessagesStore: {
          resetSelectedGroup,
          setSession,
          selectedGroup: { setName },
        },

        ReferralStore: { resetAll },
        EventStore: { resetAllEvents, setNewEvent },
        GroupListStore: {
          resetNewGroup,
          showFlags,
          resetSelectedGroup: resetGroup,
        },
        FormMessageStore: { changeformsVisible },
        DeletedMessagesStore: {
          setShowUnDeleted,
          setShowUnFlagged,
          setShowDeleted,
          setShowGroupDeleted,
          setShowGroupUnDeleted,
        },
      },
    } = this.props;
    reset();
    setName("");
    resetGroup();
    setSession(false);
    resetAll();
    setShowUnDeleted(false);
    resetNewGroup(false);
    setNewEvent(false);
    setShowDeleted(false);
    setShowUnFlagged(false);
    changeformsVisible(false);
    setShowGroupDeleted(false);
    setShowGroupUnDeleted(false);
    showFlags(false);
    if (view !== "events") {
      resetAllEvents();
    }
    resetSelectedGroup();
  };

  getSiteById = async ({ key }) => {
    const {
      match: {
        params: { view },
      },
      store: {
        SiteStore: { getSiteById },
      },
    } = this.props;
    const siteId = Number(key);
    getSiteById(siteId, view);
  };

  fetchAllHistory = () => {
    const {
      store: {
        HistoryStore: { fetchAllHistory, setShowAllActivities },
      },
    } = this.props;
    fetchAllHistory();
    setShowAllActivities(true);
  };

  switchChat = () => {
    const {
      store: {
        MessagesStore: { resetSelectedGroup },
      },
    } = this.props;

    resetSelectedGroup();
  };

  renderTerritoryNamesByEnv = () => {
    const {
      store: {
        SiteStore: { selectedSiteId, },
      },
    } = this.props;
    if (IS_COMM) {
      {/* Select option for All Community */ }
      return (
        <Menu.Item
          key={-2}
          className="menu-item"
          disabled={selectedSiteId === -2}
        >
          <div>{template.header.allCommunity}</div>
        </Menu.Item>);
    }
    if (IS_NIDA) {
      {/* Select option for All Research */ }
      return (
        <Menu.Item
          key={-2}
          className="menu-item"
          disabled={selectedSiteId === -2}
        >
          <div>All Research</div>
        </Menu.Item>);
    }
    return (<React.Fragment>
      {/* Select option for All Delaware */}
      <Menu.Item
        key={-2}
        className="menu-item"
        disabled={selectedSiteId === -2}
      >
        <div>{template.header.allDelaware}</div>
      </Menu.Item>
      {/* Select option for All Rhode Island */}
      <Menu.Item
        key={-3}
        className="menu-item"
        disabled={selectedSiteId === -3}
      >
        <div>{template.header.allRhodeIsland}</div>
      </Menu.Item>
      {/* Select option for All Massachusetts */}
      <Menu.Item
        key={-5}
        className="menu-item"
        disabled={selectedSiteId === -5}
      >
        <div>{template.header.allMassachusetts}</div>
      </Menu.Item>
      {/* Select option for All Illinois */}
      <Menu.Item
        key={-4}
        className="menu-item"
        disabled={selectedSiteId === -4}
      >
        <div>{template.header.allIllinois}</div>
      </Menu.Item>
    </React.Fragment>);
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        AuthStore: { username, type, logout },
        SiteStore: { allSites, allTerritories, selectedSiteId, selectedSiteName, loading },
        MessagesStore: {
          unreadMessages,
          setIsWaiting,
          selectedGroup: { name },
        },
        MemberListStore: { unreadWaitingMessages, newAccountsTotal },
        FlaggedMessagesStore: { unresolvedTotal },
        ReferralStore: {
          isDataSaved,
          setIntakeModalVisible,
          setReferralModalVisible,
          selfIntake,
          showReferralForm,
        },
      },
    } = this.props;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isNA = userType === "NOA" ? true : false;
    const isUser = userType === "user" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    const isFrontDesk = userType === "FD" ? true : false;
    const menu = (
      <div className="sites-container">
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <Menu onClick={this.getSiteById} className="menu">
            {/* Select option for All Territories */}
            <Menu.Item
              key={-1}
              className="menu-item"
              disabled={selectedSiteId === -1}
            >
              <div>{template.header.allTerritories}</div>
            </Menu.Item>
            {this.renderTerritoryNamesByEnv()}

            <Menu.Item
              key={0}
              className="menu-item"
              disabled={selectedSiteId === 0}
            >
              <div>{template.header.myGroups}</div>
            </Menu.Item>
            {sortBy([...allSites.values()], ["name"]).map((site) => (
              <Menu.Item
                key={site.id}
                className="menu-item"
                disabled={site.id === selectedSiteId}
              >
                <div>{site.name}</div>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
    );
    const alertNumber =
      isNaN(newAccountsTotal) || isNaN(unresolvedTotal + newAccountsTotal)
        ? null
        : isFrontDesk
          ? newAccountsTotal
          : unresolvedTotal + newAccountsTotal;
    const cantSwitchReferral = showReferralForm && !isDataSaved;
    const cantSwitchIntake = selfIntake && !isDataSaved;
    return (
      <header>
        <div className="my-navbar">
          <button className="my-nav-btn left" onClick={() => logout()}>
            Logout
          </button>
          <div className="innerlogo">
            {" "}
            {isAdmin || isSuperAdmin || isNA ? (
              <Link
                to={
                  cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/members"
                }
                className="navbar-brand"
              >
                <img src={logo} alt="Logo" />
              </Link>
            ) : (
              <div>
                <img src={logo} alt="Logo" />
              </div>
            )}
          </div>
          <button
            className="my-nav-btn right"
            disabled={!name}
            onClick={this.switchChat}
          >
            Switch Chat
          </button>

          <div className="d-flex">
            <ul className="navbar-nav flex-row">
              {isAdmin || isSuperAdmin ? (
                <li>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <Tooltip title={toolTips.sites} placement="leftBottom">
                      <div className="nav-item" style={{ cursor: "pointer" }}>
                        {selectedSiteName}
                        {selectedSiteName ? <Icon type="down" /> : null}
                      </div>
                    </Tooltip>
                  </Dropdown>
                </li>
              ) : isNA ? (
                <li>
                  <div className="nav-item">{selectedSiteName}</div>
                </li>
              ) : null}
              {!isFrontDesk ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/chat"
                  }
                  className="hide-content"
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " +
                      (view === "chat" ? "active " : "") +
                      (unreadMessages.length > 0 ? " unread-messages" : "")
                    }
                  >
                    <Tooltip title={`${toolTips.chats} ${selectedSiteName}`}>
                      {template.header.chats}
                    </Tooltip>
                  </li>
                </Link>
              ) : null}
              {isAdmin || isSuperAdmin || isNA ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake
                      ? "/CBRS"
                      : "/members"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " + (view === "members" ? "active " : "")
                    }
                  >
                    <Tooltip title={`${toolTips.members} ${selectedSiteName}`}>
                      {template.header.members}
                    </Tooltip>
                  </li>
                </Link>
              ) : null}

              {(IS_HP || IS_STAGING) && (isAdmin || isNA || isSuperAdmin) ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/events"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " + (view === "events" ? "active " : "")
                    }
                  >
                    Zoom Groups
                  </li>
                </Link>
              ) : null}

              {(IS_HP || IS_STAGING) && (isAdmin || isNA || isSuperAdmin) ? (
                <Link
                  to="/CBRS"
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={"nav-item " + (view === "CBRS" ? "active " : "")}
                  >
                    CBRS Referral
                  </li>
                </Link>
              ) : null}
              {isAdmin || isSuperAdmin ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/groups"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " + (view === "groups" ? "active " : "")
                    }
                  >
                    {template.header.groups}
                  </li>
                </Link>
              ) : null}

              {/* Comment the stats & insights tab for nida */}
              {isAdmin || isSuperAdmin || isNA ? (
                <Link to="/stats">
                  <li className={"nav-item " + (view === "stats" ? "active " : "")}>
                    {template.header.stats}
                  </li>
                </Link>
              ) : null}

              {/* {isAdmin || isSuperAdmin || isNA ? (
                <Link to="/insights">
                  <li className={"nav-item " + (view === "insights" ? "active " : "")}>
                    {template.header.insights}
                  </li>
                </Link>
              ) : null} */}

              {isAdmin || isSuperAdmin || isNA ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/invite"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " + (view === "invite" ? "active " : "")
                    }
                  >
                    {template.header.invite}
                  </li>
                </Link>
              ) : null}
              {/* <li className="nav-item">
                <Tooltip title="Groups currently taking new members">
                  <a
                    href="https://marigoldhealth-my.sharepoint.com/:x:/p/shrenik/Edt8JTBMK-JKu35av11px2sBJ9opH7cP7LBRz1hXbZSu3A?e=iuV8Jn"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-link"
                  >
                    Open Groups
                  </a>
                </Tooltip>
              </li> */}
              {isAdmin || isSuperAdmin
                ? sites.map((site) =>
                  site.name === selectedSiteName ? (
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      key={site.name}
                    >
                      <li className="nav-item">
                        <Tooltip
                          title={
                            IS_HP
                              ? toolTips.onboardingHighpoint
                              : toolTips.onboardingCleanslate
                          }
                        >
                          <a
                            href={site.onboarding}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="header-link"
                          >
                            {template.header.onboarding}
                          </a>
                        </Tooltip>
                      </li>
                      <li className="nav-item">
                        <Tooltip title={toolTips.schedule}>
                          <a
                            href={site.schedule}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="header-link"
                          >
                            {template.header.schedule}
                          </a>
                        </Tooltip>
                      </li>
                    </div>
                  ) : null
                )
                : null}
              {(isAdmin || isSuperAdmin) ? (
                <Link
                  to="flagNeeds"
                >
                  <li
                    className={
                      "nav-item " + (view === "flagNeeds" ? "active " : "")
                    }
                  >
                    <Tooltip title={`${toolTips.flagNeeds}`}>
                      {template.header.flagNeeds}
                    </Tooltip>
                  </li>
                </Link>
              ) : null}

              {/* Add inbox section for sms */}
              {(isAdmin || isSuperAdmin) ? (
                <Link to="/inbox">
                  <li className={"nav-item " + (view === "inbox" ? "active " : "")}>
                    {template.header.inbox}
                    <span className="beta-badge">BETA</span>
                  </li>
                </Link>
              ) : null}
            </ul>
          </div>
          <div className="right-nav">
            <ul className="navbar-nav flex-row align-items-center">
              {isAdmin || isSuperAdmin || isNA || isFrontDesk ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake ? "/CBRS" : "/alert"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li>
                    <Tooltip title={toolTips.alert}>
                      <div
                        className={
                          view === "alert"
                            ? "alert-container alert-container-active"
                            : "alert-container"
                        }
                      >
                        <small className={alertNumber ? "alert-count" : ""}>
                          {alertNumber ? alertNumber : null}
                        </small>
                        <SVG src={alert}></SVG>
                      </div>
                    </Tooltip>
                  </li>
                </Link>
              ) : null}
              <li className="nav-item hide-content">
                {template.header.hi} <span>{username}</span>
              </li>
              <li className="nav-item hide-content">
                <button
                  type="button"
                  className="btn-custom btn logout"
                  onClick={() => logout()}
                >
                  {template.header.logout}
                </button>
              </li>
              {isAdmin || isSuperAdmin ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake
                      ? "/CBRS"
                      : "/mixpanel"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " + (view === "mixpanel" ? "active " : "")
                    }
                  >
                    <Tooltip title={toolTips.mixpanel} placement="bottomRight">
                      <img
                        src={mix}
                        style={{ height: "25px", width: "25px" }}
                        alt="mixpanel report"
                      />
                    </Tooltip>
                  </li>
                </Link>
              ) : null}
              {isAdmin || isSuperAdmin || isNA ? (
                <Link
                  to={
                    cantSwitchReferral || cantSwitchIntake
                      ? "/CBRS"
                      : "/waiting"
                  }
                  onClick={() => {
                    if (cantSwitchReferral) {
                      setReferralModalVisible(true);
                    } else if (cantSwitchIntake) {
                      setIntakeModalVisible(true);
                    } else {
                      this.closeAll();
                      setIsWaiting(false);
                    }
                  }}
                >
                  <li
                    className={
                      "nav-item " +
                      (view === "waiting" ? "active " : "") +
                      (unreadWaitingMessages.length > 0
                        ? " unread-messages"
                        : "")
                    }
                  >
                    <Tooltip
                      title={`${toolTips.members} ${selectedSiteName}`}
                      placement="bottomRight"
                    >
                      <i
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="fa fa-quora"
                      ></i>
                    </Tooltip>
                  </li>
                </Link>
              ) : null}
              {(!IS_COMM && isAdmin) || isSuperAdmin ? (
                <li
                  className={
                    "nav-item " + (view === "activities" ? "active " : "")
                  }
                  onClick={() => this.fetchAllHistory()}
                >
                  <Tooltip
                    title={toolTips.allActivities}
                    placement="bottomRight"
                  >
                    <img
                      src={activity}
                      style={{ height: "25px", width: "22px" }}
                      alt="activity report"
                    />
                  </Tooltip>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
