import React from "react";
import close from "../../static/images/close.png";
import {
  Checkbox,
  Tabs,
  Radio,
  Spin,
  Menu,
  Dropdown,
  Select,
  Button,
} from "antd";
import { inject, observer } from "mobx-react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import InAppSurvey from "./InAppSurvey";
import info from "../../static/images/info.png";
import { CustomModal } from "../UI/CustomModal";
import { get } from "lodash";
import { getIntakeStatus } from "../../utils/getIntakeStatus";
import moment from "moment";
import { CBRS } from "../../en.json";
const TabPane = Tabs.TabPane;
const Option = Select.Option;

@inject("store")
@observer
class NewIntakeForm extends React.Component {
  handleCloseIntakeForm = (value) => {
    const {
      store: {
        ReferralStore: { setNewIntake },
      },
    } = this.props;
    setNewIntake(value);
  };

  selectForm = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    const selectedForm = e.target.value;
    setClientDetails("clientSelectedForm", selectedForm);
  };

  fetchAssignHistory = () => {
    const {
      store: {
        ReferralStore: { fetchAssignHistory, setAssignHistoryVisible },
      },
    } = this.props;

    fetchAssignHistory();
    setAssignHistoryVisible(true);
  };

  generateForm = () => {
    const {
      store: {
        ReferralStore: { generateForm, currentReferral },
      },
    } = this.props;
    generateForm(currentReferral);
  };

  loadMoreAssignHistory = () => {
    const {
      store: {
        ReferralStore: { fetchAssignHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchAssignHistory();
  };

  selfIntake() {
    const {
      store: {
        ReferralStore: { setSelfIntake },
      },
    } = this.props;
    setSelfIntake(true);
  }

  render() {
    const {
      store: {
        ReferralStore: {
          canBeGenerated,
          currentReferral,
          updatingStatus,
          currentStatus,
          hasInAppSurvey,
          targetUserId,
          fetchingReferralData,
          targetUsername,
          skipAssignHistory,
          totalAssignHistory,
          historyLoading,
          resetHistory,
          assignHistoryVisible,
          setAssignHistoryVisible,
          assignHistory,
        },
        ProfileStore: { HpUsers, getUserFromHpUsers },
        SiteStore: { getSiteNameFromAllSites },
        BroadcastStore: { createDm, dmLoading },
      },
    } = this.props;

    const statusMenu = [
      {
        status: "New Referral",
        key: 101,
      },
      {
        status: "Form Reviewed",
        key: 102,
      },
      {
        status: "Submitted, Pending Auth",
        key: 103,
      },
      {
        status: "Archive - Unable to contact",
        key: 104,
      },
      {
        status: "Archive - Pt denied",
        key: 105,
      },
      {
        status: "Archive - Other",
        key: 106,
      },
      {
        status: "Assigned",
        key: 107,
      },
      {
        status: "Approved",
        key: 108,
      },
    ];
    const referralStatusMenu = (
      <div>
        <Menu onClick={this.updateReferralStatus}>
          {statusMenu.map((item) => {
            return (
              <Menu.Item
                disabled={updatingStatus || item.key === currentStatus}
                key={item.key}
                icon={<UserOutlined />}
              >
                {item.status}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );

    return (
      <div>
        <CustomModal
          title="Assign History"
          visible={assignHistoryVisible}
          loading={historyLoading}
          loadMore={this.loadMoreAssignHistory}
          hasMore={skipAssignHistory < totalAssignHistory}
          onCancel={() => {
            resetHistory();
            setAssignHistoryVisible(false);
          }}
          data={assignHistory ? assignHistory : []}
        />
        <div className="referral-header">
          <div>{CBRS.selfReferral}</div>
          <div className="flag-actions-container">
            <button
              className="referral-form-button"
              disabled={dmLoading}
              onClick={() => createDm(targetUsername, targetUserId)}
            >
              {CBRS.msgClient}
            </button>
            <img
              src={close}
              onClick={() => this.handleCloseIntakeForm(false)}
              alt="Close"
            />
          </div>
        </div>
        <div className="referral-sub">
          {/* <div>
            <span className="sub">{CBRS.client}</span>{" "}
            <span className="main">
              {currentReferral.targetUserInfo.fullName}
            </span>
            <span className="sub"> {CBRS.nowActive}</span>
          </div> */}
          <div className="referral-info">
            <div>
              <div>
                <span className="sub">{CBRS.site} </span>
                <span className="main">
                  {getSiteNameFromAllSites(currentReferral.siteId)}
                </span>
              </div>
              <div>
                <span className="sub">{CBRS.username} </span>
                <span className="main">
                  {currentReferral.targetUserInfo.username}
                </span>
              </div>
              <div>
                <span className="sub">{CBRS.dobForm} </span>
                <span className="main">
                  {moment(
                    currentReferral.targetUserInfo.dob,
                    "YYYY-MM-DD"
                  ).format("MMMM Do YYYY")}
                </span>
              </div>
              <div>
                <span className="sub">{CBRS.phoneForm} </span>
                <span className="main">
                  {currentReferral.targetUserInfo.phoneNumber}
                </span>
              </div>
              <div>
                <span className="sub">
                  <Checkbox checked={!currentReferral.hasPhone}>
                    <span>{CBRS.noPhone}</span>
                  </Checkbox>
                </span>
              </div>
            </div>
            <div></div>
          </div>
          <div className="referral-status">
            <span className="refer-status">
              <div id="status-dropdown" style={{ position: "relative" }}>
                {CBRS.refStatus}
                <Dropdown
                  getPopupContainer={() =>
                    document.getElementById("status-dropdown")
                  }
                  overlay={referralStatusMenu}
                >
                  <Button>
                    {getIntakeStatus(currentStatus)} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </span>
            <span className="assign-status">
              <div id="assign-dropdown" style={{ position: "relative" }}>
                {CBRS.assignTo}
                <Select
                  showSearch
                  style={{ width: 200 }}
                  defaultValue={
                    currentReferral.assignedTo &&
                    currentReferral.assignedTo !== -1
                      ? getUserFromHpUsers(currentReferral.assignedTo)
                      : "Select Contact"
                  }
                  placeholder="Select HighPoint Contact"
                  optionFilterProp="children"
                  onChange={this.updateAssignTo}
                  filterOption={(input, option) =>
                    get(option, "props.children.props.children", [])
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={() =>
                    document.getElementById("assign-dropdown")
                  }
                >
                  {HpUsers.map((user) => (
                    <Option
                      key={user.id}
                      value={user.contactId}
                      className="menu-item"
                    >
                      <div>{user.contactName}</div>
                    </Option>
                  ))}
                </Select>
                <img
                  src={info}
                  className="info-icon"
                  alt="info"
                  onClick={() => {
                    this.fetchAssignHistory();
                  }}
                />
              </div>
            </span>
          </div>
          <div className="update-status">
            <div className="bold">{CBRS.updateStatusBold}</div>
            <div>{CBRS.updateStatus}</div>
          </div>
        </div>
        <div className="start-new-intake">
          <Tabs
            defaultActiveKey="1"
            tabBarStyle={{ border: 0 }}
            className="my-tabs-nav"
            onChange={() => {}}
          >
            <TabPane className="my-tabs-ink-bar" tab="Referral Form" key="1">
              {fetchingReferralData ? (
                <div className="saving-form">
                  <Spin />
                </div>
              ) : null}
              <div className="form-header">{CBRS.chooseToComplete}</div>
              <Radio.Group onChange={this.selectForm}>
                <Radio value={"RSN"}>{CBRS.rsn}</Radio>
                <Radio value={"RC"}>{CBRS.rc}</Radio>
              </Radio.Group>
            </TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="(In-app) Client Survey"
              key="2"
            >
              {hasInAppSurvey ? (
                <div className="rsn-form-container">
                  <div className="referral-forms-container">
                    <div className="form-header"> {CBRS.clientInfo} </div>
                    <InAppSurvey />
                  </div>
                </div>
              ) : null}
            </TabPane>
          </Tabs>
        </div>
        <div className="send-new-referral">
          <button
            disabled={canBeGenerated || fetchingReferralData}
            className="new-referral-button"
            onClick={() => this.generateForm()}
          >
            {CBRS.generate}
          </button>
        </div>
      </div>
    );
  }
}

export default NewIntakeForm;
