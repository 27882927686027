import AuthStore from "../stores/AuthStore";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import SecureLocalStorage from "./SecureLS";
import { somethingWrong } from "../en.json";

const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_SUBKEY: SUBKEY,
  REACT_APP_PUBNUB_REST_ENDPOINT: PUBNUB_REST_ENDPOINT,
} = process.env;
var mixpanel = require("mixpanel-browser");

let errorMessage = "";
let userId = null;
try {
  userId = SecureLocalStorage.get("USERID");
} catch {
  userId = localStorage.getItem("USERID");
}

const localStorageId = Number(userId);
errorMessage = `No record found for id '${localStorageId}'`;

const checkToken = (response, url, options) => {
  if (response.message == errorMessage) {
    AuthStore.logout();
  }
  Sentry.captureException(
    new Error(`Error on ${options.method} Request ${url}`)
  );
};
const fetch_retry = async (url, options, n) => {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      const data = await response.json();
      checkToken(data, url, options);
      throw data;
    } else {
      return response.json();
    }
  } catch (err) {
    if (n === 1) throw err;
    return await fetch_retry(url, options, n - 1);
  }
};

export default {
  /**
   *
   * @param {string} path Api endpoint to hit eg: 'users'
   * @param {number} id id of the model requested
   * @param {object} params an object containing key-value pair for query parameters
   */
  async getRequest(path, params = {}, id = null, isHardCodedUrls = false, hardCodedUrl = '') {
    let token = "";
    try {
      token = SecureLocalStorage.get("TOKEN");
    } catch {
      token = localStorage.getItem("TOKEN");
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    const option = {
      method: "GET",
      headers,
    };

    let url;
    let queryString = "";

    if (!_.isEmpty(params)) {
      queryString = "?";

      Object.keys(params).map((key, index) => {
        queryString += key + "=" + params[key] + "&";

        return params[key];
      });

      queryString = queryString.replace(/&\s*$/, "");
    }

    if (id) {
      url = API_URL + "/" + path + "/" + id;
    } else {
      url = API_URL + "/" + path;
    }

    if (isHardCodedUrls && hardCodedUrl) {
      url = `${hardCodedUrl}/${path}`;
    }

    url += queryString;

    // const request = new Request(url, option);
    const responseData = {
      success: false,
      error: true,
      data: [],
      meta: {
        total: 0,
        limit: 0,
        skip: 0,
      },
    };
    mixpanel.time_event(`Loading time on getRequest{${path}}`, { from: "WEB" });

    // await fetch(request)
    await fetch_retry(url, option, 2)
      .then((response) => {
        mixpanel.track(`Loading time on getRequest{${path}}`, { from: "WEB" });
        responseData.success = true;
        responseData.error = false;
        responseData.data = response.data ? response.data : response;
        responseData.meta = {
          total: response.total,
          limit: response.limit,
          skip: response.skip,
        };
      })
      .catch((error) => {
        if (!isHardCodedUrls) {
          responseData.data = { message: somethingWrong };
        }
      });

    return responseData;
  },


  /**
   *
   * @param {string} path Api endpoint to hit eg: 'users'
   * @param {object} params object containing request parameters
   */
  async postRequest(path, params, isHardCodedUrls = false, hardCodedUrl = '') {
    let token = "";
    try {
      token = SecureLocalStorage.get("TOKEN");
    } catch {
      token = localStorage.getItem("TOKEN");
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    const option = {
      method: "POST",
      headers,
      body: JSON.stringify(params),
    };

    let url = API_URL + "/" + path;
    if (isHardCodedUrls && hardCodedUrl) {
      url = `${hardCodedUrl}/${path}`;
    }
    // const request = new Request(url, option);
    let responseData = {
      success: false,
      error: true,
      data: [],
      errors: [],
    };
    mixpanel.time_event(`Loading time on postRequest{${path}}`, {
      from: "WEB",
    });
    await fetch_retry(url, option, 2)
      .then((response) => {
        mixpanel.track(`Loading time on postRequest{${path}}`, { from: "WEB" });
        responseData.success = true;
        responseData.error = false;
        responseData.data = response;
      })
      .catch(async (error) => {
        responseData.data = error.data || error;
        responseData.errors = error.errors;
      });
    return responseData;
  },

  /**
   *
   * @param {string} path Api endpoint to hit eg: 'users'
   * @param {number} id id of the model requested
   * @param {object} params object containing request parameters
   */
  async patchRequest(path, id, params) {
    let token = "";
    try {
      token = SecureLocalStorage.get("TOKEN");
    } catch {
      token = localStorage.getItem("TOKEN");
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    const option = {
      method: "PATCH",
      headers,
      body: JSON.stringify(params),
    };

    let url;
    if (id) {
      url = API_URL + "/" + path + "/" + id;
    } else {
      url = API_URL + "/" + path;
    }
    // const request = new Request(url, option);
    let responseData = {
      success: false,
      error: true,
      data: [],
    };

    mixpanel.time_event(`Loading time on patchRequest{${path}}`, {
      from: "WEB",
    });
    await fetch_retry(url, option, 2)
      .then((response) => {
        mixpanel.track(`Loading time on patchRequest{${path}}`, {
          from: "WEB",
        });
        responseData.success = true;
        responseData.error = false;
        responseData.data = response;
      })
      .catch(async (error) => {
        const msg = error.errors?.length ? error.errors[0]?.message : error.message ? error.message : somethingWrong;
        responseData.data = { message: msg };
      });
    return responseData;
  },

  /**
   *
   * @param {string} path Api endpoint to hit eg: 'users'
   * @param {number} id id of the model requested
   * @param {*} params object containing request parameters
   */
  async deleteRequest(path, id = null, params = {}) {
    let token = "";
    try {
      token = SecureLocalStorage.get("TOKEN");
    } catch {
      token = localStorage.getItem("TOKEN");
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    const option = {
      method: "DELETE",
      headers,
    };

    let url;
    let queryString = "";

    if (!_.isEmpty(params)) {
      queryString = "?";

      Object.keys(params).map((key, index) => {
        queryString += key + "=" + params[key] + "&";

        return params[key];
      });

      queryString = queryString.replace(/&\s*$/, "");
    }

    if (id) {
      url = API_URL + "/" + path + "/" + id;
    } else {
      url = API_URL + "/" + path;
    }
    url += queryString;

    // const request = new Request(url, option);
    let responseData = {
      success: false,
      error: true,
      data: [],
    };
    mixpanel.time_event(`Loading time on deleteRequest{${path}}`, {
      from: "WEB",
    });
    await fetch_retry(url, option, 2)
      .then((response) => {
        mixpanel.track(`Loading time on deleteRequest{${path}}`, {
          from: "WEB",
        });
        responseData.success = true;
        responseData.error = false;
        responseData.data = response;
      })
      .catch(async (error) => {
        responseData.data = { message: somethingWrong };
      });
    return responseData;
  },

  /**
   *
   * @param {string} path Api endpoint to hit eg: 'get-last-seen'
   * @param {object} params an object containing key-value pair for query parameters
   */
  async pubNubGetRequest(path, params) {
    const headers = {
      "Content-Type": "application/json",
    };

    const option = {
      method: "GET",
      headers,
    };

    let queryString = "";

    if (!_.isEmpty(params)) {
      queryString = "?";

      Object.keys(params).map((key, index) => {
        queryString += key + "=" + params[key] + "&";

        return params[key];
      });

      queryString = queryString.replace(/&\s*$/, "");
    }

    const url = PUBNUB_REST_ENDPOINT + SUBKEY + "/" + path + queryString;

    const request = new Request(url, option);
    const responseData = {
      success: false,
      error: true,
      data: [],
    };

    await fetch(request)
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        }
        throw response;
      })
      .then(async (response) => {
        responseData.success = true;
        responseData.error = false;
        responseData.data = response;
      })
      .catch(async (error) => {
        try {
          responseData.data = await error.json();
        } catch (error) {
          responseData.data = { message: somethingWrong };
        }
      });

    return responseData;
  },

  /**
   *
   * @param {string} path Api endpoint to hit eg: 'last-seen'
   * @param {object} params object containing request parameters
   */
  async pubNubPostRequest(path, params) {
    const headers = {
      "Content-Type": "application/json",
    };

    const option = {
      method: "POST",
      headers,
      body: JSON.stringify(params),
    };

    const url = PUBNUB_REST_ENDPOINT + SUBKEY + "/" + path;
    const request = new Request(url, option);
    let responseData = {
      success: false,
      error: true,
      data: [],
    };

    await fetch(request)
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        }
        throw response;
      })
      .then((response) => {
        responseData.success = true;
        responseData.error = false;
        responseData.data = response;
      })
      .catch(async (error) => {
        try {
          responseData.data = await error.json();
        } catch (error) {
          responseData.data = { message: somethingWrong };
        }
      });

    return responseData;
  },
};
