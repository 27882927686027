import { Spin, Tabs, Input } from "antd";
import _, { noop } from "lodash";
import { inject, observer } from "mobx-react";

import InfiniteScroll from "react-infinite-scroller";
import ListItem from "./ListItem";
import ModeratorTag from "./ModeratorTag";
import React from "react";
import { chat } from "../../en.json";

const TabPane = Tabs.TabPane;
const { Search } = Input;
@inject("store")
@observer
class LeftSide extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { channel },
          setActiveTab,
        },
      },
    } = this.props;

    if (channel) {
      const tab = _.startsWith(channel, "DIRECT_MESSAGE") ? "dm" : "group";
      setActiveTab(tab);
    }
  }

  handleClickDM = (id, channel, type, isBot, flag = 0) => {
    const {
      store: {
        ProfileStore: { setMemberProfileVisibility, changeSelectedAction },
      },
    } = this.props;
    this.props.changeChannel(id, channel, type, null, isBot, flag);
    changeSelectedAction("");
    setMemberProfileVisibility(false);
  };

  handleClickGroup = (id, name, type, isPaused, isIntersiteGroup, flag = 1) => {
    const {
      store: {
        MessagesStore: { isBroadcasting, selectedGroup },
        BroadcastStore: { toggleChannelStatus },
        ProfileStore: { setMemberProfileVisibility, changeSelectedAction },
      },
    } = this.props;
    if (selectedGroup.id === id) {
      return;
    }
    if (isBroadcasting) {
      toggleChannelStatus(id);
    } else {
      this.props.changeChannel(id, name, type, isPaused, null, flag, isIntersiteGroup);
    }
    changeSelectedAction("");
    setMemberProfileVisibility(false);
  };

  getDmNames = (users) => {
    const {
      store: {
        AuthStore: { username },
      },
    } = this.props;

    return _.split(users, ",")
      .filter((user) => user !== username)
      .map((name, index) => (
        <React.Fragment key={index}>
          {index !== 0 && ","}
          <span>{name}</span>
          <ModeratorTag username={name} />
        </React.Fragment>
      ));
  };

  handleTabChange = (activeKey) => {
    const {
      store: {
        BroadcastStore: { resetSelections },
        AuthStore: { type, changeUserOnlineStatus },
        MessagesStore: {
          resetSelectedGroup,
          setSession,
          selectedGroup: { setName },
          setActiveTab,
        },
        GroupListStore: { resetSelectedGroup: resetGroup },
      },
    } = this.props;
    if (type === "user" || type === "semi-moderator") {
      changeUserOnlineStatus();
    }
    setActiveTab(activeKey);
    setName("");
    resetSelectedGroup();
    resetSelections();
    setSession(false);
    resetGroup();
  };

  updateQueryString = (key, e) => {
    const {
      store: {
        MessagesStore: { updateQueryString },
      },
    } = this.props;
    updateQueryString(key, e.target.value);
  };

  // Here we are handling enter press on search box 
  handleEnterPressOnSearch = () => {
    const {
      store: {
        AuthStore: { fetchAllDmsForSuperAdmins },
      },
    } = this.props;
    // We are fetching DMs based on entered value
    fetchAllDmsForSuperAdmins();
  };

  loadMore = () => {
    const {
      store: {
        SiteStore: { allGroups, allGroupsSiteData, groupLoading },
      },
    } = this.props;
    if (groupLoading) {
      return;
    } else {
      allGroups({
        $skip: allGroupsSiteData.length,
      });
    }
  };

  render() {
    const {
      store: {
        AuthStore: { groups, type, dms, otherDms, allDmsLoading, loading },
        BroadcastStore: { resetSelections },
        SiteStore: {
          selectedSiteData,
          selectedSiteId,
          allGroupsSiteData,
          allGroupTotal,
          groupLoading,
        },
        MessagesStore: {
          activeTab,
          newMessage,
          hasUnreadGroupMessage,
          hasUnreadDirectMessage,
          selectedGroup,
          isBroadcasting,
          hasUnreadMessages,
          hasDeletedMessages,
          searchedOthersDmQuery,
          searchedDmQuery,
          searchedGroupQuery,
          groupQuery,
          dmQuery,
          othersDmQuery,
          setQuery,
        },
      },
    } = this.props;

    noop(newMessage);
    const siteId = selectedSiteId;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;

    const DMList = _.uniqBy(dms, "id").map((data, index) => {
      if (
        dmQuery &&
        searchedDmQuery &&
        !_.includes(data.name.toLowerCase(), searchedDmQuery.toLowerCase())
      ) {
        return null;
      } else
        return (
          <ListItem
            key={index}
            id={data.id}
            channel={data.name}
            isBot={data.isBot}
            name={this.getDmNames(data.name)}
            highlighted={selectedGroup.id === data.id}
            handleClick={() =>
              this.handleClickDM(data.id, data.name, userType, data.isBot)
            }
            unreadMessages={hasUnreadMessages(data.id)}
            deletedMessages={hasDeletedMessages(`DIRECT_MESSAGE_${data.id}`)}
          />
        );
    });

    const othersDMList = _.uniqBy(otherDms, "id").map((data, index) => {
      if (
        othersDmQuery &&
        searchedOthersDmQuery &&
        !_.includes(
          data.name.toLowerCase(),
          searchedOthersDmQuery.toLowerCase()
        )
      ) {
        return null;
      } else {
        return (
          <ListItem
            key={index}
            id={data.id}
            channel={data.name}
            name={this.getDmNames(data.name)}
            highlighted={selectedGroup.id === data.id}
            handleClick={() =>
              this.handleClickDM(data.id, data.name, userType, null, 2)
            }
            unreadMessages={hasUnreadMessages(data.id)}
            deletedMessages={hasDeletedMessages(`DIRECT_MESSAGE_${data.id}`)}
          />
        );
      }
    });

    const groupList = groups.map((data, index) => {
      const type = data.user_groups ? data.user_groups.type : userType;
      if (
        groupQuery &&
        searchedGroupQuery.toLowerCase() &&
        !_.includes(data.name.toLowerCase(), searchedGroupQuery.toLowerCase())
      ) {
        return null;
      } else {
        return (
          <ListItem
            key={index}
            id={data.id}
            name={data.name}
            isPaused={
              data.user_groups && data.user_groups.status === 3 ? true : false
            }
            type={type ? type : null}
            highlighted={selectedGroup.id === data.id}
            isIntersiteGroup={data.isIntersiteGroup}
            handleClick={this.handleClickGroup}
            unreadMessages={hasUnreadMessages(data.id)}
            deletedMessages={hasDeletedMessages(`GROUP_CHAT_${data.id}`)}
            showCheckbox={isBroadcasting}
          />
        );
      }
    });

    const selectedList = selectedSiteData.map((data, index) => {
      const type = data.user_groups ? data.user_groups.type : userType;
      if (
        groupQuery &&
        searchedGroupQuery &&
        !_.includes(data.name.toLowerCase(), searchedGroupQuery.toLowerCase())
      ) {
        return null;
      } else {
        return (
          <ListItem
            key={index}
            id={data.id}
            name={data.name}
            isPaused={
              data.user_groups && data.user_groups.status === 3 ? true : false
            }
            isIntersiteGroup={data.isIntersiteGroup}
            type={type ? type : null}
            highlighted={selectedGroup.id === data.id}
            handleClick={this.handleClickGroup}
            unreadMessages={hasUnreadMessages(data.id)}
            deletedMessages={hasDeletedMessages(`GROUP_CHAT_${data.id}`)}
            showCheckbox={isBroadcasting}
          />
        );
      }
    });

    const allGroupsList = (
      <div
        style={{
          height: "calc(100vh - 150px)",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          loadMore={this.loadMore}
          useWindow={false}
          hasMore={allGroupsSiteData.length < allGroupTotal && !groupLoading}
        >
          {allGroupsSiteData.map((data, index) => {
            const type = data.user_groups ? data.user_groups.type : userType;
            if (
              groupQuery &&
              searchedGroupQuery &&
              !_.includes(
                data.name.toLowerCase(),
                searchedGroupQuery.toLowerCase()
              )
            ) {
              return null;
            } else {
              return (
                <ListItem
                  key={index}
                  id={data.id}
                  name={data.name}
                  type={type ? type : null}
                  isIntersiteGroup={data.isIntersiteGroup}
                  highlighted={selectedGroup.id === data.id}
                  handleClick={this.handleClickGroup}
                  unreadMessages={hasUnreadMessages(data.id)}
                  deletedMessages={hasDeletedMessages(`GROUP_CHAT_${data.id}`)}
                  showCheckbox={isBroadcasting}
                />
              );
            }
          })}
          {groupLoading && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
    const toDisplayLength = () => {
      if (isAdmin || isSuperAdmin) {
        if (siteId === 0) return groups.length;
        else if (siteId === -1) {
          return allGroupsSiteData.length;
        } else return selectedSiteData.length;
      } else return groups.length;
    };
    const toDisplay = () => {
      if (isAdmin || isSuperAdmin) {
        if (siteId === 0) return groupList;
        else if (siteId === -1) {
          return allGroupsList;
        } else return selectedList;
      } else return groupList;
    };
    const groupsLength = toDisplayLength();
    return (
      <div className="group-chat">
        <Tabs onChange={this.handleTabChange} activeKey={activeTab}>
          <TabPane
            tab={
              <div
                className={
                  hasUnreadGroupMessage
                    ? "unread-messages alert-tab"
                    : "alert-tab"
                }
              >
                {chat.groupChats}{" "}
              </div>
            }
            key="group"
          >
            <div className="list-group">
              {groupsLength ? (
                <div>
                  <Search
                    value={searchedGroupQuery}
                    onChange={(e) => {
                      this.updateQueryString("searchedGroupQuery", e);
                    }}
                    size="large"
                    placeholder="input search text"
                    onSearch={() => {
                      setQuery("groupQuery", true);
                    }}
                  />
                </div>
              ) : null}
              {toDisplay()}
              {loading ? (
                <div className="form-loading">
                  <Spin />
                </div>
              ) : groupsLength ? null : (
                <div className="no-alerts">No Group Chats yet.</div>
              )}
            </div>
          </TabPane>
          {isAdmin || isSuperAdmin ? (
            <TabPane
              tab={<div className="alert-tab">{chat.broadcast}</div>}
              key="broadcast"
            >
              <div
                className="d-flex"
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  flexDirection: "row-reverse",
                }}
              >
                <div onClick={resetSelections} className="broadcast-close">
                  <i className="fa fa-trash" />
                </div>
              </div>
              <ul className="list-group">
                {toDisplay()}
                {loading ? (
                  <div className="form-loading">
                    <Spin />
                  </div>
                ) : null}
              </ul>
            </TabPane>
          ) : null}
          <TabPane
            tab={
              <div
                className={
                  hasUnreadDirectMessage
                    ? "unread-messages alert-tab"
                    : "alert-tab"
                }
              >
                {chat.messages}{" "}
              </div>
            }
            key="dm"
          >
            {dms.length ? (
              <div>
                <Search
                  size="large"
                  onChange={(e) => {
                    this.updateQueryString("searchedDmQuery", e);
                  }}
                  value={searchedDmQuery}
                  placeholder="input search text"
                  onSearch={() => {
                    setQuery("dmQuery", true);
                  }}
                />
              </div>
            ) : null}
            {DMList}
            {loading ? (
              <div className="form-loading">
                <Spin />
              </div>
            ) : dms.length ? null : (
              <div className="no-alerts">No Direct Messages yet.</div>
            )}
          </TabPane>
          {isSuperAdmin ? (
            <TabPane
              tab={
                <div
                  className={
                    hasUnreadDirectMessage
                      ? "unread-messages alert-tab"
                      : "alert-tab"
                  }
                >
                  {chat.others}{" "}
                </div>
              }
              key="other-dms"
            >
              <div>
                <Search
                  size="large"
                  value={searchedOthersDmQuery}
                  placeholder="input search text"
                  onChange={(e) => {
                    this.updateQueryString("searchedOthersDmQuery", e);
                  }}
                  onPressEnter={this.handleEnterPressOnSearch}
                  onSearch={() => {
                    setQuery("othersDmQuery", true);
                  }}
                />
              </div>
              {othersDMList && othersDMList.length ?
                othersDMList
                : <div className="other-dms-not-found">Please search for the username whose DM you are looking for</div>}
              {allDmsLoading ? (
                <div className="form-loading">
                  <Spin />
                </div>
              ) : null}
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}
export default LeftSide;
