import Option from "./Option";
import { types } from "mobx-state-tree";

const FormQuestion = types.model("FormQuestion", {
  id: types.identifierNumber,
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  options: types.array(Option),
  formId: types.maybe(types.number),
  questionTitle: types.maybe(types.string),
  type: types.maybe(types.string)
});

export default FormQuestion;
