import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SimpleLineChart = (props) => {
    const { width = '100%', data = [], isBothLinesRequired = false, firstDataKey = '', firstDataKeyLabel = '', secondDataKey = '', secondDataKeyLabel = '' } = props;

    const CustomizedLabel = (props) => {
        const { x, y, stroke, value } = props;
        return (
            <text x={x} y={y} dy={-10} fill={stroke} fontWeight="bold" fontSize={20} textAnchor="middle">
                {value}
            </text>
        );
    };

    return (
        <>
            <ResponsiveContainer width={width} height={325}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 80,
                        right: 30,
                        left: 20,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone"
                        dataKey={firstDataKey}
                        name={firstDataKeyLabel}
                        stroke="#8884d8"
                        label={<CustomizedLabel />}
                        activeDot={{ r: 8 }} />

                    {isBothLinesRequired &&
                        <Line type="monotone"
                            dataKey={secondDataKey}
                            name={secondDataKeyLabel}
                            stroke="#82ca9d"
                            strokeDasharray="5 5"
                            label={<CustomizedLabel />}
                            activeDot={{ r: 8 }}
                        />
                    }
                </LineChart>
            </ResponsiveContainer>
        </>
    )
};

export default SimpleLineChart;