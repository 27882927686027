import React from 'react';
import { Button, Col, Row, Spin, notification } from 'antd';
import { inject, observer } from 'mobx-react';
import { userProfile, tasks as tasksLabel } from '../../en.json';
import SingleTask from '../UI/SingleTask';
import moment from 'moment';
import { TASK_STATUS } from '../../constants/GlobalConstant';
import { TaskInput } from '../UI/TaskInput';
import { IMG_DOWN_ARROW, IMG_UP_ARROW } from '../../utils/ImageUtils';
import { CheckOutlined } from '@ant-design/icons';

@inject("store")
@observer
class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWellnessPlanEdit: false,
      showAddTaskModal: false,
      taskDescription: '',
      dueAt: null,
      assignedPeerId: null,
      editTaskId: null,
      showCompletedSection: false,
    };
  }

  async componentDidMount() {
    const {
      store: {
        ProfileStore,
        TaskStore,
      }
    } = this.props;
    // Fetch wellness plan by user when admin clicks on any user profile
    await TaskStore.fetchTask(ProfileStore.userId);
  };

  componentWillUnmount() {
    this.setState({
      isWellnessPlanEdit: false,
    });
  };

  onSelectAssignedPeer = (value) => {
    this.setState({ assignedPeerId: value })
  }

  onChangeTaskDescription = (event) => {
    this.setState({ taskDescription: event.target.value })
  }

  onChangeDate = (e) => {
    this.setState({ dueAt: e.target.value })
  }

  onSubmit = async () => {
    const { store: {
      ProfileStore: { userId },
      TaskStore: { addTask },
    } } = this.props;
    const { assignedPeerId, dueAt, taskDescription } = this.state

    if (!taskDescription) {
      alert(tasksLabel.descriptionRequired);
      return
    }

    const dueDate = dueAt ? moment(dueAt).format('YYYY-MM-DD') : null
    const dataToSave = {
      description: taskDescription,
      assignedToUserId: assignedPeerId,
      userId,
      dueAt: dueDate
    }
    await addTask(dataToSave)
    this.openNotification(tasksLabel.taskAdded)
    this.resetState()
  }

  onUpdate = async (taskId) => {
    const { store: {
      TaskStore: { updateTask },
    } } = this.props;
    const { assignedPeerId, dueAt, taskDescription } = this.state

    if (!taskDescription) {
      alert(tasksLabel.descriptionRequired);
      return
    }

    const dueDate = dueAt ? moment(dueAt).format('YYYY-MM-DD') : null
    const dataToUpdate = {
      taskId,
      description: taskDescription,
      assignedToUserId: assignedPeerId,
      dueAt: dueDate
    }
    await updateTask(dataToUpdate)
    this.openNotification(tasksLabel.taskUpdated)
    this.resetState()
  }

  openNotification = (description) => {
    notification.open({
      message: 'Success',
      description,
        icon: <CheckOutlined style={{ color: 'green' }} />,
    });
  };

  resetState = () => {
    this.setState({
      assignedPeerId: null,
      taskDescription: '',
      userId: null,
      dueAt: null,
      showAddTaskModal: false,
      editTaskId: null
    })
  }

  onComplete = async (taskId) => {
    const {
      store: {
        TaskStore: { updateTask },
      },
    } = this.props;

    const dataToUpdate = {
      taskId,
      status: TASK_STATUS.COMPLETED
    }
    await updateTask(dataToUpdate)
    this.openNotification(tasksLabel.taskCompleted)
  }

  onChangeEditTask = (task) => {
    this.setState({
      editTaskId: task.id,
      taskDescription: task.description,
      dueAt: task.dueAt ? moment(task.dueAt).format('YYYY-MM-DD') : null,
      assignedPeerId: task.assignedToUserId,
      showAddTaskModal: false,
    })
  }

  onAddTask = () => {
    this.setState({
      showAddTaskModal: true,
      assignedPeerId: null,
      taskDescription: '',
      userId: null,
      dueAt: null,
      editTaskId: null
    })
  }

  renderTasks = () => {
    const {
      store: {
        MemberListStore: { adminUsers },
        TaskStore: { tasks, completeTaskLoading, updateTaskLoading }
      },
    } = this.props;

    const { editTaskId, assignedPeerId, taskDescription, dueAt, showCompletedSection } = this.state;
    const inProgressTasks = tasks.filter(t => t.status === TASK_STATUS.IN_PROGRESS)
    const completedTasks = tasks.filter(t => t.status === TASK_STATUS.COMPLETED)

    return (
      <React.Fragment>
        {/** In progress */}
        <div style={{ marginTop: 30 }}>
          <label className="task-label">{tasksLabel.openTasks}</label>
          {inProgressTasks.length ?
            inProgressTasks.map((t, k) => {
              return <SingleTask
                task={t}
                key={k}
                confirmLoading={completeTaskLoading}
                editTaskId={editTaskId}
                assignedPeerId={assignedPeerId}
                adminUsers={adminUsers}
                taskDescription={taskDescription}
                dueAt={dueAt}
                onChangeTaskDescription={(event) => this.onChangeTaskDescription(event)}
                onChangeDate={this.onChangeDate}
                onComplete={(task) => this.onComplete(task.id)}
                onChangeEditTask={(task) => this.onChangeEditTask(task)}
                onUpdate={(task) => this.onUpdate(task.id)}
                onCancel={() => this.resetState()}
                onSelectAssignedPeer={(value) => this.onSelectAssignedPeer(value)}
                updateTaskLoading={updateTaskLoading}
              />
            }) :
            <Row>
              <Col span={24} className={"no-tasks"}>
                <p>{tasksLabel.noOpenTasks}</p>
              </Col>
            </Row>
          }
        </div>

        {/** Completed */}
        <div>
          <Row>
            <Col span={12}>
              <label className="task-label">{tasksLabel.completedTasks}</label>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {showCompletedSection ?
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({ showCompletedSection: false })}
                >
                  <img
                    src={IMG_UP_ARROW}
                    className="dropdown-arrow"
                    onClick={this.editPress}
                    alt="Up"
                  />
                </span>
                :
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({ showCompletedSection: true })}
                >
                  <img
                    src={IMG_DOWN_ARROW}
                    className="dropdown-arrow"
                    alt="Down"
                  />
                </span>
              }
            </Col>
          </Row>
          {showCompletedSection && (completedTasks.length ?
            completedTasks.map((t, k) => {
              return <SingleTask
                task={t}
                key={k}
              />
            }) :
            <Row>
              <Col span={24} className={"no-tasks"}>
                <p>{tasksLabel.noCompletedTasks}</p>
              </Col>
            </Row>)
          }
        </div>
      </React.Fragment>)
  };

  render() {
    const {
      store: {
        MemberListStore: { adminUsers },
        TaskStore: { addTaskLoading, fetchTaskLoading },
      },
    } = this.props;

    const { showAddTaskModal, assignedPeerId, dueAt, taskDescription } = this.state

    return (
      <React.Fragment>
        <div className="profile-edit" style={{ marginBottom: 0 }}>
          <div className="task-label">{userProfile.tasks}</div>
          {!showAddTaskModal && <Button
            className="download-button"
            type="primary"
            size="large"
            style={{ width: "145px" }}
            onClick={() => this.onAddTask()}
          >
            {tasksLabel.addTasks}
          </Button>}
        </div>

        {/** Insert task component start */}
        {showAddTaskModal && <TaskInput
          taskDescription={taskDescription}
          adminUsers={adminUsers}
          assignedPeerId={assignedPeerId}
          dueAt={dueAt}
          addTaskLoading={addTaskLoading}
          onChangeTaskDescription={(event) => this.onChangeTaskDescription(event)}
          onSelectAssignedPeer={(value) => this.onSelectAssignedPeer(value)}
          onChangeDate={this.onChangeDate}
          onCancel={() => this.setState({ showAddTaskModal: false })}
          onSubmit={this.onSubmit}
          addTask={true}
        />}
        {/** Insert task component end */}

        <div className='task-root-container' style={{ marginBottom: 30 }}>
          {fetchTaskLoading ?
            <div className="tasks-loader">
              <Spin />
            </div>
            : this.renderTasks()}
        </div>
      </React.Fragment>
    );
  }
};

export default Task;