import { Button, Icon, Popover, Tag } from "antd";
import { chat, user } from "../../en.json";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import ModeratorTag from "./ModeratorTag";
import React from "react";
import flag from "../../static/images/flag.png";
import enrolled from "../../static/images/enrolled.png";
import heart from "../../static/images/heart.png";
import hug from "../../static/images/hug.png";
import lol from "../../static/images/lol.png";
import bulb from "../../static/images/bulb.png";
import moment from "moment";
import FormModerator from "./FormModerator";
import Banner from "./Banner";
import FormUser from "./FormUser";
import Linkify from "react-linkify";
import { reactions } from "../../en.json";
import { getEmoji } from "../../utils/getEmoji";
import LinkifyMatchDecorator from "../../utils/LinkifyMatchDecorator";
import { CustomBanner } from "../UI/CustomBanner";
import { IS_CS } from "../../utils/getEnvironment";
import "../../styles/style.scss";
import { FlagOptionsModal } from "../UI/FlagOptionsModal";
import UserBadges from "../UI/UserBadges";
import orangeFlag from "../../static/images/orangeFlag.png";
import redFlag from "../../static/images/redFlag.png";
import blueFlag from "../../static/images/blueFlag.png";
import CustomFormModal from "../UI/CustomFormModal";

const badgeViewAllowedUserTypes = ["NOA", "SA", "moderator"]
@inject("store")
@observer
class MessageBubble extends React.Component {
  constructor() {
    super();
    this.state = {
      formListVisible: false
    };
  }

  openProfile = async () => {
    const {
      message: {
        entry: { userId },
      },
      store: {
        ProfileStore,
        MessagesStore: { setIsWaiting },
        MemberListStore,
        NotificationStore,
      },
    } = this.props;

    ProfileStore.reset();
    ProfileStore.setLoading(true);
    const member = await MemberListStore.getMemberDetails(userId);
    if (member) {
      ProfileStore.setMemberData(member);
      setIsWaiting(false);
      ProfileStore.changeSelectedAction("");
      ProfileStore.setMemberProfileVisibility(true);
      ProfileStore.toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
    ProfileStore.setLoading(false);
  };

  onSubmitFlagReaction = async (data) => {
    const { message, reactionId, typeOfNeed, description } = data;
    const {
      store: {
        MessagesStore: { closeFlagOptionPopup },

      },
    } = this.props;

    if (message) {
      message.saveReaction(reactionId, typeOfNeed, description);
    }

    await closeFlagOptionPopup();
  }

  onCloseFlagReactionModal = () => {
    const {
      store: {
        MessagesStore: { closeFlagOptionPopup },
      },
    } = this.props;

    closeFlagOptionPopup();
  }

  reactToMessage = (reactionId, isUnflag) => {
    const { message } = this.props;
    const {
      store: {
        MessagesStore: { showFlagOptionPopup },
      },
    } = this.props;

    if (!isUnflag && (reactionId === 7 || reactionId === 8)) {
      showFlagOptionPopup(message, reactionId);
    }
    else {
      message.saveReaction(reactionId);
    }
  };

  getFormsList = async (e) => {
    try {
      const {
        message,
        store: {
          FormMessageStore: { fetchAllFormsInListing },
          ProfileStore: { setUserBasicDetails, fetchFullnameByUsername }
        }
      } = this.props;
      const { sender, userId } = message.entry;
      fetchAllFormsInListing();
      let data = await fetchFullnameByUsername(sender)
      let fullname = sender
      if (data.length) {
        fullname = data[0].fullName
      }
      setUserBasicDetails(sender, userId, fullname)
      this.changeFormsListVisible(true);
    } catch (e) {
      alert(e)
    }
  };

  loadMoreFormsListing = () => {
    const {
      store: {
        FormMessageStore: { loading, fetchAllFormsInListing }
      },
    } = this.props;
    if (loading) return;
    fetchAllFormsInListing();
  };

  changeFormsListVisible = (value) => {
    this.setState({
      formListVisible: value,
    });
  };

  renderMessage = (message, isDeleted) => {
    const {
      store: {
        AuthStore: { type: userType },
      },
    } = this.props;
    const { type } = message.entry;
    const typeAdd = type === "add";
    const typeCustomBanner = type === "customBanner";
    const typeForm = type === "form";

    let messageContent;
    if (type === "text") {
      messageContent = (
        <Linkify matchDecorator={LinkifyMatchDecorator}>
          <pre>{message.entry.text.trim()}</pre>
        </Linkify>
      );
    } else if (typeAdd) {
      return message.entry.groupId ? (
        <Banner groupId={message.entry.groupId} />
      ) : null;
    } else if (typeCustomBanner) {
      return (
        <CustomBanner
          emojiName={message.entry.emojiName}
          messageText={message.entry.message.trim()}
        />
      );
    } else if (typeForm) {
      if (userType === "moderator") {
        return (
          <FormModerator
            timetoken={message.timetoken}
            formId={message.entry.formId}
          />
        );
      } else {
        return (
          <FormUser
            timetoken={message.timetoken}
            formId={message.entry.formId}
          />
        );
      }
    } else {
      messageContent = (
        <img className="img-message" src={message.entry.imgUrl} alt="" />
      );
    }

    return (
      <div className={"msgcontainer" + (isDeleted ? " delete" : "")}>
        {messageContent}
      </div>
    );
  };

  reactions = (group) => {
    const {
      message,
      message: { hasReacted },
      store: {
        NotificationStore,
        AuthStore: { type },
      },
    } = this.props;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    // show only flag & delete button if intersite group is available
    const isIntersiteGroup = group && group.isIntersiteGroup;

    return (
      <div className="reactions-container">
        {isIntersiteGroup ? null : (
          <>
            <div className="reaction-row">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("like") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(1)}
              >
                <img src={heart} alt="likes" className="reaction" />
                <span className="reaction-name">Like</span>
              </div>
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("hug") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(4)}
              >
                <img src={hug} alt="Support" className="reaction" />
                <span className="reaction-name">Support</span>
              </div>
            </div>
            <div className="reaction-row">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("lol") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(5)}
              >
                <img src={lol} alt="LOL" className="reaction" />
                <span className="reaction-name">LOL</span>
              </div>
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("bulb") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(6)}
              >
                <img src={bulb} alt="Inspiring" className="reaction" />
                <span className="reaction-name">Inspiring</span>
              </div>
            </div>
          </>
        )}
        <div className="flag-delete-container">
          {/* For admin flag and user flag */}
          {isAdmin || isSuperAdmin ? (
            <>
              <div className="reaction-row reaction-row-flag">
                <div
                  className={
                    "message-reactions clickable" +
                    (hasReacted("redFlag") ? " selected" : "")
                  }
                  onClick={() => this.reactToMessage(7, hasReacted("redFlag"))}
                >
                  <img src={redFlag} alt="flags" className="reaction" />
                  <span className="reaction-name">Flag</span>
                </div>
                <div
                  className={
                    "message-reactions clickable" +
                    (hasReacted("blueFlag") ? " selected" : "")
                  }
                  onClick={() => this.reactToMessage(8, hasReacted("blueFlag"))}
                >
                  <img src={blueFlag} alt="flags" className="reaction" />
                  <span className="reaction-name">Flag</span>
                </div>
              </div>
            </>
          ) :
            <div className="reaction-row reaction-row-flag">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("flag") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(2, hasReacted("flag"))}
              >
                <img src={orangeFlag} alt="flags" className="reaction" />
                <span className="reaction-name">Flag</span>
              </div>
            </div>
          }
          {isAdmin || isSuperAdmin ? (
            <Button
              type="primary"
              className="fillform-button"
              disabled={NotificationStore.active}
              onClick={this.getFormsList}
            >
              {chat.fillForm}
            </Button>
          ) : null}
          {isAdmin || isSuperAdmin ? (
            <Button
              type="danger"
              disabled={NotificationStore.active}
              onClick={() =>
                NotificationStore.setConfirm(
                  () => this.reactToMessage(3),
                  message.isDeleted
                    ? reactions.restoreNotification
                    : reactions.deleteNotification
                )
              }
            >
              {message.isDeleted ? chat.restore : chat.delete}
            </Button>
          ) : null}
        </div>
      </div>
    );
  };
  getUserType = (username) => {
    const {
      groupChat,
      groupId,
      store: { GroupStore },
      message,
    } = this.props;

    let type = null;
    if (groupChat) {
      type = GroupStore.getUserType(groupId, username);
    } else {
      return message.entry.userType;
    }
    return type;
  };

  renderBadge(badgeType) {
    return (<>
      {badgeType ? <UserBadges className="chat-user-badge" badgeType={badgeType} /> : null}
    </>);
  };

  viewReaction = (group) => {
    if (!group) return null

    return (<Popover content={this.reactions(group)} trigger="hover">
      <Icon type="more" />
    </Popover>);
  };

  render() {
    const {
      groupId,
      message,
      isWaiting,
      isForFlagNeeds,
      store: {
        GroupStore: { getColor, getFlair, getEnrolledUserColor, getBadgeType, getBadgeColor },
        AuthStore: { type: userType },
        MessagesStore: { selectedGroup, isShowFlagOptionModal, flagReactionId, messageObj },
        FormMessageStore: { formsListData, loading, skipFormsInListing, totalFormsInListing },
        ProfileStore: { setUserBasicDetails }
      },
    } = this.props;

    const { type, sender, userId } = message.entry;
    const enrolledColor = getEnrolledUserColor(userId);
    const userBadgeType = getBadgeType(userId);
    const badgeColor = getBadgeColor(userId)
    const likesCount = message.getReactionsCount("like");
    const flagsCount = message.getReactionsCount("flag");
    const redflagsCount = message.getReactionsCount("redFlag");
    const blueflagsCount = message.getReactionsCount("blueFlag");
    const supportCount = message.getReactionsCount("hug");
    const lolCount = message.getReactionsCount("lol");
    const inspireCount = message.getReactionsCount("bulb");
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    const isNA = userType === "NOA" ? true : false;
    const userFlair = getFlair(groupId, userId);
    const flair = getEmoji(userFlair);
    const badgeType = this.getUserType(sender);
    const isImg = type === "img";
    const isGif = type === "gif";
    const isForm = type === "form";
    const isAudio = type === "audio";
    const isVideo = type === "video";
    const isDoc = type === "document";
    const isCustomBanner = type === "customBanner";
    const isAdd = type === "add";
    const validType = isImg || isGif || isForm || isCustomBanner || isAdd;
    return (type === "text" && message.entry.text !== "") || validType ? (
      <>
        <div
          id="display-data"
          className={
            selectedGroup.flaggedMessageTimetoken === message.timetoken
              ? "lightblue-background"
              : ""
          }
        >
          <div className="d-flex align-items-center">
            <span
              className="badge badge-pill user-badge"
              style={{
                backgroundColor: getColor(groupId, sender),
              }}
            />
            <span className="chat-username">
              {groupId === 105 && IS_CS ? null : (
                <small
                  style={{
                    color: getColor(groupId, sender),
                  }}
                  className="username"
                  onClick={
                    isAdmin || isSuperAdmin || isNA ? this.openProfile : null
                  }
                >
                  {sender}
                  {badgeViewAllowedUserTypes.includes(userType) ? this.renderBadge(userBadgeType) : ""}
                </small>
              )}
              <span className="user-flair">{flair}</span>
              <ModeratorTag badgeType={badgeType} />
            </span>
          </div>

          <div className="d-flex chatText">
            {this.renderMessage(message, message.isDeleted)}
            <div className="reaction-container">
              {/* <div>
                {sender === AuthStore.username &&
                  (message.timetoken >= groupLastSeen ||
                  groupLastSeen === "99999999999999999" ? (
                    <Icon type="check" className="last-seen-icon" />
                  ) : (
                    <Icon type="eye" className="last-seen-icon" />
                  ))}
              </div> */}
              {(isWaiting || isForm || isCustomBanner || isShowFlagOptionModal || isForFlagNeeds) ? null : (
                <React.Fragment> {this.viewReaction(selectedGroup)}</React.Fragment>
              )}
              <br />
              <small>
                {moment.unix(parseInt(message.timetoken) / 10000000).format("LT")}
              </small>
            </div>

          </div>
          {isWaiting || isForm || isCustomBanner ? null : (
            <div className="message-reactions-count">
              {likesCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={heart}
                        alt="likes"
                        className="message-reactions"
                      />
                    </span>
                    <span>{likesCount} </span>
                  </div>
                </div>
              )}{" "}
              {flagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={orangeFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{flagsCount} </span>
                  </div>
                </div>
              )}
              {redflagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={redFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{redflagsCount} </span>
                  </div>
                </div>
              )}
              {blueflagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={blueFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{blueflagsCount} </span>
                  </div>
                </div>
              )}
              {supportCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={hug}
                        alt="Support"
                        className="message-reactions"
                      />
                    </span>
                    <span>{supportCount} </span>
                  </div>
                </div>
              )}
              {lolCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={lol} alt="LOL" className="message-reactions" />
                    </span>
                    <span>{lolCount} </span>
                  </div>
                </div>
              )}
              {inspireCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={bulb}
                        alt="Inspire"
                        className="message-reactions"
                      />
                    </span>
                    <span>{inspireCount} </span>
                  </div>
                </div>
              )}
            </div>
          )}
          {isShowFlagOptionModal &&
            <FlagOptionsModal
              onOk={this.onSubmitFlagReaction}
              visible={isShowFlagOptionModal}
              flagReactionId={flagReactionId}
              messageObj={messageObj}
              onClose={this.onCloseFlagReactionModal}
            />
          }
        </div>
        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />
      </>
    ) : isAudio || isVideo || isDoc ? (
      <>
        <div id="display-data">
          <div className="d-flex align-items-center">
            <span
              className="badge badge-pill user-badge"
              style={{
                backgroundColor: getColor(groupId, sender),
              }}
            />
            <span className="chat-username">
              {groupId === 105 && IS_CS ? null : (
                <small
                  style={{
                    color: getColor(groupId, sender),
                  }}
                  className="username"
                  onClick={isAdmin || isSuperAdmin ? this.openProfile : null}
                >
                  {sender} {badgeViewAllowedUserTypes.includes(userType) ? this.renderBadge(userBadgeType) : ""}
                </small>
              )}
              <span className="user-flair">{flair}</span>
              <ModeratorTag badgeType={badgeType} />
            </span>
          </div>
          <div className="d-flex chatText">
            <div className="msgcontainer not-supported">
              Message type not Supported Yet.
            </div>
          </div>
        </div>
        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />
      </>
    ) : null;

  }
}

export default MessageBubble;