import { types } from "mobx-state-tree";

const DM = types.model("DM", {
  id: types.number,
  name: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  isBot: types.maybeNull(types.boolean),
  dm_channel_users: types.frozen({
    addedBy: types.maybeNull(types.number),
    channelId: types.maybe(types.number),
    userId: types.maybe(types.number),
    lastSeenTimetoken: types.string,
    createdAt: types.string,
    addedByUsername: types.maybeNull(types.string),
    updatedAt: types.string,
  }),
});
export default DM;
