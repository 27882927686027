import { Icon, Spin } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
@inject("store")
@observer
class FormUser extends React.Component {
  goPress = (formId, timetoken, name) => {
    const {
      store: {
        FormMessageStore: { getFormQuestions },
      },
    } = this.props;
    getFormQuestions(formId, timetoken, name);
  };

  render() {
    const {
      store: {
        FormMessageStore: { form, isLoading, loading },
      },
      timetoken,
      formId,
    } = this.props;
    let name,
      minutesNeededToComplete,
      isSubmitted,
      hasError = false;
    if (form.has(timetoken)) {
      isSubmitted = form.get(timetoken).isSubmitted;
      minutesNeededToComplete = form.get(timetoken).minutesNeededToComplete;
      name = form.get(timetoken).name;
      hasError = form.get(timetoken).hasError;
    }

    return hasError ? (
      <div className="mod-form-container">
        <div className="form-text">Form does not exist.</div>
      </div>
    ) : isLoading(timetoken) ? (
      <div className="mod-form-container">
        <div className="mod-form-loading">
          <Spin indicator={antIcon} />
        </div>
      </div>
    ) : (
      <div className="mod-form-container">
        <div>
          <div className="form-text">{name}</div>
          <div className="form-completed">
            This form takes about{" "}
            {minutesNeededToComplete ? minutesNeededToComplete : "< 1"} mins
          </div>
        </div>
        <div>
          {isSubmitted ? (
            <div className="form-already">
              <span className="form-go-text">
                <i className="fa fa-check"></i>
              </span>
            </div>
          ) : (
            <div
              className="form-go"
              onClick={
                loading ? null : () => this.goPress(formId, timetoken, name)
              }
            >
              <span className="form-go-text">GO</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default FormUser;
