import React from "react";
import { Modal, Spin, Button } from "antd";
import InfiniteScroll from "react-infinite-scroller";

import { SUBMITTED_FROM_TYPE_ADMIN } from "../../constants/GlobalConstant";
import AuthStore from "../../stores/AuthStore";
import FormMessageStore from "../../stores/FormMessageStore";
import ProfileStore from "../../stores/ProfileStore";
import ApiService from '../../utils/ApiService';
import { ulid } from '../../utils/getRandomId';

const fetchSelectedForm = async ({ formData, onCancel }) => {
    try {
        const { name, id: formId } = formData;
        FormMessageStore.setLoading(true);
        const messageId = `${ulid() * 10000}`;
        const params = {
            userId: ProfileStore.userId,
            formId,
            submittedFrom: SUBMITTED_FROM_TYPE_ADMIN,
            submittedFromId: AuthStore.userId,
            messageId,
        };
        const userFormsResponse = await ApiService.postRequest("user-forms", params);
        if (userFormsResponse.success) {
            await FormMessageStore.getFormQuestionsMod(formId);
            FormMessageStore.setSelectedForm(formId, messageId, name);
            FormMessageStore.setIsAdminFillingTheForm(true);
            FormMessageStore.changeUserformsVisible(true);
        }
        FormMessageStore.setLoading(false);
        onCancel();
        FormMessageStore.resetFormsListData();
    } catch (error) {
    }
};

const CustomFormModal = (props) => {
    const {
        title,
        onOk,
        onCancel,
        visible,
        data,
        loadMore,
        hasMore,
        loading,
    } = props;
    return (
        <Modal
            width={"980px"}
            title={title}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
        >
            {data.length ? (
                <div
                    className="history-of-activities"
                    style={{ height: "350px", overflow: "auto" }}
                >
                    <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMore}
                        useWindow={false}
                    >
                        {data.map((formData) => (
                            <div className="form-listing" key={formData.id}>
                                <span className="form-name">{formData.name}</span>
                                <span className="form-listing-details">
                                    <em style={{ paddingRight: 5 }}>
                                        {formData.minutesNeededToComplete ? formData.minutesNeededToComplete : 1}
                                    </em>
                                    minutes needed to complete
                                </span>
                                <Button
                                    type="primary"
                                    title={`Fill ${formData.name}`}
                                    className="download-button"
                                    size="default"
                                    onClick={() => fetchSelectedForm({ formData, onCancel })}
                                >
                                    Fill {formData.name}
                                </Button>
                            </div>
                        ))}
                        {loading ? (
                            <div className="form-loading">
                                <Spin />
                            </div>
                        ) : null}
                    </InfiniteScroll>
                </div>
            ) : loading ? (
                <div className="form-loading">
                    <Spin />
                </div>
            ) : (
                <div className="no-alerts">No form data found !</div>
            )}
        </Modal>
    );
};

export default CustomFormModal;