import AuthStore from "../stores/AuthStore";
import Message from "./Message";
import MessageInput from "./MessageInput";
import SelectedGroup from "./SelectedGroup";
import SiteStore from "../stores/SiteStore";
import _ from "lodash";
import { ImageUpload } from "../utils/uploadFile";
import { commonTrackEvent } from "../utils/Analytics";
import { types } from "mobx-state-tree";
import {
  latestMessage,
  getPubnubInstanceByUserType,
  setStateInChannel,
  history,
  subscribeSingleChannelWithPresence,
  subscribeSingleChannel,
  publishImage,
  fetchViewInContext,
} from "../utils/PubnubMethods";

const Messages = types
  .model("Messages", {
    messageInputs: types.array(MessageInput),
    selectedGroup: SelectedGroup,
    previouslySelectedGroup: types.maybeNull(SelectedGroup),
    isWaiting: false,
    isSmsSectionVisible: false,
    imageUploading: false,
    gifUploading: false,
    unreadMessages: types.array(
      types.frozen({
        channelId: types.number,
        type: types.enumeration(["GROUP_CHAT", "DIRECT_MESSAGE"]),
      })
    ),
    bannerMessage: types.maybeNull(types.string),
    bannerEmojiName: types.maybeNull(types.string),
    scrollVisible: false,
    userIsPaused: types.maybeNull(types.boolean),
    subscribedChannels: types.array(types.string),
    metaChannels: types.array(types.string),
    newMessage: false,
    newDeletedMessages: types.array(types.string),
    intervalId: types.maybe(types.number),
    latestMessages: types.array(
      types.frozen({
        message: Message,
        channel: types.string,
      })
    ),
    sessionStarted: false,
    activeTab: "group",
    viewportMessagesLoaded: false,
    othersDmQuery: false,
    dmQuery: false,
    groupQuery: false,
    searchedOthersDmQuery: "",
    searchedDmQuery: "",
    searchedGroupQuery: "",
    isShowFlagOptionModal: false,
    flagReactionId: 0,
  })
  .actions((self) => ({
    setSelectedGroup({ id, userType, isPaused, name, channel, isIntersiteGroup, start = null }) {
      self.selectedGroup = { id, userType, isPaused, name, channel, start, isIntersiteGroup };
      if (_.startsWith(channel, "GROUP_CHAT")) {
        commonTrackEvent("GROUP", "Open Group Chat", name, id);
      } else if (_.startsWith(channel, "DIRECT_MESSAGE")) {
        commonTrackEvent("DIRECT", "Open DM", name, id);
      }
    },
    setBannerMessage(value) {
      self.bannerMessage = value;
    },
    setBannerEmojiName(value) {
      self.bannerEmojiName = value;
    },
    resetBannerMessage() {
      self.bannerMessage = "";
      self.bannerEmojiName = "";
    },
    updateQueryString(key, value) {
      self[key] = value;
    },
    setQuery(key, value) {
      self[key] = value;
    },
    resetAll() {
      self.messageInputs = [];
      self.selectedGroup = SelectedGroup.create();
      self.previouslySelectedGroup = null;
      self.isWaiting = false;
      self.isSmsSectionVisible = false;
      self.imageUploading = false;
      self.gifUploading = false;
      self.unreadMessages = [];
      self.sessionStarted = false;
      self.activeTab = "group";
      self.viewportMessagesLoaded = false;
      self.othersDmQuery = false;
      self.dmQuery = false;
      self.groupQuery = false;
      self.searchedOthersDmQuery = "";
      self.searchedDmQuery = "";
      self.searchedGroupQuery = "";
      self.isShowFlagOptionModal = false;
      self.flagReactionId = 0;
    },
    resetAllSearchQueries() {
      self.othersDmQuery = false;
      self.isWaiting = false;
      self.isSmsSectionVisible = false;
      self.dmQuery = false;
      self.groupQuery = false;
      self.searchedOthersDmQuery = "";
      self.searchedDmQuery = "";
      self.searchedGroupQuery = "";
    },
    resetBannerEmojiName() {
      self.bannerEmojiName = "";
    },
    setSession(value) {
      self.sessionStarted = value;
    },
    setScrollVisible(value) {
      self.scrollVisible = value;
    },
    setSelectedGroupPause(isPaused) {
      self.selectedGroup = { isPaused };
    },
    setSelectedGroupStatus(status) {
      self.selectedGroup = { status };
    },
    setSelectedGroupName(name) {
      self.selectedGroup = { name };
    },
    updateImageLoading(value) {
      self.imageUploading = value;
    },
    updateGifLoading(value) {
      self.gifUploading = value;
    },
    resetSelectedGroup() {
      self.selectedGroup = SelectedGroup.create();
      self.sessionStarted = false;
    },
    setViewportMessagesLoaded(value) {
      self.viewportMessagesLoaded = value;
    },
    getMessage() {
      let message = _.find(self.messageInputs, {
        groupId: self.selectedGroup.id,
      });

      if (!message) {
        message = MessageInput.create({ groupId: self.selectedGroup.id });
        self.messageInputs.push(message);
      }
      return message;
    },
    pendUserAction(groupId) {
      if (self.selectedGroup.id == groupId) {
        self.setSelectedGroupName("");
      } else {
        return;
      }
    },
    hasUnreadMessages(channelId, prefix) {
      return (
        _.some(self.unreadMessages, { channelId }) ||
        self.hasDeletedMessages(prefix + channelId)
      );
    },
    setUserPause(value) {
      self.userIsPaused = value;
    },
    setUnreadMessage(channelName) {
      const underscoreIndex = channelName.lastIndexOf("_");
      const channelId = parseInt(channelName.slice(underscoreIndex + 1));
      if (
        channelId !== self.selectedGroup.id &&
        !self.hasUnreadMessages(channelId)
      ) {
        self.unreadMessages.push({
          channelId,
          type: channelName.slice(0, underscoreIndex),
        });
      }
    },
    removeUnreadMessage(channelId) {
      const index = _.indexOf(
        self.unreadMessages,
        _.find(self.unreadMessages, { channelId })
      );
      if (index >= 0) {
        self.unreadMessages.splice(index, 1);
        self.toggleNewMessage();
      }
    },
    addSubscribedChannel(channel) {
      if (!_.includes(self.subscribedChannels, channel)) {
        self.subscribedChannels.push(channel);
      }
    },
    toggleNewMessage() {
      self.newMessage = !self.newMessage;
    },
    addNewDeletedMessage(channel) {
      self.newDeletedMessages.push(channel);
    },
    removeNewDeletedMessage(channel) {
      const index = _.indexOf(self.newDeletedMessages, channel);
      self.newDeletedMessages.splice(index, 1);
    },
    publishHelper(id, name, type, callback = null) {
      const channel =
        type === "group" ? `GROUP_CHAT_${id}` : `DIRECT_MESSAGE_${id}`;

      self.setSelectedGroup({ id: parseInt(id), name, channel });
    },
    changeChannel(id, name, userType, isPaused, type, isIntersiteGroup = false, callback = null) {
      let channel;
      self.setSession(false);
      const mess = self.getMessage();
      self.resetBannerMessage();
      mess.clearText();
      self.setPreviouslySelectedGroup(
        self.selectedGroup.id,
        self.selectedGroup.name,
        self.selectedGroup.userType,
        self.selectedGroup.isPaused,
        self.selectedGroup.channel
      );
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      if (type === "group") {
        channel = `GROUP_CHAT_${id}`;

        setStateInChannel(pubnub, [channel], {
          isOnline: true,
        });
      } else if (type === "dm" || type === "direct") {
        channel = `DIRECT_MESSAGE_${id}`;
      } else if (type === "other-dms") {
        channel = `DIRECT_MESSAGE_${id}`;
      } else if (type === "waiting") {
        channel = `WAITING_ROOM_${id}`;
      }
      if (type !== "waiting" && type !== "direct") {
        self.setActiveTab(type);
      }

      if (self.selectedGroup && self.selectedGroup.id) {
        setStateInChannel(pubnub, [self.selectedGroup.channel], {
          isOnline: false,
        });
        if (self.selectedGroup.id !== id && type === "group") {
          commonTrackEvent(
            "GROUP",
            "Close Group Chat",
            self.selectedGroup.name,
            self.selectedGroup.id
          );
        } else if (self.selectedGroup.id !== id && type === "dm") {
          commonTrackEvent(
            "DIRECT",
            "Close DM",
            self.selectedGroup.name,
            self.selectedGroup.id
          );
        }
      }
      self.setSelectedGroup({
        id: parseInt(id),
        userType,
        isPaused,
        name,
        channel,
        isIntersiteGroup
      });
      self.removeUnreadMessage(id);
      self.removeNewDeletedMessage(channel);
      if (type === "group") {
        subscribeSingleChannelWithPresence(pubnub, channel);
      } else {
        subscribeSingleChannel(pubnub, channel);
      }
      history(pubnub, callback, 1);
      // self.selectedGroup.fetchReactions(callback);
    },
    setPreviouslySelectedGroup(id, name, userType, isPaused, channel) {
      self.previouslySelectedGroup = { id, userType, isPaused, name, channel };
    },
    loadChannelMessages(
      id,
      name,
      type,
      timeToken,
      callback = null,
      messageList = null,
      isIntersiteGroup = false
    ) {
      const channel =
        type === "group" ? `GROUP_CHAT_${id}` : `DIRECT_MESSAGE_${id}`;
      self.setActiveTab(type);
      self.setSelectedGroup({
        id: parseInt(id),
        name,
        channel,
        start: timeToken,
        isIntersiteGroup
      });
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      self.removeUnreadMessage(id);
      self.removeNewDeletedMessage(channel);
      subscribeSingleChannel(pubnub, channel);
      fetchViewInContext(pubnub, callback, messageList);
    },
    async uploadImageInChat(file, fileType = 'img') {
      try {
        let imageUploadRes = "";

        if (fileType === 'gif') {
          self.updateGifLoading(true);
        } else {
          self.updateImageLoading(true);
        }

        if (file["name"]) {
          imageUploadRes = await ImageUpload(file);
        }
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        if (imageUploadRes) {
          publishImage(pubnub, imageUploadRes);
        }

        if (fileType === 'gif') {
          self.updateGifLoading(false);
        } else {
          self.updateImageLoading(false);
        }
      } catch (error) {
        if (fileType === 'gif') {
          self.updateGifLoading(false);
        } else {
          self.updateImageLoading(false);
        }
      }
    },
    setInterval() {
      self.intervalId = setInterval(async () => {
        await self.fetchNewDeletedMessages();
      }, 5000);
    },
    setIsWaiting(value) {
      self.isWaiting = value;
    },
    setIsSmsSectionVisible(value) {
      self.isSmsSectionVisible = value;
    },
    clearInterval() {
      clearInterval(self.intervalId);
    },
    setActiveTab(value) {
      self.activeTab = value;
    },
    fetchLatestMessages() {
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const promises = [];
      self.subscribedChannels.forEach((channel) =>
        promises.push(latestMessage(pubnub, channel))
      );
      Promise.all(promises).then(() => {
        self.sortLatestMessages();
        AuthStore.sortDms();
        AuthStore.sortGroups();
        SiteStore.sortSelectedSiteGroups();
        SiteStore.sortAllGroups();
      });
    },
    saveLatestMessage(channel, message) {
      const index = _.findIndex(self.latestMessages, { channel });
      if (index >= 0) {
        self.latestMessages[index] = { channel, message };
      } else {
        self.latestMessages.push({ channel, message });
      }
    },
    sortLatestMessages() {
      self.latestMessages = _.orderBy(
        self.latestMessages,
        ["message.timetoken"],
        ["desc"]
      );
    },
    showFlagOptionPopup(message, reactionId) {
      self.isShowFlagOptionModal = true;
      self.flagReactionId = reactionId;
      self.messageObj = message;
    },
    closeFlagOptionPopup() {
      self.isShowFlagOptionModal = false;
      self.flagReactionId = 0;
      self.messageObj = null;
    }

  }))
  .views((self) => ({
    get text() {
      const message = _.find(self.messageInputs, {
        groupId: self.selectedGroup.id,
      });

      return message ? message.text : "";
    },
    get isSubscribed() {
      return _.some(self.subscribedChannels, self.selectedGroup.channel);
    },
    get hasUnreadGroupMessage() {
      return _.some(self.unreadMessages, { type: "GROUP_CHAT" });
    },
    get hasUnreadDirectMessage() {
      return _.some(self.unreadMessages, { type: "DIRECT_MESSAGE" });
    },
    get isBroadcasting() {
      return self.activeTab === "broadcast";
    },
    isDeleted(messageId, channelId) {
      return _.some(self.deletedMessages, {
        channelId,
        messageId: parseInt(messageId),
      });
    },
    hasDeletedMessages(channel) {
      return (
        self.selectedGroup.channel !== channel &&
        _.includes(self.newDeletedMessages, channel)
      );
    },
  }));

export default Messages;
