import React from "react";

import { IMG_CHECKERED_FLAG, IMG_MARIO_RUN_GIF } from "../../utils/ImageUtils";

const containerStyles = {
    height: 38,
    width: '100%',
    border: '1px solid #FB9048',
    backgroundColor: "#ffffff",
    borderRadius: 5,
    // marginLeft: '30%'
};

const labelStyles = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14
};

const ProgressBar = (props) => {
    const { completed, label, goal, goalLabel } = props;
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#FB9048',
        borderRadius: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'width 1s ease-in-out',
    };

    return (
        <>
            <div className="progress-bar-wrapper">
                <em className="progress-bar-image-inner" style={{ width: `${completed}%` }}>
                    <img src={IMG_MARIO_RUN_GIF} style={{ width: 23, height: 23 }} />
                </em>
                <em className="progress-bar-image-inner" style={{ width: `${goal}%` }}>
                    <span>{goalLabel}</span>
                    <img src={IMG_CHECKERED_FLAG} />
                </em>
                <div style={containerStyles}>
                    <div style={fillerStyles}>
                        <span style={labelStyles}>{label}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;