import AuthStore from "./AuthStore";
import BroadcastStore from "./BroadcastStore";
import DMStore from "./DMs";
import GroupListStore from "./GroupListStore";
import GroupStore from "./Groups";
import MemberListStore from "./MemberListStore";
import MessagesStore from "./MessagesStore";
import NotificationStore from "./NotificationStore";
import ProfileStore from "./ProfileStore";
import FormMessageStore from "./FormMessageStore";
import ReactionsStore from "./ReactionsStore";
import SignupStore from "./SignupStore";
import UserSignUpStore from "./UserSignUpStore";
import SiteStore from "./SiteStore";
import ReferralStore from "./ReferralStore";
import FlaggedMessagesStore from "./FlaggedMessagesStore";
import HistoryStore from "./HistoryStore";
import DeletedMessagesStore from "./DeletedMessagesStore";
import MixpanelStore from "./MixpanelStore";
import EventStore from "./EventStore";
import FlagNeedsStore from "./FlagNeedsStore";
import StatsStore from "./StatsStore";
import GifStore from "./GifStore";
import InsightsStore from "./InsightsStore";
import SmsInboxStore from "./SmsInboxStore";
import TaskStore from "./TaskStore";

const store = {
  AuthStore,
  BroadcastStore,
  DeletedMessagesStore,
  DMStore,
  EventStore,
  FlaggedMessagesStore,
  FlagNeedsStore,
  FormMessageStore,
  GifStore,
  GroupStore,
  GroupListStore,
  HistoryStore,
  InsightsStore,
  MemberListStore,
  MessagesStore,
  MixpanelStore,
  NotificationStore,
  ProfileStore,
  ReactionsStore,
  ReferralStore,
  SignupStore,
  SiteStore,
  StatsStore,
  SmsInboxStore,
  UserSignUpStore,
  TaskStore
};

export default store;