import React from "react";
import close from "../../static/images/close.png";
import { Checkbox, Tabs, Select, Spin } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import InAppSurvey from "./InAppSurvey";
const Option = Select.Option;

@inject("store")
@observer
class FinishedForm extends React.Component {
  setDescriptiveAnswer = (id, e) => {
    const {
      store: {
        ReferralStore: { setDescriptive },
      },
    } = this.props;
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState((prevState) => {
      return {
        rows: {
          ...prevState.rows,
          [id]: currentRows < maxRows ? currentRows : maxRows,
        },
      };
    });

    setDescriptive(id, e.target.value);
  };

  handleCloseFinishedForm = () => {
    const {
      store: {
        ReferralStore: { setHasAlreadyFinished, setSelfIntake, resetResponses },
      },
    } = this.props;
    setHasAlreadyFinished(false);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          currentReferral,
          fetchingReferralData,
          clientSelectedForm,
        },
        SiteStore: { getSiteNameFromAllSites },
      },
    } = this.props;

    return (
      <div>
        <div className="referral-header">
          <div>
            {clientSelectedForm === "RSN_RC" ? "RSN & RC" : clientSelectedForm}{" "}
            Referral Form
          </div>
          <div className="flag-actions-container">
            <img
              src={close}
              onClick={() => this.handleCloseFinishedForm()}
              alt="Close"
            />
          </div>
        </div>

        <div className="referral-scroll">
          <div className="referral-sub">
            <div className="referral-info">
              <div>
                <div>
                  <span className="sub">Site: </span>
                  <span className="main">
                    {getSiteNameFromAllSites(currentReferral.siteId)}
                  </span>
                </div>
                <div>
                  <span className="sub">Username: </span>
                  <span className="main">
                    {currentReferral.targetUserInfo.username}
                  </span>
                </div>
                <div>
                  <span className="sub">DOB: </span>
                  <span className="main">
                    {moment(
                      currentReferral.targetUserInfo.dob,
                      "YYYY-MM-DD"
                    ).format("MMMM Do YYYY")}
                  </span>
                </div>
                <div>
                  <span className="sub">Phone #: </span>
                  <span className="main">
                    {currentReferral.targetUserInfo.phoneNumber}
                  </span>
                </div>
                <div>
                  <span className="sub">
                    <Checkbox checked={!currentReferral.hasPhone}>
                      <span>Member does not have smartphone access.</span>
                    </Checkbox>
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="sub">Referrer: </span>
                  <span className="main">
                    {currentReferral.sourceUserInfo
                      ? currentReferral.sourceUserInfo.fullName
                      : "Self Referral"}
                  </span>
                </div>
                <div>
                  <span className="sub">Started on: </span>
                  <span className="main">
                    {moment(currentReferral.createdAt).format("MMM Do YYYY")}
                  </span>
                </div>
                <div>
                  <span className="sub">Phone #: </span>
                  <span className="main">
                    {currentReferral.sourceUserInfo
                      ? currentReferral.sourceUserInfo.phoneNumber
                      : " - "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {fetchingReferralData ? (
            <div className="saving-form">
              <Spin />
            </div>
          ) : (
            <div className="start-new-intake">
              <div className="rsn-form-container">
                <div className="referral-forms-container">
                  <div className="form-header"> Client Information </div>
                  <InAppSurvey />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FinishedForm;
