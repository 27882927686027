import { Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

import Alert from "../components/protected/Alert";
import Chat from "../components/protected/Chat";
import DmSection from "../components/protected/DmSection";
import WaitingSection from "../components/protected/WaitingSection";
import GroupDetails from "../components/protected/GroupDetails";
import SessionDetails from "../components/protected/SessionDetails";
import GroupList from "../components/protected/GroupList";
import Layout from "../components/Layout";
import Member from "../components/protected/Member";
import Stats from "../components/protected/Stats";
import Insights from "../components/protected/Insights";
import SmsInbox from "../components/protected/SmsInbox";
import Waiting from "../components/protected/Waiting";
import ReferrerView from "../components/protected/ReferrerView";
import IntakeView from "../components/protected/IntakeView";
import EventList from "../components/protected/EventList";
import NewEvent from "../components/protected/NewEvent";
import NewIntakeForm from "../components/protected/NewIntakeForm";
import SelfIntakeForm from "../components/protected/SelfIntakeForm";
import ReferrerForm from "../components/protected/ReferrerForm";
import ReferrerInvite from "../components/protected/ReferrerInvite";
import Invite from "../components/protected/Invite";
import MixpanelAnalysis from "../components/protected/MixpanelAnalysis";
import Profile from "../components/protected/Profile";
import AllForms from "../components/protected/AllForms";
import NewGroup from "../components/protected/NewGroup";
import UserForms from "../components/protected/UserForms";
import FinishedForm from "../components/protected/FinishedForm";
import React from "react";
import SVG from "react-inlinesvg";
import UserFlagsContainer from "../components/protected/UserFlagsContainer";
import GroupFlagsContainer from "../components/protected/GroupFlagsContainer";
import UserDeletedContainer from "../components/protected/UserDeletedContainer";
import GroupDeletedContainer from "../components/protected/GroupDeletedContainer";
import { alert } from "../en.json";
import close from "../static/images/close.png";
import flag from "../static/images/flag.svg";
import deleted from "../static/images/deleted.png";
import flagFilled from "../static/images/flagFilled.svg";
import { withAuth } from "../components/Authentication";
import ViewInContext from "../components/protected/ViewInContext";
import AssignToContainer from "../components/protected/AssignToContainer";
import { unsubscribeAll } from "../utils/PubnubMethods";
import { hasIntake } from "../utils/hasIntake";
import { CustomModal } from "../components/UI/CustomModal";
import FlagNeeds from "../components/protected/FlagNeeds";
import UserSmsSection from "../components/protected/UserSmsSection";
import CustomFormModal from "../components/UI/CustomFormModal";

import { Spin } from "antd";
import {
  isChrome,
  isIE,
  isEdge,
  isEdgeChromium,
  isFirefox,
} from "../utils/detectBrowser";
import SecureLocalStorage from "../utils/SecureLS";
import { NoChromeModal } from "../components/UI/NoChrome";

@withAuth
@withRouter
@inject("store")
@observer
class Protected extends React.Component {
  constructor() {
    super();
    this.state = {
      formListVisible: false
    };
  }

  async componentDidMount() {
    let siteId = null;
    try {
      siteId = await Number(SecureLocalStorage.get("SITE_ID"));
    } catch {
      siteId = await Number(localStorage.getItem("SITE_ID"));
    }
    const {
      store: {
        MemberListStore,
        GroupStore,
        AuthStore,
        ReactionsStore,
        SiteStore,
        FormMessageStore: { fetchAllForms },
        FlaggedMessagesStore: { fetchNumberOfUnResolvedAlerts },
      },
    } = this.props;
    AuthStore.setShowChromeModal(true);
    const userType = AuthStore.type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    const isFrontDesk = userType === "FD" ? true : false;
    const isNA = userType === "NOA" ? true : false;
    await SiteStore.setSelectedSite(siteId);
    if (isAdmin || isSuperAdmin || isNA) {
      AuthStore.fetchGroupsAndDms();
      MemberListStore.fetchModerators();
      SiteStore.fetchAllSites();
      SiteStore.siteAlreadySelected(siteId);
      // ReactionsStore.fetchAllReactions();
      // GroupStore.fetchPendingUsers();
      fetchAllForms();
      if (siteId !== 0 && siteId !== -1) {
        GroupStore.fetchAllGroups(siteId);
        fetchNumberOfUnResolvedAlerts({ siteId });
        MemberListStore.fetchUnApprovedMembersCount({ siteId });
      } else {
        GroupStore.fetchAllGroups();
        fetchNumberOfUnResolvedAlerts();
        MemberListStore.fetchUnApprovedMembersCount();
      }
    }
    if (isFrontDesk) {
      MemberListStore.fetchUnApprovedMembersCount();
    }
  }

  async componentWillUnmount() {
    const {
      store: {
        AuthStore: { changeUserOnlineStatus },
      },
    } = this.props;
    changeUserOnlineStatus();
    unsubscribeAll();
  }

  getComponent = (view) => {
    const {
      store: {
        GroupListStore: { showGroupDetails, showGroupFlags },
        AuthStore: { type, siteId: adminSiteId, loading },
        FlaggedMessagesStore: { setUrlAlertId },
      },
    } = this.props;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    switch (view) {
      case "members":
        return (
          <div className="member">
            <Member />
          </div>
        );
      case "flagNeeds":
        return (
          <div className="member">
            <FlagNeeds />
          </div>
        );
      case "waiting":
        return (
          <div className="member">
            <Waiting />
          </div>
        );
      case "chat":
        return (
          <div className="chat">
            <Chat />
          </div>
        );
      case "CBRS":
        return loading ? (
          <div className="center-loading">
            <Spin />
          </div>
        ) : !adminSiteId ? null : (
          <div className="chat">
            {hasIntake(adminSiteId) ? <IntakeView /> : <ReferrerView />}
          </div>
        );
      case "invite":
        return (
          <div className="chat">
            <Invite />
          </div>
        );
      case "events":
        return (
          <div className="chat">
            <EventList />
          </div>
        );
      case `alert/${view.split("/")[1]}`:
        const id = Number(view.split("/")[1]);
        setUrlAlertId(id);
        return (
          <div className="chat">
            <Alert />
          </div>
        );
      case "alert":
        return (
          <div className="chat">
            <Alert />
          </div>
        );
      case "mixpanel":
        return (
          <div className="chat">
            <MixpanelAnalysis />
          </div>
        );
      case "groups":
        return (
          <div className="chat">
            <GroupList />
            {showGroupDetails && (
              <div className="user-sidebar p-1">
                <GroupDetails />
              </div>
            )}
            {showGroupFlags && (
              <div className="user-flag-sidebar">
                <GroupFlagsContainer />
              </div>
            )}
          </div>
        );
      case "stats":
        return (
          <div className="chat">
            <Stats />
          </div>
        );
      case "inbox":
        return (
          <div className="chat">
            <SmsInbox />
          </div>
        );
      case "insights":
        return (
          <div className="chat">
            <Insights />
          </div>
        );
      default:
        return isAdmin || isSuperAdmin ? (
          <Redirect to="/members" />
        ) : (
          <Redirect to="/chat" />
        );
    }
  };

  fetchDeletedMessages = () => {
    const {
      store: {
        ProfileStore: { userId },
        DeletedMessagesStore: {
          setShowDeleted,
          fetchUserDeletedMessages,
          userDeletedLoading,
        },
      },
    } = this.props;
    if (userDeletedLoading || !userId) {
      return;
    }
    fetchUserDeletedMessages(userId);
    setShowDeleted(true);
  };

  handleClose = () => {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: {
          reset,
          setIsResetNeededForUserSmsData
        },
        DeletedMessagesStore: {
          setShowDeleted,
          setShowGroupUnDeleted,
          setShowUnFlagged,
        },
      },
    } = this.props;
    if (view === 'inbox') {
      setIsResetNeededForUserSmsData(false);
    }
    reset();
    setShowDeleted(false);
    setShowGroupUnDeleted(false);
    setShowUnFlagged(false);
  };

  openWaitingChat = (username, userId) => {
    const {
      store: {
        BroadcastStore: { startWaitingChat },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    setSelectedWaiting(userId);
    startWaitingChat(username, userId);
  };

  changeFlagValue = (value) => {
    const {
      store: {
        ProfileStore: { toggleFlag, userId },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    toggleFlag(value);
  };

  loadMoreGroupHistory = () => {
    const {
      store: {
        HistoryStore: { fetchAllHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchAllHistory();
  };

  getFormsList = (e) => {
    const { store: { FormMessageStore: { fetchAllFormsInListing } } } = this.props;
    fetchAllFormsInListing();
    this.changeFormsListVisible(true);
  };

  loadMoreFormsListing = () => {
    const {
      store: {
        FormMessageStore: { loading, fetchAllFormsInListing }
      },
    } = this.props;
    if (loading) return;
    fetchAllFormsInListing();
  };

  changeFormsListVisible = (value) => {
    this.setState({
      formListVisible: value,
    });
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: {
          visible,
          memberProfileVisible,
          username,
          type,
          userId,
          selectedAction,
          showFlag,
        },
        DeletedMessagesStore: {
          showDeleted,
          showGroupDeleted,
          showGroupUnDeleted,
          showUnDeleted,
          showUnFlagged,
        },
        HistoryStore: {
          historyLoading,
          totalAllHistory,
          skipAllHistory,
          allHistory,
          showAllActivities,
          resetHistory,
          setShowAllActivities,
        },
        ReferralStore: {
          newCBRSReferral,
          newIntake,
          alreadyFinished,
          showReferralForm,
          selfIntake,
        },
        EventStore: { newEvent },
        AuthStore: {
          userId: ownId,
          type: userType,
          showNoChromeModal,
          setShowChromeModal,
        },
        MemberListStore: { detailsLoading },
        FormMessageStore: { formsVisible, formsListData, loading, skipFormsInListing, totalFormsInListing, userFormsVisible },
        GroupListStore: { newGroup },
        BroadcastStore: { createDm },
        MessagesStore: {
          isSmsSectionVisible,
          setIsSmsSectionVisible,
          isWaiting,
          sessionStarted
        },
      },
    } = this.props;

    return (
      <Layout title="dashboard" classname="dashboard">
        {this.getComponent(view)}
        {visible && (
          <div className="user-sidebar p-2">
            <Profile />
          </div>
        )}
        {selectedAction === "Dm creation" ? (
          <div className="user-profile-sidebar-2">
            <DmSection />
          </div>
        ) : null}

        {(!isChrome || isFirefox || isIE || isEdge || isEdgeChromium) &&
          showNoChromeModal ? (
          <NoChromeModal onConfirm={() => setShowChromeModal(false)} />
        ) : null}

        <CustomModal
          title="All Activities"
          visible={showAllActivities}
          loading={historyLoading}
          loadMore={this.loadMoreGroupHistory}
          hasMore={skipAllHistory < totalAllHistory}
          onCancel={() => {
            resetHistory();
            setShowAllActivities(false);
          }}
          data={allHistory ? allHistory : []}
        />

        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />

        {formsVisible ? (
          <div className="user-profile-sidebar-2">
            <AllForms />
          </div>
        ) : null}

        {newCBRSReferral ? (
          <div className="user-profile-sidebar">
            <ReferrerInvite />
          </div>
        ) : null}

        {newEvent ? (
          <div className="user-profile-sidebar">
            <NewEvent />
          </div>
        ) : null}

        {newGroup ? (
          <div className="user-profile-sidebar">
            <NewGroup />
          </div>
        ) : null}

        {newIntake ? (
          <div className="user-profile-sidebar">
            <NewIntakeForm />
          </div>
        ) : null}

        {selfIntake ? (
          <div className="user-profile-sidebar">
            <SelfIntakeForm />
          </div>
        ) : null}

        {showReferralForm ? (
          <div className="user-profile-sidebar">
            <ReferrerForm />
          </div>
        ) : null}

        {alreadyFinished ? (
          <div className="user-profile-sidebar">
            <FinishedForm />
          </div>
        ) : null}

        {isWaiting ? (
          <div className="user-profile-sidebar-2">
            <WaitingSection />
          </div>
        ) : null}

        {isSmsSectionVisible ? (
          <div className="user-profile-sidebar-2">
            <UserSmsSection />
          </div>
        ) : null}

        {sessionStarted ? (
          <div className="session-sidebar p-1">
            <SessionDetails />
          </div>
        ) : null}

        {userFormsVisible ? (
          <div className="user-profile-sidebar-2">
            <UserForms />
          </div>
        ) : null}

        {showDeleted && (
          <div className="user-flag-sidebar">
            <UserDeletedContainer showdeleted={true} />
          </div>
        )}
        {showUnDeleted && (
          <div className="user-flag-sidebar">
            <UserDeletedContainer showdeleted={false} />
          </div>
        )}
        {showGroupDeleted && (
          <div className="user-flag-sidebar">
            <GroupDeletedContainer showdeleted={true} />
          </div>
        )}
        {showGroupUnDeleted && (
          <div className="user-flag-sidebar">
            <GroupDeletedContainer showdeleted={false} />
          </div>
        )}
        {showFlag && (
          <div className="user-flag-sidebar">
            <UserFlagsContainer showFlag={true} />
          </div>
        )}
        {showUnFlagged && (
          <div className="user-flag-sidebar">
            <UserFlagsContainer showFlag={false} />
          </div>
        )}
        {selectedAction === "View in context" ? (
          <div className="user-profile-sidebar-2">
            <ViewInContext />
          </div>
        ) : null}
        {selectedAction === "Assign to" ? (
          <div className="user-profile-sidebar-2">
            <AssignToContainer />
          </div>
        ) : null}
        {memberProfileVisible ? (
          <div className="user-profile-sidebar">
            {detailsLoading ? null : (
              <div className="header-section">
                <h1>
                  {alert.profileOf}{" "}
                  {username.length > 30
                    ? `${username.slice(0, 30)}...`
                    : username}
                  <button
                    className="flag-resolve-button"
                    onClick={() => createDm(username, userId)}
                  >
                    {alert.dm}
                  </button>
                  {userType !== "NOA" &&
                    type !== "moderator" &&
                    type !== "SA" &&
                    userId !== ownId ? (
                    <button
                      className="flag-resolve-button"
                      onClick={() => this.openWaitingChat(username, userId)}
                    >
                      {alert.queue}
                    </button>
                  ) : null}
                  <button
                    className="flag-resolve-button"
                    onClick={() => { setIsSmsSectionVisible(true) }}
                  >
                    {alert.sms}
                  </button>
                  <button
                    className="flag-resolve-button"
                    onClick={this.getFormsList}
                  >
                    {alert.fillForm}
                  </button>
                </h1>
                <div className="flag-actions-container">
                  <div
                    className="actions-container"
                    onClick={() => this.fetchDeletedMessages()}
                  >
                    <img
                      src={deleted}
                      alt="Deleted"
                      className="undeleted-icon"
                    />
                    Show Deleted Messages
                  </div>
                  {showFlag ? (
                    <div
                      className="actions-container"
                      onClick={() => this.changeFlagValue(false)}
                    >
                      <SVG src={flagFilled}></SVG>
                      Hide Flags
                    </div>
                  ) : (
                    <div
                      className="actions-container"
                      onClick={() => this.changeFlagValue(true)}
                    >
                      <SVG src={flag}></SVG>
                      Show Flags
                    </div>
                  )}
                  <img
                    src={close}
                    onClick={() => this.handleClose()}
                    alt="Close"
                  />
                </div>
              </div>
            )}

            <AssignToContainer />
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default Protected;
