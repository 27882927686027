import {
    IMG_ENROLLED_DELAWARE,
    IMG_ELIGIBLE_DELAWARE,
    IMG_ENROLLED_HP,
    IMG_ELIGIBLE_HP,
    IMG_ENROLLED_IHP,
    IMG_ELIGIBLE_IHP,
    IMG_ELIGIBLE_STUDY,
    IMG_ENROLLED_STUDY
} from '../utils/ImageUtils';

export const BADGE_TYPE_ELIGIBLE_COLOR = '#ffb224';
export const BADGE_TYPE_ENROLLED_COLOR = '#108ee9';
export const BADGE_TYPE_REMOVE = 'REMOVE_BADGE';

export const USER_BADGES = {
    ENROLLED_DELAWARE: 'Confirmed ACDE Enrollment',
    ENROLLED_HP: 'Confirmed HP Enrollment',
    ENROLLED_IHP: 'Confirmed IHP Enrollment',
    ENROLLED_STUDY: 'Confirmed STUDY Enrollment',
    ELIGIBLE_DELAWARE: 'Assumed/Eligible ACDE',
    ELIGIBLE_HP: 'Assumed/Eligible HP',
    ELIGIBLE_IHP: 'Assumed/Eligible IHP',
    ELIGIBLE_STUDY: 'Assumed/Eligible STUDY'
};

export const USER_BADGE_IMAGES = {
    ENROLLED_DELAWARE: IMG_ENROLLED_DELAWARE,
    ENROLLED_HP: IMG_ENROLLED_HP,
    ENROLLED_IHP: IMG_ENROLLED_IHP,
    ENROLLED_STUDY: IMG_ENROLLED_STUDY,
    ELIGIBLE_DELAWARE: IMG_ELIGIBLE_DELAWARE,
    ELIGIBLE_HP: IMG_ELIGIBLE_HP,
    ELIGIBLE_IHP: IMG_ELIGIBLE_IHP,
    ELIGIBLE_STUDY: IMG_ELIGIBLE_STUDY,
};

export const USER_BADGES_FLAGS = {
    ENROLLED_DELAWARE: 'ENROLLED_DELAWARE',
    ENROLLED_HP: 'ENROLLED_HP',
    ENROLLED_IHP: 'ENROLLED_IHP',
    ENROLLED_STUDY: 'ENROLLED_STUDY',
    ELIGIBLE_DELAWARE: 'ELIGIBLE_DELAWARE',
    ELIGIBLE_HP: 'ELIGIBLE_HP',
    ELIGIBLE_IHP: 'ELIGIBLE_IHP',
    ELIGIBLE_STUDY: 'ELIGIBLE_STUDY',
};