import React from "react";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import { CBRS } from "../../en.json";

@inject("store")
@observer
class InviteMember extends React.Component {
  startMemberInvite = (value) => {
    const {
      store: {
        ReferralStore: { sendInvite, setShowReferralForm },
      },
    } = this.props;
    sendInvite();
  };

  changeValue = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    setClientDetails(e.target.getAttribute("data-field"), e.target.value);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          sendInviteDisabled,
          invitedMemberName,
          invitedMemberPhone,
          sendingInvite,
          isNotaPhoneNumber,
        },
      },
    } = this.props;

    return (
      <div>
        <div className="referral-section">
          <h1>Invite Member</h1>
          <div className="start-referral text-left">
            Put in the requested patient information, and the user will be sent
            sign up instructions by SMS.
          </div>
          <div className="referral-box">
            <div className="refer-input-container">
              <div className="invite-sms">{CBRS.name}</div>
              <input
                onChange={this.changeValue}
                placeholder="Full Name"
                value={invitedMemberName}
                className="referral-input"
                data-field="invitedMemberName"
              />
              <div className="invite-sms">{CBRS.phone}</div>
              <input
                onChange={this.changeValue}
                placeholder={CBRS.phonePlaceHolder}
                value={invitedMemberPhone}
                className="referral-input"
                data-field="invitedMemberPhone"
                style={
                  invitedMemberPhone.length
                    ? isNotaPhoneNumber
                      ? { borderBottom: "2px solid red" }
                      : null
                    : null
                }
              />
            </div>
          </div>
          {sendingInvite ? (
            <div className="form-loading">
              <Spin />
            </div>
          ) : null}
          <div className="send-referral">
            <button
              disabled={sendInviteDisabled || sendingInvite}
              className="new-referral-button"
              onClick={() => this.startMemberInvite(true)}
            >
              Send Invite
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteMember;
