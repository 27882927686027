import { types } from "mobx-state-tree";

const Option = types.model("Option", {
  id: types.identifierNumber,
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  formQuestionId: types.maybe(types.number),
  option: types.maybe(types.string)
});

export default Option;
