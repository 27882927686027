import Footer from "./Footer";
import Header from "./Header";
import React from "react";
import { get } from "lodash";

class Layout extends React.Component {
  render() {
    const title = get(this.props, "title", " ");
    const classname = get(this.props, "classname", " ");

    return (
      <div className={classname}>
        {/* <div>
          <title>Margigold -{title}</title>
          <meta charSet="utf-8" />
          <link
            rel="icon"
            href="/static/images/favicon.png"
            type="image/x-icon"
          />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </div> */}
        <Header />
        <article>{this.props.children}</article>
        <Footer />
      </div>
    );
  }
}
export default Layout;
