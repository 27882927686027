import moment from 'moment';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getDateWithMonthName = (date) => {
    return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};

const appendZeroIfLessThanTen = (number) => {
    return number < 10 ? `0${number}` : number
};

const getDateInSpecificFormat = (date) => {
    const currentDate = new Date(`${date}`);
    return `${appendZeroIfLessThanTen(currentDate.getMonth() + 1)}/${appendZeroIfLessThanTen(currentDate.getDate())}/${currentDate.getFullYear()}`;
};

const getWeekRanges = (isPastDateFlag = false) => {
    const date = new Date();
    date.setHours(0, 0, 0);
    if (isPastDateFlag) {
        date.setDate(date.getDate() - 7);
    }

    const currentDate = date.getDate();
    const currentDay = date.getDay();
    const lessDays = currentDay == 0 ? 6 : currentDay - 1;

    const weekStartDate = new Date(new Date(date).setDate(currentDate - lessDays));
    const weekEndDate = new Date(new Date(weekStartDate).setDate(weekStartDate.getDate() + 6));

    const firstDayOfWeekWithMonthName = getDateWithMonthName(weekStartDate);
    const lastDayOfWeekWithMonthName = getDateWithMonthName(weekEndDate);

    return `${firstDayOfWeekWithMonthName} - ${lastDayOfWeekWithMonthName}`;
};

const getWeekRangesFromDay = (daysToSubtract = 0) => {
    const startDateOfWeek = moment().startOf('week').subtract(daysToSubtract, 'days').format('MMM DD');
    const endDateOfWeek = moment().endOf('week').subtract(daysToSubtract, 'days').format('MMM DD');
    return `${startDateOfWeek} - ${endDateOfWeek}`;
};

const getMonthName = (monthsToSubtract = 0, formatType = 'MMMM') => {
    return moment().subtract(monthsToSubtract, 'month').format(formatType);
};

const getMonthRanges = (monthsToSubtract = 0, formatType = 'MMM Do', monthName = '') => {
    if (monthName) {
        const startDateOfMonth = moment().month(monthName).startOf('month').format(formatType);
        const endDateOfMonth = moment().month(monthName).endOf('month').format(formatType);

        return {
            startDateOfMonth,
            endDateOfMonth
        };
    }
    const startDateOfMonth = moment().subtract(monthsToSubtract, 'months').startOf('month').format(formatType);
    const endDateOfMonth = moment().subtract(monthsToSubtract, 'months').endOf('month').format(formatType);

    return {
        startDateOfMonth,
        endDateOfMonth
    };
};

const getLast3MonthRanges = (data = {}) => {
    const { startMonth = 0, endMonth = 0, formatType = 'YYYY-MM-DD' } = data;
    const startDateOfMonth = moment().subtract(startMonth, 'months').startOf('month').format(formatType);
    const endDateOfMonth = moment().subtract(endMonth, 'months').endOf('month').format(formatType);

    return {
        startDateOfMonth,
        endDateOfMonth
    };
};

const getFormattedDate = (date, formatType = 'MMM Do YYYY') => {
    return moment(date).format(formatType);
};

const getRandomNumber = () => {
    return Math.floor(100 + Math.random() * 900);
};

const convertStringToCamelCase = (str = '', splitType = '-') => {
    return str.split(splitType).map((value, index) => {
        return index && value.length
            ? value[0].toUpperCase() + value.substring(1)
            : value;
    }).join('');
};

// Here function is used to prepare a string of only first letter from full string  i.e. -> Java Script Object Notation will be JSON
const getCombinedStringOfOnlyFirstLettersFromString = (str = '') => {
    let combinedString = '';
    // If str is available then we will perform some operation
    if (str) {
        // Extract the first letters from full string and create a array of only first letters for example Java Script Object Notation will be ['J','S','O','N'].
        const firstLettersArr = str.match(/\b(\w)/g);
        // Here we will convert the array of first letters to full string using join method
        combinedString = firstLettersArr.join('');
    }
    return combinedString;
};

const getSortedDataByDateSection = (data = []) => {
    if (data && data.length) {
        const allDataObjects = data.reduce((allData, obj) => {
            const date = moment(obj.createdAt).format('YYYY-MM-DD');
            if (!allData[date]) {
                allData[date] = [];
            }
            allData[date].push(obj);
            return allData;
        }, {});
        const allSortedObjects = Object.keys(allDataObjects).map((date, index) => {
            let formattedDate = getFormattedDate(date);
            if (moment(date).isSame(moment(), 'day')) {
                formattedDate = 'Today';
            } else if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
                formattedDate = 'Yesterday';
            }
            return { index, title: formattedDate, data: allDataObjects[date] };
        });
        return allSortedObjects;
    }
    return data;
};

const getAllTerritoriesBySites = (sitesData = {}) => {
    let territoriesData = [];
    if (sitesData) {
        territoriesData = [...new Set(Object.values(sitesData).map(site => site.territory))].sort();
        return territoriesData;
    }
    return territoriesData;
};

export {
    getWeekRanges,
    getDateInSpecificFormat,
    getWeekRangesFromDay,
    getMonthName,
    getMonthRanges,
    getLast3MonthRanges,
    getFormattedDate,
    getRandomNumber,
    convertStringToCamelCase,
    getCombinedStringOfOnlyFirstLettersFromString,
    getSortedDataByDateSection,
    getAllTerritoriesBySites
};