import PubNub from "pubnub";
import moment from "moment";
import { toUpper, cloneDeep } from "lodash";
import { types } from "mobx-state-tree";

import { somethingWrong, user } from "../en.json";

import AuthStore from "../stores/AuthStore";
import NotificationStore from "../stores/NotificationStore";
import ApiService from "../utils/ApiService";
import history from "../utils/history";
import SecureLocalStorage from "../utils/SecureLS";
import { extractSecretKey } from "../utils/extractKeys";
import { IS_ISLAND } from "../utils/getEnvironment";

var mixpanel = require("mixpanel-browser");
const { REACT_APP_SITE_SECRET_KEY: SITE_SECRET } = process.env;

const Signup = types
  .model("Signup", {
    fullName: "",
    username: "",
    phone: "",
    password: "",
    certification: "",
    email: "",
    adminSignUp: "",
    DOB: "",
    siteId: types.maybeNull(types.number),
    loading: false,
  })
  .actions((self) => ({
    resetAll() {
      self.fullName = "";
      self.username = "";
      self.phone = "";
      self.password = "";
      self.certification = "";
      self.email = "";
      self.adminSignUp = "";
      self.DOB = "";
      self.siteId = null;
      self.loading = false;
    },
    async createUser() {
      if (!self.isEmailValid) {
        NotificationStore.setNotification("error", user.emailError);
        return false;
      }
      if (!self.isFullNameValid) {
        NotificationStore.setNotification("error", user.fullNameError);
        return false;
      }
      if (!self.isUsernameValid) {
        NotificationStore.setNotification("error", user.usernameError2);
        return false;
      }
      if (
        (self.phone && self.phone.length < 10) ||
        (self.phone && self.phone.length > 10)
      ) {
        NotificationStore.setNotification("error", user.phoneError);
        return false;
      }
      if (self.isPasswordValid && self.isValid) {
        self.setLoading(true);
        if (self.adminSignUp) {
          const params = {
            type: "ADMIN_SIGN_UP_CODE",
            secret: SITE_SECRET,
            adminSignUpCode: toUpper(self.adminSignUp),
          };
          const response = await ApiService.postRequest("get-sites", params);
          if (response.data.data.length && response.data.data[0].id) {
            self.setSiteId(response.data.data[0].id);
          } else {
            NotificationStore.setNotification(
              "error",
              user.invalidAdminSignUpCode
            );
            self.setLoading(false);
            return false;
          }
        } else if (!self.adminSignUp) {
          NotificationStore.setNotification("error", user.emptyAdminSignUpCode);
          self.setLoading(false);
          return false;
        }
        const dateOfBirth = moment(self.DOB, "YYYY-MM-DD").format("YYYY-MM-DD");
        const formattedPhone =
          "(" +
          self.phone.slice(0, 3) +
          ")" +
          " " +
          self.phone.slice(3, 6) +
          "-" +
          self.phone.slice(6);
        const params = {
          fullName: self.fullName,
          username: self.username,
          phoneNumber: formattedPhone,
          certification: self.certification,
          email: self.email,
          siteId: self.siteId,
          dob: dateOfBirth,
          password: self.password,
          type: "NOA",
        };
        const checkUniqueEmail = await ApiService.getRequest(
          "unique-username-email",
          { email: self.email }
        );
        const checkUniqueUsername = await ApiService.getRequest(
          "unique-username-email",
          { username: self.username }
        );
        if (!checkUniqueUsername.data.present) {
          if (!checkUniqueEmail.data.present) {
            const response = await ApiService.postRequest("users", params);
            if (response.success) {
              let userData = cloneDeep(response.data);
              const loginParams = {
                username: self.username,
                password: self.password,
                strategy: "local",
              };
              const loginResponse = await ApiService.postRequest(
                "authentication",
                loginParams
              );
              if (loginResponse.success) {
                self.setLoading(false);
                userData = {
                  ...userData,
                  accessToken: loginResponse.data.accessToken,
                };
                if (loginResponse.data.hasOwnProperty("keys")) {
                  const { key, originalData } = extractSecretKey(
                    loginResponse.data.keys
                  );
                  const pubnub = new PubNub({
                    publishKey: "",
                    subscribeKey: "",
                    authKey: "",
                    useRandomIVs: IS_ISLAND ? true : false
                  });
                  const keys = pubnub.decrypt(originalData, key);
                  SecureLocalStorage.setMultipleKeys(keys);
                }
                AuthStore.loginCreatedUser(userData);
                mixpanel.people.set({
                  $email: self.email,
                  $username: self.username,
                });
                mixpanel.alias(String(response.data.id));
                mixpanel.track("Sign Up Success", { from: "WEB" });
                mixpanel.track("First Login", { from: "WEB" });
                history.push("/members");
              } else {
                try {
                  NotificationStore.setNotification(
                    "error",
                    response.data.errors[0].message
                  );
                } catch (error) {
                  NotificationStore.setNotification("error", somethingWrong);
                }
              }
            } else {
              try {
                NotificationStore.setNotification(
                  "error",
                  response.data.errors[0].message
                );
              } catch (error) {
                NotificationStore.setNotification("error", somethingWrong);
              }
            }
          } else {
            NotificationStore.setNotification("error", user.emailError2);
            self.setLoading(false);
            return false;
          }
        } else {
          NotificationStore.setNotification("error", user.usernameError);
          self.setLoading(false);
          return false;
        }
        self.setLoading(false);
      }
    },
    setAttribute(prop, value) {
      self[prop] = value.trim();
    },
    setSiteId(id) {
      self.siteId = id;
    },
    setLoading(value) {
      self.loading = value;
    },
  }))
  .views((self) => ({
    get isValid() {
      return (
        self.fullName !== "" &&
        self.username !== "" &&
        self.adminSignUp !== "" &&
        self.phone !== "" &&
        self.certification !== "" &&
        self.email !== "" &&
        self.password !== ""
      );
    },
    get isUsernameValid() {
      let regEx = /^[a-zA-Z0-9_-]{2,30}$/;
      return regEx.test(self.username);
    },
    get isFullNameValid() {
      let regEx = /^[a-zA-Z'_.-]+(\s?[a-zA-z'_.-]+)(\s?[a-zA-z'_.-]+)\s*$/;
      return regEx.test(self.fullName);
    },
    get isEmailValid() {
      let regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return regEx.test(self.email.toLowerCase());
    },
    get isNotNumber() {
      let regEx = /^\d+$/;
      return !regEx.test(self.phone);
    },

    get isPasswordValid() {
      const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

      if (!strongRegex.test(self.password)) {
        NotificationStore.setNotification("error", user.passwordError);
        return false;
      }
      return true;
    },
  }));

export default Signup;
