import React from "react";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import MessageBubble from "./MessageBubble";
import _ from "lodash";
import sendIcon from "../../static/images/send-icon.png";
import close from "../../static/images/close.png";
import {
  publish,
  getPubnubInstanceByUserType,
  history,
  fetchBottomMessages,
} from "../../utils/PubnubMethods";
@inject("store")
@observer
class ViewInContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      rows: 2,
      minRows: 2,
      maxRows: 10,
    };
    this.messageList = React.createRef();
  }
  appendData = async () => {
    const {
      store: {
        MessagesStore: { getMessage },
        AuthStore: { type },
      },
    } = this.props;
    const message = getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = await getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
    }
  };

  handleScroll = async (e) => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { messages, hasBottomMessages },
        },
        AuthStore: { type },
      },
    } = this.props;
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      const pubnub = await getPubnubInstanceByUserType(type);
      messages.length && hasBottomMessages && fetchBottomMessages(pubnub);
    }
  };

  scrollToTop = (prevScrollHeight) => {
    this.props.messageList.current.scrollTop =
      this.props.messageList.current.scrollHeight -
      prevScrollHeight +
      this.props.messageList.current.scrollTop;
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { setLoading, loading, reactionsLoaded },
          viewportMessagesLoaded,
          setViewportMessagesLoaded,
        },
      },
    } = this.props;
    if (!loading && !reactionsLoaded && viewportMessagesLoaded) {
      const prevScrollHeight = this.props.messageList
        ? this.props.messageList.current.scrollHeight
        : null;
      setLoading(true);
      setViewportMessagesLoaded(false);
      history(this.scrollToTop, prevScrollHeight);
    }
  };
  handleChange = (e) => {
    const {
      store: { MessagesStore },
    } = this.props;

    const text = e.target.value;
    const message = MessagesStore.getMessage();

    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    this.setState({
      value: e.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });

    message.setText(text);
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: { startDm },
      },
    } = this.props;

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        startDm();
      } else {
        this.appendData();
      }
    }
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };
  scrollToBottomPress = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { id, name, userType, clearMessages, isPaused },
          changeChannel,
          setScrollVisible,
        },
        GroupStore: { fetchGroupUsers },
      },
    } = this.props;
    const type = "group";
    clearMessages();
    await changeChannel(id, name, userType, isPaused, type, () =>
      this.scrollToBottom()
    );
    fetchGroupUsers(id);
    this.scrollToBottom();
    setScrollVisible(false);
  };
  scrollToBottom = () => {
    if (this.messageList.current) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  handleClose = () => {
    const {
      store: {
        ProfileStore: { changeSelectedAction, setSelectedAlert },
      },
    } = this.props;
    changeSelectedAction("");
    setSelectedAlert(null);
  };

  render() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            channel,
            name,
            groupLastSeen,
          },
          scrollVisible,
          text,
        },
      },
    } = this.props;
    let prevDate = "";
    return (
      <div className="right-section">
        <div className="header-section p-1">
          <h1 className="dm-heading">
            {name}
            <img src={close} onClick={() => this.handleClose()} alt="Close" />
          </h1>
        </div>
        <div className="d-flex">
          <div className="p-1" style={{ flex: "1", position: "relative" }}>
            <div className="d-flex flex-column h-100">
              <div
                className={scrollVisible ? "scroll-to-bottom" : "hide-scroll"}
                onClick={() => this.scrollToBottomPress()}
              >
                Scroll To Bottom
              </div>
              <div className="input-container">
                <textarea
                  value={text}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyPress}
                  placeholder="Type here to send a message..."
                />
                <img
                  src={sendIcon}
                  onClick={this.appendData}
                  value="Append"
                  className="send-text-img ml-auto"
                  style={text.trim() !== "" ? { opacity: 1 } : { opacity: 0.4 }}
                  alt="Send"
                />
              </div>
              {loading && <Spin />}
              <div
                id="display-data-Container"
                ref={this.messageList}
                onScroll={(e) => this.handleScroll(e)}
              >
                <InfiniteScroll
                  loadMore={this.loadMore}
                  hasMore={hasMore}
                  isReverse
                  useWindow={false}
                >
                  {_.uniqBy(messages, "timetoken").map((message, index) => {
                    const currDate = moment
                      .unix(parseInt(message.timetoken) / 10000000)
                      .format("LL");
                    const messageBox = (
                      <React.Fragment key={index}>
                        {currDate !== prevDate &&
                          this.checkMessageType(message) && (
                            <div className="message-date">
                              <span>{currDate}</span>
                            </div>
                          )}
                        <MessageBubble
                          message={message}
                          groupChat={_.startsWith(channel, "GROUP_CHAT")}
                          groupId={
                            _.startsWith(channel, "GROUP_CHAT") ? id : null
                          }
                          groupLastSeen={groupLastSeen}
                        />
                      </React.Fragment>
                    );

                    prevDate = currDate;
                    return messageBox;
                  })}
                </InfiniteScroll>
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInContext;
