import { Button, notification } from "antd";

import React from "react";
import { notificationMessage } from "../en.json";
import { types } from "mobx-state-tree";

const Notification = types
  .model("Notification", { active: false })
  .actions(self => ({
    setNotification(type, message, description, duration = 4.5) {
      notification.config({
        placement: "bottomLeft"
      });
      notification[type]({
        message,
        description,
        duration
      });
    },
    setActive(value) {
      self.active = value;
    },
    setConfirm(
      onConfirm,
      description,
      message = notificationMessage.confirmMessage
    ) {
      const key = `open${Date.now()}`;
      self.setActive(true);
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onConfirm();
            self.setActive(false);
            notification.close(key);
          }}
        >
          Confirm
        </Button>
      );

      notification.config({
        placement: "bottomLeft"
      });
      notification.open({
        message,
        description,
        btn,
        key,
        onClose: () => self.setActive(false),
        duration: 0
      });
    }
  }));

export default Notification;
