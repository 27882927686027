import "../../styles/global.scss";

import React from "react";
import _ from "lodash";
import noop from "lodash";
import { Checkbox, Dropdown, Icon, Input, Menu, Table, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { withRouter } from "react-router-dom";

import { members } from "../../en.json";
import { ResetPwdModal } from "../UI/ResetPwdModal";
import UserBadges from "../UI/UserBadges";
import { TEXT_GREEN_COLOR, TEXT_RED_COLOR, TEXT_YELLOW_COLOR } from "../../constants/GlobalConstant";
import { getFormattedDate } from "../../utils/CommonUtils";
import { IMG_CHAT_GREEN, IMG_CHAT_RED, IMG_CHAT_GREY, IMG_CALL, IMG_CHAT_ORIGINAL } from '../../utils/ImageUtils';
import { IS_HP, IS_ISLAND } from "../../utils/getEnvironment";
import history from "../../utils/history";

const membersText = members;
const GROUP_NAME = 'town square';

const styleObjForCertainGroup = {
  border: '1px solid #FF4D00',
  borderRadius: '5px',
  paddingLeft: '5px',
  paddingTop: '10px',
  paddingBottom: '10px',
};

const OUTREACH_MAPPING_FLAGS = {
  SMS_DUE: 'SMS Due',
  SMS_SENT: 'SMS Sent',
  CALL_DUE: 'Call Due',
  CALL_ATTEMPT: 'Call Attempt',
  TALKED_TO: 'Talked to',
  SMS_DUE_COLOR: TEXT_RED_COLOR,
  SMS_SENT_COLOR: TEXT_GREEN_COLOR,
  CALL_DUE_COLOR: TEXT_RED_COLOR,
  CALL_ATTEMPT_COLOR: TEXT_YELLOW_COLOR,
  TALKED_TO_COLOR: TEXT_GREEN_COLOR
};

@withRouter
@inject("store")
@observer
class Member extends React.Component {
  async componentDidMount() {
    const {
      store: {
        ProfileStore: { getGroupDetails, getProfileQuestions },
        SiteStore: { allSites, fetchAllSites },
      },
    } = this.props;
    getGroupDetails();
    getProfileQuestions();
    if (!allSites.length) {
      fetchAllSites();
    }
    this.fetchMembers();
  }
  toggleCheckbox = (e) => {
    const {
      store: {
        MemberListStore: { resetPagination, toggleAdmins },
      },
    } = this.props;
    resetPagination();
    toggleAdmins(e.target.checked);
  };

  fetchMembers = () => {
    const {
      store: {
        MemberListStore: { callFetchMembers },
      },
    } = this.props;
    callFetchMembers();
  };

  getMembersByKey = async ({ key, domEvent }) => {
    const {
      store: {
        MemberListStore: { getMembersByKey },
      },
    } = this.props;
    const selectedKeyName = domEvent.target.innerHTML;
    getMembersByKey(key, selectedKeyName);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        MemberListStore,
        MemberListStore: { sortOrder, sortColumn },
      },
    } = this.props;

    if (sorter.order !== sortOrder || sorter.columnKey !== sortColumn) {
      pagination.current = 1;
    }

    MemberListStore.setSortParameters(sorter.columnKey, sorter.order);
    MemberListStore.setPaginationData(pagination);
    this.fetchMembers();
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setMemberProfileVisibility,
          setMemberData,
          setAssignedPeerDetails,
          toggleLabel,
          reset,
          userId,
          getHighPointUsers,
          changeSelectedAction,
          getProviderModeratedGroups,
          fetchGroupsFromForms,
          getContactModeratedGroups,
        },
        DeletedMessagesStore: { setShowDeleted },
        MemberListStore: { getMemberDetails, fetchAdminUsers },
        MessagesStore: { setIsSmsSectionVisible },
      },
    } = this.props;
    if (sender === userId) {
      return;
    }
    reset();
    setIsSmsSectionVisible(false);
    setShowDeleted(false);
    setMemberProfileVisibility(true);
    setAssignedPeerDetails({});
    fetchAdminUsers();
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
    }
    toggleLabel(false);
    changeSelectedAction("");
  };

  setQueryString = (e) => {
    const {
      store: {
        MemberListStore: { saveQueryString, loading },
      },
    } = this.props;
    if (loading) {
      return;
    }
    saveQueryString();
  };

  updateQueryString = (e) => {
    const {
      store: {
        MemberListStore: { updateQueryString },
      },
    } = this.props;
    updateQueryString(e.target.value);
  };

  onOkResetPwdModal = async () => {
    const {
      store: {
        AuthStore: { closeResetPwdModal },

      },
    } = this.props;
    await closeResetPwdModal();
    history.push("forgot-password");
  };

  render() {
    const {
      store: {
        AuthStore: { showResetPwdPopup, closeResetPwdModal },
        MemberListStore: {
          loading,
          members,
          pagination,
          getSortOrder,
          selectedKeyName,
          clearQueryString,
          queryString,
          showOnlyAdmins,
          membersGroupMessageHistoryLoading,
          isMembersGroupHistoryDataFetched,
          selectedKey,
        },
        GroupStore,
      },
    } = this.props;

    const renderDmChannels = (dmChannels) => {
      return dmChannels.map((dmChannel, index) => {
        return (
          <div className="group-activity table-col" key={index}>
            {dmChannel}
          </div>
        );
      });
    };

    const renderUserConsentProvider = (userConsentType) => {
      return (
        <>
          {userConsentType ?
            (<div className="group-activity table-col">
              {userConsentType}
            </div>)
            : null}
        </>);
    };

    const renderUsernameWithBadge = (text, row) => {
      const { notes, color, isUserSentMessageInGroup } = row;
      return (
        <>
          <div className="link-text table-col">
            {IS_ISLAND ? (<>
              {membersGroupMessageHistoryLoading ? (<Spin />) :
                (
                  <img
                    src={!isMembersGroupHistoryDataFetched ? IMG_CHAT_GREY : isUserSentMessageInGroup ? IMG_CHAT_GREEN : IMG_CHAT_RED}
                    style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                )}
            </>) : null}
            {text}
          </div>
        </>
      );
    };

    const renderUserBadges = (badgeType) => {
      return (<>
        {badgeType && <UserBadges badgeType={badgeType} />}
      </>);
    };

    const renderGroupNames = (groups = [], isGroupLengthIsOneFound = false) => {
      return (
        <>
          {_.map(groups, (group) => {
            const styleObj = isGroupLengthIsOneFound && group.name.toLowerCase().trim() === GROUP_NAME ? styleObjForCertainGroup : {};
            return group ? (
              <div className="group-activity table-col" style={styleObj} key={group.id}>
                {group.user_groups && group.user_groups.status === 2 ? (
                  <i className="fa fa-minus-circle" />
                ) : group.user_groups && group.user_groups.status === 3 ? (
                  <i className="fa fa-pause" />
                ) : (
                  <i className="fa fa-check" />
                )}
                {group.name}
              </div>
            ) : null;
          })
          }
        </>
      )
    };

    const renderOutreachContent = (row) => {
      const { callOutreach, smsOutreach, latestCallOutreachHistory, latestSmsOutreachHistory } = row;

      const { outreachValue: outreachValueForSms, createdAt: createdAtForSms, sourceUserDetails: sourceUserDetailsForSms } = latestSmsOutreachHistory;
      const { outreachValue: outreachValueForCall, createdAt: createdAtForCall, sourceUserDetails: sourceUserDetailsForCall } = latestCallOutreachHistory;

      const textForSmsOutreach = OUTREACH_MAPPING_FLAGS[smsOutreach] || OUTREACH_MAPPING_FLAGS['SMS_DUE'];
      const textForCallOutreach = OUTREACH_MAPPING_FLAGS[callOutreach] || OUTREACH_MAPPING_FLAGS['CALL_DUE'];

      const textColorForSmsOutreach = OUTREACH_MAPPING_FLAGS[`${smsOutreach}_COLOR`] || OUTREACH_MAPPING_FLAGS['SMS_DUE_COLOR'];
      const textColorForCallOutreach = OUTREACH_MAPPING_FLAGS[`${callOutreach}_COLOR`] || OUTREACH_MAPPING_FLAGS['CALL_DUE_COLOR'];

      return <>
        <div className="table-col">
          <div style={{ color: textColorForSmsOutreach }} >
            <img src={IMG_CHAT_ORIGINAL} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
            {textForSmsOutreach}
            {outreachValueForSms && outreachValueForSms === smsOutreach && sourceUserDetailsForSms ?
              (<span style={{ color: '#000000' }}> - By {sourceUserDetailsForSms.username} on {getFormattedDate(createdAtForSms)}</span>
              ) : null
            }
          </div>
          <div style={{ color: textColorForCallOutreach }}>
            <img src={IMG_CALL} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
            {textForCallOutreach}
            {outreachValueForCall && outreachValueForCall === callOutreach && sourceUserDetailsForCall ?
              (<span style={{ color: '#000000' }}> - By {sourceUserDetailsForCall.username} on {getFormattedDate(createdAtForCall)}</span>
              ) : null
            }
          </div>
        </div>
      </>
    };

    const renderAssignedPeerDetails = (assignedPeerDetails = {}) => {
      const { assignedPeerFullName = '' } = assignedPeerDetails;
      return <>
        <div className="group-activity table-col">
          {assignedPeerFullName ? assignedPeerFullName : ''}
        </div>
      </>
    };

    const renderUserTerritory = (sites = {}) => {
      return (<>
        {sites && sites.territory ?
          <div className="group-activity table-col">{sites.territory}</div>
          : null}
      </>);
    };

    const columns = [
      {
        title: membersText.fullName,
        dataIndex: "fullName",
        render: (text) => <div className="link-text table-col">{text}</div>,
        sorter: true,
        sortOrder: getSortOrder("fullName"),
      },
      {
        title: membersText.username,
        dataIndex: "username",
        width: '10%',
        render: renderUsernameWithBadge,
        sorter: true,
        sortOrder: getSortOrder("username"),
      },
      {
        title: membersText.badgeType,
        dataIndex: "badgeType",
        render: renderUserBadges,
      },
      {
        title: membersText.territory,
        dataIndex: "sites",
        width: '10%',
        render: renderUserTerritory,
      },
      {
        title: membersText.insurer,
        dataIndex: "userConsentType",
        render: renderUserConsentProvider,
        sorter: false,
      },
      {
        title: membersText.groups,
        dataIndex: "groups",
        render: (groups, row) => {
          if (groups && groups.length) {
            const isGroupLengthIsOneFound = groups.length === 1;
            return renderGroupNames(groups, isGroupLengthIsOneFound);
          }
          return null;
        },
        sorter: false,
      },
      {
        title: membersText.dmChannels,
        render: (row) => {
          const { dmChannels } = row;
          return <>
            {dmChannels && dmChannels.length ? renderDmChannels(dmChannels) : null}
          </>
        },
        sorter: false,
      },
      // {
      //   title: "Messages",
      //   dataIndex: "messages",
      //   //   onFilter: (value, record) => record.address.indexOf(value) === 0,
      //   sorter: false,
      //   sortDirections: ["descend", "ascend"]
      // },
      // {
      //   title: "Alerts",
      //   dataIndex: "alert",
      //   // render: (text, record) => (
      //   //     <Badge
      //   //     count={4}
      //   //     style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
      //   //   />
      //   // ),
      //   sorter: false,
      //   sortDirections: ["descend", "ascend"]
      // },
      // {
      //   title: "Last active",
      //   dataIndex: "active",
      //   sorter: false,
      //   sortDirections: ["descend", "ascend"]
      // },
      {
        title: membersText.joined,
        dataIndex: "createdAt",
        render: (createdAt) => getFormattedDate(createdAt),
        sorter: true,
        sortOrder: getSortOrder("createdAt"),
      },
      {
        title: membersText.outreach,
        render: renderOutreachContent
      },
      {
        title: membersText.assignedPeer,
        dataIndex: "assignedPeersDetails",
        render: renderAssignedPeerDetails
      },
    ];

    const menu = (
      <div className="selection-container">
        <Menu className="menu" onClick={this.getMembersByKey}>
          <Menu.Item key="fullName" className="menu-item">
            {membersText.memberSearch.memberName}
          </Menu.Item>
          <Menu.Item key="username" className="menu-item">
            {membersText.memberSearch.username}
          </Menu.Item>
          <Menu.Item key="mrn" className="menu-item">
            {membersText.memberSearch.mrn}
          </Menu.Item>
          <Menu.Item className="menu-item" key="email">
            {membersText.memberSearch.email}
          </Menu.Item>
          <Menu.Item key="phoneNumber" className="menu-item">
            {membersText.memberSearch.phoneNumber}
          </Menu.Item>
          <Menu.Item key="color" className="menu-item">
            {membersText.memberSearch.codecolor}
          </Menu.Item>
          <Menu.Item key="name" className="menu-item">
            {membersText.memberSearch.groupName}
          </Menu.Item>
          <Menu.Item key="siteId" className="menu-item">
            {membersText.memberSearch.siteName}
          </Menu.Item>
        </Menu>
      </div>
    );

    const rowEvents = (record, rowIndex) => {
      return {
        onClick: () => {
          this.usernamePress(record.id);
        },
      };
    };
    noop(toJS(GroupStore));

    return (
      <div className="member-table">
        <div className="search-member-container">
          <h4>
            {membersText.membersText}
            <Icon
              type="reload"
              className="reload-icon"
              onClick={() => {
                this.fetchMembers();
              }}
              spin={loading}
            />
          </h4>
          <div>
            <div className="dropdown-input-container">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="member-dropdown"
                placement="bottomLeft"
              >
                <div style={{ cursor: "pointer" }}>
                  {selectedKeyName}
                  <Icon type="down" />
                </div>
              </Dropdown>
              <Input
                prefix={
                  <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                suffix={
                  <Icon
                    type="close"
                    style={{ color: "rgba(0,0,0,.45)" }}
                    onClick={() => clearQueryString()}
                  />
                }
                value={queryString}
                onChange={this.updateQueryString}
                placeholder={membersText.memberSearchPlaceholder}
                className="member-input"
                onPressEnter={this.setQueryString}
              />
            </div>

            <div className="checkbox-container">
              <Checkbox checked={showOnlyAdmins} onChange={this.toggleCheckbox}>
                <span className="show-admins">{membersText.showAdmins}</span>
              </Checkbox>
            </div>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={toJS(members)}
          onChange={this.handleTableChange}
          pagination={pagination}
          loading={loading}
          onRow={rowEvents}
        />
        {showResetPwdPopup &&
          <div className="left-sidebar2">
            <ResetPwdModal
              onOk={this.onOkResetPwdModal}
              visible={showResetPwdPopup}
              onClose={closeResetPwdModal}
            />
          </div>
        }
      </div>
    );
  }
}

export default Member;