let pubnubInstances = {
  personal: null,
  moderator: null,
};

export const setPubnubInstance = (obj) => {
  if (!pubnubInstances.personal) {
    pubnubInstances.personal = obj.personal;
  }
  if (!pubnubInstances.moderator) {
    pubnubInstances.moderator = obj.moderator;
  }

  return pubnubInstances;
};

export default pubnubInstances;
