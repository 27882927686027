import _ from "lodash";
import { types } from "mobx-state-tree";

import { groups, sites } from "../en.json";
import { TERRITORIES_TYPE_FLAGS } from "../constants/GlobalConstant";
import AuthStore from "../stores/AuthStore";
import BroadcastStore from "../stores/BroadcastStore";
import FlaggedMessagesStore from "../stores/FlaggedMessagesStore";
import GroupStore from "../stores/Groups";
import MemberListStore from "../stores/MemberListStore";
import MessagesStore from "../stores/MessagesStore";
import NotificationStore from "../stores/NotificationStore";
import ProfileStore from "../stores/ProfileStore";
import ApiService from "../utils/ApiService";
import history from "../utils/history";
import { IS_COMM, IS_NIDA } from "../utils/getEnvironment";
import {
  getPubnubInstanceByUserType,
  subscribeAllGroupChannels,
} from "../utils/PubnubMethods";
import template from "../utils/templates";
import Group from "./Group";
import Site from "./Site";

const Sites = types
  .model("Sites", {
    allSites: types.map(Site),
    allTerritories: types.optional(types.frozen({})),
    selectedSiteData: types.array(Group),
    allGroupsSiteData: types.array(Group),
    selectedSiteId: types.maybeNull(types.number),
    selectedSiteName: "",
    loading: false,
    headerName: "",
    allGroupTotal: 0,
    groupLoading: false,
  })
  .actions((self) => ({
    resetAll() {
      self.selectedSiteData = [];
      self.allGroupsSiteData = [];
      self.selectedSiteId = null;
      self.selectedSiteName = "";
    },
    async fetchAllSites() {
      const params = {
        $limit: 100,
      };
      const path = "sites";
      self.setLoading(true);
      const response = await ApiService.getRequest(path, params);
      if (response.success && response.data.length) {
        self.setLoading(false);
        self.setAllSites(response.data);
        self.setAllTerritories(response.data);
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", sites.sitesFetchError);
        return;
      }
    },
    setAllSites(apiResponse) {
      apiResponse.forEach((data) => {
        self.allSites.set(
          data.id,
          Site.create({
            id: data.id,
            name: data.name,
            sitePin: data.sitePin,
            adminSignUpCode: data.adminSignUpCode,
            territory: data.territory,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
          })
        );
      });
    },
    setAllTerritories(response) {
      const territoriesData = response.reduce((results, siteData) => {
        (results[siteData.territory] = results[siteData.territory] || []).push(siteData.id);
        return results;
      }, {});
      self.allTerritories = territoriesData;
    },

    async siteAlreadySelected(siteId) {
      self.emptySelectedSiteData();
      self.emptyAllGroupsData();
      self.setSelectedSite(siteId);
      if (siteId === 0) {
        self.selectedSiteName = "My Groups";
      } else if (siteId === -1) {
        self.selectedSiteName = "All Groups";
        for (let i = 0; i < 3; i++) {
          if (self.allGroupTotal >= self.allGroupsSiteData.length) {
            await self.allGroups({ $skip: self.allGroupsSiteData.length });
          }
        }
      } else {
        const path = `sites/${siteId}`;
        const params = {
          include: true,
        };
        const response = await ApiService.getRequest(path, params);
        if (response.success) {
          self.setSelectedSiteData(response.data);
        } else {
          NotificationStore.setNotification("error", sites.siteDataFetchError);
        }
      }
    },
    // Function to get object of multiple sites which is used to pass in the api call
    getSiteDataObj() {
      const selectedSiteId = self.selectedSiteId;
      // Fetch territory by using selected site id
      let territory = '';
      if (selectedSiteId === -2 && IS_COMM) {
        territory = TERRITORIES_TYPE_FLAGS.COMMUNITY;
      } else if (selectedSiteId === -2 && IS_NIDA) {
        territory = TERRITORIES_TYPE_FLAGS.RESEARCH;
      } else if (selectedSiteId === -2) {
        territory = TERRITORIES_TYPE_FLAGS.DELAWARE;
      } else if (selectedSiteId === -3) {
        territory = TERRITORIES_TYPE_FLAGS.RHODE_ISLAND;
      } else if (selectedSiteId === -4) {
        territory = TERRITORIES_TYPE_FLAGS.ILLINOIS;
      } else if (selectedSiteId === -5) {
        territory = TERRITORIES_TYPE_FLAGS.MASSACHUSETTS;
      }
      return { territory };
    },
    fetchAllDataByTerritory(territory, view) {
      // Fetch site data object according to the selected territory
      const siteDataObj = self.getSiteDataObj();
      // If user is currently focused on members page then will fetch the members with groups details 
      if (view === "members") {
        MemberListStore.fetchMembers({
          ...siteDataObj,
          includeOnly: "groups",
        });
      } else if (view === "alert") {
        // If user is currently focused on alert page (bell icon in web) then will fetch the unapproved members & approved members
        MemberListStore.fetchUnApprovedMembers({ ...siteDataObj });
        MemberListStore.fetchApprovedMembers({ ...siteDataObj });
        // ################################################################################################
        // Here we need to fetch message-reactions-summary but will do it later as per the requirements
        // FlaggedMessagesStore.fetchFlaggedMessagesSummary({
        //   isResolved: false,
        //   siteId,
        // });
        // ################################################################################################
      } else if (view === "waiting") {
        MemberListStore.fetchUnResolvedMembers({ ...siteDataObj, $skip: 0 });
        MemberListStore.fetchResolvedMembers({ ...siteDataObj, $skip: 0 });
      }
      // ################################################################################################
      // Here we need to fetch all groups data & number of unresolved alerts but will do it later as per the requirements
      // GroupStore.fetchAllGroups(null, true, siteIds);
      // FlaggedMessagesStore.fetchNumberOfUnResolvedAlerts({ siteId });
      // ################################################################################################
      // Here we need to fetch unapproved members count 
      MemberListStore.fetchUnApprovedMembersCount({ ...siteDataObj });
    },
    async getSiteById(siteId, view) {
      if (
        window.location.pathname === "/CBRS" &&
        (siteId === 0 || siteId === -1)
      ) {
        history.push("/members");
      }
      GroupStore.resetAllGroups();
      MemberListStore.emptyApprovedMembers();
      MemberListStore.emptyUnApprovedMembers();
      self.emptySelectedSiteData();
      MemberListStore.emptyUnResolvedMembers();
      MemberListStore.emptyResolvedMembers();
      MemberListStore.resetPagination();
      FlaggedMessagesStore.clear();
      MemberListStore.resetSkip();
      MemberListStore.resetTotal();
      MemberListStore.updateQueryString('');
      BroadcastStore.clearFilteredOptions();
      MessagesStore.resetAllSearchQueries();
      MessagesStore.setActiveTab("group");
      MessagesStore.resetSelectedGroup();
      ProfileStore.reset();
      self.setSelectedSite(siteId);
      if (siteId === 0) {
        if (view === "members") {
          MemberListStore.callFetchMembers({ includeOnly: "groups,userConsent" });
        } else if (view === "waiting") {
          MemberListStore.fetchUnResolvedMembers({ $skip: 0 });
          MemberListStore.fetchResolvedMembers({ $skip: 0 });
        } else if (view === "alert") {
          MemberListStore.fetchUnApprovedMembers();
          MemberListStore.fetchApprovedMembers();
          FlaggedMessagesStore.fetchFlaggedMessagesSummary({
            isResolved: false,
          });
        }
        GroupStore.fetchAllGroups();
        FlaggedMessagesStore.fetchNumberOfUnResolvedAlerts();
        MemberListStore.fetchUnApprovedMembersCount();
        self.selectedSiteName = "My Groups";
      } else if (siteId === -1) {
        if (view === "members") {
          MemberListStore.callFetchMembers({ includeOnly: "groups" });
        } else if (view === "waiting") {
          MemberListStore.fetchUnResolvedMembers({ $skip: 0 });
          MemberListStore.fetchResolvedMembers({ $skip: 0 });
        } else if (view === "alert") {
          MemberListStore.fetchUnApprovedMembers();
          MemberListStore.fetchApprovedMembers();
          FlaggedMessagesStore.fetchFlaggedMessagesSummary({
            isResolved: false,
          });
        }
        GroupStore.fetchAllGroups();
        FlaggedMessagesStore.fetchNumberOfUnResolvedAlerts();
        MemberListStore.fetchUnApprovedMembersCount();
        self.selectedSiteName = template.header.allTerritories;
        if (!self.allGroupsSiteData.length) {
          for (let i = 0; i < 3; i++) {
            if (self.allGroupTotal >= self.allGroupsSiteData.length) {
              await self.allGroups({ $skip: self.allGroupsSiteData.length });
            }
          }
        }
      } else if (siteId === -2 && IS_COMM) {
        // Condition for All Community
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.COMMUNITY, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allCommunity;
      } else if (siteId === -2 && IS_NIDA) {
        // Condition for All Research
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.RESEARCH, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allResearch;
      } else if (siteId === -2) {
        // Condition for All Delaware
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.DELAWARE, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allDelaware;
      } else if (siteId === -3) {
        // Condition for All Rhode England
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.RHODE_ISLAND, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allRhodeIsland;
      } else if (siteId === -4) {
        // Condition for All Illinois
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.ILLINOIS, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allIllinois;
      } else if (siteId === -5) {
        // Condition for All Massachusetts
        self.fetchAllDataByTerritory(TERRITORIES_TYPE_FLAGS.MASSACHUSETTS, view);
        // Set selected site name so that we can display it in dropdown selected option
        self.selectedSiteName = template.header.allMassachusetts;
      } else {
        if (view === "members") {
          MemberListStore.fetchMembers({
            siteId: siteId,
            includeOnly: "groups",
          });
        } else if (view === "alert") {
          MemberListStore.fetchUnApprovedMembers({ siteId: siteId });
          MemberListStore.fetchApprovedMembers({ siteId: siteId });
          FlaggedMessagesStore.fetchFlaggedMessagesSummary({
            isResolved: false,
            siteId,
          });
        } else if (view === "waiting") {
          MemberListStore.fetchUnResolvedMembers({ siteId: siteId, $skip: 0 });
          MemberListStore.fetchResolvedMembers({ siteId: siteId, $skip: 0 });
        }
        GroupStore.fetchAllGroups(siteId);
        FlaggedMessagesStore.fetchNumberOfUnResolvedAlerts({ siteId });
        MemberListStore.fetchUnApprovedMembersCount({ siteId });
        const path = `sites/${siteId}`;
        const params = {
          include: true,
          includeOnly: "groups",
        };

        const response = await ApiService.getRequest(path, params);

        if (response.success) {
          self.setSelectedSiteData(response.data);
        } else {
          NotificationStore.setNotification("error", sites.siteDataFetchError);
        }
      }
    },
    setSelectedSite(siteId) {
      self.selectedSiteId = siteId;
    },
    emptyAllGroupsData() {
      self.allGroupsSiteData = [];
    },
    async allGroups(extraParams = {}) {
      const limit = 50;
      const params = {
        ...extraParams,
        "$sort[createdAt]": -1,
        $limit: limit,
      };
      self.setGroupLoading(true);
      const response = await ApiService.getRequest("groups", params);
      if (response.success) {
        self.setAllGroupsSiteData(response.data);
        self.updateAllGroupTotal(response.meta);
      } else {
        NotificationStore.setNotification("error", groups.allGroupsFetchError);
      }
      self.setGroupLoading(false);
    },
    setGroupLoading(value) {
      self.groupLoading = value;
    },
    updateAllGroupTotal(meta) {
      self.allGroupTotal = meta.total;
    },
    getSiteNameFromAllSites(siteId) {
      if (self.allSites.has(siteId)) {
        const site = self.allSites.get(siteId);
        return site.name;
      } else {
        return "";
      }
    },
    getSiteIdFromAllSites(name) {
      const allSites = _.cloneDeep(self.allSites.values());
      const size = _.cloneDeep(self.allSites.size);
      let count = 0;
      let matchedSites = [];
      while (count < size) {
        const site = allSites.next().value;
        if (
          _.includes(site.name.toLowerCase().trim(), name.toLowerCase().trim())
        ) {
          matchedSites.push(site.id);
        }
        count++;
      }
      return matchedSites;
    },
    emptySelectedSiteData() {
      self.selectedSiteData = [];
    },
    setAllGroupsSiteData(groups) {
      const channelsToSubscribe = [];
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const allGroups = groups.map((group) => {
        const channel = `GROUP_CHAT_${group.id}`;
        channelsToSubscribe.push(channel);
        return Group.create(group);
      });
      subscribeAllGroupChannels(pubnub, channelsToSubscribe);
      self.allGroupsSiteData = [...self.allGroupsSiteData, ...allGroups];
    },
    setSelectedSiteData(apiResponse) {
      self.selectedSiteName = apiResponse.name;
      const channelsToSubscribe = [];
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      if (apiResponse.groups && apiResponse.groups.length > 0) {
        self.selectedSiteData = apiResponse.groups.map((group) => {
          const channel = `GROUP_CHAT_${group.id}`;
          channelsToSubscribe.push(channel);
          return Group.create(group);
        });
      }
      subscribeAllGroupChannels(pubnub, channelsToSubscribe);
      // MessagesStore.fetchLatestMessages();
    },
    addGroupToSiteGroups(sites, group) {
      if (sites.includes(self.selectedSiteId)) {
        self.selectedSiteData = [...self.selectedSiteData, Group.create(group)];
      }
    },
    setLoading(boolean) {
      self.loading = boolean;
    },
    sortSelectedSiteGroups() {
      self.selectedSiteData = self.selectedSiteData
        .slice()
        .sort((item1, item2) =>
          self.sortListItems("GROUP_CHAT_" + item1.id, "GROUP_CHAT_" + item2.id)
        );
    },
    sortAllGroups() {
      self.allGroupsSiteData = self.allGroupsSiteData
        .slice()
        .sort((item1, item2) =>
          self.sortListItems("GROUP_CHAT_" + item1.id, "GROUP_CHAT_" + item2.id)
        );
    },
    sortListItems(channel1, channel2) {
      let index1 = _.findIndex(MessagesStore.latestMessages, {
        channel: channel1,
      });
      let index2 = _.findIndex(MessagesStore.latestMessages, {
        channel: channel2,
      });

      index1 = index1 === -1 ? Number.MAX_SAFE_INTEGER : index1;
      index2 = index2 === -1 ? Number.MAX_SAFE_INTEGER : index2;
      return index1 - index2;
    },
  }))
  .views((self) => {
    return {};
  });

export default Sites;
