import React from "react";
import { Spin, Checkbox } from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

import {
    APP_HEALTH_USER_FLAGS,
    USERS_WAU_DATA_FETCH_FLAGS_TERRITORY
} from "../../constants/GlobalConstant";
import ProgressBar from '../UI/ProgressBar';
import SimpleLineChart from "../UI/SimpleLineChart";
import { getWeekRangesFromDay, getMonthName } from '../../utils/CommonUtils';
import { IMG_MORE, IMG_LESS } from '../../utils/ImageUtils';

const CheckboxGroup = Checkbox.Group;

const EXPAND_MORE_FLAGS = {
    QUARTERLY_GOAL: 'QUARTERLY_GOAL',
    ENROLLED: 'ENROLLED',
    ENGAGED: 'ENGAGED',
    APP_HEALTH: 'APP_HEALTH',
    OUTREACH_AND_TOUCHED: 'OUTREACH_AND_TOUCHED',
    RETENTION: 'RETENTION',
    ONE_ONE_SUPPORT: 'ONE_ONE_SUPPORT'
};

const { LAST_WEEK, THIS_WEEK } = APP_HEALTH_USER_FLAGS;

@inject("store")
@observer
class StatsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandMoreValue: '',
            headingArrForOutreachQuarterlyData: [],
            outreachAttemptsArr: [],
            outreachPeopleCountArr: [],
            touchesCompletedArr: [],
            touchedPeopleCountArr: [],
            siteIds: [],
            allTerritoriesNames: [],
            checkedListForTerritory: [],
            indeterminate: false,
            isAllTerritoriesChecked: false,
            currentMonthOutreachData: {},
            currentWeekOutreachData: {},
            lastWeekOutreachData: {}
        };
    }

    componentDidMount() {
        this.fetchOverviewData();
    };

    setQuarterlyDataForOutreach = () => {
        const {
            store: {
                StatsStore: {
                    overviewData
                }
            },
        } = this.props;
        const { outreach: { overview, expanded } } = overviewData;
        const { quarterlyData } = expanded ? expanded : {};
        const headingsArrForOutreachData = [];
        const outreachAttemptsArr = [];
        const outreachPeopleCountArr = [];
        const touchesCompletedArr = [];
        const touchedPeopleCountArr = [];
        let currentMonthOutreachData = {};
        let currentWeekOutreachData = {};
        let lastWeekOutreachData = {};
        if (overview && overview.length) {
            for (const overviewObj of overview) {
                const { month, currentweek, lastweek } = overviewObj;
                if (month) {
                    currentMonthOutreachData = { ...overviewObj };
                } else if (currentweek) {
                    currentWeekOutreachData = { ...overviewObj };
                } else if (lastweek) {
                    lastWeekOutreachData = { ...overviewObj };
                }
            }
        }
        if (quarterlyData && quarterlyData.length) {
            for (const dataObj of quarterlyData) {
                const { monthName, outreachAttempts, outreachedPeople, touchesCompleted, touchedPeople } = dataObj;
                headingsArrForOutreachData.push(monthName);
                outreachAttemptsArr.push(outreachAttempts);
                outreachPeopleCountArr.push(outreachedPeople);
                touchesCompletedArr.push(touchesCompleted);
                touchedPeopleCountArr.push(touchedPeople);
            }
        }
        this.setState({
            ...this.state,
            headingArrForOutreachQuarterlyData: headingsArrForOutreachData,
            outreachAttemptsArr,
            outreachPeopleCountArr,
            touchesCompletedArr,
            touchedPeopleCountArr,
            currentMonthOutreachData,
            currentWeekOutreachData,
            lastWeekOutreachData
        });
    };

    fetchOverviewData = async () => {
        try {
            const { store: { StatsStore, SiteStore: { allTerritories } } } = this.props;
            StatsStore.setLoading(true);
            await StatsStore.fetchAppHealthData();
            await StatsStore.getUsersWauData();
            await StatsStore.getRetentionData();
            await StatsStore.fetchOverviewData();
            this.setQuarterlyDataForOutreach();
            if (toJS(allTerritories)) {
                this.setTerritoriesNamesData(toJS(allTerritories));
            }
            StatsStore.setLoading(false);
        } catch (error) {
        }
    };

    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.reset();
    };

    handleMoreClick = (value) => {
        const { expandMoreValue } = this.state;
        let updatedExpandMoreValue = value;
        if (value === expandMoreValue) {
            updatedExpandMoreValue = '';
        }
        this.setState({
            ...this.state,
            expandMoreValue: updatedExpandMoreValue
        });
    };

    handleSiteSelect = (siteId) => {
        const {
            store: { StatsStore }
        } = this.props;
        const { siteIds } = this.state;
        const copySiteIdsArr = [...siteIds];
        const index = copySiteIdsArr.indexOf(siteId);
        if (index > -1) {
            copySiteIdsArr.splice(index, 1);
        } else {
            copySiteIdsArr.push(siteId);
        }
        this.setState({
            ...this.state,
            siteIds: [...copySiteIdsArr]
        });
        StatsStore.fetchUsersWauDataBySites({ sitesArr: copySiteIdsArr });
    };

    handleTerritoryGroupChange = (list) => {
        const { store: { StatsStore } } = this.props;
        const { allTerritoriesNames } = this.state;
        this.setState({
            ...this.state,
            checkedListForTerritory: list,
            indeterminate: !!list.length && list.length < allTerritoriesNames.length,
            isAllTerritoriesChecked: list.length === allTerritoriesNames.length
        });
        StatsStore.fetchUsersWauDataBySites({
            territory: list,
            fetchType: USERS_WAU_DATA_FETCH_FLAGS_TERRITORY
        });
    };

    handleAllTerritoryChange = (e) => {
        const { store: { StatsStore } } = this.props;
        const { allTerritoriesNames } = this.state;
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            checkedListForTerritory: isChecked ? [...allTerritoriesNames] : [],
            indeterminate: false,
            isAllTerritoriesChecked: isChecked
        });
        StatsStore.fetchUsersWauDataBySites({
            territory: isChecked ? [...allTerritoriesNames] : [],
            fetchType: USERS_WAU_DATA_FETCH_FLAGS_TERRITORY
        });
    };

    setTerritoriesNamesData = (allTerritories) => {
        const { store: { StatsStore } } = this.props;
        const allTerritoriesNames = Object.keys(allTerritories);
        this.setState({
            ...this.state,
            allTerritoriesNames,
            checkedListForTerritory: [...allTerritoriesNames],
            isAllTerritoriesChecked: true
        });
        StatsStore.fetchUsersWauDataBySites({
            territory: [...allTerritoriesNames],
            fetchType: USERS_WAU_DATA_FETCH_FLAGS_TERRITORY
        });
    };

    render() {
        const {
            store: {
                StatsStore: {
                    loading,
                    wauChartLoading,
                    wauChartByTerritoriesLoading,
                    firstSectionCommonUsersCount,
                    secondSectionCommonUsersCount,
                    usersWauData,
                    userMessagesReportData,
                    firstSectionThisWeekUsersData,
                    firstSectionLastWeekUsersData,
                    secondSectionLastWeekUsersData,
                    secondSectionLastToLastWeekUsersData,
                    currentMonthRetentionUsers,
                    pastMonthRetentionUsers,
                    currentMonthUsersMessagedInCurrentMonth,
                    pastMonthUsersMessagedInCurrentMonth,
                    overviewData,
                    usersQTsBreakdownData,
                    usersWauDataBySites,
                    usersWauDataByTerritories
                },
                SiteStore: {
                    allSites
                }
            },
        } = this.props;
        const allSitesData = Object.values(toJS(allSites));
        const {
            expandMoreValue,
            headingArrForOutreachQuarterlyData,
            outreachAttemptsArr,
            outreachPeopleCountArr,
            touchesCompletedArr,
            touchedPeopleCountArr,
            indeterminate,
            isAllTerritoriesChecked,
            checkedListForTerritory,
            allTerritoriesNames,
            currentMonthOutreachData,
            currentWeekOutreachData,
            lastWeekOutreachData
        } = this.state;
        const { QUARTERLY_GOAL, ENGAGED, ENROLLED, APP_HEALTH, ONE_ONE_SUPPORT, OUTREACH_AND_TOUCHED, RETENTION } = EXPAND_MORE_FLAGS;
        const isQuarterlyGoalMatch = expandMoreValue === QUARTERLY_GOAL;
        const isEngagedGoalMatch = expandMoreValue === ENGAGED;
        const isEnrolledGoalMatch = expandMoreValue === ENROLLED;
        const isAppHealthGoalMatch = expandMoreValue === APP_HEALTH;
        const isOneOneSupportGoalMatch = expandMoreValue === ONE_ONE_SUPPORT;
        const isOutreachAndTouchedGoalMatch = expandMoreValue === OUTREACH_AND_TOUCHED;
        const isRetentionGoalMatch = expandMoreValue === RETENTION;

        const thisWeekRange = getWeekRangesFromDay(-1);
        const lastWeekRange = getWeekRangesFromDay(6);
        const lastToLastWeekRange = getWeekRangesFromDay(13);
        const currentMonthName = getMonthName();
        const pastMonthName = getMonthName(1);

        const { enrolled = {}, annualGoal = {}, engagement = {}, outreach = {}, } = overviewData;
        const { monthData: enrolledDataByMonths, weekData: enrolledDataByWeeks } = enrolled && enrolled.expanded ? enrolled.expanded : {};
        const { enrolledThisMonth = 0, enrolledThisWeek = 0, enrolledTotal = 0, enrollmentMonth, enrollmentWeek, enrollmentsThatDoNotHaveAQtThisMonth = 0 } = enrolled && enrolled.overview ? enrolled.overview : {};
        const { numberOfPeopleAt1QT = 0, numberOfPeopleAt2QT = 0, numberOfActiveEnrolled = 0 } = engagement && engagement.overview ? engagement.overview : {};
        const { peerQTsBreakdown } = engagement && engagement.expanded ? engagement.expanded : {};
        const { quarterlyData: outreachQuarterlyData } = outreach && outreach.expanded ? outreach.expanded : {};
        const { month: annualGoalMonth, progress, goal, total } = annualGoal;

        return (
            <React.Fragment>
                <div className="stats-overview-container">
                    {loading ?
                        (<div className="stats-overview-loading"><Spin /></div>) :
                        (
                            <>
                                {/* Annual Goal Container */}
                                <div className="annual-goal-container">
                                    <h4> {annualGoalMonth} Goal:</h4>
                                    {progress && total && goal ?
                                        (<ProgressBar
                                            completed={(progress * 100) / total}
                                            goal={(goal * 100) / total}
                                            label={`${progress} Engaged`}
                                            goalLabel={`${goal} Engaged`}
                                        />) :
                                        (<div className="no-data-found">
                                            No Data Found...
                                        </div>)}
                                </div>

                                {/* Quarterly Goal Container */}
                                {/* <div className="annual-goal-container">
                                    <h4> Quarterly goal:</h4>
                                    <ProgressBar
                                        completed={55}
                                        goal={85}
                                        label="1000 Reached"
                                        goalLabel="5000 Reached" />

                                    <div className="img-expand" onClick={() => this.handleMoreClick(QUARTERLY_GOAL)}>
                                        <img src={isQuarterlyGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isQuarterlyGoalMatch ? (
                                        <>
                                            This Data Will Be right here
                                        </>
                                    ) : null}
                                </div> */}

                                {/* Enrolled Container */}
                                <div className="annual-goal-container">
                                    <h4> Enrolled </h4>
                                    <div className="enrolled-overview-wrapper">
                                        {enrollmentsThatDoNotHaveAQtThisMonth ?
                                            (<h5>Enrollments this month that do not have a QT yet:- {enrollmentsThatDoNotHaveAQtThisMonth} </h5>)
                                            : null}

                                        <table className="enrolled-overview-inner">
                                            <tbody>
                                                <tr>
                                                    <td className="left-align">Enrollment in {enrollmentMonth}</td>
                                                    <td className="left-align">{enrolledThisMonth}</td>
                                                </tr>

                                                <tr>
                                                    <td className="left-align">This Week ({enrollmentWeek})</td>
                                                    <td className="left-align">{enrolledThisWeek}</td>
                                                </tr>

                                                <tr>
                                                    <td className="left-align">Total Enrollment</td>
                                                    <td className="left-align">{enrolledTotal}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(ENROLLED)}>
                                        <img src={isEnrolledGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isEnrolledGoalMatch ? (
                                        <>
                                            <div className="enrolled-table-wrapper">

                                                {enrolledDataByMonths && enrolledDataByMonths.length ?
                                                    (<>
                                                        <table className="enrolled-month-data">
                                                            <tbody>
                                                                {enrolledDataByMonths.map(({ monthName, usersCount }, index) => (
                                                                    <tr key={index}>
                                                                        <td className="left-align">{monthName}</td>
                                                                        <td>{usersCount}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </table>
                                                    </>) : null}

                                                {enrolledDataByWeeks && enrolledDataByWeeks.length ?
                                                    (<>
                                                        <table className="enrolled-week-data">
                                                            <tbody>
                                                                {enrolledDataByWeeks.map(({ weekRange, usersCount }, index) => (
                                                                    <tr key={index}>
                                                                        <td className="left-align">{weekRange}</td>
                                                                        <td>{usersCount}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </>) : null
                                                }

                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                {/* Engaged Container */}
                                <div className="annual-goal-container">
                                    <h4> Engaged</h4>

                                    <table className="engaged-goal-overview">
                                        <tbody>
                                            <tr>
                                                <td className="left-align">Patients at 1 QT</td>
                                                <td>{numberOfPeopleAt1QT}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Patients at 2+ QT</td>
                                                <td>{numberOfPeopleAt2QT}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Active Patients</td>
                                                <td>{numberOfActiveEnrolled}</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(ENGAGED)}>
                                        <img src={isEngagedGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isEngagedGoalMatch ? (
                                        <>
                                            {peerQTsBreakdown && peerQTsBreakdown.length ? (
                                                <>
                                                    <table className="engaged-goal-data">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Number of First QT</th>
                                                                <th>Number of Second QT</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {peerQTsBreakdown.map(({ peerName, numberOf1QT, numberOf2QT }, index) => (
                                                                <tr key={index}>
                                                                    <td className="left-align">{peerName}</td>
                                                                    <td className="border-view-engaged">{numberOf1QT}</td>
                                                                    <td className="border-view-engaged">{numberOf2QT}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                    </table>
                                                </>
                                            ) : null}

                                            {usersQTsBreakdownData && usersQTsBreakdownData.length ? (
                                                <>
                                                    <table className="engaged-goal-data">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Number of QT</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {usersQTsBreakdownData.map(({ name, qt }, index) => {
                                                                const rowClass = qt >= 2 ? 'row-green' : '';
                                                                return (<tr key={index}>
                                                                    <td className={`left-align ${rowClass}`}>{name}</td>
                                                                    <td className={`border-view-engaged ${rowClass}`}>{qt}</td>
                                                                </tr>)
                                                            })}
                                                        </tbody>

                                                    </table>
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>

                                {/* Outreach & Touched Container */}
                                <div className="annual-goal-container">
                                    <h4> Outreach &#38; Touched</h4>

                                    <table className="outreach-goal-overview">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>This Month {currentMonthOutreachData ? `(${currentMonthOutreachData.month})` : null}</th>
                                                <th>This Week {currentWeekOutreachData ? `(${currentWeekOutreachData.currentweek})` : null}</th>
                                                <th>Last Week {lastWeekOutreachData ? `(${lastWeekOutreachData.lastweek})` : null}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="left-align">Outreach Attempts</td>
                                                <td className="border-view-engaged">{currentMonthOutreachData.outreachAttempts || 0}</td>
                                                <td className="border-view-engaged">{currentWeekOutreachData.outreachAttempts || 0}</td>
                                                <td className="border-view-engaged">{lastWeekOutreachData.outreachAttempts || 0}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Outreached People</td>
                                                <td className="border-view-engaged">{currentMonthOutreachData.outreachedPeople || 0}</td>
                                                <td className="border-view-engaged">{currentWeekOutreachData.outreachedPeople || 0}</td>
                                                <td className="border-view-engaged">{lastWeekOutreachData.outreachedPeople || 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="left-align">Touches Completed</td>
                                                <td className="border-view-engaged">{currentMonthOutreachData.touchesCompleted || 0}</td>
                                                <td className="border-view-engaged">{currentWeekOutreachData.touchesCompleted || 0}</td>
                                                <td className="border-view-engaged">{lastWeekOutreachData.touchesCompleted || 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="left-align">Touched People</td>
                                                <td className="border-view-engaged">{currentMonthOutreachData.touchedPeople || 0}</td>
                                                <td className="border-view-engaged">{currentWeekOutreachData.touchedPeople || 0}</td>
                                                <td className="border-view-engaged">{lastWeekOutreachData.touchedPeople || 0}</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(OUTREACH_AND_TOUCHED)}>
                                        <img src={isOutreachAndTouchedGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isOutreachAndTouchedGoalMatch ? (
                                        <>
                                            <table className="outreach-goal-data">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        {headingArrForOutreachQuarterlyData && headingArrForOutreachQuarterlyData.length ?
                                                            (<>
                                                                {headingArrForOutreachQuarterlyData.map((value, index) => (
                                                                    <th key={index}>{value}</th>
                                                                ))}
                                                            </>)
                                                            : null
                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="left-align">Outreach Attempts</td>
                                                        {outreachAttemptsArr && outreachAttemptsArr.length ?
                                                            (<>
                                                                {outreachAttemptsArr.map((value, index) => (
                                                                    <td className="border-view-engaged" key={index}>{value}</td>
                                                                ))}
                                                            </>) : null}
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">Outreached People</td>
                                                        {outreachPeopleCountArr && outreachPeopleCountArr.length ?
                                                            (<>
                                                                {outreachPeopleCountArr.map((value, index) => (
                                                                    <td className="border-view-engaged" key={index}>{value}</td>
                                                                ))}
                                                            </>) : null}
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">Touches Completed</td>
                                                        {touchesCompletedArr && touchesCompletedArr.length ?
                                                            (<>
                                                                {touchesCompletedArr.map((value, index) => (
                                                                    <td className="border-view-engaged" key={index}>{value}</td>
                                                                ))}
                                                            </>) : null}
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">Touched People</td>
                                                        {touchedPeopleCountArr && touchedPeopleCountArr.length ?
                                                            (<>
                                                                {touchedPeopleCountArr.map((value, index) => (
                                                                    <td className="border-view-engaged" key={index}>{value}</td>
                                                                ))}
                                                            </>) : null}
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </>
                                    ) : null}
                                </div>

                                {/* App Health Container */}
                                <div className="annual-goal-container">
                                    <h4> App Health </h4>

                                    <table className="app-health-data">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td>{thisWeekRange}</td>
                                                <td>{lastWeekRange}</td>
                                                <td>{lastToLastWeekRange}</td>
                                            </tr>

                                            <tr>
                                                <th className="left-align">Active Members</th>
                                                <th>This week</th>
                                                <th>Last week</th>
                                                <th>Last to Last week</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {loading ? <Spin /> :
                                                userMessagesReportData && userMessagesReportData.length ?
                                                    (<>
                                                        {userMessagesReportData.map((reportObj, index) => {
                                                            const { keyToDisplay, data: { overallData } } = reportObj;
                                                            const { thisWeekTotal = 0, lastWeekTotal = 0, lastToLastWeekTotal = 0 } = overallData || {};
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="left-align">{keyToDisplay}</td>
                                                                    <td className="font-bold">{thisWeekTotal}</td>
                                                                    <td>{lastWeekTotal}</td>
                                                                    <td>{lastToLastWeekTotal}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>) :
                                                    (<tr>
                                                        <td className="left-align">No data found...</td>
                                                    </tr>)}
                                        </tbody>

                                    </table>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(APP_HEALTH)}>
                                        <img src={isAppHealthGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isAppHealthGoalMatch ? (
                                        <>
                                            <div className="users-wau-chart-wrapper">
                                                <span>ACDE WAU (6 Weeks)</span>
                                                <SimpleLineChart data={toJS(usersWauData)}
                                                    firstDataKey="currentValue"
                                                    firstDataKeyLabel="Users"
                                                    secondDataKey="predictedValue"
                                                    secondDataKeyLabel="Users Predicted"
                                                    isBothLinesRequired
                                                />
                                                <span>Date represents the start date of the 7 day week for WAU</span>
                                            </div>

                                            {/* Territory based wau chart */}
                                            <div className="site-based-wau-chart-wrapper">
                                                <h4 className="site-based-wau-chart">Territory Based WAU</h4>
                                                <div className="sites-wau-chart-inner">
                                                    <div className="territories-list-wrapper">
                                                        {allTerritoriesNames && allTerritoriesNames.length
                                                            ? (<>
                                                                <div className="territories-list-view">
                                                                    <Checkbox indeterminate={indeterminate} onChange={this.handleAllTerritoryChange} checked={isAllTerritoriesChecked}>
                                                                        All Territories
                                                                    </Checkbox>
                                                                </div>
                                                                <CheckboxGroup className="territory-options-view" options={allTerritoriesNames} value={checkedListForTerritory} onChange={this.handleTerritoryGroupChange} />
                                                            </>)
                                                            : null
                                                        }

                                                    </div>
                                                    <div className="site-based-container">
                                                        {wauChartByTerritoriesLoading ? <Spin /> :
                                                            (<>
                                                                <SimpleLineChart data={toJS(usersWauDataByTerritories)}
                                                                    firstDataKey="currentValue"
                                                                    firstDataKeyLabel="Users"
                                                                    secondDataKey="predictedValue"
                                                                    secondDataKeyLabel="Users Predicted"
                                                                    isBothLinesRequired
                                                                />
                                                                <span>Date represents the start date of the 7 day week for WAU</span>
                                                            </>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Site based wau chart */}
                                            <div className="site-based-wau-chart-wrapper">
                                                <h4 className="site-based-wau-chart">Site Based WAU</h4>
                                                <div className="sites-wau-chart-inner">
                                                    <div className="sites-list-wrapper">
                                                        {allSitesData && allSitesData.length ? (
                                                            <>
                                                                {allSitesData.map(({ name, id }, index) => (
                                                                    <div className="sites-list-view" key={id}>
                                                                        <Checkbox onChange={() => this.handleSiteSelect(id)} />
                                                                        <span className="site-name">{name}</span>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )
                                                            : null}
                                                    </div>
                                                    <div className="site-based-container">
                                                        {wauChartLoading ? <Spin /> :
                                                            (<>
                                                                <SimpleLineChart data={toJS(usersWauDataBySites)}
                                                                    firstDataKey="currentValue"
                                                                    firstDataKeyLabel="Users"
                                                                    secondDataKey="predictedValue"
                                                                    secondDataKeyLabel="Users Predicted"
                                                                    isBothLinesRequired
                                                                />
                                                                <span>Date represents the start date of the 7 day week for WAU</span>
                                                            </>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="app-health-message-data-first-section" >
                                                <h4 className="message-overview-heading">{firstSectionCommonUsersCount ? firstSectionCommonUsersCount : 0} People messaged last week and this week</h4>

                                                <div className="week-message-data-wrapper">
                                                    <div className="last-week-users-data">
                                                        <h4>{lastWeekRange}</h4>
                                                        <div className="last-week-data">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="left-align">Username</th>
                                                                        <th>Total Messages</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {firstSectionLastWeekUsersData && firstSectionLastWeekUsersData.length ?
                                                                        (
                                                                            <>
                                                                                {firstSectionLastWeekUsersData.map((userObj, index) => {
                                                                                    const { username, totalMessages, statusFlag } = userObj;
                                                                                    const rowClass = `${statusFlag === THIS_WEEK ? 'row-green' : statusFlag === LAST_WEEK ? 'row-red' : 'row-yellow'}`;
                                                                                    return (
                                                                                        <tr className={rowClass} key={index}>
                                                                                            <td className="left-align">{username}</td>
                                                                                            <td>{totalMessages}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        ) :
                                                                        (<tr>
                                                                            <td className="left-align">No data found...</td>
                                                                        </tr>)}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="current-week-users-data">
                                                        <h4>{thisWeekRange}</h4>
                                                        <div className="current-week-data">
                                                            <table >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="left-align">Username</th>
                                                                        <th>Total Messages</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {firstSectionThisWeekUsersData && firstSectionThisWeekUsersData.length ?
                                                                        (
                                                                            <>
                                                                                {firstSectionThisWeekUsersData.map((userObj, index) => {
                                                                                    const { username, totalMessages, statusFlag } = userObj;
                                                                                    const rowClass = `${statusFlag === THIS_WEEK ? 'row-green' : statusFlag === LAST_WEEK ? 'row-red' : 'row-yellow'}`;
                                                                                    return (
                                                                                        <tr className={rowClass} key={index}>
                                                                                            <td className="left-align">{username}</td>
                                                                                            <td>{totalMessages}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        ) :
                                                                        (<tr>
                                                                            <td className="left-align">No data found...</td>
                                                                        </tr>)}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="app-health-message-data-second-section" >
                                                <h4 className="message-overview-heading">{secondSectionCommonUsersCount ? secondSectionCommonUsersCount : 0} People messaged last week and last to last week</h4>

                                                <div className="week-message-data-wrapper">
                                                    <div className="last-week-users-data">
                                                        <h4>{lastToLastWeekRange}</h4>
                                                        <div className="last-week-data">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="left-align">Username</th>
                                                                        <th>Total Messages</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {secondSectionLastToLastWeekUsersData && secondSectionLastToLastWeekUsersData.length ?
                                                                        (
                                                                            <>
                                                                                {secondSectionLastToLastWeekUsersData.map((userObj, index) => {
                                                                                    const { username, totalMessages, statusFlag } = userObj;
                                                                                    const rowClass = `${statusFlag === THIS_WEEK ? 'row-green' : statusFlag === LAST_WEEK ? 'row-red' : 'row-yellow'}`;
                                                                                    return (
                                                                                        <tr className={rowClass} key={index}>
                                                                                            <td className="left-align">{username}</td>
                                                                                            <td>{totalMessages}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        ) :
                                                                        (<tr>
                                                                            <td className="left-align">No data found...</td>
                                                                        </tr>)}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="current-week-users-data">
                                                        <h4>{lastWeekRange}</h4>
                                                        <div className="current-week-data">
                                                            <table >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="left-align">Username</th>
                                                                        <th>Total Messages</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {secondSectionLastWeekUsersData && secondSectionLastWeekUsersData.length ?
                                                                        (
                                                                            <>
                                                                                {secondSectionLastWeekUsersData.map((userObj, index) => {
                                                                                    const { username, totalMessages, statusFlag } = userObj;
                                                                                    const rowClass = `${statusFlag === THIS_WEEK ? 'row-green' : statusFlag === LAST_WEEK ? 'row-red' : 'row-yellow'}`;
                                                                                    return (
                                                                                        <tr className={rowClass} key={index}>
                                                                                            <td className="left-align">{username}</td>
                                                                                            <td>{totalMessages}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        ) :
                                                                        (<tr>
                                                                            <td className="left-align">No data found...</td>
                                                                        </tr>)}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                {/* Retention Container */}
                                <div className="annual-goal-container">
                                    <h4> Retention</h4>

                                    <table className="retention-goal-overview">
                                        <tbody>
                                            <tr>
                                                <td className="left-align font-bold">{pastMonthName} Sign Ups</td>
                                                <td className="font-bold">{pastMonthRetentionUsers ? pastMonthRetentionUsers.length : 0}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align font-bold border-view-bottom">{pastMonthName} Sign Ups messaging in {currentMonthName}</td>
                                                <td className="border-view-bottom">{pastMonthUsersMessagedInCurrentMonth}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align font-bold">{currentMonthName} Sign Ups</td>
                                                <td className="font-bold">{currentMonthRetentionUsers ? currentMonthRetentionUsers.length : 0}</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align font-bold">{currentMonthName} Sign Ups that messaged in {currentMonthName}</td>
                                                <td>{currentMonthUsersMessagedInCurrentMonth}</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(RETENTION)}>
                                        <img src={isRetentionGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isRetentionGoalMatch ? (
                                        <>
                                            <div className="retention-month-wrapper">
                                                <div className="last-month-users-data">
                                                    <h4>{pastMonthName}</h4>
                                                    <div className="last-month-data">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="left-align">Username</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {pastMonthRetentionUsers && pastMonthRetentionUsers.length ?
                                                                    (
                                                                        <>
                                                                            {pastMonthRetentionUsers.map((userObj, index) => {
                                                                                const { username, statusFlag } = userObj;
                                                                                const rowClass = `${statusFlag === 'GREEN' ? 'row-green' : 'row-red'}`;
                                                                                return (
                                                                                    <tr className={rowClass} key={index}>
                                                                                        <td className="left-align">{username}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ) :
                                                                    (<tr>
                                                                        <td className="left-align">No data found...</td>
                                                                    </tr>)}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="current-month-users-data">
                                                    <h4>{currentMonthName}</h4>
                                                    <div className="current-month-data">
                                                        <table >
                                                            <thead>
                                                                <tr>
                                                                    <th className="left-align">Username</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {currentMonthRetentionUsers && currentMonthRetentionUsers.length ?
                                                                    (
                                                                        <>
                                                                            {currentMonthRetentionUsers.map((userObj, index) => {
                                                                                const { username, statusFlag } = userObj;
                                                                                const rowClass = `${statusFlag === 'GREEN' ? 'row-green' : 'row-red'}`;
                                                                                return (
                                                                                    <tr className={rowClass} key={index}>
                                                                                        <td className="left-align">{username}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ) :
                                                                    (<tr>
                                                                        <td className="left-align">No data found...</td>
                                                                    </tr>)}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                {/* 1-1 Support Container */}
                                {/* <div className="annual-goal-container">
                                    <h4> 1-1 Support </h4>

                                    <table className="support-table-months">
                                        <thead>
                                            <tr>
                                                <th className="left-align"></th>
                                                <th>Wellness Plans</th>
                                                <th>BARC10 FU</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className="border-view">
                                                <td className="left-align">This Month</td>
                                                <td className="right-border">10</td>
                                                <td>20</td>
                                            </tr>

                                            <tr className="border-view">
                                                <td className="left-align">By ACDE Pt</td>
                                                <td className="right-border">10</td>
                                                <td>10</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">This week</td>
                                                <td>5</td>
                                                <td>10</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                    <br />
                                    <br />
                                    <table className="support-table-users">
                                        <thead>
                                            <tr>
                                                <th className="left-align">This Month</th>
                                                <th>Wellness Plans</th>
                                                <th>BARC10 FU</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="left-align">Julie</td>
                                                <td>4</td>
                                                <td>4</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Jasmine</td>
                                                <td>1</td>
                                                <td>1</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Ara</td>
                                                <td>2</td>
                                                <td>2</td>
                                            </tr>

                                            <tr>
                                                <td className="left-align">Stephanie</td>
                                                <td>1</td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="img-expand" onClick={() => this.handleMoreClick(ONE_ONE_SUPPORT)}>
                                        <img src={isOneOneSupportGoalMatch ? IMG_LESS : IMG_MORE} alt="ExpandArrow" />
                                    </div>
                                    {isOneOneSupportGoalMatch ? (
                                        <>
                                            This Data Will Be right here
                                        </>
                                    ) : null}
                                </div> */}
                            </>
                        )}
                </div>
            </React.Fragment>
        );
    }
};

export default StatsOverview;