import React from "react";
import { Table, Select, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';

import { statsAnalytics } from '../../en.json';
import { TERRITORIES_TYPE_FLAGS } from '../../constants/GlobalConstant';
import { getWeekRanges, getMonthName, getFormattedDate, getRandomNumber } from '../../utils/CommonUtils';
import UserBadges from '../UI/UserBadges';
import { IS_NIDA } from "../../utils/getEnvironment";

const { Option } = Select;

const monthDropdownStyles = {
    width: 180,
    marginLeft: 30,
    marginBottom: 10
};

@inject("store")
@observer
class StatsAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalWeekData: [],
            totalStatsData: []
        };
    }

    componentDidMount() {
        const {
            store: { StatsStore, StatsStore: { statsData } }
        } = this.props;
        if (!toJS(statsData).length) {
            this.fetchUsersCount();
        }
    };

    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.setStatsData([]);
    };

    calculateTotalCountFromArr(arr, key) {
        const reducer = (accumulator, current) => accumulator + (current[key] || 0);
        return arr.reduce(reducer, 0);
    }

    getUserSignupData(usersData = []) {
        let finalData = [];
        let totalCurrentWeekCount = 0;
        let totalPastWeekCount = 0;
        if (usersData.length) {
            const currentWeekUserData = usersData.filter((obj) => +obj.currentWeekUsersCount > 0).map((obj) => {
                return { siteName: obj.name, territory: obj.territory, currentWeekUsersCount: +obj.currentWeekUsersCount }
            });
            const pastWeekUserData = usersData.filter((obj) => +obj.pastWeekUsersCount > 0).map((obj) => {
                return { siteName: obj.name, territory: obj.territory, pastWeekUsersCount: +obj.pastWeekUsersCount }
            });

            if (currentWeekUserData.length) {
                currentWeekUserData.sort((a, b) => (a.territory > b.territory) ? 1 : (b.territory > a.territory) ? -1 : 0);
                totalCurrentWeekCount = this.calculateTotalCountFromArr(currentWeekUserData, 'currentWeekUsersCount');
            }
            currentWeekUserData.push({ name: 'Total', totalCurrentWeekCount, isCurrentWeek: true });

            if (pastWeekUserData.length) {
                pastWeekUserData.sort((a, b) => (a.territory > b.territory) ? 1 : (b.territory > a.territory) ? -1 : 0);
                totalPastWeekCount = this.calculateTotalCountFromArr(pastWeekUserData, 'pastWeekUsersCount');
            }
            pastWeekUserData.push({ name: 'Total', totalPastWeekCount, isCurrentWeek: false })

            if (currentWeekUserData.length || pastWeekUserData.length) {
                finalData = [...currentWeekUserData, ...pastWeekUserData].map((obj, index) => {
                    return {
                        ...obj,
                        key: index
                    }
                });
            }

        }
        if (!finalData.length) {
            finalData.push({ name: 'Total', totalCurrentWeekCount, isCurrentWeek: true }, { name: 'Total', totalPastWeekCount, isCurrentWeek: false });
        }
        return finalData;
    };

    getStatsDataValues(statsDataArr) {
        const modifiedStatsData = [];
        let totalEnrolledUsersCount = 0;
        let totalEligibleUsersCount = 0;
        let totalNotEligibleUsersCount = 0;
        const territoryStatsCountData = {};

        for (const statsDataObj of statsDataArr) {
            const { enrolledUsersCount, territory, eligibleUsersCount, notEligibleUsersCount } = statsDataObj;
            if (!+enrolledUsersCount && !+eligibleUsersCount && !+notEligibleUsersCount) {
                continue;
            } else {
                // Need to check if territory is available in the territory stats count array 
                // If it exists then will update its enrolled, eligible and not eligible count as sub total of territories
                if (territoryStatsCountData.hasOwnProperty(territory)) {
                    territoryStatsCountData[territory].totalEnrolledUsersCount += +enrolledUsersCount;
                    territoryStatsCountData[territory].totalEligibleUsersCount += +eligibleUsersCount;
                    territoryStatsCountData[territory].totalNotEligibleUsersCount += +notEligibleUsersCount;
                } else {
                    // Here we will add a territory as key and assign a object with initial values of enrolled, eligible and not eligible count
                    territoryStatsCountData[territory] = {
                        totalEnrolledUsersCount: +enrolledUsersCount,
                        totalEligibleUsersCount: +eligibleUsersCount,
                        totalNotEligibleUsersCount: +notEligibleUsersCount
                    }
                }
                totalEnrolledUsersCount += +enrolledUsersCount;
                totalEligibleUsersCount += +eligibleUsersCount;
                totalNotEligibleUsersCount += +notEligibleUsersCount;
                modifiedStatsData.push({ ...statsDataObj });
            }
        }
        if (modifiedStatsData.length) {
            for (let territory in territoryStatsCountData) {
                modifiedStatsData.push({
                    id: getRandomNumber(),
                    name: `Total ${territory}`,
                    totalEnrolledUsersCount: territoryStatsCountData[territory].totalEnrolledUsersCount,
                    totalEligibleUsersCount: territoryStatsCountData[territory].totalEligibleUsersCount,
                    totalNotEligibleUsersCount: territoryStatsCountData[territory].totalNotEligibleUsersCount
                });
            }
            modifiedStatsData.push({ id: getRandomNumber(), name: 'Total', totalEnrolledUsersCount, totalEligibleUsersCount, totalNotEligibleUsersCount });
        }
        // Initially sort the stats data by territory in ascending order
        return modifiedStatsData.sort((a, b) => (a.territory > b.territory) ? 1 : (b.territory > a.territory) ? -1 : 0);
    }

    async fetchUsersCount() {
        try {
            const {
                store: { StatsStore, StatsStore: { statsData } }
            } = this.props;
            StatsStore.setLoading(true);
            await StatsStore.getUsersCount();

            const parsedStatsData = toJS(statsData);
            const userSignupData = this.getUserSignupData(parsedStatsData);
            const statsDataResponse = this.getStatsDataValues(parsedStatsData);

            this.setState({
                ...this.state,
                totalWeekData: userSignupData,
                totalStatsData: statsDataResponse
            });
            StatsStore.setLoading(false);
        } catch (error) {
        }
    };

    // Here we will trigger this function if admin change the month from the dropdown
    handleMonthChangeInQT = (selectedMonth, territoryType) => {
        const {
            store: { StatsStore }
        } = this.props;
        // Here we will check the territory type and accordingly will update the selected month in dropdown
        if (territoryType === TERRITORIES_TYPE_FLAGS.DELAWARE) {
            StatsStore.setSelectedMonthForDelawareQT(selectedMonth);
        } else if (territoryType === TERRITORIES_TYPE_FLAGS.RHODE_ISLAND) {
            StatsStore.setSelectedMonthForRhodeIslandQT(selectedMonth);
        } else if (territoryType === TERRITORIES_TYPE_FLAGS.MASSACHUSETTS) {
            StatsStore.setSelectedMonthForMassachusettsQT(selectedMonth);
        } else if (territoryType === TERRITORIES_TYPE_FLAGS.ILLINOIS) {
            StatsStore.setSelectedMonthForIllinoisQT(selectedMonth);
        }
        // Here if admin changes the month then we need to fetch the users QT table data according to the month & territory
        StatsStore.fetchUsersQTData({ territoryType, selectedMonth });
    };

    handleFullNamePress = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        changeSelectedAction,
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                    MessagesStore: { setIsSmsSectionVisible },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            reset();
            setIsSmsSectionVisible(false);
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                setMemberData(member);
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) {
        }
    };

    render() {
        const {
            store: {
                StatsStore,
                StatsStore: {
                    loading,
                    statsData,
                    usersQTDataForDelaware,
                    usersQTDataForRhodeIsland,
                    usersQTDataForMassachusetts,
                    usersQTDataForIllinois,
                    selectedMonthForIllinoisQT,
                    selectedMonthForRhodeIslandQT,
                    selectedMonthForMassachusettsQT,
                    selectedMonthForDelawareQT
                }
            },
        } = this.props;
        const { totalWeekData, totalStatsData } = this.state;

        const currentWeekRange = getWeekRanges(false);
        const pastWeekRange = getWeekRanges(true);

        // Here we will create a array of last three month names so that we can display it in the dropdown list for QT table by territory 
        const currentMonthName = getMonthName(0, 'MMM');
        const lastMonthName = getMonthName(1, 'MMM');
        const lastThirdMonthName = getMonthName(2, 'MMM');
        const monthsArrForQTDropdown = [lastThirdMonthName, lastMonthName, currentMonthName];

        const rowEvents = (record, rowIndex) => {
            return {
                onClick: () => {
                    if (record.userId) {
                        this.handleFullNamePress(record.userId);
                    }
                },
            };
        };

        const renderWeekRanges = (text, row) => {
            const { currentWeekUsersCount, pastWeekUsersCount, name, isCurrentWeek } = row;
            if (name === 'Total') {
                if (isCurrentWeek) {
                    return <div className="table-col"><strong> {`${name} ${currentWeekRange}`}</strong> </div>
                }
                return <div className="table-col"><strong>{`${name} ${pastWeekRange}`}</strong> </div>
            } else if (currentWeekUsersCount) {
                return <div className="table-col">{currentWeekRange}</div>
            } else if (pastWeekUsersCount) {
                return <div className="table-col">{pastWeekRange}</div>
            } else { return <></> }
        };

        const renderUserSignupCount = (text, row) => {
            const { currentWeekUsersCount, pastWeekUsersCount, name, isCurrentWeek, totalCurrentWeekCount, totalPastWeekCount } = row;
            if (name === 'Total') {
                if (isCurrentWeek) {
                    return <div className="table-col"><strong>{totalCurrentWeekCount}</strong></div>
                }
                return <div className="table-col"><strong>{totalPastWeekCount} </strong></div>
            } else if (currentWeekUsersCount) {
                return <div className="table-col">{currentWeekUsersCount}</div>
            } else if (pastWeekUsersCount) {
                return <div className="table-col">{pastWeekUsersCount}</div>
            } else { return <></> }
        };

        const renderSiteData = (text, row, dynamicKey) => {
            const { name } = row;
            return (
                <>
                    {name.includes('Total') ? (<div className="table-col"><strong>{row[dynamicKey]}</strong></div>) :
                        (<div className="table-col">{text}</div>)
                    }
                </>
            )
        };

        const renderUsername = (text, row) => {
            return (
                <>
                    <div className="link-text table-col">
                        {text}
                    </div>
                </>
            );
        };

        const renderUserBadges = (badgeType) => {
            return (<>
                {badgeType && <UserBadges badgeType={badgeType} />}
            </>)
        };

        const renderFullName = (row) => {
            const { totalKey, fullName } = row;
            // Here value of totalKey will be "Total QT in July" or "Total Users in July"
            // so if object has property of totalKey then above values will be shown here
            if (totalKey && totalKey.includes('Total')) {
                return (<div className="table-col"><strong>{totalKey}</strong></div>);
            }
            // Here if fullName is there then we need to display the fullName of user
            return (<div className="link-text table-col">{fullName}</div>);
        };

        const renderFormattedDate = (text) => {
            // If value of text i.e. "completedAt" field is not null then we will format the month like "May", "Jun", "Jul" etc.
            if (text) {
                return getFormattedDate(text, 'MMM');
            }
            // If value of text i.e. "completedAt" is null then we don't need to show anything.
            return null;
        };

        const renderTotalScore = (row) => {
            const { totalKey, totalUsers, totalScore } = row;
            // Here value of totalKey will be "Total QT in July" or "Total Users in July"
            // so if object has property of totalKey then we need to display the total score in the "Number of QT" field
            if (totalKey && totalKey.includes('Total')) {
                return (<>
                    {row.hasOwnProperty('totalScore') ?
                        <div className="table-col"><strong>{totalScore}</strong></div> :
                        <div className="table-col"><strong>{totalUsers}</strong></div>
                    }
                </>);
            }
            // If we don't have any totalKey available then we will show totalScore for that particular object or data
            return (<div className="table-col">{totalScore}</div>);
        };

        const columns = [
            {
                title: <div className="group-head">{statsAnalytics.territory}</div>,
                dataIndex: "territory",
                render: (text, row) => renderSiteData(text, row, 'territory')
            },
            {
                title: <div className="group-head">{statsAnalytics.siteName}</div>,
                dataIndex: "name",
                render: (text, row) => renderSiteData(text, row, 'name')
            },
            {
                title: <div className="group-head">{statsAnalytics.enrolled}</div>,
                dataIndex: 'enrolledUsersCount',
                sorter: false,
                render: (text, row) => renderSiteData(text, row, 'totalEnrolledUsersCount')
            },
            {
                title: <div className="group-head">{statsAnalytics.eligible}</div>,
                dataIndex: "eligibleUsersCount",
                sorter: false,
                render: (text, row) => renderSiteData(text, row, 'totalEligibleUsersCount')
            },
            {
                title: <div className="group-head">{statsAnalytics.notEligible}</div>,
                dataIndex: "notEligibleUsersCount",
                render: (text, row) => renderSiteData(text, row, 'totalNotEligibleUsersCount')
            },
        ];

        const columnsForUsersSignup = [
            {
                title: <div className="group-head">{statsAnalytics.week}</div>,
                render: renderWeekRanges,
                sorter: false,
            },
            {
                title: <div className="group-head">{statsAnalytics.territory}</div>,
                dataIndex: "territory",
            },
            {
                title: <div className="group-head">{statsAnalytics.siteName}</div>,
                dataIndex: "siteName",
            },
            {
                title: <div className="group-head">{statsAnalytics.numberOfSignups}</div>,
                render: renderUserSignupCount,
                sorter: false,
            },
        ];

        const columnsForQTTableByTerritory = [
            {
                title: <div className="group-head">{statsAnalytics.month}</div>,
                dataIndex: "completedAt",
                render: renderFormattedDate
            },
            {
                title: <div className="group-head">{statsAnalytics.fullName}</div>,
                render: renderFullName
            },
            {
                title: <div className="group-head">{statsAnalytics.username}</div>,
                dataIndex: 'username',
                width: '20%',
                render: renderUsername,
            },
            {
                title: <div className="group-head">{statsAnalytics.badge}</div>,
                dataIndex: 'badgeType',
                render: renderUserBadges,
            },
            {
                title: <div className="group-head">{statsAnalytics.numberOfQT}</div>,
                render: renderTotalScore
            },
        ];

        return (
            <React.Fragment>
                <div className="analytics-table-wrapper">
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={totalStatsData}
                        pagination={false}
                        loading={loading}
                    />
                </div>
                <br />
                <div className="analytics-table-wrapper">
                    <Table
                        rowKey="key"
                        columns={columnsForUsersSignup}
                        dataSource={totalWeekData}
                        pagination={false}
                        loading={loading}
                    />

                </div>

                {IS_NIDA ? null : (
                    <>
                        {/* For Illinois */}
                        <div className="single-territory-wrapper">
                            <h4 className="territory-header">{statsAnalytics.illinois}</h4>
                            {
                                // If we have data for months array then we will show dropdown list in QT table
                                monthsArrForQTDropdown && monthsArrForQTDropdown.length ?
                                    (
                                        <Select
                                            value={selectedMonthForIllinoisQT}
                                            style={monthDropdownStyles}
                                            onChange={(value) => this.handleMonthChangeInQT(value, TERRITORIES_TYPE_FLAGS.ILLINOIS)}
                                        >
                                            {monthsArrForQTDropdown.map((value, index) =>
                                                <Option key={index} value={value}>{value}</Option>
                                            )}
                                        </Select>
                                    ) :
                                    null
                            }

                            <Table
                                rowKey="key"
                                columns={columnsForQTTableByTerritory}
                                dataSource={usersQTDataForIllinois}
                                pagination={false}
                                loading={loading}
                                onRow={rowEvents}
                            />
                        </div>

                        {/* For Rhode Island */}
                        <div className="single-territory-wrapper">
                            <h4 className="territory-header">{statsAnalytics.rhodeIsland}</h4>
                            {
                                // If we have data for months array then we will show dropdown list in QT table
                                monthsArrForQTDropdown && monthsArrForQTDropdown.length ?
                                    (
                                        <Select
                                            value={selectedMonthForRhodeIslandQT}
                                            style={monthDropdownStyles}
                                            onChange={(value) => this.handleMonthChangeInQT(value, TERRITORIES_TYPE_FLAGS.RHODE_ISLAND)}
                                        >
                                            {monthsArrForQTDropdown.map((value, index) =>
                                                <Option key={index} value={value}>{value}</Option>
                                            )}
                                        </Select>
                                    ) :
                                    null
                            }
                            <Table
                                rowKey="key"
                                columns={columnsForQTTableByTerritory}
                                dataSource={usersQTDataForRhodeIsland}
                                pagination={false}
                                loading={loading}
                                onRow={rowEvents}
                            />
                        </div>

                        {/* For Massachusetts */}
                        <div className="single-territory-wrapper">
                            <h4 className="territory-header">{statsAnalytics.massachusetts}</h4>
                            {
                                // If we have data for months array then we will show dropdown list in QT table
                                monthsArrForQTDropdown && monthsArrForQTDropdown.length ?
                                    (
                                        <Select
                                            value={selectedMonthForMassachusettsQT}
                                            style={monthDropdownStyles}
                                            onChange={(value) => this.handleMonthChangeInQT(value, TERRITORIES_TYPE_FLAGS.MASSACHUSETTS)}
                                        >
                                            {monthsArrForQTDropdown.map((value, index) =>
                                                <Option key={index} value={value}>{value}</Option>
                                            )}
                                        </Select>
                                    ) :
                                    null
                            }
                            <Table
                                rowKey="key"
                                columns={columnsForQTTableByTerritory}
                                dataSource={usersQTDataForMassachusetts}
                                pagination={false}
                                loading={loading}
                                onRow={rowEvents}
                            />
                        </div>

                        {/* For Delaware */}
                        <div className="single-territory-wrapper">
                            <h4 className="territory-header">{statsAnalytics.delaware}</h4>
                            {
                                // If we have data for months array then we will show dropdown list in QT table
                                monthsArrForQTDropdown && monthsArrForQTDropdown.length ?
                                    (
                                        <Select
                                            value={selectedMonthForDelawareQT}
                                            style={monthDropdownStyles}
                                            onChange={(value) => this.handleMonthChangeInQT(value, TERRITORIES_TYPE_FLAGS.DELAWARE)}
                                        >
                                            {monthsArrForQTDropdown.map((value, index) =>
                                                <Option key={index} value={value}>{value}</Option>
                                            )}
                                        </Select>
                                    ) :
                                    null
                            }
                            <Table
                                rowKey="key"
                                columns={columnsForQTTableByTerritory}
                                dataSource={usersQTDataForDelaware}
                                pagination={false}
                                loading={loading}
                                onRow={rowEvents}
                            />
                        </div>
                    </>
                )}

            </React.Fragment>
        );
    }
};

export default StatsAnalytics;