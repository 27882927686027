import _ from "lodash";
import { types } from "mobx-state-tree";
import moment from "moment";

import { TASK_DATE_FORMAT, TASK_STATUS } from "../constants/GlobalConstant";
import AuthStore from "../stores/AuthStore";
import MemberListStore from "../stores/MemberListStore";
import ApiService from "../utils/ApiService";

const Task = types
  .model("Profile", {
    description: "",
    status: "",
    addTaskLoading: false,
    fetchTaskLoading: false,
    completeTaskLoading: false,
    updateTaskLoading: false,
    tasks: types.optional(types.frozen([]))
  })
  .actions((self) => ({
    reset() {
      self.description = "";
      self.status = "";
      self.addTaskLoading = false;
      self.fetchTaskLoading = false;
      self.completeTaskLoading = false;
      self.updateTaskLoading = false;
      self.tasks = [];
    },
    async addTask(dataToSave) {
      const { description, assignedToUserId, userId, dueAt } = dataToSave
      try {
        self.setAddTaskLoading(true);
        const objToInsert = {
          description,
          assignedToUserId,
          userId,
          dueAt
        };
        const response = await ApiService.postRequest("task", objToInsert)

        if (response.success) {
          let dataToStore = response.data.data
          dataToStore['createdByUserDetails'] = {
            id: AuthStore.userId,
            fullName: AuthStore.fullName
          }
          if (assignedToUserId) {
            let admins = MemberListStore.adminUsers
            let assinedAdmin = admins.filter((admin) => admin.id == assignedToUserId)
            dataToStore['assignedToUserDetails'] = {
              id: assinedAdmin[0].id,
              fullName: assinedAdmin[0].fullName
            }
          }
          const sortArr = self.dateWiseSort([dataToStore, ...self.tasks])
          self.setTasks([...sortArr])
          self.setAddTaskLoading(false);
        }
      } catch (error) {
        self.setAddTaskLoading(false);
      }
    },
    async fetchTask(userId) {
      try {
        self.setFetchTaskLoading(true);
        const path = `task/${userId}`;
        let response = await ApiService.getRequest(path)
        self.setFetchTaskLoading(false);
        if (response.success) {
          const sortArr = self.dateWiseSort(response.data)
          self.setTasks([...sortArr])
        }
      } catch (error) {
        self.setFetchTaskLoading(false);
      }
    },
    async updateTask({ taskId, status, description, dueAt, assignedToUserId }) {
      try {
        const objToUpdate = {}
        if (status) {
          objToUpdate['status'] = status
        }
        if (description) {
          objToUpdate['description'] = description
        }
        // accept dueAt if value is date or null, skip if undefined
        if (dueAt !== undefined) {
          objToUpdate['dueAt'] = dueAt
        }
        if (assignedToUserId) {
          objToUpdate['assignedToUserId'] = assignedToUserId
        }
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(true) : self.setUpdateTaskLoading(true);
        let response = await ApiService.patchRequest('task', taskId, objToUpdate)
        if (response.success) {
          // update store object

          // tasks which not to be updated
          const filteredCloneTasksArr = self.tasks.filter(t => t.id !== taskId)

          // find the index of task to be updated
          var foundIndex = self.tasks.findIndex(t => t.id == taskId);
          const taskToBeUpdate = self.tasks[foundIndex]

          if (status === TASK_STATUS.COMPLETED) {
            // add completedByUserDetails to task object
            taskToBeUpdate['completedByUserDetails'] = {
              id: AuthStore.userId,
              fullName: AuthStore.fullName
            }
            taskToBeUpdate['status'] = TASK_STATUS.COMPLETED
            taskToBeUpdate['completedAt'] = moment().toISOString()
          } else {
            // add update description, dueAt, or assignedToUserId to task object
            if (description) {
              // add descrition to task object
              taskToBeUpdate['description'] = description
            }
            // accept dueAt if value is date or null, skip if undefined
            if (dueAt !== undefined) {
              // add dueAt to task object
              taskToBeUpdate['dueAt'] = dueAt ? moment(dueAt).toISOString() : null
            }
            if (assignedToUserId) {
              // add assignedToUserDetails to task object
              let admins = MemberListStore.adminUsers
              let assinedAdmin = admins.filter((admin) => admin.id == assignedToUserId)
              taskToBeUpdate['assignedToUserDetails'] = {
                id: assinedAdmin[0].id,
                fullName: assinedAdmin[0].fullName
              }
            }
          }
          const sortArr = self.dateWiseSort([taskToBeUpdate, ...filteredCloneTasksArr])
          self.setTasks([...sortArr])
        }
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(false) : self.setUpdateTaskLoading(false);
      } catch (error) {
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(false) : self.setUpdateTaskLoading(false);
      }
    },
    setAddTaskLoading(value) {
      self.addTaskLoading = value;
    },
    setFetchTaskLoading(value) {
      self.fetchTaskLoading = value;
    },
    setCompleteTaskLoading(value) {
      self.completeTaskLoading = value;
    },
    setUpdateTaskLoading(value) {
      self.updateTaskLoading = value;
    },
    setTasks(value) {
      self.tasks = value;
    }
  }))
  .views((self) => ({
    dateWiseSort(arr) {
      return arr.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
  }));

export default Task;
