import React from "react";
import { Table, Select, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { toJS } from "mobx";

import { caseloads, toolTips, stats } from "../../en.json";
import UserBadges from "../UI/UserBadges";
import { getMonthRanges, getFormattedDate } from '../../utils/CommonUtils';
import { IS_HP } from "../../utils/getEnvironment";
import {
    TABLE_ROW_RED_COLOR,
    TABLE_ROW_GREEN_COLOR,
    TABLE_ROW_YELLOW_COLOR,
    STYLE_OBJ_FOR_CERTAIN_GROUP,
    DEFAULT_GROUP_NAME,
    CASELOAD_USER_FLAGS,
    TERRITORIES_TYPE_ALL_TERRITORIES,
    TABLE_FONT_RED_COLOR,
    TABLE_FONT_YELLOW_COLOR,
    TABLE_FONT_GREEN_COLOR,
    TEXT_RED_COLOR,
    TEXT_GREEN_COLOR,
    TEXT_YELLOW_COLOR,
    SORTING_ORDERS
} from "../../constants/GlobalConstant";
import { IMG_CALENDAR, IMG_CHART, IMG_EYE, IMG_EYE_GREY, IMG_NOTE_GREEN, IMG_NOTE_GREY, IMG_NOTE_ORANGE, IMG_SEND_GREY, IMG_SEND_ICON, IMG_TICK_CIRCLE_SOLID, IMG_NOTE_RED, IMG_SEND_RED, IMG_EYE_RED } from "../../utils/ImageUtils";

const { Option } = Select;

const NOT_ASSIGNED = -1;
const WELCOME_PROCESS = -2;
const ALL_ENROLLED = -3;

@inject("store")
@observer
class StatsCaseLoads extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            qtPointsSortOrder: "",
            barc10DateSortOrder: "",
            showPeerColumn: false
        }
    }

    componentDidMount() {
        const {
            store: {
                MemberListStore: { adminUsersForCaseLoad, fetchAdminUsers },
                SiteStore: { allSites },
                StatsStore: { allTerritories, fetchAllTerritoriesBySites, setSelectedTerritoryForCaseLoad }
            }
        } = this.props;
        if (!adminUsersForCaseLoad.length) {
            // fetch admins list to display in select dropdown
            fetchAdminUsers({ isMarigoldAdminsRequired: true, isAdminsRequiredInCaseLoad: true });
        }
        setSelectedTerritoryForCaseLoad(TERRITORIES_TYPE_ALL_TERRITORIES);
        if (allSites && !allTerritories.length) {
            fetchAllTerritoriesBySites(toJS(allSites));
        }
    };

    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.reset();
        this.setState({ showPeerColumn: false })
    };

    handleTableChange = (pagination, filters, sorter) => {
        const {
            store: {
                StatsStore: {
                    setPaginationData,
                    setSortParameters,
                    resetSortParameters,
                    selectedUserIdForCaseLoad,
                    fetchCaseLoadData
                },
            },
        } = this.props;

        let sortOrder;

        // If admin is selected in case load dropdown then and then only sorting and other thing will work
        // If we are not applying this condition then what will happen if admin is not selected first time then api call will be made by using sorting indicators in case load headers
        if (selectedUserIdForCaseLoad) {
            if (sorter.hasOwnProperty('column')) {
                const { field, order } = sorter;
                if (field && order) {
                    // if field & order is available then we will set the sort parameters in state
                    setSortParameters(field, order);
                }
            } else {
                //  if user cancel the sorting then we need to reset sort parameters and fetch data without sorting
                resetSortParameters();
            }
            // Set pagination data i.e. skip, limit while clicking on page number in table
            setPaginationData(pagination);
            // Fetch case load data according to the updated data of pagination
            fetchCaseLoadData(selectedUserIdForCaseLoad, sortOrder);
        }
    };

    handleFullNamePress = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        getHighPointUsers,
                        changeSelectedAction,
                        getProviderModeratedGroups,
                        fetchGroupsFromForms,
                        getContactModeratedGroups,
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            reset();
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                const { providerId, recommendedBy } = member;
                setMemberData(member);
                if (IS_HP) {
                    getHighPointUsers();
                    if (providerId) {
                        getProviderModeratedGroups(providerId);
                    }
                    if (recommendedBy) {
                        getContactModeratedGroups(recommendedBy);
                    }
                    fetchGroupsFromForms();
                }
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) {
        }
    };

    handleCaseloadsChange = (value) => {
        const {
            store: {
                StatsStore: { setSortParameters, resetSortParameters, resetPagination, setSelectedUserIdForCaseLoad, setSelectedUsernameForCaseLoad, fetchCaseLoadData },
                MemberListStore: { adminUsersForCaseLoad, },
            }
        } = this.props;
        // Here if user is changed in case load dropdown then we need to reset the pagination
        resetPagination();
        // We need to reset sort parameters if admin change user in case load drop down
        resetSortParameters();
        // Need to update user id after selecting in drop down
        setSelectedUserIdForCaseLoad(value);
        if (adminUsersForCaseLoad.length) {
            // we need to find out the selected admin object in all admins array 
            const adminObj = adminUsersForCaseLoad.find(obj => obj.id === value);
            // Set selected admin user name in state
            setSelectedUsernameForCaseLoad(adminObj ? adminObj.username : null);
        }
        if(value === WELCOME_PROCESS || value === ALL_ENROLLED) {
            this.setState({ showPeerColumn: true })
            setSortParameters('createdAt', 'descend');
        } else {
            this.setState({ showPeerColumn: false })
        }
        // Fetch all the case load data if user id is changed in drop down
        fetchCaseLoadData(value);
    };

    handleTerritoryChange = (value) => {
        const {
            store: {
                StatsStore: { setSelectedTerritoryForCaseLoad, selectedUserIdForCaseLoad, fetchCaseLoadData },
            }
        } = this.props;
        setSelectedTerritoryForCaseLoad(value);
        fetchCaseLoadData(selectedUserIdForCaseLoad);
    };

    render() {
        const {
            store: {
                StatsStore: {
                    loading,
                    pagination,
                    usersData,
                    selectedUserIdForCaseLoad,
                    selectedUsernameForCaseLoad,
                    selectedTerritoryForCaseLoad,
                    getSortOrder,
                    allTerritories
                },
                MemberListStore: { adminUsersForCaseLoad },
            },
        } = this.props;

        // Get month range from utils function (moment) to display it in indator field
        const currentMonthRange = getMonthRanges();
        const lastMonthRange = getMonthRanges(1);

        // When user clicks on specific row then we need to show the user details popup
        const rowEvents = (record, rowIndex) => {
            return {
                onClick: () => {
                    this.handleFullNamePress(record.id);
                },
            };
        };

        // Render join date in DD/MM/YYYY format by using utils function
        const renderJoinDate = (text, row) => {
            const { createdAt } = row;
            return <>
                {createdAt ? getFormattedDate(createdAt) : null}
            </>
        };

        // From api, we are getting group names as array of strings so need to iterate over it then we can display it in table
        const renderGroupNames = (groups = [], isGroupLengthIsOneFound = false) => {
            return (
                <>
                    {groups.map((name, index) => {
                        // Here we are checking if group length is 1 and group name is equal to DEFAULT_GROUP_NAME then we need to add red border around default group name
                        const styleObj = isGroupLengthIsOneFound && name.toLowerCase().trim() === DEFAULT_GROUP_NAME ? STYLE_OBJ_FOR_CERTAIN_GROUP : {};
                        return <div style={styleObj} key={index}>{name}</div>
                    })}
                </>
            )
        };

        // From api, we are getting dm channels names as array of strings so need to iterate over it then we can display it in table
        const renderDMChannels = (dmChannels) => {
            return dmChannels.map((dmChannel, index) => {
                return (
                    <div className="group-activity table-col" key={index}>
                        {dmChannel}
                    </div>
                );
            });
        };

        const renderTotalQT = (record) => {
            if (!record.totalPoints && !record.lastCompletedAt) {
                return <div className="group-activity table-col total-qt-container">-</div>
            }
            let monthDiff = 0;
            if(record.lastCompletedAt) {
                monthDiff = findDiffBetweenMonths(record.lastCompletedAt);
            }
            return <div className="group-activity table-col total-qt-container">
                <span style={{ color: 'green' }}>{`${monthDiff ? 0 : record.totalPoints} QT`}</span>
                {record.lastCompletedAt ? <>
                    <span>{`Last QT: ${monthDiff ? `${monthDiff} ${monthDiff === 1 ? `month` : `months`} ago` : moment(record.lastCompletedAt).fromNow()}`}</span>
                    <span>{`${moment(record.lastCompletedAt).format("ll")}`}</span>
                </> : <span>{stats.noPastQT}</span>}
            </div>
        };

        const findDiffBetweenMonths = (date) => {
            const curDate = moment().format('DD/MM/YYYY');
            const targetDate = moment(date).format('DD/MM/YYYY');

            const getDate = (date) => moment(date, 'DD/MM/YYYY').startOf('month');
            const diff = Math.abs(getDate(curDate).diff(getDate(targetDate), 'months'));
            return diff;
        }

        // It is used to render form completed date or submitted date
        const renderBARC10Date = (record) => {
            const { formReviewedAt, formCompletedAt, formSendAt } = record;

            let numberOfDays = 0
            let lastCompletedBARC10Color = '#000000'
            let BARC10CalenderIcon = IMG_NOTE_GREY

            // last completed BARC10
            if (formCompletedAt) {
                const completedDate = moment(formCompletedAt);
                const currentDate = moment();
                // Here we are calculating number of days between two dates
                numberOfDays = currentDate.diff(completedDate, 'days');

                lastCompletedBARC10Color = numberOfDays > 30 ? TABLE_FONT_RED_COLOR : numberOfDays > 15 ? TABLE_FONT_YELLOW_COLOR : TABLE_FONT_GREEN_COLOR;
                BARC10CalenderIcon = numberOfDays > 30 ? IMG_NOTE_RED : numberOfDays > 15 ? IMG_NOTE_ORANGE : IMG_NOTE_GREEN;
            }

            return {
                children: <div className="barc10-container">
                    {formReviewedAt && <Tooltip placement="left" title={toolTips.barc10Reviewd}>
                        <div className="barc10-row-container">
                            <img src={IMG_EYE_GREY} className="barc10-row-icon" />
                            <span>{getFormattedDate(formReviewedAt)}</span>
                        </div></Tooltip>}
                    {formCompletedAt && <Tooltip placement="left" title={toolTips.lastbarc10Date}>
                        <div className="barc10-row-container">
                            <img src={BARC10CalenderIcon} className="barc10-row-icon" />
                            <span style={{ color: lastCompletedBARC10Color }}>{getFormattedDate(formCompletedAt)} {(`(${numberOfDays} days)`)}</span>
                        </div></Tooltip>}
                    {formSendAt && <Tooltip placement="left" title={toolTips.lastbarc10Send}>
                        <div className="barc10-row-container">
                            <img src={IMG_SEND_GREY} className="barc10-row-icon" />
                            <span>{getFormattedDate(formSendAt)}</span>
                        </div></Tooltip>}
                </div>
            };
        };

        const sortCatogoryByString = (arr) => {
            let records = [...arr]
            records.sort((a, b) => {
                let fa = a.category.toLowerCase(),
                    fb = b.category.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            return records
        }

        const renderWellnessPlan = (wellnessPlan) => {

            const { store: { StatsStore: { usersData } } } = this.props;

            if (wellnessPlan && wellnessPlan.length) {
                let wellnessPlanArr = sortCatogoryByString(wellnessPlan)
                /**
                 * Check for status:
                 * 1. Default blank - No answer at all
                 * 2. In-progress - Partially answered
                 * 3. Completed - All answer given
                 */

                // Default blank status
                let status = ""

                // Check for in-progress status
                let inProgress = wellnessPlanArr.filter(wp => {
                    if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount != wp.questioncount)) return wp
                })
                // Set status in-progress
                status = inProgress.length ? "in-progress" : ""

                // skip status check if it is already set
                if (!status) {
                    // Check for completed status
                    let completed = wellnessPlanArr.filter(wp => {
                        if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount == wp.questioncount)) return wp
                    })
                    // Set status completed
                    status = completed.length ? "completed" : ""
                }

                return {
                    children: <div>
                        {status && status == "in-progress" ? <div className="wellnessplan-container">
                            <img src={IMG_CHART} className="barc10-row-icon" />
                            <span style={{ color: '#F98654' }}>In Progress</span>
                        </div> : <div className="wellnessplan-container">
                            <img src={IMG_TICK_CIRCLE_SOLID} className="barc10-row-icon" />
                            <span style={{ color: '#3FBF61' }}>Completed</span>
                        </div>}

                        {wellnessPlanArr.map((wp, key) => {
                            return (
                                <>
                                    <div className="barc10-container" key={key}>
                                        {wp.category == "goals" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Goals: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                        {wp.category == "strengths" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Strength: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                        {wp.category == "stress-crisis-prevention" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Crisis: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                };
            }
        };

        const renderUsername = (text, row) => {
            const { color } = row;
            return (
                <>
                    <div className="link-text table-col">
                        {text}
                    </div>
                </>
            );
        };

        const renderUserBadges = (badgeType) => {
            return (
                <>
                    {badgeType && <UserBadges badgeType={badgeType} />}
                </>
            );
        };

        const renderIndatorData = (row) => {
            const { indatorFlag, monthFlag, thisMonthMessages, lastMonthMessages } = row;
            // Here if user has not messaged in this month or last month then we need to highlight in RED color 
            // Also need to add No in indator field, by default we are taking "No" value
            let text = 'No';
            let textForLastMonth = '';
            let backgroundColor = TABLE_ROW_RED_COLOR;
            // Here if user has messaged in this month then we need to highlight in GREEN color 
            // Also need to add current month/ this month range with total messages count
            if (indatorFlag === 'GREEN') {
                backgroundColor = TABLE_ROW_GREEN_COLOR;
                if (monthFlag === CASELOAD_USER_FLAGS.BOTH_MONTH) {
                    text = `${currentMonthRange.startDateOfMonth} to ${currentMonthRange.endDateOfMonth} : ${thisMonthMessages} Msgs`;
                    textForLastMonth += `${lastMonthRange.startDateOfMonth} to ${lastMonthRange.endDateOfMonth} : ${lastMonthMessages} Msgs`;
                } else if (monthFlag === CASELOAD_USER_FLAGS.THIS_MONTH) {
                    text = `${currentMonthRange.startDateOfMonth} to ${currentMonthRange.endDateOfMonth} : ${thisMonthMessages} Msgs`;
                }

            } else if (indatorFlag === 'YELLOW' && monthFlag === CASELOAD_USER_FLAGS.LAST_MONTH) {
                // Here if user has messaged in last month then we need to highlight in YELLOW color 
                // Also need to add last month range with total messages count
                text = `${lastMonthRange.startDateOfMonth} to ${lastMonthRange.endDateOfMonth} : ${lastMonthMessages} Msgs`;
                backgroundColor = TABLE_ROW_YELLOW_COLOR;
            }
            return {
                props: {
                    style: { background: backgroundColor, }
                },
                children:
                    <>
                        <div>{text}</div>
                        {textForLastMonth ? <div><br /> {textForLastMonth}</div> : null}
                    </>
            };
        };

        const columns = [
            {
                title: <div className="group-head">{caseloads.indator}</div>,
                width: '8%',
                sorter: true,
                render: renderIndatorData,
                dataIndex: "indator",
                sortOrder: getSortOrder('indator')
            },
            {
                title: <div className="group-head">{caseloads.fullName}</div>,
                width: '12%',
                render: (text) => <div className="table-col">{text}</div>,
                dataIndex: 'fullName',
                sorter: false,
            },
            {
                title: <div className="group-head">{caseloads.username}</div>,
                dataIndex: 'username',
                width: '10%',
                render: renderUsername,
                sorter: true,
                sortOrder: getSortOrder('username')
            },
            {
                title: <div className="group-head">{caseloads.peer}</div>,
                render: (text) => <div className="table-col">{text}</div>,
                width: '8%',
                dataIndex: 'peerFullName',
                sorter: false,
                className: !this.state.showPeerColumn && 'hide-peers-column'
            },
            {
                title: <div className="group-head">{caseloads.badge}</div>,
                dataIndex: 'badgeType',
                render: renderUserBadges,
                sorter: true,
                sortOrder: getSortOrder('badgeType')
            },
            {
                title: <div className="group-head">{caseloads.joinedDate} </div>,
                dataIndex: 'createdAt',
                width: '10%',
                render: renderJoinDate,
                sorter: true,
                sortOrder: getSortOrder('createdAt')
            },
            {
                title: <div className="group-head">{caseloads.groups} </div>,
                dataIndex: 'groups',
                width: '15%',
                render: (groups) => {
                    if (groups && groups.length) {
                        const isGroupLengthIsOneFound = groups.length === 1;
                        return renderGroupNames(groups, isGroupLengthIsOneFound);
                    }
                    //  If there is no groups present then we need to mark the empty cell in RED background color
                    return {
                        props: {
                            style: { background: TABLE_ROW_RED_COLOR }
                        },
                    };
                },
            },
            {
                title: <div className="group-head">{caseloads.dmChannels} </div>,
                dataIndex: 'dmChannels',
                width: '10%',
                render: (dmChannels) => {
                    if (dmChannels && dmChannels.length) {
                        // Check if DM Channel exists & selected user in dropdown is exists in that DM Channels array or not
                        if (dmChannels.indexOf(selectedUsernameForCaseLoad) > -1) {
                            return renderDMChannels(dmChannels);
                        }
                        // If DM Channel exists and login user name is not exists in dm channels array 
                        // then we need to turn the background color to RED
                        return {
                            props: {
                                style: { background: TABLE_ROW_RED_COLOR }
                            },
                            children: renderDMChannels(dmChannels)
                        };
                    }
                    // If there is no DM Channel present then we need to mark the empty cell in RED background color
                    return {
                        props: {
                            style: { background: TABLE_ROW_RED_COLOR }
                        },
                    };
                },

            },
            {
                title: <div className="group-head">{caseloads.wellnessPlan} </div>,
                width: '15%',
                sorter: true,
                render: renderWellnessPlan,
                dataIndex: "wellnessPlan",
                sortOrder: getSortOrder('wellnessPlan'),
            },
            {
                title: <div className="group-head">{caseloads.barc10Date} </div>,
                width: '15%',
                render: renderBARC10Date,
                sorter: true,
                dataIndex: "barc10Date",
                sortOrder: getSortOrder('barc10Date'),
            },
            {
                title: <div className="group-head">{`${caseloads.statusQT}`} </div>,
                width: '5%',
                render: renderTotalQT,
                sorter: true,
                dataIndex: "qtPoints",
                sortOrder: getSortOrder('qtPoints')
            },
        ];

        return (
            <React.Fragment>
                <div style={{
                    marginLeft: 10,
                    marginBottom: 10,
                    marginRight: 15,
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {/* Render all admin users to the case loads dropdown */}
                    {adminUsersForCaseLoad && adminUsersForCaseLoad.length ? (
                        <div>
                            <label>Caseloads</label>
                            <Select
                                value={selectedUserIdForCaseLoad}
                                style={{ width: 180, marginLeft: 10 }}
                                onChange={this.handleCaseloadsChange}
                            >
                                {adminUsersForCaseLoad.map(({ id, fullName }, index) => (
                                    <Option key={index} value={id} disabled={id === selectedUserIdForCaseLoad}>{fullName}</Option>
                                ))}
                                <Option value={NOT_ASSIGNED} disabled={NOT_ASSIGNED === selectedUserIdForCaseLoad}>Not Assigned</Option>
                                <Option value={WELCOME_PROCESS} disabled={WELCOME_PROCESS === selectedUserIdForCaseLoad}>Welcome Process</Option>
                                <Option value={ALL_ENROLLED} disabled={ALL_ENROLLED === selectedUserIdForCaseLoad}>All Enrolled</Option>
                            </Select>
                        </div>
                    ) : null}

                    {/* Render all the territories in the territory select dropdown */}
                    {selectedUserIdForCaseLoad && allTerritories && allTerritories.length ? (
                        <div style={{ marginLeft: 20 }}>
                            <label>Territory</label>
                            <Select
                                value={selectedTerritoryForCaseLoad}
                                style={{ width: 180, marginLeft: 10 }}
                                onChange={this.handleTerritoryChange}
                            >
                                <Option value={TERRITORIES_TYPE_ALL_TERRITORIES} disabled={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForCaseLoad}>All Territories</Option>
                                {allTerritories.map((territory, index) => (
                                    <Option key={index} value={territory} disabled={territory === selectedTerritoryForCaseLoad}>{territory}</Option>
                                ))}
                            </Select>
                        </div>
                    ) : null}
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={toJS(usersData)}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={rowEvents}
                />
            </React.Fragment>
        );
    }
}

export default StatsCaseLoads;