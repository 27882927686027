import "../../styles/global.scss";

import { Tabs, Icon, Table } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import _ from "lodash";
import { flagNeeds, user } from "../../en.json";
import moment from "moment";
import noop from "lodash";
import { toJS } from "mobx";
import { IS_HP } from "../../utils/getEnvironment";
import { withRouter } from "react-router-dom";
import MessageBubble from "./MessageBubble";

const TabPane = Tabs.TabPane;

const flagNeedsText = flagNeeds;

@withRouter
@inject("store")
@observer
class FlagNeeds extends React.Component {
  async componentDidMount() {
    const {
      store: {
        FlagNeedsStore: {
          setActiveTab,
        },
      },
    } = this.props;
    setActiveTab("createdAt");
    this.fetchFlagsData("createdAt");
  }

  openProfile = async (userId) => {
    const {
      store: {
        ProfileStore,
        MessagesStore: { setIsWaiting },
        MemberListStore,
        NotificationStore,
      },
    } = this.props;

    ProfileStore.reset();
    ProfileStore.setLoading(true);
    const member = await MemberListStore.getMemberDetails(userId);
    if (member) {
      ProfileStore.setMemberData(member);
      setIsWaiting(false);
      ProfileStore.changeSelectedAction("");
      ProfileStore.setMemberProfileVisibility(true);
      ProfileStore.toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
    ProfileStore.setLoading(false);
  };


  fetchFlagsData = (filterBy) => {
    const {
      store: {
        FlagNeedsStore: { fetchFlagNeeds },
      },
    } = this.props;
    fetchFlagNeeds(filterBy);
  };

  handleTableChange = (pagination) => {
    const {
      store: {
        FlagNeedsStore: { activeTab, setPaginationData },
      },
    } = this.props;

    setPaginationData(pagination);
    this.fetchFlagsData(activeTab);
  };

  handleTabChange = (tab) => {
    const {
      store: {
        FlagNeedsStore: {
          setActiveTab,
          setPaginationData
        },
      },
    } = this.props;

    const pagination = {
      current: 1,
      pageSize: 100,
      total: 0,
    };

    setPaginationData(pagination);
    setActiveTab(tab);
    this.fetchFlagsData(tab);
  };


  render() {
    const {
      store: {
        FlagNeedsStore: {
          loading,
          flagNeeds,
          activeTab,
          pagination,
        },
        GroupStore,
      },
    } = this.props;

    const columns = [
      {
        title: activeTab === "userId" ? flagNeedsText.name : flagNeedsText.flagDate,
        dataIndex: activeTab === "userId" ? "fullName" : "messageDate",
        render: (value, row, index) => {
          const obj = {
            children: <div className="link-text table-col" onClick={(() => {
              this.openProfile(row.userId)
            })}>{value}</div>,
            props: {}
          };
          if(activeTab === "userId"){
            if (index >= 1 && row.userId === flagNeeds[index - 1].userId) {
              obj.props.rowSpan = 0;
            } else {
              for (
                let i = 0;
                index + i !== flagNeeds.length &&
                row.userId === flagNeeds[index + i].userId;
                i += 1
              ) {
                obj.props.rowSpan = i + 1;
              }
            }
            return obj;
          } else {
            if (index >= 1 && value === flagNeeds[index - 1].messageDate) {
              obj.props.rowSpan = 0;
            } else {
              for (
                let i = 0;
                index + i !== flagNeeds.length &&
                value === flagNeeds[index + i].messageDate;
                i += 1
              ) {
                obj.props.rowSpan = i + 1;
              }
            }
            return obj;
          }
        }
      },
      {
        title: flagNeedsText.username,
        dataIndex: "username",
        render: (value, row, index) => {
          if(activeTab === "userId") {
            const obj = {
              children: <div className="link-text table-col" onClick={(() => {
                this.openProfile(row.userId)
              })}>{value}</div>,
              props: {}
            };
            if (index >= 1 && row.userId === flagNeeds[index - 1].userId) {
              obj.props.rowSpan = 0;
            } else {
              for (
                let i = 0;
                index + i !== flagNeeds.length &&
                row.userId === flagNeeds[index + i].userId;
                i += 1
              ) {
                obj.props.rowSpan = i + 1;
              }
            }
            return obj;
          }
          else {
            return <div className="link-text table-col">{value}</div>
          }
        } 
      },
      {
        title: flagNeedsText.message,
        dataIndex: "message",
        render: (text, row) => (
          <div className="right-section"> 
            <div className="display-flagmessage-Container align-items-center d-flex">
              <span>{row.messageDate}</span>
              {row.message ? 
              <MessageBubble
                isForFlagNeeds={true}
                message={row.message}
                groupChat={_.startsWith(row.channel, "GROUP_CHAT")}
              /> : null }
            </div>  
          </div>
        )
      },
      {
        title: "Channel",
        dataIndex: "groupname",
        render: (groupname) => <div className="table-col">{groupname || "NA"}</div>
      },
      {
        title: "Flagged by",
        dataIndex: "flaggedbyname",
        render: (flaggedbyname, row) => <div className="link-text table-col" onClick={(() => {
          this.openProfile(row.flaggedby)
        })}>{flaggedbyname}</div>
      },
      {
        title: flagNeedsText.flagDescription,
        dataIndex: "typeOfNeedLabel",
        render: (typeOfNeedLabel, row) => (
          <div className="table-col">
            {row.isAnonymity ? <i className="fa fa-user-secret" aria-hidden="true"></i> : null  } 
            {row.typeOfNeedColor === "red" ? (
              <> <span className="redFlagDescription">{row.typeOfNeedLabel}</span> <span> {row.description}</span> </>
            ) : (
              <> <span className="blueFlagDescription">{row.typeOfNeedLabel}</span> <span> {row.description}</span> </>
            )}
          </div>
        )  
      },
      // {
      //   title: flagNeedsText.flagForward,
      //   dataIndex: "description",
      //   render: () => <div className="link-text table-col"><i className="fa fa-envelope" /></div>
      // }
    ];

   

    noop(toJS(GroupStore));

    return (
      <div className="member-table">
        <div className="search-member-container">
        <Tabs onChange={this.handleTabChange} activeKey={activeTab}>
        <TabPane
              tab={<div className="alert-tab">Flags by date</div>}
              key="createdAt"
            >
              <div
                className="d-flex"
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  flexDirection: "row-reverse",
                }}
              >
              </div>
            </TabPane>
            <TabPane
              tab={<div className="alert-tab">Flags by user</div>}
              key="userId"
            >
              <div
                className="d-flex"
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  flexDirection: "row-reverse",
                }}
              >
              </div>
            </TabPane>
          </Tabs>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={toJS(flagNeeds)}
          onChange={this.handleTableChange}
          pagination={pagination}
          loading={loading}
        />
      </div>
    );
  }
}

export default FlagNeeds;
