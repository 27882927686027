import { destroy, types } from "mobx-state-tree";
import _ from "lodash";
import moment from "moment";
import { toJS } from "mobx";
// import axios from "axios";

import Admin from "./Admin";
import Group from "./Group";
import Member from "./Member";
import AuthStore from "../stores/AuthStore";
import FlaggedMessagesList from "../stores/FlaggedMessagesStore";
import GroupStore from "../stores/Groups";
import NotificationStore from "../stores/NotificationStore";
import ProfileStore from "../stores/ProfileStore";
import SiteStore from "../stores/SiteStore";
import { members } from "../en.json";
import {
  fetchLastMessage,
  getPubnubInstanceByUserType,
  unsubscribeSingleChannel,
} from "../utils/PubnubMethods";
import ApiService from "../utils/ApiService";
import SecureLocalStorage from "../utils/SecureLS";
import { IS_ISLAND } from "../utils/getEnvironment";
var mixpanel = require("mixpanel-browser");

const MemberList = types
  .model("MemberList", {
    members: types.array(Member),
    adminUsers: types.optional(types.frozen({
      id: types.maybeNull(types.number),
      username: types.maybeNull(types.string),
      fullName: types.maybeNull(types.string)
    })),
    adminUsersForCaseLoad: types.optional(types.frozen({
      id: types.maybeNull(types.number),
      username: types.maybeNull(types.string),
      fullName: types.maybeNull(types.string)
    })),
    resolveLoading: false,
    approvedMembers: types.array(Member),
    unApprovedMembers: types.array(Member),
    resolvedMembers: types.array(Member),
    unResolvedMembers: types.array(Member),
    unApprovedMembersSkip: 0,
    approvedMembersSkip: 0,
    newAccountsTotal: types.maybeNull(types.number),
    resolvingId: types.maybe(types.number),
    unApprovedMembersTotal: types.maybe(types.number),
    approvedMembersTotal: types.maybe(types.number),
    unResolvedMembersTotal: 0,
    resolvedMembersSkip: 0,
    unResolvedMembersSkip: 0,
    loadingResolvedMembers: false,
    loadingUnResolvedMembers: false,
    resolvedMembersTotal: 0,
    loading: false,
    queryString: "",
    modSearchQuery: "",
    selectedKey: "fullName",
    pagination: types.frozen({
      pageSize: 30,
      total: 0,
      current: 1,
    }),
    assignEmailLoading: false,
    unreadWaitingMessages: types.array(
      types.frozen({
        channelId: types.number,
      })
    ),
    assignedAdmins: types.array(Admin),
    detailsLoading: false,
    membersGroupMessageHistoryLoading: false,
    isMembersGroupHistoryDataFetched: false,
    sortColumn: types.maybe(types.string),
    sortOrder: types.maybe(types.string),
    semiModerators: types.array(types.maybeNull(types.string)),
    admins: types.array(types.string),
    usernameMap: types.map(types.string),
    moderators: types.array(Member),
    moderatorsTotal: 0,
    moderatorsSkip: 0,
    moderatorsLoading: false,
    searchedMods: types.array(Admin),
    searchedModSemiModTotal: types.maybe(types.number),
    selectedKeyName: "Member Name",
    showOnlyAdmins: false,
    selectedUserId: types.maybeNull(types.number),
    assignedUsers: types.map(
      types.frozen({ assignedAdminName: types.array(types.string) })
    ),
  })
  .actions((self) => ({
    clearAssignInfo() {
      self.assignedAdmins = [];
      self.modSearchQuery = "";
    },
    setModeratorsLoading(value) {
      self.moderatorsLoading = value;
    },
    setSelectedUserId(id) {
      self.selectedUserId = id;
    },
    toggleAdmins(value) {
      if (self.loading) {
        return;
      }
      self.showOnlyAdmins = value;
      self.members = [];
      self.callFetchMembers();
    },
    reset() {
      self.members = [];
      self.approvedMembers = [];
      self.unApprovedMembers = [];
      self.resetPagination();
      self.queryString = "";
      self.showOnlyAdmins = false;
    },
    resetAll() {
      self.queryString = "";
      self.modSearchQuery = "";
      self.selectedKey = "fullName";
      self.members = [];
      self.adminUsers = [];
      self.adminUsersForCaseLoad = [];
      self.approvedMembers = [];
      self.unApprovedMembers = [];
      self.resolvedMembers = [];
      self.unResolvedMembers = [];
    },

    callFetchMembers() {
      const siteId = SiteStore.selectedSiteId;
      if (siteId && siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        if (self.queryString) {
          self.fetchSearchedMembers(siteDataObj);
        } else {
          self.fetchMembers(siteDataObj);
        }
      } else {
        if (self.queryString) {
          self.fetchSearchedMembers({});
        } else {
          self.fetchMembers();
        }
      }
    },
    setAssignEmailLoading(value) {
      self.assignEmailLoading = value;
    },
    setAdminUsers(arr) {
      self.adminUsers = arr;
    },
    setAdminUsersForCaseLoad(arr) {
      self.adminUsersForCaseLoad = arr;
    },
    clearQueryString() {
      if (self.loading) {
        return;
      }
      self.queryString = "";
      self.members = [];
      self.resetPagination();
      self.callFetchMembers();
    },
    emptyApprovedMembers() {
      self.approvedMembers = [];
    },
    emptyUnApprovedMembers() {
      self.unApprovedMembers = [];
    },
    emptyAlert() {
      self.emptyApprovedMembers();
      self.emptyUnApprovedMembers();
      self.emptyUnResolvedMembers();
      self.emptyResolvedMembers();
      self.resetSkip();
      self.resetTotal();
    },
    emptyUnResolvedMembers() {
      self.unResolvedMembers = [];
      self.unResolvedMembersSkip = 0;
      self.unResolvedMembersTotal = 0;
    },
    emptyResolvedMembers() {
      self.resolvedMembers = [];
      self.resolvedMembersSkip = 0;
      self.resolvedMembersTotal = 0;
    },
    setMembersGroupMessageHistoryLoading(value) {
      self.membersGroupMessageHistoryLoading = value;
    },
    setIsMembersGroupHistoryDataFetched(value) {
      self.isMembersGroupHistoryDataFetched = value;
    },
    addSendMessageInGroupFlagForUsers(mainUsersArr = [], compareArr = []) {
      if (mainUsersArr.length) {
        for (const userObj of mainUsersArr) {
          let isUserSentMessageInGroup = false;
          const { id } = userObj;
          const arrOfUserMatchInCompareArr = compareArr.filter(obj => obj.userId === id);
          if (arrOfUserMatchInCompareArr && arrOfUserMatchInCompareArr.length) {
            isUserSentMessageInGroup = true;
          }
          userObj['isUserSentMessageInGroup'] = isUserSentMessageInGroup;
        }
      }
      return mainUsersArr;
    },
    async fetchMessageSendHistoryForUsers() {
      try {
        // Comment this api call because there is no report related to the nida so once it is created then we can make it enabled
        // const EVENT_NAME = 'Message Publish Success';
        // const params = { 'report[]': EVENT_NAME };

        // const response = await ApiService.getRequest("mixpanel-reports", params);
        // const { success, data } = response;
        // if (success && data.length) {
        //   const [eventData] = data;
        //   const { name, jsonData = [] } = eventData;
        //   const isEventNameMatched = name.toLowerCase() === EVENT_NAME.toLowerCase();
        //   if (isEventNameMatched && jsonData && jsonData.length) {
        //     const updatedUsersArr = self.addSendMessageInGroupFlagForUsers([...toJS(self.members)], jsonData);
        //     self.setCopyMembersData(updatedUsersArr);
        //     self.setIsMembersGroupHistoryDataFetched(true);
        //     self.setMembersGroupMessageHistoryLoading(false);
        //   } else {
        //     self.setIsMembersGroupHistoryDataFetched(false);
        //     self.setMembersGroupMessageHistoryLoading(false);
        //   }
        // } else {
        self.setIsMembersGroupHistoryDataFetched(false);
        self.setMembersGroupMessageHistoryLoading(false);
        // }
      } catch (error) {
        mixpanel.track("Fetch Message Publish Users", { error: JSON.stringify(error), from: 'WEB' });
        self.setIsMembersGroupHistoryDataFetched(false);
        self.setMembersGroupMessageHistoryLoading(false);
      }
    },
    async fetchAdminUsers(params = {}) {
      try {
        const { isMarigoldAdminsRequired = true, isAdminsRequiredInCaseLoad = false } = params;
        self.setLoading(true);
        params = {
          ...params,
          isMarigoldAdminsRequired,
          sortBy: 'fullName',
          sortOrder: 'asc'
        };
        const response = await ApiService.getRequest("admins-list", params);
        const { success, data } = response;
        if (success && data.length) {
          const adminUsersArr = data.map(({ id, username, fullName }, index) => ({ id, username, fullName }));
          if (isAdminsRequiredInCaseLoad) {
            self.setAdminUsersForCaseLoad(adminUsersArr);
          } else {
            self.setAdminUsers(adminUsersArr);
          }
        }
        self.setLoading(false);
      } catch (error) {
        self.setLoading(false);
      }
    },
    async fetchMembers(params = {}) {
      self.emptyAlert();
      self.setMembersGroupMessageHistoryLoading(true);
      self.setIsMembersGroupHistoryDataFetched(false);
      if (self.showOnlyAdmins) {
        params = {
          ...params,
          $skip: (self.pagination.current - 1) * self.pagination.pageSize,
          $limit: self.pagination.pageSize,
          include: true,
          includeOnly: "groups,dm-channels,user-consent,user-outreach-history,sites",  //user-consent
          "type[$in][]=moderator&type[$in][]=FD&type[$in][]=NOA&type[$in][]":
            "SA",
          isUserConsentRequired: true
        };
      } else {
        params = {
          ...params,
          $skip: (self.pagination.current - 1) * self.pagination.pageSize,
          $limit: self.pagination.pageSize,
          include: true,
          includeOnly: "groups,dm-channels,user-consent,user-outreach-history,assigned-peers,sites", //user-consent
          "type[$in][]=semi-moderator&type[$in][]": "user",
          isUserConsentRequired: true,
          isAssignedPeerDetailsRequired: true
        };
      }
      if (self.sortColumn) {
        let order;
        switch (self.sortOrder) {
          case "ascend":
            order = 1;
            break;
          case "descend":
            order = -1;
            break;
          default:
            order = 0;
            break;
        }
        params = {
          ...params,
          [`$sort[${self.sortColumn}]`]: order,
        };
      }
      self.setLoading(true);
      const response = await ApiService.getRequest("users", params);

      if (response.success) {
        if (IS_ISLAND) {
          self.fetchMessageSendHistoryForUsers();
        }
        self.setMembers(response.data);
        self.setPaginationData(self.parseMetaData(response.meta));
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", members.membersFetchError);
        return;
      }
      self.setLoading(false);
    },
    async fetchSearchedMembers(params = {}) {
      self.emptyAlert();
      if (IS_ISLAND) {
        self.setMembersGroupMessageHistoryLoading(true);
        self.setIsMembersGroupHistoryDataFetched(false);
      }
      if (self.showOnlyAdmins && self.selectedKey !== "name") {
        params = {
          ...params,
          $skip: (self.pagination.current - 1) * self.pagination.pageSize,
          $limit: self.pagination.pageSize,
          "type[$in][]=moderator&type[$in][]=FD&type[$in][]=NOA&type[$in][]":
            "SA",
          include: true,
          includeOnly: "groups,dm-channels,user-consent,user-outreach-history",
          isUserConsentRequired: true
        };
      } else if (!self.showOnlyAdmins && self.selectedKey !== "name") {
        params = {
          ...params,
          $skip: (self.pagination.current - 1) * self.pagination.pageSize,
          $limit: self.pagination.pageSize,
          "type[$in][]=semi-moderator&type[$in][]": "user",
          include: true,
          includeOnly: "groups,dm-channels,user-consent,user-outreach-history",
          isUserConsentRequired: true
        };
      }
      if (self.selectedKey === "name") {
        params = {
          ...params,
          skip: (self.pagination.current - 1) * self.pagination.pageSize,
          $limit: self.pagination.pageSize,
          groupName: `${_.trim(self.queryString)}`,
        };
        if (self.showOnlyAdmins) {
          params = {
            ...params,
            type: ["SA", "FD", "NOA", "moderator"],
          };
        } else {
          params = {
            ...params,
            type: ["user", "semi-moderator"],
          };
        }
      } else if (self.selectedKey === "siteId") {
        const siteIds = SiteStore.getSiteIdFromAllSites(self.queryString);
        if (siteIds.length) {
          const obj = siteIds.reduce((obj, item, index) => {
            obj[`siteId[${index}]`] = item;
            return obj;
          }, {});
          params = {
            ...params,
            include: true,
            includeOnly: "users,groups",
            $limit: self.pagination.pageSize,
            $skip: (self.pagination.current - 1) * self.pagination.pageSize,
            ...obj,
          };
        } else {
          NotificationStore.setNotification("error", members.noSuchSite);
          return;
        }
      } else {
        let formattedPhone = "";
        if (self.selectedKey === "phoneNumber") {
          if (!_.startsWith(self.queryString, "(")) {
            formattedPhone = "(";

            if (self.queryString.length >= 3) {
              formattedPhone =
                formattedPhone +
                self.queryString.slice(0, 3) +
                ")" +
                " " +
                self.queryString.slice(3, 6);
            }
            if (self.queryString.length >= 6) {
              formattedPhone = formattedPhone + "-" + self.queryString.slice(6);
            } else if (self.queryString.length < 3) {
              formattedPhone = formattedPhone + self.queryString;
            }
          }
        }
        if (formattedPhone.length) {
          params = {
            ...params,
            include: true,
            includeOnly: "groups",
            [`$or[0][${self.selectedKey}][$iLike]`]: `%25${formattedPhone}%25`,
            [`$or[1][${self.selectedKey}][$iLike]`]: `%25${_.trim(
              self.queryString
            )}%25`,
          };
        } else {
          params = {
            ...params,
            include: true,
            includeOnly: "groups",
            [`${self.selectedKey}[$iLike]`]: `%25${_.trim(
              self.queryString
            )}%25`,
          };
        }
      }

      if (self.sortColumn) {
        let order;
        switch (self.sortOrder) {
          case "ascend":
            order = 1;
            break;
          case "descend":
            order = -1;
            break;
          default:
            order = 0;
            break;
        }
        params = {
          ...params,
          [`$sort[${self.sortColumn}]`]: order,
        };
      }
      self.setLoading(true);
      let response;
      if (self.selectedKey !== "name") {
        params = {
          ...params,
          includeOnly: "groups,dm-channels,user-consent,user-outreach-history,assigned-peers,sites",
          isAssignedPeerDetailsRequired: true,
          isUserConsentRequired: true
        }
        response = await ApiService.getRequest("users", params);
        if (response.success) {
          if (IS_ISLAND) {
            self.fetchMessageSendHistoryForUsers();
          }
          self.setMembers(response.data);
          self.setPaginationData(self.parseMetaData(response.meta));
        } else {
          NotificationStore.setNotification("error", members.membersFetchError);
        }
        self.setLoading(false);
      } else if (self.selectedKey === "name") {
        response = await ApiService.postRequest("group-users", params);
        if (
          response.success &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          let members = [];
          let meta = {};
          response.data.data.forEach((member) => {
            member.id = member.userId;
            members.push(member);
          });
          meta = {
            total: response.data.total,
            skip: response.data.skip,
            limit: response.data.limit,
          };
          self.setMembers(members);
          self.setPaginationData(self.parseMetaData(meta));
        } else {
          self.setLoading(false);
          NotificationStore.setNotification("error", members.membersFetchError);
          return;
        }
        self.setLoading(false);
      }
    },
    async fetchSearchedMods(params = {}) {
      params = {
        ...params,
        "type[$in][]=moderator&type[$in][]": "SA",
        "$select[]=id&$select[]=username&$select[]=email&$select[]=siteId&$select[]=fullName&$select[]":
          "type",
      };

      params = {
        ...params,
        [`$or[0][username][$iLike]`]: `%25${_.trim(self.modSearchQuery)}%25`,
        [`$or[1][fullName][$iLike]`]: `%25${_.trim(self.modSearchQuery)}%25`,
      };
      self.setLoading(true);
      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        self.saveSearchedModsTotal(response.meta);
        self.addSearchedModSemiMod(response.data);
      } else {
        self.setLoading(false);
        NotificationStore.setNotification(
          "error",
          members.fetchSearchedModsError
        );
      }
    },
    assignAdmins(admin) {
      if (!_.includes(self.assignedAdmins, { id: admin.id })) {
        self.assignedAdmins.push(_.cloneDeep(admin));
      }
    },
    resetPagination() {
      self.pagination = {
        pageSize: 30,
        current: 1,
        total: 0,
      };
    },
    removeAssignedAdmin(id) {
      self.assignedAdmins = self.assignedAdmins.filter(
        (admin) => admin.id !== id
      );
    },
    addSearchedModSemiMod(data) {
      self.searchedMods = [...self.searchedMods, ...data];
      self.loading = false;
    },
    setModeratorsData(data) {
      const mods = data.map((item) => {
        return Member.create(item);
      });
      self.moderators = [...self.moderators, ...mods];
    },
    clearSearchedMods() {
      self.searchedMods = [];
    },
    async fetchResolvedMembers(params = {}) {
      params = {
        ...params,
        $skip: self.resolvedMembersSkip,
        "$sort[resolvedAt]": -1,
        isResolved: true,
        "type[$in][]=semi-moderator&type[$in][]": "user",
        $limit: 10,
      };
      self.setLoadingResolvedMembers(true);
      const response = await ApiService.getRequest("users", params);

      if (response.success) {
        self.setResolvedMembersMeta(_.cloneDeep(response.meta));
        self.setResolvedMembers(_.cloneDeep(response.data));
        self.setLoadingResolvedMembers(false);
      } else {
        self.setLoadingResolvedMembers(false);
        NotificationStore.setNotification("error", members.membersFetchError);
        return;
      }
    },
    async fetchUnResolvedMembers(params = {}) {
      self.setLoadingUnResolvedMembers(true);
      params = {
        ...params,
        $skip: self.unResolvedMembersSkip,
        "$sort[unresolvedAt]": -1,
        isResolved: false,
        "type[$in][]=semi-moderator&type[$in][]": "user",
        $limit: 10,
      };

      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        self.setUnResolvedMembersMeta(_.cloneDeep(response.meta));
        self.setUnResolvedMembers(_.cloneDeep(response.data));
        self.setLoadingUnResolvedMembers(false);
      } else {
        NotificationStore.setNotification("error", members.membersFetchError);
        self.setLoadingUnResolvedMembers(false);
        return;
      }
    },
    setLoadingResolvedMembers(value) {
      self.loadingResolvedMembers = value;
    },
    setLoadingUnResolvedMembers(value) {
      self.loadingUnResolvedMembers = value;
    },
    setResolvedMembersMeta(meta) {
      self.resolvedMembersTotal = meta.total;
      self.resolvedMembersSkip = self.resolvedMembersSkip + meta.limit;
    },
    setUnResolvedMembersMeta(meta) {
      self.unResolvedMembersTotal = meta.total;
      self.unResolvedMembersSkip = self.unResolvedMembersSkip + meta.limit;
    },
    async fetchUnApprovedMembersCount(params = {}) {
      params = {
        ...params,
        isApproved: false,
        "type[$in][]=semi-moderator&type[$in][]": "user",
        $skip: 0,
        $limit: 0,
      };
      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        self.updateUnApprovedHeaderTotal(response.meta);
      } else {
        NotificationStore.setNotification("error", members.membersFetchError);
      }
    },
    updateUnApprovedHeaderTotal(meta) {
      self.newAccountsTotal = meta.total;
    },
    async getAssignedToResponses(userIds) {
      if (userIds.length) {
        const query = userIds.reduce((obj, id, index) => {
          const key = `sourceUserId[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = id;
          }
          return obj;
        }, {});
        let skip = 0,
          total = 1,
          assigned = [];
        while (skip < total) {
          const params = {
            ...query,
            $skip: skip,
          };
          const response = await ApiService.getRequest("user-assign", params);
          if (response.success && response.data.length) {
            skip = skip + response.meta.limit;
            total = response.meta.total;
            assigned = [...assigned, ...response.data];
          } else {
            return;
          }
        }
        return assigned;
      }
    },

    async fetchUnApprovedMembers(params = {}) {
      const limit = 10;
      params = {
        ...params,
        "$sort[createdAt]": -1,
        isApproved: false,
        "type[$in][]=semi-moderator&type[$in][]": "user",
        $skip: self.unApprovedMembersSkip,
        $limit: limit,
      };

      self.setLoading(true);
      let userIds = [];
      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        self.setUnApprovedMembers(response.data);
        response.data.forEach((user) => {
          userIds.push(user.id);
        });
        const assigned = await self.getAssignedToResponses(userIds);
        if (assigned && assigned.length) {
          self.setAssignedUsers(assigned);
        }
        if (params.$skip === 0) {
          self.updateUnApprovedTotal(response.meta);
        }
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", members.membersFetchError);
        return;
      }
      self.setLoading(false);
    },
    async getMembersByKey(key, selectedKeyName) {
      self.selectedKey = key;
      self.selectedKeyName = selectedKeyName;
    },
    resetTotal() {
      self.resolvedMembersTotal = 0;
      self.unResolvedMembersTotal = 0;
      self.unApprovedMembersTotal = 0;
      self.approvedMembersTotal = 0;
    },
    updateUnApprovedTotal(meta) {
      self.unApprovedMembersTotal = meta.total;
    },
    updateApprovedTotal(meta) {
      self.approvedMembersTotal = meta.total;
    },
    updateUsernameAndSite(
      username,
      fullName,
      userId,
      siteId,
      isApproved,
      allMembers
    ) {
      if (allMembers) {
        const member = _.find(self.members, { id: userId });
        if (member) {
          member.username = username;
          member.siteId = siteId;
          member.fullName = fullName;
          member.isApproved = isApproved;
        }
      } else {
        const approvedUser = _.find(self.approvedMembers, { id: userId });
        const unApprovedUser = _.find(self.unApprovedMembers, { id: userId });
        if (approvedUser) {
          approvedUser.username = username;
          approvedUser.siteId = siteId;
        } else if (unApprovedUser) {
          unApprovedUser.username = username;
          unApprovedUser.siteId = siteId;
        }
      }
    },
    resetSkip() {
      self.approvedMembersSkip = 0;
      self.unApprovedMembersSkip = 0;
    },
    removeMember(userId) {
      let user = _.find(self.members, { id: userId });
      if (user) {
        destroy(user);
        ProfileStore.setProfileVisibility(false);
      }
    },
    removeUnApproved(userId) {
      let user = _.find(self.unApprovedMembers, { id: userId });
      if (user) {
        destroy(user);
        self.addToApprovedMembers(user);
        self.decreaseUnapprovedTotal();
        ProfileStore.setProfileVisibility(false);
      }
    },
    addToApprovedMembers(user) {
      self.approvedMembers = [user, ...self.approvedMembers];
    },
    decreaseUnapprovedTotal() {
      self.unApprovedMembersTotal = self.unApprovedMembersTotal - 1;
      self.newAccountsTotal = self.newAccountsTotal - 1;
    },
    async updateResolveStatus(userId, isResolved) {
      self.setSelectId(userId);
      self.setResolveLoading(true);
      const response = await ApiService.patchRequest("users", userId, {
        isResolved,
      });
      if (response.success) {
        self.makeread(userId);
        NotificationStore.setNotification(
          "success",
          members.resolveMemberSuccess
        );
      } else {
        NotificationStore.setNotification("error", members.resolveMemberError);
      }
      self.setResolveLoading(false);
    },
    setResolveLoading(value) {
      self.resolveLoading = value;
    },
    setSelectId(userId) {
      self.resolvingId = userId;
    },

    addToUnResolved(data) {
      const userId = data.id;
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const channel = [`WAITING_ROOM_${userId}`];
      fetchLastMessage(pubnub, channel, false);
      const newUnResolvedMember = Member.create(data);
      const checkInResolvedList = _.find(self.resolvedMembers, { id: userId });
      if (checkInResolvedList) {
        destroy(checkInResolvedList);
      }
      const checkInUnResolvedList = _.find(self.unResolvedMembers, {
        id: userId,
      });
      if (!checkInUnResolvedList) {
        self.unResolvedMembers = [
          newUnResolvedMember,
          ...self.unResolvedMembers,
        ];
      }
    },

    removeFromUnResolved(data) {
      const userId = data.id;
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const channel = [`WAITING_ROOM_${userId}`];
      fetchLastMessage(pubnub, channel, true);
      let user = _.find(self.unResolvedMembers, { id: userId });
      if (user) {
        destroy(user);
      }
      const newResolved = Member.create(data);
      self.resolvedMembers = [newResolved, ...self.resolvedMembers];
    },
    toggleUnread(message) {
      const channelId = Number(message.channel.split("_")[2]);
      let member = _.find(self.unResolvedMembers, { id: channelId });
      const selfId = AuthStore.userId;
      if (member) {
        if (selfId === member.handledBy) {
          member.unread = true;
        }
        member.lastMessageTime = message.timetoken;
        member.lastMessage = message.message.text;
        if (
          !self.unreadWaitingMessages.filter(
            (unread) => unread.channelId === channelId
          ).length
        ) {
          if (selfId === member.handledBy) {
            self.addToUnread(channelId);
          }
        }
      }
    },
    addToUnread(id) {
      self.unreadWaitingMessages.push({
        channelId: id,
      });
    },
    removeFromUnread(id) {
      const index = _.indexOf(
        self.unreadWaitingMessages,
        _.find(self.unreadWaitingMessages, { channelId: id })
      );
      if (index >= 0) {
        self.unreadWaitingMessages.splice(index, 1);
      }
    },
    makeread(id) {
      let member = _.find(self.unResolvedMembers, { id });
      if (member) {
        member.unread = false;
        self.removeFromUnread(id);
      }
    },
    removeFromResolved(userId) {
      let user = _.find(self.unResolvedMembers, { id: userId });
      if (user) {
        destroy(user);
      }
    },
    removeApproved(userId) {
      let user = _.find(self.approvedMembers, { id: userId });
      if (user) {
        destroy(user);
        ProfileStore.setProfileVisibility(false);
      }
    },
    skipApproved() {
      self.approvedMembersSkip = self.approvedMembersSkip + 10;
    },
    skipUnApproved() {
      self.unApprovedMembersSkip = self.unApprovedMembersSkip + 10;
    },
    async fetchApprovedMembers(params = {}) {
      const limit = 10;
      params = {
        ...params,
        "$sort[approvedAt]": -1,
        isApproved: true,
        "type[$in][]=semi-moderator&type[$in][]": "user",
        $skip: self.approvedMembersSkip,
        $limit: limit,
      };
      self.setLoading(true);
      const response = await ApiService.getRequest("users", params);

      if (response.success) {
        self.setApprovedMembers(response.data);
        self.updateApprovedTotal(response.meta);
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", members.membersFetchError);
        return;
      }
      self.setLoading(false);
    },

    async deleteUser(userId) {
      const response = await ApiService.deleteRequest("users", userId);
      if (response.success) {
        NotificationStore.setNotification(
          "success",
          members.deleteMemberSuccess
        );
        if (response.data.dms && response.data.dms.length) {
          AuthStore.removeDMFromUserDMs(response.data.dms);
        }
        ProfileStore.setMemberProfileVisibility(false);
        ProfileStore.setProfileVisibility(false);
        self.removeMember(userId);
        self.removeUnApproved(userId);
        self.removeApproved(userId);
        FlaggedMessagesList.removeEntry(userId);
      } else {
        NotificationStore.setNotification("error", members.deleteMemberError);
      }
    },
    setLoading(value) {
      self.loading = value;
    },
    setCopyMembersData(data) {
      self.members = data;
    },
    setMembers(data) {
      self.members = _.map(data, (value, key) => {
        const groups = _.map(value.groups, (group) => Group.create(group));
        value.groups = groups;
        const userConsentObj = value.userConsent || null;
        value.userConsentType = userConsentObj ? userConsentObj.type : null;
        self.usernameMap[value.id] = value.username;
        if (value.assignedPeersDetails) {
          const { assignedPeerUserDetails, submittedByUserDetails, ...rest } = value.assignedPeersDetails;
          value.assignedPeersDetails = {
            ...rest,
            assignedPeerFullName: assignedPeerUserDetails ? assignedPeerUserDetails.fullName : '',
            submittedByUserFullName: submittedByUserDetails ? submittedByUserDetails.fullName : ''
          }
        }
        return Member.create(value);
      });
    },
    updateStatusInGroup(groupId, userId, statusId) {
      const members = _.cloneDeep(self.members);
      const memberIndex = members.findIndex((member) => member.id === userId);
      if (memberIndex >= 0) {
        let memberToEdit = members[memberIndex];
        if (memberToEdit.groups && memberToEdit.groups.length) {
          const memberGroups = memberToEdit.groups;
          const groupIndex = memberToEdit.groups.findIndex(
            (group) => group.id === groupId
          );
          if (groupIndex >= 0) {
            let groupToEdit = memberGroups[groupIndex];
            if (groupToEdit.user_groups) {
              groupToEdit.user_groups.status = statusId;
              memberGroups[groupIndex] = groupToEdit;
            }
          }
        }
        members[memberIndex] = memberToEdit;
      }
      self.members = members;
    },
    setApprovedMembers(data) {
      const arr = _.map(data, (value, key) => {
        return Member.create(value);
      });
      self.approvedMembers = _.concat(self.approvedMembers, arr);
    },
    setUnApprovedMembers(data) {
      const arr = _.map(data, (value, key) => {
        return Member.create(value);
      });
      self.unApprovedMembers = _.concat(self.unApprovedMembers, arr);
    },
    setResolvedMembers(data) {
      let channels = [];
      const arr = _.map(data, (value, key) => {
        const channel = `WAITING_ROOM_${value.id}`;
        channels.push(channel);
        return Member.create(value);
      });
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      fetchLastMessage(pubnub, channels, true);
      self.resolvedMembers = [...self.resolvedMembers, ...arr];
    },
    setUnResolvedMembers(data) {
      let channels = [];
      const arr = _.map(data, (value, key) => {
        const channel = `WAITING_ROOM_${value.id}`;
        channels.push(channel);
        return Member.create(value);
      });
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      fetchLastMessage(pubnub, channels, false);
      self.unResolvedMembers = [...self.unResolvedMembers, ...arr];
    },

    setPaginationData(pagination) {
      self.pagination = pagination;
    },
    parseMetaData(meta) {
      return {
        pageSize: meta.limit,
        current: meta.skip / meta.limit + 1,
        total: meta.total,
      };
    },
    assignMemberToGroup(memberId, groupId) {
      const members = _.cloneDeep(self.members);
      self.members = _.map(members, (member) => {
        if (member.id === memberId) {
          let group = _.cloneDeep(
            _.find(GroupStore.allGroups, { id: groupId })
          );
          if (!group && ProfileStore.userId === memberId) {
            group = _.cloneDeep(
              _.find(ProfileStore.assignedGroups, { id: groupId })
            );
          }
          if (group) {
            member.groups.push(group);
          }
        }
        return member;
      });
    },
    removeGroup(memberId, groupId) {
      const userId = AuthStore.userId;
      const members = _.cloneDeep(self.members);
      self.members = _.map(members, (member) => {
        if (member.id === memberId) {
          _.remove(member.groups, { id: groupId });
        }
        return member;
      });
      if (userId === memberId) {
        AuthStore.removeGroupFromUserGroups(groupId);
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        const groupChannel = `GROUP_CHAT_${groupId}`;
        unsubscribeSingleChannel(pubnub, groupChannel);
      }
    },
    addLastMessages(channel, timetoken, message, isResolved) {
      const channelId = Number(channel.split("_")[2]);
      if (isResolved) {
        const member = _.find(self.resolvedMembers, { id: channelId });
        member.lastMessageTime = timetoken;
        if (message.type === "text") {
          member.lastMessage = message.text;
          member.lastMessageSender = message.sender;
        }
        // else if (message.type === "form") {
        //   member.lastMessage = "a Form";
        // } else if (message.type === "add") {
        //   member.lastMessage = "a message";
        // }
        else {
          member.lastMessage = "a message";
          member.lastMessageSender = message.sender;
        }
      } else {
        const member = _.find(self.unResolvedMembers, { id: channelId });
        member.lastMessageTime = timetoken;
        if (message.type === "text") {
          member.lastMessage = message.text;
          member.lastMessageSender = message.sender;
        }
        // else if (message.type === "form") {
        //   member.lastMessage = "a Form";
        // } else if (message.type === "add") {
        //   member.lastMessage = "a message";
        // }
        else {
          member.lastMessage = "a message";
          member.lastMessageSender = message.sender;
        }
      }
    },
    setSortParameters(column, order) {
      self.sortColumn = column;
      self.sortOrder = order;
    },
    async getMemberDetails(userId) {
      mixpanel.track("Opening Profile", { userId: userId, from: "WEB" });
      self.setDetailsLoading(true);
      const response = await ApiService.getRequest("users", {
        id: userId,
        include: true,
        includeOnly: "pend-users,user-forms,user-consent,profile-question-responses,assigned-peers",
        isReviewedPersonDetailsRequired: true,
        isAssignedPeerDetailsRequired: true
      });
      let userResponse = {};
      if (response.success) {
        mixpanel.track("Opened Profile", { userId: userId, from: "WEB" });

        let groups = [];
        // let channels = [];
        let groupSkip = 0,
          // dmSkip = 0,
          // dmTotal = 1,
          groupTotal = 1;
        while (groupSkip < groupTotal) {
          const groupResponse = await ApiService.getRequest("user-groups", {
            userId,
            include: "true",
            $skip: groupSkip,
          });
          if (groupResponse.success) {
            let groupItem = [];
            groupTotal = groupResponse.meta.total;
            groupSkip = groupSkip + 10;
            if (groupResponse.data.length) {
              groupItem = groupResponse.data.reduce((groupsObj, item) => {
                if (item.group) {
                  const groupDetails = item.group;
                  delete item.group;
                  const group = {
                    ...groupDetails,
                    user_groups: item,
                  };
                  groupsObj.push(group);
                }
                return groupsObj;
              }, []);

              groups = [...groups, ...groupItem];
            }
          }
        }
        if (!response.data.length) {
          self.setDetailsLoading(false);
          NotificationStore.setNotification(
            "error",
            members.fetchMemberDetailsError
          );
          return;
        }
        userResponse = response.data[0];
        userResponse.groups = _.uniqBy(groups, "id");
        self.setDetailsLoading(false);
        return userResponse;
      } else {
        mixpanel.track("Failed to open Profile", {
          userId: userId,
          from: "WEB",
        });
        NotificationStore.setNotification(
          "error",
          members.fetchMemberDetailsError
        );
      }
      self.setDetailsLoading(false);
    },
    setDetailsLoading(value) {
      self.detailsLoading = value;
    },
    async addMember(data) {
      const member = Member.create(data);
      self.members.push(member);
      return member;
    },
    addSemiModerator(username) {
      self.semiModerators.push(username);
    },
    addAdmin(username) {
      self.admins.push(username);
    },
    async fetchModerators() {
      self.setModeratorsLoading(true);
      let params = {
        $skip: self.moderatorsSkip,
        "type[$in][]=moderator&type[$in][]": "SA",
      };
      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        self.setModeratorsMeta(_.cloneDeep(response.meta));
        self.setModeratorsData(_.cloneDeep(response.data));
        self.setModeratorsLoading(false);
      } else {
        self.setModeratorsLoading(false);
        NotificationStore.setNotification("error", members.fetchModsError);
        return;
      }
    },
    saveSearchedModsTotal(meta) {
      self.searchedModSemiModTotal = meta.total;
    },
    setModeratorsMeta(meta) {
      self.moderatorsTotal = meta.total;
      self.moderatorsSkip = self.moderatorsSkip + meta.limit;
    },
    async getUsernames(userIds) {
      const idArray = [];
      userIds.forEach((userId) => {
        !self.usernameMap.has("" + userId) && idArray.push(userId);
      });
      if (idArray.length > 0) {
        let str = "id[$in][]";
        for (let index = 0; index < idArray.length - 1; index++) {
          str += "=" + idArray[index] + "&id[$in][]";
        }

        const params = {
          [str]: idArray[idArray.length - 1],
        };

        const response = await ApiService.getRequest("users", params);

        if (response.success) {
          response.data.map((user) => self.addUsername(user.id, user.username));
        }
      }

      return self.usernameMap;
    },
    addUsername(userId, username) {
      self.usernameMap.set(userId, username);
    },
    updateQueryString(queryString) {
      self.queryString = queryString;
    },
    updateModSearchQuery(queryString) {
      self.modSearchQuery = queryString;
    },
    setAssignedUsers(data) {
      data.forEach((alert) => {
        if (!self.assignedUsers.has(alert.sourceUserId)) {
          self.assignedUsers.set(alert.sourceUserId, [
            alert.targetUserUsername.username,
          ]);
        } else if (self.assignedUsers.has(alert.sourceUserId)) {
          let assignedUsers = self.assignedUsers.get(alert.sourceUserId);
          if (!assignedUsers.includes(alert.targetUserUsername.username)) {
            assignedUsers = [
              ...assignedUsers,
              alert.targetUserUsername.username,
            ];
          }
          self.assignedUsers.set(alert.sourceUserId, assignedUsers);
        }
      });
    },
    updateAssignedList(userId, adminName) {
      if (!self.assignedUsers.has(userId)) {
        self.assignedUsers.set(userId, [adminName]);
      } else if (self.assignedUsers.has(userId)) {
        let alreadyAssigned = self.assignedUsers.get(userId);
        if (!alreadyAssigned.includes(adminName)) {
          alreadyAssigned = [...alreadyAssigned, adminName];
        }
        self.assignedUsers.set(userId, alreadyAssigned);
      }
    },
    async saveQueryString() {
      if (!self.queryString.trim().length) {
        return;
      }
      const siteId = SiteStore.selectedSiteId;
      self.resetPagination();
      if (siteId && siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        self.fetchSearchedMembers(siteDataObj);
      } else self.fetchSearchedMembers({});
    },
    sendMailToAdmins() {
      const { username } = AuthStore;
      const { selectedAlert } = ProfileStore;
      let promises = [];
      self.setAssignEmailLoading(true);
      self.assignedAdmins.forEach((admin) => {
        const params = {
          groupName: selectedAlert.groupName,
          adminUsername: admin.username,
          email: admin.email,
          assigner: username,
          flaggedUser: selectedAlert.flaggedUser,
          time: moment(selectedAlert.time).format("HH:mm"),
        };
        const endPoint = `assign-alert-email?targetUserId=${admin.id}&messageReactionId=${selectedAlert.id}`;
        promises.push(ApiService.postRequest(endPoint, params));
      });
      Promise.all(promises)
        .then((res) => {
          if (!res[0].success) {
            NotificationStore.setNotification("error", members.sendMailError);
            self.setAssignEmailLoading(false);
            ProfileStore.changeSelectedAction("");
          } else {
            self.assignedAdmins.forEach((admin) => {
              FlaggedMessagesList.updateAssignedList(
                selectedAlert.id,
                admin.username
              );
            });
            NotificationStore.setNotification(
              "success",
              members.sendMailSuccess
            );
            self.setAssignEmailLoading(false);
            ProfileStore.changeSelectedAction("");
          }
        })
        .catch((err) => { });
    },
    assignNewUsers() {
      let promises = [];

      self.setAssignEmailLoading(true);
      self.assignedAdmins.forEach((admin) => {
        const params = {
          targetUserId: admin.id,
          sourceUserId: self.selectedUserId,
        };
        const endPoint = `user-assign`;
        promises.push(ApiService.postRequest(endPoint, params));
      });
      Promise.all(promises)
        .then((res) => {
          if (!res[0].success) {
            self.setAssignEmailLoading(false);
            ProfileStore.changeSelectedAction("");
            NotificationStore.setNotification("error", members.sendMailError);
          } else {
            self.assignedAdmins.forEach((admin) => {
              self.updateAssignedList(self.selectedUserId, admin.username);
            });

            self.setAssignEmailLoading(false);
            ProfileStore.changeSelectedAction("");
            NotificationStore.setNotification(
              "success",
              members.sendMailSuccess
            );
          }
        })
        .catch((err) => { });
    },
  }))
  .views((self) => ({
    isSemiModerator(username) {
      return _.includes(self.semiModerators, username);
    },
    isAdmin(username) {
      return _.includes(self.admins, username);
    },
    getSortOrder(column) {
      return self.sortColumn === column ? self.sortOrder : false;
    },
    isAdminPresent(id) {
      return self.assignedAdmins.filter((item) => item.id === id).length;
    },
  }));

export default MemberList;
