import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import Group from "./Group";
import GroupUser from "./GroupUser";
import MemberListStore from "../stores/MemberListStore";
import MessagesStore from "../stores/MessagesStore";
import NotificationStore from "../stores/NotificationStore";
import ProfileStore from "../stores/ProfileStore";
import _ from "lodash";
import { groups } from "../en.json";
import { types } from "mobx-state-tree";
import {
  publishBanner,
  subscribeSingleChannel,
  getPubnubInstanceByUserType,
} from "../utils/PubnubMethods";
import SiteStore from "../stores/SiteStore";
import { toJS } from "mobx";

const Groups = types
  .model("Groups", {
    allGroups: types.array(Group),
    assignedGroups: types.array(Group),
    // pendingUsers: types.array(
    //   types.frozen({
    //     userId: types.number,
    //     groupId: types.number,
    //   })
    // ),
    banners: types.map(
      types.frozen({
        description: types.string,
        memberCount: types.maybe(types.number),
        name: types.string,
        hasError: types.maybe(types.boolean),
      })
    ),
    groupUsers: types.array(GroupUser),
    pendingTotal: 0,
    groupsLoading: false,
    allGroupsTotal: 0,
    allGroupsSkip: 0,
    allGroupsLoading: false,
    fetchingGroupUsers: false,
    selectedGroupUsers: types.array(types.string),
    showTagList: false,
    result: types.map(
      types.frozen({
        semiMods: types.array(types.string),
      })
    ),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions((self) => ({
    resetAll() {
      self.allGroupsTotal = 0;
      self.allGroupsSkip = 0;
      self.allGroups = [];
      self.assignedGroups = [];
      self.groupUsers = [];
      self.selectedGroupUsers = [];
    },
    resetAllGroups() {
      self.allGroupsTotal = 0;
      self.allGroupsSkip = 0;
      self.allGroups = [];
    },
    setShowTagList(value) {
      self.showTagList = value;
      // if (value) {
      //   self.fetchSelectedGroupUsers();
      // }
    },
    setAllGroupsLoading(value) {
      self.allGroupsLoading = value;
    },
    async fetchAllGroups(siteId = null) {
      const limit = 50;
      const params = {
        "$sort[createdAt]": -1,
        $skip: self.allGroupsSkip,
        $limit: limit,
        include: true,
        includeOnly: "users,sites",
      };
      self.setAllGroupsLoading(true);
      const response = await ApiService.getRequest("groups", params);
      if (response.success) {
        if (siteId) {
          self.setSiteGroupDetails(_.cloneDeep(response.data), siteId);
        } else {
          self.setAllGroupDetails(_.cloneDeep(response.data));
        }
        self.setAllGroupsMeta(_.cloneDeep(response.meta));
        ProfileStore.updateGroups();
      } else {
        NotificationStore.setNotification("error", groups.groupFetchError);
      }
      self.setAllGroupsLoading(false);
    },
    async fetchGroupSemiMods(groupId) {
      const params = {
        groupId,
      };
      const response = await ApiService.postRequest(
        "group-users-by-id",
        params
      );
      if (response.success && response.data.total) {
        const resultArr = self.saveGroupsSemiMods(response.data.data);
        self.addToResult(groupId, resultArr);
      } else {
        NotificationStore.setNotification("error", groups.groupFetchError);
        return;
      }
    },
    addToResult(groupId, resultArr) {
      self.result.set(groupId, { semiMods: resultArr });
    },
    saveGroupsSemiMods(members) {
      let arr = [];
      members.forEach((member) => {
        if (member.userTypeInGroup === "semi-moderator") {
          arr.push(member.username);
        }
      });
      return arr;
    },
    setGroupLoading(value) {
      self.groupsLoading = value;
    },
    setAllGroupsMeta(meta) {
      self.allGroupsTotal = meta.total;
      self.allGroupsSkip = self.allGroupsSkip + meta.limit;
    },
    // async fetchPendingUsers() {
    //   do {
    //     const params = {
    //       $limit: 9999,
    //       $skip: self.pendingUsers.length,
    //     };

    //     const response = await ApiService.getRequest("pend-users", params);

    //     if (response.success) {
    //       if (response.data.length) {
    //         self.setPendingUsers(response.data);
    //       }
    //       self.setPendingMeta(response.meta);
    //     } else {
    //       NotificationStore.setNotification("error", groups.pendingUserError);
    //       break;
    //     }
    //   } while (self.pendingTotal > self.pendingUsers.length);
    // },
    // setPendingMeta(meta) {
    //   self.pendingTotal = meta.total;
    // },
    setAllGroupDetails(apiResponse) {
      const groups = apiResponse.map((data) => {
        const users = data.members
          ? _.filter(data.members, (member) =>
            _.includes(["user", "semi-moderator"], member.type)
          )
          : [];
        data.memberCount = users.length;
        return Group.create(data);
      });
      self.allGroups = [...self.allGroups, ...groups];
    },
    setSiteGroupDetails(apiResponse, siteId) {
      const groups = apiResponse.filter((data) => {
        const users = data.members
          ? _.filter(data.members, (member) =>
            _.includes(["user", "semi-moderator"], member.type)
          )
          : [];
        data.memberCount = users.length;

        if (_.some(data.sites, { id: siteId })) {
          return Group.create(data);
        }
      });
      self.allGroups = [...self.allGroups, ...groups];
    },
    // setPendingUsers(apiResponse) {
    //   const pendingUsers = apiResponse.map((data) => ({
    //     groupId: data.groupId,
    //     userId: data.userId,
    //   }));
    //   self.pendingUsers = [...self.pendingUsers, ...pendingUsers];
    // },
    addToSiteGroups(sites, group) {
      if (sites.includes(SiteStore.selectedSiteId)) {
        self.allGroups = [...self.allGroups, Group.create(group)];
      }
    },
    fetchBanner(groupIds) {
      let groupPromises = [],
        groupMembersPromises = [];
      groupIds.forEach((group, index) => {
        const endPoint = `groups/${group.groupId}`;
        groupPromises.push(ApiService.getRequest(endPoint));
        const memberCountParams = {
          groupId: group.groupId,
          "type[$ne]": "moderator",
          $limit: 0,
        };
        groupMembersPromises.push(
          ApiService.getRequest("user-groups", memberCountParams)
        );
      });
      Promise.all(groupPromises)
        .then((res) => {
          groupIds.forEach((group, index) => {
            if (res[index] && res[index].data) {
              self.setBannerData("name", group.groupId, res[index].data.name);
              self.setBannerData(
                "description",
                group.groupId,
                res[index].data.description
              );
            } else {
              self.setBannerData("hasError", group.groupId, true);
            }
          });
        })
        .catch((err) => {
          groupIds.forEach((group, index) => {
            self.setBannerData("hasError", group.groupId, true);
          });
        });
      Promise.all(groupMembersPromises)
        .then((res) => {
          groupIds.forEach((group, index) => {
            if (res[index] && res[index].meta) {
              self.setBannerData(
                "memberCount",
                group.groupId,
                res[index].meta.total
              );
            } else {
              self.setBannerData("hasError", group.groupId, true);
            }
          });
        })
        .catch((err) => {
          groupIds.forEach((group, index) => {
            self.setBannerData("hasError", group.groupId, true);
          });
        });
    },
    isBannerLoading(groupId) {
      if (self.banners.has(groupId)) {
        const banners = self.banners.get(groupId);
        return banners.name && banners.memberCount && banners.description
          ? false
          : true;
      } else {
        return true;
      }
    },
    setBannerData(key, id, bannerDetails) {
      if (self.banners.has(id)) {
        const oldData = self.banners.get(id);
        self.banners.set(id, {
          ...oldData,
          [key]: bannerDetails,
        });
      } else {
        self.banners.set(id, {
          [key]: bannerDetails,
        });
      }
    },
    async setUserGroupStatus(groupId, userId, status, existingGroup = true) {
      let success = false;
      self.setGroupLoading(true);
      switch (status) {
        case "approved":
          success = existingGroup
            ? await self.deletePendingUser(groupId, userId)
            : await self.addUserToGroup(groupId, userId);
          break;
        case "activate":
          success =
            // existingGroup;
            // ?
            await self.deletePendingUser(groupId, userId);
          // : await self.addUserToGroup(groupId, userId);
          break;
        case "suspend":
          // if (!existingGroup) {
          //   success = await self.addUserToGroup(groupId, userId);
          // }
          // if (existingGroup || success) {
          success = await self.createPendingUser(groupId, userId);
          // }
          break;
        case "denied":
          success = await self.deleteUser(groupId, userId);
          ProfileStore.removeFromAssignedGroups(groupId);
          if (
            success &&
            _.some(self.pendingUsers, {
              groupId,
              userId,
            })
          ) {
            success = await self.deletePendingUser(groupId, userId);
          }
          break;
        case "pause":
          success = await self.pauseUser(groupId, userId, true);
          break;
        case "resume":
          success = await self.pauseUser(groupId, userId, false);
          break;
        default:
          break;
      }
      self.setGroupLoading(false);
      return success;
    },
    // async createPendingUser(groupId, userId) {
    //   const params = { groupId, userId };
    //   const response = await ApiService.postRequest("pend-users", params);

    //   if (response.success) {
    //     self.addPendingUser(groupId, userId);
    //   } else {
    //     NotificationStore.setNotification("error", groups.toggleError);
    //   }
    //   return response.success;
    // },
    async changeStatus(groupId, userId, statusId) {
      if (statusId === 4) {
        const success = await self.deleteUser(groupId, userId);
        ProfileStore.removeFromAssignedGroups(groupId);
        return success;
      } else {
        const params = { status: statusId };
        const endPoint = `user-groups`;
        const response = await ApiService.patchRequest(
          endPoint,
          `${userId}?groupId=${groupId}`,
          params
        );
        if (response.success) {
          MemberListStore.updateStatusInGroup(groupId, userId, statusId);
          return true;
        } else {
          NotificationStore.setNotification("error", groups.toggleError);
          return false;
        }
      }
    },
    // async pauseUser(groupId, userId, isPaused) {
    //   const params = { isPaused };
    //   const endPoint = `user-groups`;
    //   const response = await ApiService.patchRequest(
    //     endPoint,
    //     `${userId}?groupId=${groupId}`,
    //     params
    //   );
    //   if (response.success) {
    //     ProfileStore.changeStatusInGroup(groupId, isPaused);
    //   } else {
    //     NotificationStore.setNotification("error", groups.toggleError);
    //   }
    //   return response.success;
    // },

    async deletePendingUser(groupId, userId) {
      const params = { groupId, userId };
      const response = await ApiService.deleteRequest(
        "pend-users",
        null,
        params
      );

      if (response.success) {
        self.removePendingUser(groupId, userId);
      } else {
        NotificationStore.setNotification("error", groups.toggleError);
      }
      return response.success;
    },
    async addUserToGroup(groupId, userId, type, isIceBreakerMessageSent) {
      const { selectedGroup } = MessagesStore;
      const selfID = AuthStore.userId;
      const params = { groupId, userId, type, isIceBreakerMessageSent };
      self.setGroupLoading(true);
      const response = await ApiService.postRequest("user-groups", params);

      if (!response.success) {
        NotificationStore.setNotification("error", response?.data?.message || groups.assignError);
      } else {
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        const user_groups = response.data;
        if (userId === selfID) {
          const groupResponse = await ApiService.getRequest("groups", {
            id: groupId,
          });
          const groupDetails = groupResponse.data[0];
          AuthStore.addGroupToUserGroups({
            ...groupDetails,
            user_groups,
          });
          subscribeSingleChannel(pubnub, `GROUP_CHAT_${groupId}`);
        }
        self.updateGroup(groupId, response.data);
        if (_.startsWith(selectedGroup.channel, "WAITING_ROOM")) {
          publishBanner(pubnub, groupId);
        }
      }
      self.setGroupLoading(false);
      return response.success;
    },
    updateGroup(groupId, data) {
      const group = _.find(self.allGroups, { id: groupId });
      if (group) {
        group.user_groups = data;
      }
    },
    // addPendingUser(groupId, userId) {
    //   self.pendingUsers.push({ groupId, userId });
    //   return true;
    // },
    // removePendingUser(groupId, userId) {
    //   const index = _.findIndex(
    //     self.pendingUsers,
    //     (group) => group.groupId === groupId && group.userId === userId
    //   );
    //   self.pendingUsers.splice(index, 1);
    // },
    async deleteUser(groupId, userId) {
      const params = { group_id: groupId };
      const response = await ApiService.deleteRequest(
        "user-groups",
        userId,
        params
      );

      if (!response.success) {
        NotificationStore.setNotification("error", groups.removeError);
      }
      return response.success;
    },
    getGroupUsingId(id) {
      return _.find(self.allGroups, { groupId: id });
    },
    updateMemberCount(groupId, difference) {
      const group = _.find(self.allGroups, { id: groupId });
      if (group) {
        group.memberCount += difference;
      }
    },
    async fetchGroupsById(idArray) {
      let str = "id[$in][]";
      for (let index = 0; index < idArray.length - 1; index++) {
        str += "=" + idArray[index] + "&id[$in][]";
      }
      let skip = 0,
        total = 1;
      while (skip < total) {
        const params = {
          [str]: idArray[idArray.length - 1],
          include: true,
          $skip: skip,
          includeOnly: "users",
        };
        const response = await ApiService.getRequest("groups", params);
        if (response.success) {
          total = response.meta.total;
          skip = skip + 10;
          self.setAssignedGroupDetails(_.get(response, "data", []));
        } else {
          NotificationStore.setNotification("error", groups.groupFetchError);
          return;
        }
      }
    },
    async fetchSelectedGroupUsers() {
      if (self.selectedGroupUsers.length) {
        return;
      }
      const groupId = MessagesStore.selectedGroup.id;

      if (!groupId) {
        return;
      }
      self.setFetchingGroupUsers(true);
      const params = { groupId };
      const response = await ApiService.postRequest(
        "group-users-by-id",
        params
      );
      if (response.success) {

        self.setSelectedGroupUsers(_.get(response, "data.data", []));
        self.setFetchingGroupUsers(false);
      } else {
        self.setFetchingGroupUsers(false);
        return;
      }
    },
    setFetchingGroupUsers(value) {
      self.fetchingGroupUsers = value;
    },
    setSelectedGroupUsers(users) {
      const selectedGroupUsers = users.map((user) => {
        if (user && user.username) {
          return user.username;
        } else return "";
      });

      self.selectedGroupUsers = selectedGroupUsers;
    },
    setAssignedGroupDetails(apiResponse) {
      const groups = apiResponse.map((data) => {
        const users = data.members
          ? _.filter(data.members, (member) =>
            _.includes(["user", "semi-moderator"], member.type)
          )
          : [];
        data.memberCount = users.length;
        return Group.create(data);
      });
      self.assignedGroups = [...self.assignedGroups, ...groups];
    },
    async fetchGroupUsers(groupId) {
      const params = { groupId };
      const response = await ApiService.postRequest(
        "group-users-by-id",
        params
      );
      if (response.success) {
        self.saveAllGroupUsers(_.get(response, "data.data", []));
        return _.get(response, "data.data", []);
      } else {
        return [];
      }
    },

    changeUserType(groupId, userId, type) {
      const groupUserCopy = _.cloneDeep(self.groupUsers);
      groupUserCopy.forEach((groupUser) => {
        if (groupUser.userId === userId && groupUser.groupId === groupId) {
          groupUser.type = type;
        }
      });
      self.groupUsers = groupUserCopy;
    },

    async saveAllGroupUsers(users) {
      self.groupUsers = users.map((groupUser) => {
        return GroupUser.create(groupUser);
      });

      self.selectedGroupUsers = users.map((user) => {
        if (user && user.username) {
          return user.username;
        } else return "";
      });
    },

    updateColor(color) {
      const {
        selectedGroup: { id: groupId },
      } = MessagesStore;
      const { userId, username } = AuthStore;

      const index = self.groupUsers.findIndex(
        (groupUser) =>
          groupUser.groupId === groupId && groupUser.userId === userId
      );
      if (index >= 0) {
        self.groupUsers[index] = { ...self.groupUsers[index], color };
      } else {
        self.groupUsers.push(
          GroupUser.create({
            groupId,
            userId,
            type: null,
            username,
            color,
          })
        );
      }
    },
  }))
  .views((self) => ({
    getMemberCount(groupId) {
      const group = _.find(self.allGroups, { id: groupId });
      return group ? group.memberCount : null;
    },
    getIsTagged(username) {
      return self.selectedGroupUsers.includes(username);
    },
    getAssignedGroupMemberCount(groupId) {
      const group = _.find(self.assignedGroups, { id: groupId });
      return group ? group.memberCount : null;
    },
    getUserType(groupId, username) {
      const groupUser = _.find(toJS(self.groupUsers), { username });
      // commented code because groupId is not available in self.groupUsers array object
      // const groupUser = _.find(toJS(self.groupUsers), { groupId, username });
      return groupUser ? groupUser.userTypeInGroup : null;
      // return groupUser ? groupUser.type : null;
    },
    getUserId(groupId, username) {
      const groupUser = _.find(self.groupUsers, { groupId, username });
      return groupUser ? groupUser.userId : null;
    },
    getFlair(groupId, userId) {
      const groupUser = _.find(self.groupUsers, { groupId, userId });
      return groupUser && groupUser.flair ? groupUser.flair : "";
    },
    getColor(groupId, username) {
      const groupUser = _.find(self.groupUsers, { groupId, username });
      return groupUser && groupUser.color ? groupUser.color : "black";
    },
    getEnrolledUserColor(userId) {
      const enrolledUser = _.find(self.groupUsers, { userId });
      return enrolledUser ? enrolledUser.enrolledColor : "";
    },
    getBadgeType(userId) {
      const groupUser = _.find(self.groupUsers, { userId });
      return groupUser ? groupUser.badgeType : "";
    },
    getBadgeColor(userId) {
      const user = _.find(self.groupUsers, { userId });
      return user ? user.enrolledColor : "";
    }
  }));

export default Groups;
