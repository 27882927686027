import { types } from "mobx-state-tree";
import Message from "./Message";

const FlaggedMessage = types
  .model("FlagNeeds", {
    id: types.maybeNull(types.number),
    message: types.maybe(Message),
    userId: types.maybeNull(types.number),
    username: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    flaggedby: types.maybeNull(types.number),
    flaggedbyname: types.maybeNull(types.string),
    groupname: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    messageId: types.maybeNull(types.string),
    reactionId: types.maybeNull(types.number),
    typeOfNeedColor: types.maybeNull(types.string),
    typeOfNeedLabel: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    messageDate: types.maybeNull(types.string),
    isAnonymity: types.maybe(types.boolean),
  })
  .actions(self => ({}));

export default FlaggedMessage;
