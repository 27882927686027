import _ from "lodash";
import moment from "moment";
import { toJS } from "mobx";
import { destroy, types } from "mobx-state-tree";
import { user, userProfile, sheetyCalls } from "../en.json";

import Answer from "./Answer";
import Form from "./Form";
import DM from "./DM";
import FormAnswer from "./FormAnswer";
import FormQuestion from "./FormQuestion";
import Group from "./Group";
import MixpanelQueryResult from "./MixpanelQueryResult";
import Option from "./Option";
import Question from "./Question";
import HPUser from "./HPUser";
import { BADGE_TYPE_REMOVE } from "../constants/BadgeConstant";
import { SUBMITTED_FROM_TYPE_ADMIN } from "../constants/GlobalConstant";
import AuthStore from "../stores/AuthStore";
import GroupStore from "../stores/Groups";
import MemberListStore from "../stores/MemberListStore";
import NotificationStore from "../stores/NotificationStore";
import StatsStore from "../stores/StatsStore";
import ApiService from "../utils/ApiService";
import { convertStringToCamelCase } from "../utils/CommonUtils";
import { hpUsers } from "../utils/Hpusers";
import { IS_HP } from "../utils/getEnvironment.js";
import { ulid } from "../utils/getRandomId";

var mixpanel = require("mixpanel-browser");
var CryptoJS = require("crypto-js");

const Profile = types
  .model("Profile", {
    username: "",
    oldUsername: "",
    showLabel: false,
    showFlag: false,
    showUnFlag: false,
    showActions: false,
    siteId: types.maybeNull(types.number),
    password: types.maybe(types.string),
    fullname: types.maybe(types.string),
    DOB: types.maybeNull(types.string),
    userId: types.maybe(types.number),
    mrn: types.maybeNull(types.string),
    codeOfConduct: types.maybeNull(types.string),
    createdAt: types.maybe(types.string),
    formQuestions: types.array(FormQuestion),
    formAnswers: types.array(FormAnswer),
    wellnessFormQuestionsByCategory: types.optional(types.frozen({})),
    answersForQuestions: types.optional(types.frozen({})),
    originalAnswersForQuestions: types.optional(types.frozen({})),
    metaInfoForWellnessForm: types.optional(types.frozen({})),
    selectedMember: types.maybeNull(types.number),
    unassignedGroups: types.array(Group),
    assignedGroups: types.array(Group),
    color: types.maybeNull(types.string),
    showDOB: false,
    trainingStatus: types.maybeNull(types.string),
    notes: types.maybeNull(types.string),
    approvedBy: types.maybeNull(types.number),
    approvedAt: types.maybeNull(types.string),
    isApproved: types.maybeNull(types.boolean),
    approvedbyUser: types.maybeNull(types.string),
    Questions: types.array(Question),
    visible: false,
    dms: types.array(DM),
    providerId: types.maybeNull(types.number),
    recommendedBy: types.maybeNull(types.number),
    formLoading: false,
    loading: false,
    wellnessFormLoading: false,
    userFormsLoading: false,
    isSmsHistoryLoading: false,
    isSmsSending: false,
    isResolved: false,
    popOverVisible: false,
    profileVisible: false,
    memberProfileVisible: false,
    forms: types.array(Form),
    type: types.maybe(types.string),
    badgeType: types.maybeNull(types.string),
    formId: types.maybeNull(types.number),
    messageId: "",
    editOverview: false,
    editBio: false,
    editClinical: false,
    fetchingHpUsers: false,
    phone: types.maybeNull(types.string),
    email: types.maybe(types.string),
    line1: types.maybeNull(types.string),
    line2: types.maybeNull(types.string),
    groupsString: types.maybeNull(types.string),
    answers: types.array(Answer),
    newAnswers: types.array(Answer),
    selectedAlert: types.frozen({
      id: types.maybeNull(types.number),
      groupName: types.maybeNull(types.string),
      time: types.maybeNull(types.string),
      flaggedUser: types.maybeNull(types.string),
      messageId: types.maybeNull(types.string),
    }),
    bioLoading: false,
    latestAnswer: types.maybe(types.string),
    selectedAction: "",
    championName: types.maybe(types.string),
    contactName: types.maybe(types.string),
    moderatedGroups: types.array(types.string),
    contactModeratedGroups: types.array(types.string),
    champModeratedGroups: types.array(types.string),
    HpUsers: types.array(HPUser),
    queryResult: types.array(types.array(MixpanelQueryResult)),
    mixpanelDM: types.array(types.array(MixpanelQueryResult)),
    dmGraphLoading: false,
    groupGraphLoading: false,
    groupOptions: types.array(types.string),
    newUsername: "",
    newFullname: "",
    newMrn: types.maybeNull(types.string),
    newSiteId: types.maybeNull(types.number),
    newEmail: "",
    newPhone: "",
    newType: types.maybeNull(types.string),
    newBadgeType: types.maybeNull(types.string),
    newTrainingStatus: types.maybeNull(types.string),
    newColor: types.maybeNull(types.string),
    newNotes: types.maybeNull(types.string),
    newDOB: types.maybeNull(types.string),
    addedByUsernames: types.map(types.frozen({ username: types.string })),
    callOutreach: types.maybeNull(types.string),
    smsOutreach: types.maybeNull(types.string),
    assignedPeerDetails: types.optional(types.frozen({})),
    isResetNeededForUserSmsData: true,
    userSmsData: types.array(types.frozen({})),
    smsHistoryUpdatedTime: types.maybeNull(types.number),
    userInsuranceDetails: types.optional(types.frozen({
      id: types.maybeNull(types.number),
      image: types.maybeNull(types.string),
      userId: types.maybeNull(types.string),
      confirm_membership_insurance_number: types.maybeNull(types.string),
      membership_insurance_number: types.maybeNull(types.string),
      plan_name: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
    }))
  })
  .actions((self) => ({
    reset() {
      self.username = "";
      self.contactName = "";
      self.championName = "";
      self.userId = undefined;
      self.unassignedGroups = [];
      self.assignedGroups = [];
      self.selectedAction = "";
      self.editClinical = false;
      self.editBio = false;
      self.showUnFlag = false;
      self.popOverVisible = false;
      self.groupsString = null;
      self.dms = [];
      self.forms = [];
      self.answers = [];
      self.newAnswers = [];
      self.selectedMember = null;
      self.showFlag = false;
      self.showActions = false;
      self.visible = false;
      self.profileVisible = false;
      self.memberProfileVisible = false;
      self.smsOutreach = "";
      self.callOutreach = "";
      self.assignedPeerDetails = {};
      self.wellnessFormLoading = false;
      self.userFormsLoading = false;
      self.wellnessFormQuestionsByCategory = {};
      self.answersForQuestions = {};
      self.originalAnswersForQuestions = {};
      self.metaInfoForWellnessForm = {};
      self.isSmsHistoryLoading = false;
      self.isSmsSending = false;
      if (self.isResetNeededForUserSmsData) {
        self.userSmsData = [];
        self.smsHistoryUpdatedTime = null;
      }
      self.isResetNeededForUserSmsData = true;
      self.badgeType = null;
      self.newBadgeType = null;
      self.userInsuranceDetails = {};
    },
    setAddedByUsernames(data) {
      data.forEach((user) => {
        if (!self.addedByUsernames.has(user.id)) {
          self.addedByUsernames.set(user.id, user.username);
        }
      });
    },
    setAssignedPeerDetails(data) {
      self.assignedPeerDetails = data;
    },
    changeSelectedAction(action) {
      self.selectedAction = action;
      // self.setProfileVisibility(false);
    },
    TogglePopOver(value) {
      self.popOverVisible = value;
    },
    toggleFlag(value) {
      self.showFlag = value;
    },
    setShowUnflag(value) {
      self.showUnFlag = value;
    },
    toggleAction(value) {
      self.showActions = value;
    },
    toggleLabel(value) {
      self.showLabel = value;
    },
    setIsResetNeededForUserSmsData(value) {
      self.isResetNeededForUserSmsData = value;
    },
    // changeStatusInGroup(groupId, isPaused) {
    //   const group = _.find(self.assignedGroups, { id: groupId });
    //   if (group) {
    //     group.user_groups.isPaused = isPaused;
    //   }
    // },
    updateStatusInGroup(groupId, status) {
      const userGroups = _.cloneDeep(self.assignedGroups);
      const groupIndex = userGroups.findIndex((group) => group.id === groupId);
      let groupObj = userGroups[groupIndex].user_groups;
      groupObj = { ...groupObj, ...status };
      userGroups[groupIndex] = {
        ...userGroups[groupIndex],
        user_groups: groupObj,
      };
      self.assignedGroups = userGroups;
    },
    removeFromAssignedGroups(groupId) {
      // _.remove(self.assignedGroups, { id: groupId });
      const group = _.find(self.assignedGroups, { id: groupId });
      if (group) {
        destroy(group);
      }
      !_.includes(["semi-moderator", "moderator"], self.type) &&
        GroupStore.updateMemberCount(groupId, -1);
    },
    setVisibility(value) {
      if (value) {
        mixpanel.track("Adding People to Groups", { from: "WEB" });
      }
      self.visible = value;
    },
    setProfileVisibility(value) {
      self.profileVisible = value;
    },
    setMemberProfileVisibility(value) {
      self.memberProfileVisible = value;
    },
    saveResponse(answer, questionId, userId) {
      const answerIndex = self.getResponseId(questionId);
      let responseId = null;
      if (answerIndex >= 0) {
        responseId = self.answers[answerIndex].id;
      }
      const index = _.findIndex(self.newAnswers, { questionId: questionId });
      if (index >= 0) {
        self.newAnswers[index].answer = answer;
      } else {
        self.newAnswers.push({
          id: responseId,
          questionId: questionId,
          answer: answer,
          userId: userId,
        });
      }
    },
    setSelectedAlert(alert) {
      if (alert) {
        self.selectedAlert = {
          id: alert.id,
          groupName: alert.name,
          time: alert.createdAt,
          flaggedUser: alert.sender,
          messageId: alert.messageId,
        };
      }
    },
    getLatestQuestionId() {
      let updatedResponse = _.sortBy(self.answers, "updatedAt").reverse()[0];
      for (let i = 0; i < self.Questions.length; i++) {
        if (self.Questions[i].id === updatedResponse.questionId) {
          return self.Questions[i].id;
        }
      }
    },
    changeLatestAnswer(value) {
      self.latestAnswer = value;
    },
    getLatestAnswerId() {
      return _.sortBy(self.answers, "updatedAt").reverse()[0].id;
    },
    // getResponseId(questionId) {
    //   return _.findIndex(self.answers, { questionId: questionId });
    // },
    getWellnessFormQuestionsByCategory(formQuestions = []) {
      const questionsByCategoryObj = {};
      if (formQuestions.length) {
        const answersObj = {};
        // Here we will iterate over form questions array
        for (const questionObj of formQuestions) {
          const { category, responses, id, ...rest } = questionObj;
          // Here we are converting hyphen separate string to camel case string and then set it as a key of object
          const convertedCategory = convertStringToCamelCase(category);
          // If responses exists then we will take answer from it otherwise will display empty string 
          const answer = responses && responses.length ? responses[0].answer : '';
          // Here we will set question id as key and answer as a value in answersObj
          answersObj[id] = answer;
          const objToPush = {
            ...rest,
            category,
            answer,
            id
          };
          // If category is not existed then by default we will push first object in array
          let questionsArr = [objToPush];
          // If category is existed in object then we will push other questions into it and previous array will override with current one.
          if (questionsByCategoryObj.hasOwnProperty(convertedCategory)) {
            questionsArr = questionsByCategoryObj[convertedCategory];
            questionsArr.push(objToPush);
          }
          questionsByCategoryObj[convertedCategory] = [...questionsArr];
        }
        // We will set answers object to answers of questions in state of mobx
        self.setAnswersForQuestions(answersObj);
      }
      return questionsByCategoryObj;
    },
    // Here we will set form question by category like this {goals:[], strengths:[]}
    setWellnessFormQuestionsByCategory(questionsByCategoryObj = {}) {
      self.wellnessFormQuestionsByCategory = questionsByCategoryObj;
    },
    // Once component mount then we will create a mapping object for question id with answers
    setAnswersForQuestions(answersObj = {}) {
      self.answersForQuestions = { ...answersObj };
      self.originalAnswersForQuestions = { ...answersObj };
    },
    // If admin change any answer in text area then we will set a answer by using question id
    setAnswerForSpecificQuestion(questionId, answer) {
      const cloneAnswersObj = { ...self.answersForQuestions };
      cloneAnswersObj[questionId] = answer;
      self.answersForQuestions = cloneAnswersObj;
    },
    // Here we are setting meta information for wellness plan form
    setMetaInfoForWellnessForm(data) {
      self.metaInfoForWellnessForm = data;
    },
    // Here we are setting forms data after wellness plan form is submitted
    setFormsData(forms) {
      if (forms && forms.length) {
        self.forms = forms.filter((item) => {
          if (item.isSubmitted) {
            return Form.create(item)
          }
          return false;
        });
      }
    },
    // To render loading icon when user forms are fetched 
    setUserFormsLoading(value) {
      self.userFormsLoading = value;
    },
    setIsSmsHistoryLoading(value) {
      self.isSmsHistoryLoading = value;
    },
    setIsSmsSending(value) {
      self.isSmsSending = value;
    },
    setUserSmsData(arr) {
      self.userSmsData = arr;
    },
    setSmsHistoryUpdatedTime(value) {
      self.smsHistoryUpdatedTime = value;
    },
    // This function is used to fetch latest wellness plan by user
    async fetchWellnessPlanFormByUser() {
      try {
        self.setWellnessFormLoading(true);
        const params = {
          userId: self.userId
        };

        const response = await ApiService.getRequest('wellness-form', params);
        const { success, data = {} } = response;
        if (success) {
          const { form, formId, userId, id } = data;
          if (form) {
            const { questions = [] } = form;
            // Here we will set questions to the state object by converting in required object
            const questionsByCategoryObj = self.getWellnessFormQuestionsByCategory(questions ? questions : []);
            self.setWellnessFormQuestionsByCategory(questionsByCategoryObj);
            // Here we are setting meta information for wellness form
            self.setMetaInfoForWellnessForm({ formId, userId });
          }
        }
        self.setWellnessFormLoading(false);
      } catch (error) {
        self.setWellnessFormLoading(false);
      }
    },
    // This function is used to submit wellness plan form by user
    async submitWellnessPlanFormByUser() {
      try {
        self.setWellnessFormLoading(true);
        // Clone of changed answers for questions
        const answersForQuestions = { ...self.answersForQuestions };
        // Here we are comparing with original answers object that if answers are changed or not
        // If answers are not changed then we will not make any api call
        const isQuestionAnswersObjectEqual = _.isEqual(answersForQuestions, self.originalAnswersForQuestions);
        if (isQuestionAnswersObjectEqual) {
          self.setWellnessFormLoading(false);
          return;
        }
        // Here in this block answers are changed so we are preparing request body to pass in the api call
        const messageId = `${ulid() * 10000}`;
        // Here we will create a request body for creating user forms option when admin submit a form
        const reqBody = {
          ...self.metaInfoForWellnessForm,
          submittedFrom: SUBMITTED_FROM_TYPE_ADMIN,
          submittedFromId: AuthStore.userId,
          messageId,
        };
        // Here we are calling user forms api with request body payload
        const userFormsResponse = await ApiService.postRequest("user-forms", reqBody);
        if (userFormsResponse.success) {
          const {
            submittedFrom: responseFrom,
            submittedFromId: channelId,
            messageId,
            id: userFormId,
            formId,
            userId
          } = userFormsResponse.data;
          // If user forms is created then we will prepare a meta object and payload to submit the answers for that particular answers 
          const meta = {
            ...self.metaInfoForWellnessForm,
            channelId,
            messageId,
            userFormId,
            responseFrom
          };
          const payload = [];
          for (let key in answersForQuestions) {
            payload.push({ formQuestionId: +key, answer: answersForQuestions[key] });
          }
          const body = { meta, payload };
          // Here we will pass the answer response & meta information in create response api to create form question responses
          const response = await ApiService.postRequest('create-response', body);
          const { success } = response;
          if (success) {
            // Fetch wellness plan form by user after submitting the wellness plan form
            await self.fetchWellnessPlanFormByUser();
            // Fetch latest submitted form in forms listing in user profile 
            await self.fetchUserSubmittedForms({ formId, userId });
          }
        }
        self.setWellnessFormLoading(false);
      } catch (error) {
        self.setWellnessFormLoading(false);
      }
    },
    async fetchUserSubmittedForms(params = {}) {
      try {
        self.setUserFormsLoading(true);
        const queryParams = {
          ...params,
          isSubmitted: true,
          '$sort[createdAt]': -1,
          include: true,
          includeOnly: 'forms,users',
          isSpecificKeysRequiredForUser: true
        };
        // Here we are fetching all forms that are submitted by that particular user
        const userFormsResponse = await ApiService.getRequest('user-forms', queryParams);
        const { success, data } = userFormsResponse;
        if (success) {
          // Here we are setting the forms data in user profile section 
          self.setFormsData(data);
        }
        self.setUserFormsLoading(false);
      } catch (error) {
        self.setUserFormsLoading(false);
      }
    },
    // This function is used to fetch user sms history for specific user
    async fetchUserSmsHistory(userId, isFirstTimeLoading = true, userPhoneNumber) {
      try {
        // When component mounts then we will display loader
        // Otherwise we are not showing loader in the messages component
        if (isFirstTimeLoading) {
          self.setIsSmsHistoryLoading(true);
        }
        const params = {};
        if (userId) {
          params['receiverId'] = userId;
        } else {
          params['receiverPhoneNumber'] = userPhoneNumber;
        }
        // API call to fetch all messages between admin and receiver/member
        const response = await ApiService.getRequest('send-sms', params);
        const { success, data } = response;
        if (success) {
          const { smsMessagesHistory = [] } = data;
          self.setSmsHistoryUpdatedTime(Date.now());
          // If there are messages in history then we are plotting the data to the messages component
          self.setUserSmsData(smsMessagesHistory);
        }
        // Changing the state of loading if first time loads
        if (isFirstTimeLoading) {
          self.setIsSmsHistoryLoading(false);
        }
      } catch (error) {
        // Changing the state of loading when there is an error if first time loads
        if (isFirstTimeLoading) {
          self.setIsSmsHistoryLoading(false);
        }
      }
    },
    // This function is used to send user sms to the particular user
    async sendUserSms(body) {
      try {
        const { receiverId, receiverPhoneNumber } = body;
        self.setIsSmsSending(true);
        // We are sending receiver id and message text in the api call to send message to the user's number
        const response = await ApiService.postRequest('send-sms', body);
        const { success, data } = response;
        if (success) {
          // If we aer getting success response then we are fetching all messages history in silent api call without showing any loader
          self.fetchUserSmsHistory(receiverId, false, receiverPhoneNumber);
        } else {
          // If there is any error like if user unsubscribe to that particular number then we are showing as an alert popup
          if (data && data.message) {
            alert(data.message);
          }
        }

        self.setIsSmsSending(false);
      } catch (error) {
        self.setIsSmsSending(false);
      }
    },
    async updateLatestAnswer() {
      const answerId = self.getLatestAnswerId();
      const objToUpdate = {
        answer: self.latestAnswer,
      };
      self.setBioLoading(true);
      const response = await ApiService.patchRequest(
        "profile-question-responses",
        answerId,
        objToUpdate
      );
      if (response.success) {
        const member = await MemberListStore.getMemberDetails(self.userId);
        self.setMemberData(member);
        self.setBioLoading(false);
        self.toggleEdit("editBio", false);
        NotificationStore.setNotification(
          "success",
          userProfile.answerUpdateSuccess
        );
      } else {
        self.setBioLoading(false);
        NotificationStore.setNotification(
          "error",
          userProfile.answerUpdateError
        );
      }
    },
    async updateAnswers() {
      let promises = [];
      self.setBioLoading(true);
      for (let i = 0; i < self.newAnswers.length; i++) {
        if (self.newAnswers[i].id) {
          const objToUpdate = {
            answer: self.newAnswers[i].answer,
          };
          promises.push(
            ApiService.patchRequest(
              "profile-question-responses",
              self.newAnswers[i].id,
              objToUpdate
            )
          );
        } else {
          if (self.newAnswers[i].answer.length > 0) {
            const objToUpdate = {
              questionId: self.newAnswers[i].questionId,
              userId: self.newAnswers[i].userId,
              answer: self.newAnswers[i].answer,
            };
            promises.push(
              ApiService.postRequest("profile-question-responses", objToUpdate)
            );
          }
        }
      }

      Promise.all(promises)
        .then(async (res) => {
          const member = await MemberListStore.getMemberDetails(self.userId);
          self.setMemberData(member);
          self.setBioLoading(false);
          self.toggleEdit("editBio", false);
          NotificationStore.setNotification(
            "success",
            userProfile.answersUpdateSuccess
          );
        })
        .catch((err) => {
          NotificationStore.setNotification(
            "error",
            userProfile.answersUpdateError
          );
        });
    },
    setBioLoading(value) {
      self.bioLoading = value;
    },
    setSelectedWaiting(id) {
      self.selectedMember = id;
    },
    resetNewDetails() {
      self.newUsername = "";
      self.newFullname = "";
      self.newMrn = "";
      self.newSiteId = null;
      self.newEmail = "";
      self.newPhone = "";
      self.newType = null;
      self.newTrainingStatus = "";
      self.newColor = "";
      self.newNotes = "";
      self.newDOB = "";
    },
    setUserBasicDetails(username, userId, fullname) {
      self.username = username;
      self.userId = userId;
      self.fullname = fullname;
    },
    async setMemberData(member) {
      self.resetPassword();
      self.resetNewDetails();
      self.userInsuranceDetails = member.insuranceUserDetails;
      self.username = member.username;
      self.oldUsername = member.username;
      self.userId = member.id;
      self.callOutreach = member.callOutreach;
      self.smsOutreach = member.smsOutreach;
      self.badgeType = member.badgeType;
      if (member.assignedPeersDetails) {
        const { assignedPeerUserDetails, submittedByUserDetails, submittedAt } = member.assignedPeersDetails;
        const userDetails = {};
        if (assignedPeerUserDetails) {
          userDetails['assignedPeerId'] = assignedPeerUserDetails.id;
          userDetails['assignedPeerFullName'] = assignedPeerUserDetails.fullName;
        }
        if (submittedByUserDetails) {
          userDetails['submittedById'] = submittedByUserDetails.id;
          userDetails['submittedByFullName'] = submittedByUserDetails.fullName;
        }
        if (submittedAt) {
          userDetails['submittedAt'] = submittedAt;
        }
        self.assignedPeerDetails = { ...userDetails };
      }
      if (member.dob) {
        let date = moment(member.dob, "YYYY-MM-DD");
        self.setShowDOB(true);
        self.DOB = date.format("YYYY-MM-DD");
      } else {
        self.setShowDOB(false);
      }
      self.mrn = member.mrn;
      self.type = member.type;
      self.siteId = member.siteId;
      self.createdAt = member.createdAt;
      self.codeOfConduct = member.codeOfConductCompletedAt;
      self.consentDoneAt = member.consentDoneAt;
      self.userConsent = member.userConsent
      self.userConsent = member.userConsent
      self.phone = member.phoneNumber;
      self.email = member.email;
      self.isResolved = member.isResolved;
      self.recommendedBy = member.recommendedBy;
      self.isApproved = member.isApproved;
      self.approvedBy = member.approvedBy;
      if (member.isApproved && member.approvedBy) {
        self.fetchApprovedByName(member.approvedBy);
      }
      self.fullname = member.fullName;
      self.color = member.color;
      self.providerId = member.providerId;
      self.approvedAt = member.approvedAt;
      self.trainingStatus = member.trainingStatus;
      self.notes = member.notes;
      if (member.profileQuestionResponses) {
        self.answers = member.profileQuestionResponses.map((item) => {
          return Answer.create(item);
        });
      }
      if (member.forms) {
        const submittedForms = member.forms.filter((item) => item.isSubmitted);
        const sortedForms = submittedForms.sort((a, b) => (b.completedAt > a.completedAt) ? 1 : (a.completedAt > b.completedAt) ? -1 : 0);
        self.forms = sortedForms.filter((item) => {
          if (item.isSubmitted) {
            return Form.create(item)
          }
          return false;
        });
      }
      // let addedByDMIds = [];
      // if (member.channels && member.type === "moderator") {
      //   member.channels.forEach((channel) => {
      //     if (channel.dm_channel_users && channel.dm_channel_users.addedBy) {
      //       if (
      //         channel.dm_channel_users.addedBy &&
      //         channel.dm_channel_users.addedBy !== -1
      //       ) {
      //         addedByDMIds.push(
      //           ApiService.getRequest("users", {
      //             id: channel.dm_channel_users.addedBy,
      //             "$select[1]": "username",
      //           })
      //         );
      //       }
      //     }
      //   });

      //   Promise.all(addedByDMIds).then((res) => {
      //     member.channels = member.channels.map((channel, index) => {
      //       if (res[index].data.length) {
      //         if (
      //           channel.dm_channel_users &&
      //           channel.dm_channel_users.addedBy
      //         ) {
      //           channel.dm_channel_users.addedByUsername =
      //             res[index].data[0].username;
      //         }
      //       }
      //       return channel;
      //     });
      //     self.setDMs(member.channels);
      //   });
      //   self.fetchMixpanelDMGraph(member.channels);
      // }
      let memberGroupsArr = member.groups ? member.groups : [];
      const unavailableGroups = [];
      const unassignedGroups = _.cloneDeep(
        _.differenceWith(
          GroupStore.allGroups,
          memberGroupsArr,
          (group, memberGroup) => group.id === memberGroup.id
        )
      );
      self.unassignedGroups = _.cloneDeep(unassignedGroups);
      let addedByIds = [];
      memberGroupsArr.forEach((memberGroup) => {
        unavailableGroups.push(memberGroup.id);
        if (memberGroup.user_groups && memberGroup.user_groups.addedBy) {
          if (memberGroup.user_groups.addedBy !== -1) {
            addedByIds.push(memberGroup.user_groups.addedBy);
          }
        }
      });
      if (unavailableGroups.length > 0) {
        GroupStore.fetchGroupsById(unavailableGroups);
      }
      self.getSemiMods(unavailableGroups);

      const userResponse = await self.getUsernameFromIds(addedByIds);
      if (userResponse && userResponse.length) {
        self.setAddedByUsernames(userResponse);
      }
      self.setAssignedGroups(memberGroupsArr);
      // self.fetchMixpanelGroupGraph(memberGroupsArr);
    },
    async getUsernameFromIds(ids) {
      const userIds = ids.reduce((obj, item) => {
        if (!obj.hasOwnProperty(item)) {
          obj[item] = true;
        }
        return obj;
      }, {});

      if (userIds && Object.keys(userIds).length) {
        const params = {
          payload: Object.keys(userIds),
        };
        const response = await ApiService.postRequest("username-by-id", params);
        return response.data;
      }
    },
    async downloadConsentPDF(userId) {
      if (userId) {
        self.setLoading(true);
        const params = {
          userId: userId,
        };
        const response = await ApiService.postRequest("download-consent", params);
        if (response && response.data && response.data.consent) {
          self.setLoading(false);
          const encodedWord = CryptoJS.enc.Base64.parse(response.data.consent);
          const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
          return 'data:application/octet-stream;base64,' + decoded;
        }
        self.setLoading(false);
        return null;
      }
    },
    async downloadUserFormPDF(paramsData = {}) {
      try {
        const { userFormId, userIdFromAdmin, formName } = paramsData;
        self.setLoading(true);
        let decodedPDFString = null;
        if (userFormId) {
          self.setLoading(true);
          const params = {
            userFormId,
            form: formName,
            userIdFromAdmin,
            isSubmitted: true
          };
          const response = await ApiService.getRequest("form-pdf-generate", params);
          if (response && response.data && response.data.pdfGeneration) {
            const encodedWord = CryptoJS.enc.Base64.parse(response.data.pdfGeneration);
            const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
            decodedPDFString = 'data:application/octet-stream;base64,' + decoded;
          }
        }
        self.setLoading(false);
        return decodedPDFString;
      } catch (error) {
        self.setLoading(false);
      }
    },
    async fetchFullnameByUsername(username) {
      let params = {
        username,
        "$select[]=fullName&$select[]": "id",
      };
      const response = await ApiService.getRequest("users", params);
      if (response.success) {
        return response.data
      }
      return false;
    },
    async fetchMixpanelGroupGraph(groups) {
      try {
        let promises = [];
        _.cloneDeep(groups).forEach((assignedGroup) => {
          const params = {
            from: moment().subtract(7, "days"),
            to: moment(),
            type: "general",
            unit: "day",
            where: `properties["GroupId"] == ${assignedGroup.id}`,
          };
          self.setGroupGraphLoading(true);
          promises.push(
            window.MP.api.segment("Send Message In Group", null, params)
          );
        });
        self.clearQueryResult();
        const res = await Promise.all(promises);
        let extractedData = [];
        res.map((item) => {
          let groupMessageData = [];
          Object.keys(item.json["Send Message In Group"])
            .sort()
            .map((date) => {
              groupMessageData.push({
                label: moment(date).format("MMM D"),
                y: item.json["Send Message In Group"][date],
                color: "#8adfb6",
              });
              return date;
            });
          extractedData.push(groupMessageData);
          return item;
        });
        self.saveMixpanelGraphInfo(extractedData);
        self.setGroupGraphLoading(false);
      } catch (err) {
        self.setGroupGraphLoading(false);
      }
    },
    setGroupGraphLoading(value) {
      self.groupGraphLoading = value;
    },
    setDmGraphLoading(value) {
      self.dmGraphLoading = value;
    },

    setDMs(dms) {
      self.dms = _.cloneDeep(dms);
    },

    getSemiMods(memberGroupIds) {
      const promises = [];
      memberGroupIds.forEach((id) => {
        promises.push(GroupStore.fetchGroupSemiMods(id));
      });
    },

    saveMixpanelGraphInfo(data) {
      self.queryResult = data;
    },

    saveMixpanelDMs(data) {
      self.mixpanelDM = data;
    },

    clearQueryResult() {
      self.queryResult = [];
    },

    clearMixpanelDMResult() {
      self.mixpanelDM = [];
    },

    async getForm(formId, messageId) {
      if (formId == self.formId && messageId == self.messageId) {
        return;
      } else {
        self.setFormIdMessageId(formId, messageId);
        const params = {
          userId: self.userId,
          messageId: messageId,
          $limit: 100
        };
        self.setFormLoading(true);
        const response = await ApiService.getRequest(
          "form-question-responses",
          params
        );
        if (response.success && response.data.length) {
          self.setFormAnswers(response.data);
          const path = `forms/${formId}`;
          const formQuestions = await ApiService.getRequest(path, {
            include: true,
            includeOnly: "form-questions",
          });
          if (formQuestions.success) {
            self.setFormQuestions(formQuestions.data.questions);
          }
        } else {
          self.setFormLoading(false);
          self.setFormQuestions([]);
          return;
        }
        self.setFormLoading(false);
      }
    },

    setAssignedGroups(groups) {
      self.assignedGroups = _.cloneDeep(groups);
    },

    setShowDOB(value) {
      self.showDOB = value;
    },

    setFormIdMessageId(formId, messageId) {
      self.formId = parseInt(formId);
      self.messageId = messageId;
    },
    setFormLoading(value) {
      self.formLoading = value;
    },
    setFormQuestions(formQuestions) {
      self.formQuestions = formQuestions.map((item) => {
        FormQuestion.options = item.options.map((option) => {
          return Option.create(option);
        });
        return FormQuestion.create(item);
      });
    },
    setFormAnswers(formAnswers) {
      self.formAnswers = formAnswers.map((item) => {
        return FormAnswer.create(item);
      });
    },
    setCallOutreachValue(value) {
      self.callOutreach = value;
    },
    setSmsOutreachValue(value) {
      self.smsOutreach = value;
    },
    async changeAssignedPeer(data = {}) {
      try {
        const { userId, assignedPeerId, } = data;
        const path = "assigned-peers";
        self.setLoading(true);
        const response = await ApiService.postRequest(path, { userId, assignedPeerId });
        if (response.success) {
          const member = await MemberListStore.getMemberDetails(userId);
          if (member) {
            self.setMemberData(member);
          }
          await MemberListStore.callFetchMembers();
          if (StatsStore.statsTabKey === 5) {
            await StatsStore.getFormsData();
          }
          if (StatsStore.statsTabKey === 2 || StatsStore.statsTabKey === 3 || StatsStore.statsTabKey === 4) {
            await StatsStore.getUsersList();
          }
        }
        self.setLoading(false);
      } catch (error) {
        self.setLoading(false);
      }
    },
    async changeOutreachType(data = {}) {
      try {
        const { userId, isSmsOutreach = false, outreachValue = "" } = data;
        const path = "users-outreach";
        const id = userId;
        self.setLoading(true);
        const response = await ApiService.patchRequest(path, id, { isSmsOutreach, outreachValue });
        if (response.success) {
          const copyMembersArr = toJS(MemberListStore.members);
          const matchIndex = copyMembersArr.findIndex(obj => obj.id === id);
          let key = "";
          let keyForLatestHistory = "";
          if (isSmsOutreach) {
            self.setSmsOutreachValue(outreachValue);
            key = "smsOutreach";
            keyForLatestHistory = "latestSmsOutreachHistory";
          } else {
            self.setCallOutreachValue(outreachValue);
            key = "callOutreach";
            keyForLatestHistory = "latestCallOutreachHistory";
          }
          if (matchIndex > -1 && key && keyForLatestHistory) {
            copyMembersArr[matchIndex][key] = outreachValue;
            copyMembersArr[matchIndex][keyForLatestHistory] = {
              outreachValue,
              createdAt: moment().toISOString(),
              sourceUserDetails: {
                username: AuthStore.username,
                id: AuthStore.userId,
              }
            };
            MemberListStore.setCopyMembersData(copyMembersArr);
          }
        }
        self.setLoading(false);
      } catch (error) {
        self.setLoading(false);
      }
    },
    async changeUserType(type, groupId, userId) {
      const path = "user-groups";
      const id = `${userId}?groupId=${groupId}`;
      self.setLoading(true);
      const response = await ApiService.patchRequest(path, id, { type });
      if (response.success) {
        GroupStore.changeUserType(groupId, userId, type);
        if (type === "user") {
          GroupStore.updateMemberCount(groupId, +1);
        } else if (type === "semi-moderator" || type === "moderator") {
          GroupStore.updateMemberCount(groupId, -1);
        }
        self.changeTypeInAssignedGroup(groupId, type);
        self.getSemiMods([groupId]);
      }
      self.setLoading(false);
    },
    changeTypeInAssignedGroup(groupId, type) {
      const assigned = _.cloneDeep(self.assignedGroups);
      const groupIndex = assigned.findIndex((group) => group.id === groupId);
      let modifiedGroup = assigned[groupIndex];
      modifiedGroup.user_groups.type = type;
      assigned[groupIndex] = modifiedGroup;
      self.assignedGroups = assigned;
    },
    async getProfileQuestions() {
      const response = await ApiService.getRequest("profile-questions");
      if (response.data && response.data.length) {
        self.saveProfileQuestions(response.data);
      }
    },
    saveProfileQuestions(data) {
      self.Questions = data.map((item) => {
        return Question.create(item);
      });
    },

    updateGroups() {
      const unassignedGroups = _.cloneDeep(
        _.differenceWith(
          GroupStore.allGroups,
          self.assignedGroups,
          (group, memberGroup) => group.id === memberGroup.id
        )
      );
      self.unassignedGroups = _.cloneDeep(unassignedGroups);
    },
    assignUserToGroup(groupId) {
      self.deleteUnassignedGroup(groupId);
      self.addAssignedGroup(groupId);
      return true;
    },
    async changeUserPassword(userId) {
      if (self.isPasswordValid) {
        let objToUpdate = {
          password: self.password,
        };
        self.setLoading(true);
        const response = await ApiService.patchRequest(
          "users",
          userId,
          objToUpdate
        );
        if (response.success) {
          NotificationStore.setNotification(
            "success",
            userProfile.passwordUpdateSuccess
          );
          self.resetPassword();
        } else {
          NotificationStore.setNotification(
            "error",
            userProfile.passwordUpdateError
          );
        }
        self.setLoading(false);
      }
    },
    resetPassword() {
      self.password = "";
      self.TogglePopOver(false);
    },
    async getGroupDetails() {
      const option = {
        method: "GET",
      };
      const url = sheetyCalls.fetchRecommendedGroups;
      const request = new Request(url, option);
      mixpanel.time_event(`Loading time on getRequest{${url}}`);
      const response = await fetch(request)
        .then((response) => {
          mixpanel.track(`Loading time on getRequest{${url}}`, { from: "WEB" });
          if (response.ok) {
            return response.json();
          }
        })
        .catch((err) => { });
      if (response) {
        self.saveGroupsInfo(response[0]);
      }
    },
    async submitUserFormReviewDetails(userFormId) {
      try {
        self.setFormLoading(true);
        const response = await ApiService.patchRequest("user-forms", userFormId);
        if (response.success) {
          // After form is submitted, we need to fetch new filled form for user profile
          const member = await MemberListStore.getMemberDetails(self.userId);
          self.setMemberData(member);
          // If admin fills the form from forms screen in stats page then we also need to fetch new filled forms
          await StatsStore.getFormsData();
        }
        self.setFormLoading(false);
      } catch (error) {
        self.setFormLoading(false);
      }
    },
    async getProviderModeratedGroups(champId) {
      const params = {
        include: true,
        userId: champId,
        type: "moderator",
      };
      const response = await ApiService.getRequest("user-groups", params);
      const groupNames = response.data
        .filter((item) => {
          if (item.group) {
            return item.group.name;
          }
        })
        .map((item) => item.group.name);
      self.addChampModeratedGroups(groupNames);
    },
    async getContactModeratedGroups(contactId) {
      const params = {
        include: true,
        userId: contactId,
        type: "moderator",
      };
      const response = await ApiService.getRequest("user-groups", params);
      const groupNames = response.data
        .filter((item) => {
          if (item.group) {
            return item.group.name;
          }
        })
        .map((item) => item.group.name);
      self.addContactModeratedGroups(groupNames);
    },
    addContactModeratedGroups(groupNames) {
      self.contactModeratedGroups = groupNames.map((item) => item);
    },
    addChampModeratedGroups(groupNames) {
      self.champModeratedGroups = groupNames.map((item) => item);
    },

    async getHighPointUsers() {
      self.setFetchinhHpUsers(true);
      const option = {
        method: "GET",
      };
      const url = sheetyCalls.fetchHPUsers;
      const request = new Request(url, option);
      mixpanel.time_event(`Loading time on getRequest{${url}}`);
      const response = await fetch(request)
        .then((response) => {
          mixpanel.track(`Loading time on getRequest{${url}}`, { from: "WEB" });
          if (response.ok) {
            return response.json();
          }
        })
        .catch((err) => {
          self.setFetchinhHpUsers(false);
        });
      if (response) {
        self.addHPUsers(response.hpUser);
        self.setFetchinhHpUsers(false);
      }
    },
    setFetchinhHpUsers(value) {
      self.fetchingHpUsers = value;
    },
    addHPUsers(Hpusers) {
      self.HpUsers = Hpusers.map((item) => {
        if (item.contactId === self.recommendedBy) {
          self.updateHighPointChampion(item.contactName, item.champName);
        }
        return HPUser.create(item);
      });
    },
    getUserFromHpUsers(id) {
      let name = "";
      if (self.HpUsers.length) {
        self.HpUsers.forEach((item) => {
          if (item.contactId === id) {
            name = item.contactName;
          }
        });
      } else {
        hpUsers.forEach((item) => {
          if (item.contactId === id) {
            name = item.contactName;
          }
        });
      }
      return name;
    },
    getUsersFromHpUsers(ids) {
      let name = "";
      self.HpUsers.forEach((item) => {
        if (ids.length) {
          ids.forEach((id) => {
            if (item.contactId === id) {
              name = name + item.contactName + ", ";
            }
          });
        }
      });
      return name;
    },
    updateHighPointChampion(contactName, champName) {
      self.championName = champName;
      self.contactName = contactName;
    },
    saveGroupsInfo(response) {
      self.line1 = response.line1;
      self.line2 = response.line2;
      self.groupsString = response.groups;
    },
    addAssignedGroup(groupId) {
      const group = _.cloneDeep(
        _.find(GroupStore.allGroups, (group) => group.id === groupId)
      );
      if (group && group.user_groups) {
        group.user_groups.addedByUsername = AuthStore.username;
      }
      !_.includes(["semi-moderator", "moderator"], self.type) &&
        GroupStore.updateMemberCount(groupId, +1);
      self.assignedGroups.push(group);
    },
    setGroupStatus(groupId, value) {
      const index = _.findIndex(
        self.assignedGroups,
        (group) => group.id === groupId
      );
      if (
        self.assignedGroups[index].status !== "denied" &&
        value === "denied"
      ) {
        !_.includes(["semi-moderator", "moderator"], self.type) &&
          GroupStore.updateMemberCount(groupId, -1);
      } else if (
        self.assignedGroups[index].status === "denied" &&
        value !== "denied"
      ) {
        !_.includes(["semi-moderator", "moderator"], self.type) &&
          GroupStore.updateMemberCount(groupId, +1);
      }
      self.assignedGroups[index].status = value;
    },
    deleteUnassignedGroup(groupId) {
      const unAssigned = _.cloneDeep(self.unassignedGroups);
      _.remove(unAssigned, { id: groupId });
      self.unassignedGroups = unAssigned;
    },
    setLoading(value) {
      self.loading = value;
    },
    setWellnessFormLoading(value) {
      self.wellnessFormLoading = value;
    },
    toggleEdit(prop, value) {
      self[prop] = value;
    },
    setPreviousData() {
      self.newUsername = self.username;
      self.newFullname = self.fullname;
      self.newMrn = self.mrn;
      self.newSiteId = self.siteId;
      self.newEmail = self.email;
      if (self.phone) {
        self.newPhone = self.phone.replace(/[( )-]/g, "");
      } else {
        self.newPhone = null;
      }
      self.newType = self.type;
      self.newTrainingStatus = self.trainingStatus;
      self.newColor = self.color;
      self.newNotes = self.notes;
      self.newDOB = self.DOB;
    },
    changePreviousValue(prop, value) {
      self[prop] = value;
    },
    updateUserSiteId(newSiteId) {
      self.newSiteId = Number(newSiteId);
    },
    updateUserColor(color) {
      self.newColor = color;
    },
    updateUserType(type) {
      self.newType = type;
    },
    updateUserBadgeType(badgeType) {
      self.newBadgeType = badgeType;
    },
    updateUserTraining(status) {
      self.newTrainingStatus = status;
    },
    updateStartAGroup(value) {
      if (value) {
        self.trainingStatus = "completed";
        self.color = "green";
      } else {
        self.trainingStatus = "completed";
        self.color = "red";
      }
    },

    updateProviderId(contactId) {
      self.providerId = Number(contactId);
      self.getChampNameFromAllChampions(contactId);
    },
    getChampNameFromAllChampions(contactId) {
      const selectedOption = self.HpUsers.filter(
        (item) => item.contactId == contactId
      );
      if (selectedOption.length) {
        self.updateHighPointChampion(
          selectedOption[0].contactName,
          selectedOption[0].champName
        );
      }
      self.setHighPointContact(
        selectedOption[0].contactId,
        selectedOption[0].champId
      );
    },
    setHighPointContact(recommendedBy, providerId) {
      self.recommendedBy = recommendedBy;
      self.providerId = providerId;
    },
    async changeUserStatus(userId, allMembers) {
      const moderatorId = AuthStore.userId;

      const conditionToCheck = IS_HP ? true : self.color || self.newColor;
      //if (conditionToCheck)
      if (true) {
        self.setLoading(true);
        let response;
        if (self.editClinical) {
          let objToUpdate = {};
          if (self.newDOB && self.DOB !== self.newDOB) {
            if (self.DOB && !self.newDOB) {
              NotificationStore.setNotification(
                "error",
                userProfile.invalidDOB
              );
              self.setLoading(false);
              return;
            }
            const dateOfBirth = moment(self.newDOB, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            );
            objToUpdate = {
              ...objToUpdate,
              dob: dateOfBirth,
            };
          }
          if (self.username !== self.newUsername) {
            if (!self.isUsernameValid(self.newUsername)) {
              NotificationStore.setNotification("error", user.usernameError2);
              self.setLoading(false);
              return;
            }
            if (
              (self.type === "user" || self.type === "semi-moderator") &&
              self.doesUsernameContainsRealname(
                self.newUsername,
                self.newFullname
              )
            ) {
              NotificationStore.setNotification("error", user.usernameError3);
              self.setLoading(false);
              return;
            }
            const checkUniqueUsername = await ApiService.getRequest(
              "unique-username-email",
              { username: self.newUsername }
            );
            if (!checkUniqueUsername.data.present) {
              objToUpdate = {
                ...objToUpdate,
                username: self.newUsername,
              };
            } else {
              NotificationStore.setNotification("error", user.usernameError);
              self.setLoading(false);
              return;
            }
          }
          if (self.fullname !== self.newFullname) {
            if (!self.isFullNameValid(self.newFullname)) {
              NotificationStore.setNotification("error", user.fullNameError);
              self.setLoading(false);
              return;
            }
            objToUpdate = {
              ...objToUpdate,
              fullName: self.newFullname,
            };
          }
          if (self.email !== self.newEmail) {
            if (!self.isEmailValid(self.newEmail)) {
              NotificationStore.setNotification("error", user.emailError);
              self.setLoading(false);
              return;
            }
            const checkUniqueEmail = await ApiService.getRequest(
              "unique-username-email",
              { email: self.email }
            );
            if (!checkUniqueEmail.data.present) {
              objToUpdate = {
                ...objToUpdate,
                email: self.newEmail,
              };
            } else {
              NotificationStore.setNotification("error", user.emailError2);
              self.setLoading(false);
              return;
            }
          }
          if (self.type !== self.newType) {
            objToUpdate = {
              ...objToUpdate,
              type: self.newType,
            };
          }
          if (self.trainingStatus !== self.newTrainingStatus) {
            objToUpdate = {
              ...objToUpdate,
              trainingStatus: self.newTrainingStatus,
            };
          }
          if (self.newPhone && self.phone !== self.newPhone) {
            if (self.isNotNumber(self.newPhone)) {
              NotificationStore.setNotification("error", user.phoneError2);
              self.setLoading(false);
              return;
            }
            if (
              (self.newPhone && self.newPhone.length < 10) ||
              (self.newPhone && self.newPhone.length > 10)
            ) {
              NotificationStore.setNotification("error", user.phoneError);
              self.setLoading(false);
              return;
            }

            const formattedPhone =
              "(" +
              self.newPhone.slice(0, 3) +
              ")" +
              " " +
              self.newPhone.slice(3, 6) +
              "-" +
              self.newPhone.slice(6);
            objToUpdate = {
              ...objToUpdate,
              phoneNumber: formattedPhone,
            };
          }
          if (self.notes !== self.newNotes) {
            objToUpdate = {
              ...objToUpdate,
              notes: self.newNotes,
            };

            if ((self.newNotes.toLowerCase().indexOf("eligible") !== -1) && (self.newNotes.toLowerCase().indexOf("not eligible") == -1)) {
              objToUpdate = {
                ...objToUpdate,
                color: "#ffb224"
              };
            } else {
              objToUpdate = {
                ...objToUpdate,
                color: null,
              };
            }

            if (self.newNotes.toLowerCase().indexOf("enrolled") !== -1) {
              //  If eligible is  selected along with enrolled then enrolled will be given priority that's why this condition is under the eligible condition
              objToUpdate = {
                ...objToUpdate,
                color: "#108ee9",
              };
            } else if (self.newNotes.toLowerCase().indexOf("eligible") == -1) {
              // Case when eligible is selected we dont need to set color as false
              objToUpdate = {
                ...objToUpdate,
                color: null,
              };
            }

          }



          if (self.mrn !== self.newMrn) {
            objToUpdate = {
              ...objToUpdate,
              mrn: self.newMrn,
            };
          }
          if (self.color !== self.newColor) {
            objToUpdate = {
              ...objToUpdate,
              color: self.newColor,
            };
          }
          if (self.siteId !== self.newSiteId) {
            objToUpdate = {
              ...objToUpdate,
              siteId: self.newSiteId,
            };
          }

          objToUpdate = {
            ...objToUpdate,
            approvedBy: moderatorId,
            isApproved: true,
            recommendedBy: self.recommendedBy,
            providerId: self.providerId,
          };
          response = await ApiService.patchRequest(
            "users",
            userId,
            objToUpdate
          );
        } else {
          response = await ApiService.patchRequest("users", userId, {
            approvedBy: moderatorId,
            isApproved: true,
            providerId: self.providerId,
            recommendedBy: self.recommendedBy,
          });
        }

        if (response.success) {
          MemberListStore.updateUsernameAndSite(
            response.data.username,
            response.data.fullName,
            response.data.id,
            response.data.siteId,
            response.data.isApproved,
            allMembers
          );
          if (response.data.recommendedBy) {
            self.getContactModeratedGroups(response.data.recommendedBy);
          }
          if (response.data.providerId) {
            self.getProviderModeratedGroups(response.data.providerId);
          }
          self.updateMemberData(response.data);
          self.fetchApprovedByName(response.data.approvedBy);
          self.setLoading(false);
          self.toggleEdit("editClinical", false);
          MemberListStore.removeUnApproved(userId);
        } else {
          NotificationStore.setNotification(
            "error",
            userProfile.updateStatusError
          );
          self.setLoading(false);
        }
      } else {
        NotificationStore.setNotification(
          "error",
          userProfile.mandatoryFieldErrorMessage
        );
      }
    },
    async fetchGroupsFromForms() {
      const params = {
        formId: 1,
        submittedFrom: "WAITING",
        isSubmitted: true,
        userId: self.userId,
      };
      const userFormResponse = await ApiService.getRequest(
        "user-forms",
        params
      );
      if (userFormResponse.success) {
        let i = 0;
        while (i < userFormResponse.data.length) {
          const formQuestionParams = {
            userFormId: userFormResponse.data[i].id,
            formQuestionId: 1,
          };
          const formQuestionResponse = await ApiService.getRequest(
            "form-question-responses",
            formQuestionParams
          );
          if (
            formQuestionResponse.success &&
            formQuestionResponse.data.length
          ) {
            const answers = formQuestionResponse.data[0].answer.split(",");
            const answerParams = answers.reduce((acc, item, index) => {
              acc[`id[${index}]`] = item;
              return acc;
            }, {});
            const groupNames = await ApiService.getRequest(
              "form-question-options",
              answerParams
            );
            if (groupNames.success) {
              self.setGroupOptions(groupNames.data);
            }
          }
          i = i + 1;
        }
      }
    },
    setGroupOptions(groupNames) {
      self.groupOptions = groupNames.map((item) => {
        return item.option;
      });
    },
    async fetchApprovedByName(approvedById) {
      let params = {
        id: approvedById,
        "$select[1]": "fullName",
      };
      const response = await ApiService.getRequest("users", params);
      self.updateApprovedBy(_.get(response, "data[0]", ""));
    },
    updateApprovedBy(data) {
      self.approvedbyUser = _.get(data, "fullName", "");
    },
    updateMemberData(member) {
      self.username = member.username;
      self.oldUsername = member.username;
      self.userId = member.id;
      self.badgeType = member.badgeType;
      if (member.dob) {
        let date = moment(member.dob, "YYYY-MM-DD");
        self.setShowDOB(true);
        self.DOB = date.format("YYYY-MM-DD");
      } else {
        self.setShowDOB(false);
      }
      self.mrn = member.mrn;
      self.type = member.type;
      self.siteId = member.siteId;
      self.createdAt = member.createdAt;
      self.codeOfConduct = member.codeOfConductCompletedAt;
      self.consentDoneAt = member.consentDoneAt;
      self.userConsent = member.userConsent
      self.userConsent = member.userConsent
      self.phone = member.phoneNumber;
      self.email = member.email;
      self.isResolved = member.isResolved;
      self.recommendedBy = member.recommendedBy;
      self.isApproved = member.isApproved;
      self.approvedBy = member.approvedBy;
      if (member.isApproved && member.approvedBy) {
        self.fetchApprovedByName(member.approvedBy);
      }
      self.fullname = member.fullName;
      self.color = member.color;
      self.providerId = member.providerId;
      self.approvedAt = member.approvedAt;
      self.trainingStatus = member.trainingStatus;
      self.notes = member.notes;
    },
    async updateUserProfile(userId, allMembers) {
      let objToUpdate = {};
      self.setLoading(true);
      if (self.newDOB && self.DOB !== self.newDOB) {
        if (self.DOB && !self.newDOB) {
          NotificationStore.setNotification("error", userProfile.invalidDOB);
          self.setLoading(false);
          return;
        }
        const dateOfBirth = moment(self.newDOB, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        objToUpdate = {
          ...objToUpdate,
          dob: dateOfBirth,
        };
      }
      if (self.username !== self.newUsername) {
        if (!self.isUsernameValid(self.newUsername)) {
          NotificationStore.setNotification("error", user.usernameError2);
          self.setLoading(false);
          return;
        }
        if (
          (self.type === "user" || self.type === "semi-moderator") &&
          self.doesUsernameContainsRealname(self.newUsername, self.newFullname)
        ) {
          NotificationStore.setNotification("error", user.usernameError3);
          self.setLoading(false);
          return;
        }
        const checkUniqueUsername = await ApiService.getRequest(
          "unique-username-email",
          { username: self.newUsername }
        );
        if (!checkUniqueUsername.data.present) {
          objToUpdate = {
            ...objToUpdate,
            username: self.newUsername,
          };
        } else {
          NotificationStore.setNotification("error", user.usernameError);
          self.setLoading(false);
          return;
        }
      }
      if (self.fullname !== self.newFullname) {
        if (!self.isFullNameValid(self.newFullname)) {
          NotificationStore.setNotification("error", user.fullNameError);
          self.setLoading(false);
          return;
        }
        objToUpdate = {
          ...objToUpdate,
          fullName: self.newFullname,
        };
      }
      if (self.email !== self.newEmail) {
        if (!self.isEmailValid(self.newEmail)) {
          NotificationStore.setNotification("error", user.emailError);
          self.setLoading(false);
          return;
        }
        const checkUniqueEmail = await ApiService.getRequest(
          "unique-username-email",
          { email: self.newEmail }
        );
        if (!checkUniqueEmail.data.present) {
          objToUpdate = {
            ...objToUpdate,
            email: self.newEmail,
          };
        } else {
          NotificationStore.setNotification("error", user.emailError2);
          self.setLoading(false);
          return;
        }
      }
      if (self.type !== self.newType) {
        objToUpdate = {
          ...objToUpdate,
          type: self.newType,
        };
      }
      if (self.trainingStatus !== self.newTrainingStatus) {
        objToUpdate = {
          ...objToUpdate,
          trainingStatus: self.newTrainingStatus,
        };
      }
      if (self.newPhone && self.phone !== self.newPhone) {
        if (self.isNotNumber(self.newPhone)) {
          NotificationStore.setNotification("error", user.phoneError2);
          self.setLoading(false);
          return;
        }
        if (
          (self.newPhone && self.newPhone.length < 10) ||
          (self.newPhone && self.newPhone.length > 10)
        ) {
          NotificationStore.setNotification("error", user.phoneError);
          self.setLoading(false);
          return;
        }
        const formattedPhone =
          "(" +
          self.newPhone.slice(0, 3) +
          ")" +
          " " +
          self.newPhone.slice(3, 6) +
          "-" +
          self.newPhone.slice(6);
        objToUpdate = {
          ...objToUpdate,
          phoneNumber: formattedPhone,
        };
      }
      if (self.notes !== self.newNotes) {
        objToUpdate = {
          ...objToUpdate,
          notes: self.newNotes,
        };




        if ((self.newNotes.toLowerCase().indexOf("eligible") !== -1) && (self.newNotes.toLowerCase().indexOf("not eligible") == -1)) {
          objToUpdate = {
            ...objToUpdate,
            color: "#ffb224"
          };
        } else {
          objToUpdate = {
            ...objToUpdate,
            color: null,
          };
        }

        if (self.newNotes.toLowerCase().indexOf("enrolled") !== -1) {
          //  If eligible is  selected along with enrolled then enrolled will be given priority that's why this condition is under the eligible condition
          objToUpdate = {
            ...objToUpdate,
            color: "#108ee9",
          };
        } else if (self.newNotes.toLowerCase().indexOf("eligible") == -1) {
          // Case when eligible is selected we dont need to set color as false
          objToUpdate = {
            ...objToUpdate,
            color: null,
          };
        }

      }
      if (self.mrn !== self.newMrn) {
        objToUpdate = {
          ...objToUpdate,
          mrn: self.newMrn,
        };
      }
      if (self.color !== self.newColor) {
        objToUpdate = {
          ...objToUpdate,
          color: self.newColor,
        };
      }
      if (self.siteId !== self.newSiteId) {
        objToUpdate = {
          ...objToUpdate,
          siteId: self.newSiteId,
        };
      }
      if (self.badgeType !== self.newBadgeType) {
        if (self.newBadgeType) {
          const updatedBadgeType = self.newBadgeType === BADGE_TYPE_REMOVE ? null : self.newBadgeType;

          objToUpdate = {
            ...objToUpdate,
            badgeType: updatedBadgeType,
          };
        }
      }

      objToUpdate = {
        ...objToUpdate,
        recommendedBy: self.recommendedBy,
        providerId: self.providerId,
      };
      const response = await ApiService.patchRequest(
        "users",
        userId,
        objToUpdate
      );

      if (response.success) {
        MemberListStore.updateUsernameAndSite(
          response.data.username,
          response.data.fullName,
          response.data.id,
          response.data.siteId,
          response.data.isApproved,
          allMembers
        );
        if (response.data.recommendedBy) {
          self.getContactModeratedGroups(response.data.recommendedBy);
        }
        if (response.data.providerId) {
          self.getProviderModeratedGroups(response.data.providerId);
        }
        self.updateMemberData(response.data);
        self.updateUserBadgeType(null);
        NotificationStore.setNotification(
          "success",
          userProfile.profileUpdateSuccess
        );
        self.toggleEdit("editClinical", false);
      } else {
        NotificationStore.setNotification(
          "error",
          userProfile.profileUpdateError
        );
      }
      self.setLoading(false);
    },
  }))
  .views((self) => ({
    isAssigned(groupId) {
      const group = _.find(self.assignedGroups, { id: groupId });
      return group && group.status !== "denied";
    },
    get passwordIsValid() {
      return self.password !== "";
    },
    isEmailValid(email) {
      let regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return regEx.test(email.toLowerCase());
    },
    isFullNameValid(fullName) {
      let regEx = /^[a-zA-Z'_.-]+(\s?[a-zA-z'_.-]+)(\s?[a-zA-z'_.-]+)\s*$/;
      return regEx.test(fullName);
    },
    isUsernameValid(username) {
      let regEx = /^[a-zA-Z0-9_-]{2,30}$/;
      return regEx.test(username);
    },
    doesUsernameContainsRealname(username, fullName) {
      return (
        username.toLowerCase().includes(fullName.toLowerCase().split(" ")[0]) ||
        username.toLowerCase().includes(fullName.toLowerCase().split(" ")[1]) ||
        username.toLowerCase().includes(fullName.toLowerCase().split(" ")[2])
      );
    },
    isNotNumber(phone) {
      let regEx = /^\d+$/;
      return !regEx.test(phone);
    },
    get isPasswordValid() {
      const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

      if (!strongRegex.test(self.password)) {
        NotificationStore.setNotification("error", user.passwordError);
        return false;
      }
      return true;
    },
  }));

export default Profile;
