import "../../styles/global.scss";

import { Tabs, Table, Spin } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";
import { CBRS } from "../../en.json";
import moment from "moment";
import { toJS } from "mobx";
import { withRouter } from "react-router-dom";
import { getReferralStatus } from "../../utils/getReferralStatus";
import CbrsDiscontinueModal from "../UI/CbrsDiscontinueModal";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class ReferrerView extends React.Component {
  async componentDidMount() {
    const {
      store: {
        ReferralStore: { fetchAllReferrals },
        ProfileStore: { getHighPointUsers },
      },
    } = this.props;
    getHighPointUsers();
    fetchAllReferrals();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        ReferralStore: { fetchAllReferrals, setReferralPagination },
      },
    } = this.props;

    setReferralPagination(pagination);
    fetchAllReferrals();
  };

  setFilter = (key) => {
    const {
      store: {
        ReferralStore: { setReferralFilter, referralsLoading },
      },
    } = this.props;
    if (referralsLoading) {
      return;
    }
    setReferralFilter(Number(key));
  };

  newReferral = (value) => {
    const {
      store: {
        ReferralStore: { resetClientData, setNewReferral },
      },
    } = this.props;
    resetClientData();
    setNewReferral(value);
  };

  render() {
    const {
      store: {
        AuthStore: { loading },
        ProfileStore: { getUserFromHpUsers, fetchingHpUsers },
        ReferralStore: {
          getFinishedReferralResponse,
          referralsLoading,
          referrals,
          referralPagination,
          showReferralForm,
        },
      },
    } = this.props;

    const columns = [
      {
        title: "Name",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {item.targetUserInfo && item.targetUserInfo.fullName
              ? item.targetUserInfo.fullName
              : ""}
          </div>
        ),
      },
      {
        title: "Phone Number",
        key: "id",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {item.targetUserInfo && item.targetUserInfo.phoneNumber
              ? item.targetUserInfo.phoneNumber
              : "-"}
          </div>
        ),
      },
      {
        title: "Initiated by",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {item.sourceUserInfo
              ? item.sourceUserInfo.fullName
              : "Self Referral"}
          </div>
        ),
      },
      {
        title: "Referral Form",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {item.status > 100 ? "Finished" : "Incomplete"}
          </div>
        ),
      },
      {
        title: "Start Date",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {getReferralStatus(item.status, item.program)}
          </div>
        ),
      },
      {
        title: "Assigned to",
        dataIndex: "",
        render: (item) => (
          <div
            className={
              item.status === 100 ? "link-text table-col" : "table-col"
            }
          >
            {item.referAssignedTo.length === 0 ? (
              " Not Assigned "
            ) : fetchingHpUsers ? (
              <Spin />
            ) :
              (
                // JSON.stringify(item.referAssignedTo[0])
                getUserFromHpUsers(item.referAssignedTo[0].userId)
              )
            }

          </div>
        ),
      },
    ];

    const rowEvents = (record, rowIndex) => {
      const {
        store: {
          ReferralStore: {
            isDataSaved,
            getReferralFormStatus,
            setReferralModalVisible,
            getReferalResponse,
            showReferralForm,
          },
        },
      } = this.props;
      return {
        onClick: () => {
          if (showReferralForm && !isDataSaved) {
            setReferralModalVisible(true);
            return;
          } else {
            if (record.status === 100) {
              getReferalResponse(record);
            } else {
              getFinishedReferralResponse(record);
            }
            // getReferralFormStatus(record);
          }
        },
      };
    };

    return (
      <div className="member-table">
        <CbrsDiscontinueModal />
        <div className="search-member-container">
          <Tabs tabBarStyle={{ border: 0 }} onChange={this.setFilter}>
            <TabPane className="my-tabs-ink-bar" tab="All" key="0"></TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="In Progress"
              key="1"
            ></TabPane>
            <TabPane className="my-tabs-ink-bar" tab=" Sent" key="2"></TabPane>
          </Tabs>
          <div>
            <button
              className="new-referral-button"
              onClick={() => this.newReferral(true)}
            >
              {CBRS.newCBRS}
            </button>
          </div>
        </div>
        {loading || referralsLoading || (referrals && referrals.length) ? (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={toJS(referrals)}
            onChange={this.handleTableChange}
            pagination={referralPagination}
            loading={referralsLoading || loading}
            onRow={rowEvents}
          />
        ) : (
          <div className="empty-CBRS">
            <div>{CBRS.noReferrals1}</div>
            <div>{CBRS.noReferrals2}</div>
            <div>{CBRS.noReferrals3}</div>
          </div>
        )}
      </div>
    );
  }
}

export default ReferrerView;
