import React from "react";
import { Tabs } from "antd";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import StatsAnalytics from "./StatsAnalytics";
import StatsEnrolled from "./StatsEnrolled";
import StatsEligible from "./StatsEligible";
import StatsNotEligible from "./StatsNotEligible";
import StatsForms from "./StatsForms";
import StatsOverview from "./StatsOverview";
import StatsCaseLoads from "./StatsCaseLoads";

import "../../styles/global.scss";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class Stats extends React.Component {

    componentWillUnmount() {
        const {
            store: {
                StatsStore: { setStatsTabKeyForUnmount, setStatsData }
            },
        } = this.props;
        setStatsTabKeyForUnmount(1);
        setStatsData([]);
    };

    setTabKey = (key) => {
        const {
            store: {
                StatsStore: { setStatsTabKey }
            },
        } = this.props;
        setStatsTabKey(Number(key));
    };

    render() {
        return (
            <div>
                <Tabs
                    size="small"
                    tabBarStyle={{ border: 0 }}
                    onChange={this.setTabKey}
                >
                    <TabPane className="my-tabs-ink-bar" tab="Overview" key="6">
                        <StatsOverview />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="Analytics" key="1">
                        <StatsAnalytics />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="Enrolled" key="2">
                        < StatsEnrolled />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="Eligible/Assumed" key="3">
                        <StatsEligible />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="N. Members" key="4">
                        <StatsNotEligible />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="Forms" key="5">
                        <StatsForms />
                    </TabPane>
                    <TabPane className="my-tabs-ink-bar" tab="My Case Loads" key="7">
                        <StatsCaseLoads />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
};

export default Stats;