import { Select, Spin } from "antd";
import { members, userProfile } from "../../en.json";
import { inject, observer } from "mobx-react";

import InfiniteScroll from "react-infinite-scroller";
import React from "react";
import _ from "lodash";

const Option = Select.Option;

@inject("store")
@observer
class ProfileGroups extends React.Component {
  addUserToGroup = async (groupId, userId, type) => {
    const {
      store: {
        GroupStore: { addUserToGroup },
        MemberListStore: { assignMemberToGroup },
        ProfileStore: { assignUserToGroup },
      },
    } = this.props;
    if (type === "user") {
      (await addUserToGroup(groupId, userId, type, false)) &&
        (await assignUserToGroup(groupId)) &&
        (await assignMemberToGroup(userId, groupId));
    } else {
      (await addUserToGroup(groupId, userId, type, true)) &&
        (await assignUserToGroup(groupId)) &&
        (await assignMemberToGroup(userId, groupId));
    }
  };

  status = (statusId) => {
    if (statusId === 1) {
      return "Active";
    } else if (statusId === 2) {
      return "Suspended";
    } else if (statusId === 3) {
      return "Paused";
    }
  };

  setGroupStatus = async (value, groupId) => {
    const {
      store: {
        GroupStore: { changeStatus },
        ProfileStore: { userId, isAssigned, updateStatusInGroup },
        MemberListStore: { removeGroup, assignMemberToGroup },
      },
    } = this.props;
    const assigned = isAssigned(groupId);

    const success = await changeStatus(groupId, userId, value, assigned);
    if (success) {
      if (value === 4) {
        removeGroup(userId, groupId);
      } else {
        if (!assigned) {
          assignMemberToGroup(userId, groupId);
        }
        updateStatusInGroup(groupId, { status: value });
      }
    }
  };

  getMemberCount = (groupId) => {
    const {
      store: {
        GroupStore: { getMemberCount },
      },
    } = this.props;

    const count = getMemberCount(groupId);
    return count !== null ? count : <Spin size="small" />;
  };

  getAssignedGroupMemberCount = (groupId) => {
    const {
      store: {
        GroupStore: { getAssignedGroupMemberCount },
      },
    } = this.props;

    const count = getAssignedGroupMemberCount(groupId);
    return count;
  };

  loadMore = () => {
    const {
      store: {
        GroupStore: { fetchAllGroups, allGroupsLoading },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    if (allGroupsLoading) {
      return;
    }
    const siteId = selectedSiteId;
    if (siteId !== 0 && siteId !== -1) {
      fetchAllGroups(siteId);
    } else {
      fetchAllGroups();
    }
  };

  render() {
    const {
      store: {
        ProfileStore: { assignedGroups, unassignedGroups, userId, type },
        GroupStore: { allGroupsTotal, allGroupsSkip, allGroupsLoading },
      },
    } = this.props;
    return (
      <div
        style={{
          height: "calc(100vh - 150px)",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          loadMore={this.loadMore}
          useWindow={false}
          hasMore={allGroupsSkip < allGroupsTotal}
        >
          {allGroupsLoading ? (
            <div className="add-to-groups-loading">
              <Spin />
            </div>
          ) : null}
          {_.map(assignedGroups, (group) => {
            const groupStatus = group.user_groups
              ? group.user_groups.status
              : null;
            return (
              <React.Fragment key={group.id}>
                <div className="d-flex pt-2">
                  <div className="d-flex w-100">
                    <h5 className="profile-group-name">{group.name}</h5>
                    <span className="ml-auto">
                      <small>
                        {this.getAssignedGroupMemberCount(group.id)}{" "}
                        {members.membersCountText}
                      </small>
                    </span>
                  </div>
                </div>
                <div className="mt-1 d-flex justify-content-between align-items-center">
                  <small>Status</small>
                  <Select
                    value={this.status(groupStatus)}
                    style={{ width: 120 }}
                    onChange={(value) => this.setGroupStatus(value, group.id)}
                  >
                    {groupStatus === 1 ? null : (
                      <Option title="Active" value={1}>
                        <i className="fa fa-check">{userProfile.activate}</i>
                      </Option>
                    )}
                    {groupStatus === 2 ? null : (
                      <Option title="Suspended" value={2}>
                        <i className="fa fa-minus-circle">
                          {userProfile.suspend}
                        </i>
                      </Option>
                    )}
                    {groupStatus === 3 ? null : (
                      <Option title="Paused" value={3}>
                        <i className="fa fa-pause">{userProfile.paused}</i>
                      </Option>
                    )}
                    <Option value={4}>
                      <i className="fa fa-ban">{userProfile.denied}</i>
                    </Option>
                  </Select>
                </div>
              </React.Fragment>
            );
          })}
          {_.map(unassignedGroups, (group) => {
            return (
              <React.Fragment key={group.id}>
                <div className="d-flex pt-2">
                  <div className="d-flex w-100">
                    <h5 className="profile-group-name">{group.name}</h5>
                    <span className="ml-auto">
                      <small>
                        {this.getMemberCount(group.id)}{" "}
                        {members.membersCountText}
                      </small>
                    </span>
                  </div>
                </div>

                <button
                  className="btn btn-default btn-block"
                  disabled={allGroupsLoading}
                  onClick={() => this.addUserToGroup(group.id, userId, type)}
                >
                  {members.addToGroup}
                </button>
              </React.Fragment>
            );
          })}
          {allGroupsLoading && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  }
}
export default ProfileGroups;
