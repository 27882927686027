import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown, Icon, Input, Menu, Spin, message, Modal, Popover, Mentions } from 'antd';

import { chat, color } from '../../en.json';
import LeftSide from './LeftSide';
import MemberListStore from '../../stores/MemberListStore';
import MessageBubble from './MessageBubble';
import ModeratorTag from './ModeratorTag';
import UserSelection from './UserSelection';
import { CustomButton } from '../UI/CustomButton';
import sendIcon from '../../static/images/send-icon.png';
import uploadImage from '../../static/images/uploadImage.png';
import gifImage from '../../static/images/gif.png';
import form from '../../static/images/form.png';
import {
  publish,
  history,
  getPubnubInstanceByUserType,
  createModeratorPubnubInstance,
  createUserPubnubInstance,
  getOnlineUsers,
  addListener,
} from '../../utils/PubnubMethods';
import { setPubnubInstance } from '../../utils/pubnubInstance';
import { ResetPwdModal } from '../UI/ResetPwdModal';
import historyForRoute from '../../utils/history';
import { IS_ISLAND } from '../../utils/getEnvironment';

const { Option } = Mentions;

const infoMessage = message;

@inject("store")
@observer
class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.messageList = React.createRef();
    this.uploadRef = React.createRef();
    this.state = {
      value: "",
      rows: 2,
      minRows: 2,
      maxRows: 16,
      showTagList: false,
      file: {},
      contentData: [],
      isGifModalVisible: false,
      search: '',
      isFetchingGifs: false,
      rowsForTextArea: 1
    };
  }

  async componentDidMount() {
    const {
      store: {
        ProfileStore,
        AuthStore: { type, userId, username, getUserEnv },
        MessagesStore: { setActiveTab },
        FlaggedMessagesStore,
        GifStore
      },
    } = this.props;
    if (userId && type) {
      setPubnubInstance({
        personal: createUserPubnubInstance({ userId }),
        moderator: ["moderator", "SA"].includes(type)
          ? createModeratorPubnubInstance({ userId })
          : null,
      });
      const pubnub = getPubnubInstanceByUserType(type);
      addListener(pubnub, () => this.scrollToBottom());
    }
    setTimeout(() => this.scrollToBottom(), 200);
    MemberListStore.emptyAlert();
    ProfileStore.changeSelectedAction("");
    FlaggedMessagesStore.clear();
    GifStore.fetchGiphyKeyFromLocalStorage();
    setActiveTab("group");

    // refresh creds in secure storage
    if (username) {
      getUserEnv();
    }
  }

  appendData = () => {
    const {
      store: {
        MessagesStore: { selectedGroup, getMessage },
        AuthStore: { unResolveUser, type },
      },
    } = this.props;
    if (_.startsWith(selectedGroup.channel, "WAITING_ROOM")) {
      unResolveUser(selectedGroup.id);
    }
    const message = getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
    }
  };

  handleChange = (e) => {
    const {
      store: {
        MessagesStore,
        GroupStore: { setShowTagList },
      },
    } = this.props;

    const text = e;
    const numberOfLineBreaks = (text.match(/\n/g) || []).length;

    if (text == '' || !numberOfLineBreaks) {
      this.setState({
        ...this.state,
        rowsForTextArea: 1
      })
    }
    if (text.lastIndexOf("@") > text.lastIndexOf(" ")) {
      setShowTagList(true);
    } else {
      setShowTagList(false);
    }
    const message = MessagesStore.getMessage();

    // const textareaLineHeight = 24;
    // const { minRows, maxRows } = this.state;

    // const previousRows = e.target.rows;
    // e.target.rows = minRows; // reset number of rows in textarea

    // const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    // if (currentRows === previousRows) {
    //   e.target.rows = currentRows;
    // }

    // if (currentRows >= maxRows) {
    //   e.target.rows = maxRows;
    //   e.target.scrollTop = e.target.scrollHeight;
    // }

    // this.setState({
    //   value: e.target.value,
    //   rows: currentRows < maxRows ? currentRows : maxRows,
    // });

    message.setText(text);
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: {
          startDm,
          selectedUsers,
          message,
          broadcastInProgress,
        },
      },
    } = this.props;
    const { rowsForTextArea } = this.state;

    if (e.shiftKey && e.key === 'Enter') {
      if (rowsForTextArea === 1) {
        this.setState({
          ...this.state,
          rowsForTextArea: 2
        })
      }
    }

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        if (
          selectedUsers.size &&
          message.trim().length &&
          !broadcastInProgress
        ) {
          startDm();
        } else {
          return;
        }
      } else {
        this.appendData();
      }
      this.setState({
        rows: 2,
        rowsForTextArea: 1
      });
    }
  };

  changeChannel = (id, name, userType, isPaused, isBot, flag, isIntersiteGroup = false) => {
    const {
      store: {
        MessagesStore: { changeChannel },
        GroupStore: { fetchGroupUsers },
        DMStore: { fetchDmUsers },
      },
    } = this.props;

    const type = isBot
      ? "waiting"
      : flag === 1
        ? "group"
        : flag === 2
          ? "other-dms"
          : "dm";
    changeChannel(id, name, userType, isPaused, type, isIntersiteGroup, () => this.scrollToBottom());
    flag && fetchGroupUsers(id);
    if (type === "dm") {
      fetchDmUsers(id);
    }
  };

  scrollToBottom() {
    if (this.messageList.current) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  getDmNames = (users) => {
    const {
      store: {
        AuthStore: { username },
      },
    } = this.props;

    return _.split(users, ",")
      .filter((user) => user !== username)
      .map((name, index) => (
        <React.Fragment key={index}>
          {index !== 0 && ","}
          <span>{name}</span>
          <ModeratorTag username={name} />
        </React.Fragment>
      ));
  };

  imageUploadClick = () => this.uploadRef.click();

  fetchTrendingGifData = async () => {
    const { store: { GifStore: { gifsData }, GifStore } } = this.props;
    try {
      GifStore.setLoading(true);
      const response = await GifStore.getTrendingGifsService();

      const contentGifData = [...this.state.contentData, ...toJS(gifsData)];
      this.setState({
        ...this.state,
        isFetchingGifs: false,
        contentData: contentGifData
      });
      GifStore.setLoading(false);
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  fetchGifsBySearchData = async (searchValue) => {
    const { store: { GifStore: { gifsData }, GifStore } } = this.props;
    try {
      GifStore.setLoading(true);
      const response = await GifStore.getGifsBySearchService(searchValue);

      const contentGifData = [...toJS(gifsData)];
      this.setState({
        ...this.state,
        isFetchingGifs: false,
        contentData: contentGifData
      });
      GifStore.setLoading(false);
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  gifUploadClick = async () => {
    this.setState({
      ...this.state,
      isFetchingGifs: true,
      isGifModalVisible: true
    });
    this.fetchTrendingGifData();
  };

  handleGifSearch = async (event) => {
    const searchValue = event.target.value;
    this.setState({
      ...this.state,
      isFetchingGifs: true,
      search: searchValue,
    })
    if (searchValue) {
      this.fetchGifsBySearchData(searchValue);
    } else {
      this.fetchTrendingGifData();
    }
  };

  getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  convertToFileObjectAndUploadToChat = async (imgUrl) => {
    const {
      store: {
        GifStore,
        MessagesStore: { uploadImageInChat },
      },
    } = this.props;
    const imgExt = this.getUrlExtension(imgUrl);
    try {
      GifStore.setLoading(true);
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      const file = new File([blob], `trending-${Date.now()}.${imgExt}`, {
        type: blob.type,
      });
      await uploadImageInChat(file, 'gif');
      this.setState({
        ...this.state,
        search: ''
      });
      GifStore.setLoading(false);
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  handleGifUpload = (originalUrl) => {
    this.closeModal();
    this.convertToFileObjectAndUploadToChat(originalUrl);
  };

  handleLoadMore = () => {
    const { store: { GifStore: { pagination, loading }, GifStore } } = this.props;
    const searchValue = this.state.search;
    GifStore.setPaginationData({
      ...pagination,
      current: pagination.current + 1
    });
    this.setState({
      ...this.state,
      isFetchingGifs: true
    })
    if (searchValue) {
      this.fetchGifsBySearchData(searchValue);
    } else {
      this.fetchTrendingGifData();
    }
  };

  closeModal = () => {
    const { store: { GifStore } } = this.props;
    GifStore.resetPagination();
    this.setState({
      ...this.state,
      search: '',
      isGifModalVisible: false
    });
  };

  handleConfirmClick = () => {
    this.closeModal();
  };

  handleCancelClick = () => {
    this.closeModal();
  };

  changeColor = async ({ key }) => {
    const {
      store: {
        GroupStore: { updateColor },
        MessagesStore: {
          selectedGroup: { changeColor },
        },
      },
    } = this.props;

    const hide = infoMessage.loading(color.updatingColor, 0);
    const success = await changeColor(key);
    hide();
    if (success) {
      infoMessage.success(color.colorUpdated, 2.5);
      updateColor(key);
    } else {
      infoMessage.error(color.colorNotUpdated, 2.5);
    }
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { loading, reactionsLoaded },
        },
        AuthStore: { type },
      },
    } = this.props;
    if (!loading && !reactionsLoaded) {
      let prevScrollHeight;
      const pubnub = getPubnubInstanceByUserType(type);
      if (this.messageList.current) {
        prevScrollHeight = this.messageList.current.scrollHeight;
      }
      prevScrollHeight
        ? history(pubnub, this.scrollToTop, prevScrollHeight)
        : history(pubnub, null, prevScrollHeight);
    }
  };

  scrollToTop = (prevScrollHeight) => {
    this.messageList.current.scrollTop =
      this.messageList.current.scrollHeight -
      prevScrollHeight +
      this.messageList.current.scrollTop;
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };

  startSession = async () => {
    const {
      store: {
        MessagesStore: {
          setSession,
          selectedGroup: { id },
        },
        AuthStore: { type },
        GroupListStore: {
          sessionLoading,
          setGroupOnlineUsers,
          fetchGroupSession,
        },
      },
    } = this.props;
    if (sessionLoading) {
      return;
    }
    if (!id) {
      return;
    }
    const pubnub = getPubnubInstanceByUserType(type);
    await fetchGroupSession(id);
    const onlineUsers = await getOnlineUsers(pubnub, `GROUP_CHAT_${id}`);
    setGroupOnlineUsers(onlineUsers, id);
    setSession(true);
  };

  uploadImage = async (e) => {
    const {
      store: {
        MessagesStore: { uploadImageInChat },
      },
    } = this.props;
    const fileName = e.target.value.toLowerCase();
    if (
      !(
        fileName.endsWith(".jpg") ||
        fileName.endsWith(".jpeg") ||
        fileName.endsWith(".gif") ||
        fileName.endsWith(".png")
      )
    ) {
      alert("Please upload file types of .jpg, .jpeg, .png and .gif only.");
      return;
    }
    if (e.target.getAttribute("data-field") === "groupImage") {
      const files = e.target.files;
      if (!files.length) {
        return alert("Please choose a file to upload first.");
      } else {
        const file = files[0];
        await uploadImageInChat(file);
        this.uploadRef.value = "";
      }
    }
  };

  sendForm = async ({ key }) => {
    const {
      store: {
        FormMessageStore: { sendFormInGroup },
      },
    } = this.props;
    sendFormInGroup(Number(key));
  };

  onOkResetPwdModal = async () => {
    const {
      store: {
        AuthStore: { closeResetPwdModal },

      },
    } = this.props;
    await closeResetPwdModal();
    historyForRoute.push("forgot-password");
  }

  render() {
    const {
      store: {
        BroadcastStore: {
          selectedUsers,
          message,
          setMessage,
          startDm,
          broadcastInProgress,
        },
        ProfileStore: { selectedAction },
        AuthStore: { type, showResetPwdPopup, closeResetPwdModal },
        GroupStore: { selectedGroupUsers },
        FormMessageStore: { formList },
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            name,
            channel,
            groupLastSeen,
            isPaused,
          },
          isBroadcasting,
          text,
          sessionStarted,
          activeTab,
          isWaiting,
          imageUploading,
          gifUploading
        },
      },
    } = this.props;
    const { rowsForTextArea } = this.state;
    const { TextArea } = Input;

    const colorOptions = [
      "#2F3BED",
      "#2F80ED",
      "#4CA2CD",
      "#41C479",
      "#F7971E",
      "#E47B3B",
      "#DD2476",
      "#453093",
      "#29323C",
    ];

    const menu = (
      <Menu onClick={this.changeColor}>
        {colorOptions.map((color) => (
          <Menu.Item key={color}>
            <div className="color-box" style={{ background: color }} />
          </Menu.Item>
        ))}
      </Menu>
    );

    const renderGifData = () => {
      return (
        <div className="gif-data-wrapper">
          <input className="gif-search-bar" placeholder="Search GIF..." value={this.state.search} onChange={this.handleGifSearch} />
          {this.state.contentData.length ?
            (<>
              <div className="gif-img-wrapper">
                {this.state.contentData.map((obj, index) => (
                  <div className="gif-img-inner" key={index}>
                    <em>
                      <img src={obj.images.preview_gif.url} value={obj.images.original.url} onClick={() => this.handleGifUpload(obj.images.original.url)} />
                    </em>
                  </div>
                ))}
              </div>
              {this.state.isFetchingGifs ? <Spin /> : <button className="load-more-btn" onClick={this.handleLoadMore}>Load More...</button>}
            </>) : null}
        </div>
      )
    };

    const formsList = (
      <div className="form-dropdown">
        <Menu onClick={this.sendForm} className="menu">
          {formList.map((form) => {
            return (
              <Menu.Item key={form.id}>
                <div>{form.name}</div>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );

    let prevDate = "";
    const userType = type;
    const isDisabled =
      broadcastInProgress || !selectedUsers.size || !message.trim().length;
    return (
      <div className="display-flex">
        <div className={name ? "left-sidebar hide-content" : "left-sidebar "}>
          <LeftSide changeChannel={this.changeChannel} />
        </div>
        {isBroadcasting && (
          <div className="left-sidebar2">
            <UserSelection />
          </div>
        )}
        {name &&
          !isBroadcasting &&
          selectedAction !== "Dm creation" &&
          !isWaiting ? (
          <div className="right-section">
            <div className="header-section p-1 over-sized">
              <h1>
                <span>
                  {_.startsWith(channel, "DIRECT_MESSAGE")
                    ? this.getDmNames(name)
                    : name}
                </span>
              </h1>
              {_.startsWith(channel, "GROUP_CHAT") &&
                (userType === "moderator" ||
                  userType === "NOA" ||
                  userType === "SA" ||
                  userType == "semi-moderator") ? (
                <CustomButton
                  className="start-session my-btn"
                  caption={chat.startASession}
                  disabled={sessionStarted}
                  onClick={() => this.startSession()}
                />
              ) : null}
            </div>
            <div>
              <div className="p-1" style={{ flex: "1", position: "relative" }}>
                <div>
                  {isPaused ? null : (
                    <div className="input-container">
                      <Mentions
                        style={{ width: '100%' }}
                        rows={rowsForTextArea}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={text}
                        placement="top"
                        placeholder="Type here to send a message..."
                      >
                        {selectedGroupUsers.length > 0 && selectedGroupUsers.map((user, index) => {
                          return (
                            <Option value={user} key={index}>{user}</Option>
                          )
                        })}
                      </Mentions>
                      {activeTab === "dm" ? null : (
                        <Dropdown
                          overlay={menu}
                          className="color-dropdown"
                          placement="topRight"
                        >
                          <div className="ant-dropdown-link ">
                            <Icon type="bg-colors" />
                          </div>
                        </Dropdown>
                      )}
                      {userType === "moderator" ||
                        userType === "NOA" ||
                        userType === "SA" ? (
                        <div>
                          <Dropdown
                            overlay={formsList}
                            trigger={["click"]}
                            placement="topRight"
                          >
                            <img src={form} className="send-form" alt="Form" />
                          </Dropdown>
                        </div>
                      ) : null}
                      {userType === "moderator" ||
                        userType === "SA" ||
                        userType === "NOA" ||
                        userType === "semi-moderator" ? (
                        <div>
                          <Dropdown
                            overlay={formsList}
                            trigger={["click"]}
                            placement="topRight"
                          >
                            <img src={form} className="send-form" alt="Form" />
                          </Dropdown>
                          {imageUploading ? (
                            <div className="send-img-img">
                              <Spin />
                            </div>
                          ) : (
                            <img
                              src={uploadImage}
                              className="send-img-img"
                              alt="Send"
                              onClick={this.imageUploadClick}
                            />
                          )}
                          {IS_ISLAND ? (
                            <>
                              {gifUploading ? (
                                <div className="send-gif-img">
                                  <Spin />
                                </div>
                              ) : (
                                <img
                                  src={gifImage}
                                  className="send-gif-img"
                                  alt="Send GIF"
                                  onClick={this.gifUploadClick}
                                />
                              )}
                            </>
                          ) : null}
                          <Modal title="Select GIFs"
                            centered
                            visible={this.state.isGifModalVisible}
                            onOk={this.handleConfirmClick}
                            onCancel={this.handleCancelClick}
                          >
                            {renderGifData()}
                          </Modal>
                        </div>
                      ) : null}
                      {userType === "moderator" ||
                        userType === "SA" ||
                        userType === "NOA" ||
                        userType === "semi-moderator" ? (
                        <input
                          type="file"
                          ref={(ref) => (this.uploadRef = ref)}
                          onChange={this.uploadImage}
                          accept="image/*"
                          data-field="groupImage"
                          className="chat-image-upload"
                        />
                      ) : null}
                      <img
                        src={sendIcon}
                        onClick={this.appendData}
                        value="Append"
                        className="send-text-img ml-auto"
                        style={
                          text.trim() !== "" ? { opacity: 1 } : { opacity: 0.4 }
                        }
                        alt="Send"
                      />
                    </div>
                  )}

                  {loading && (
                    <div className="form-loading">
                      <Spin />
                    </div>
                  )}
                  <div id="display-data-Container" ref={this.messageList}>
                    <InfiniteScroll
                      loadMore={this.loadMore}
                      hasMore={hasMore}
                      isReverse
                      useWindow={false}
                    >
                      {_.uniqBy(messages, "timetoken").map((message, index) => {
                        const currDate = moment
                          .unix(parseInt(message.timetoken) / 10000000)
                          .format("LL");
                        const messageBox = (
                          <React.Fragment key={index}>
                            {currDate !== prevDate &&
                              this.checkMessageType(message) && (
                                <div className="message-date">
                                  <span>{currDate}</span>
                                </div>
                              )}
                            {message.isDeleted &&
                              (userType === "user" ||
                                userType == "semi-moderator") ? null : (
                              <MessageBubble
                                message={message}
                                groupChat={_.startsWith(channel, "GROUP_CHAT")}
                                groupId={
                                  _.startsWith(channel, "GROUP_CHAT")
                                    ? id
                                    : null
                                }
                                groupLastSeen={groupLastSeen}
                              />
                            )}
                          </React.Fragment>
                        );

                        prevDate = currDate;
                        return messageBox;
                      })}
                      {!messages.length ? (
                        <div className="no-messages">{chat.noMessages}</div>
                      ) : null}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : isBroadcasting ? (
          <div className="boardcast-chat-section">
            <h3 className="text-center">{chat.message}</h3>
            <div className="broadcast-container">
              <TextArea
                value={message}
                onChange={setMessage}
                onKeyDown={(e) => this.handleKeyPress(e, true)}
                placeholder="Your message, here."
              />
            </div>
            <Button
              className={
                isDisabled
                  ? "custom-btn-disabled float-right"
                  : "custom-btn float-right"
              }
              onClick={startDm}
              disabled={isDisabled}
            >
              {chat.send}
            </Button>
          </div>
        ) : (
          <>
            <div className="select-group-message">
              <h3>{chat.selectGroup}</h3>
            </div>
            {showResetPwdPopup &&
              <div className="left-sidebar2">
                <ResetPwdModal
                  onOk={this.onOkResetPwdModal}
                  visible={showResetPwdPopup}
                  onClose={closeResetPwdModal}
                />
              </div>
            }
          </>
        )}

      </div>
    );
  }
}
export default Chat;
