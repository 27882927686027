import React from "react";
import { inject, observer } from "mobx-react";
import { Switch } from "antd";
import { CustomButton } from "../UI/CustomButton";
import IceBreakerScreen from "./IceBreakerScreen";

@inject("store")
@observer
class IceBreaker extends React.Component {
  resetIceBreakerForAll = () => {
    const {
      store: {
        GroupListStore: { resetIceBreakerForMembers, selectedGroup },
      },
    } = this.props;
    resetIceBreakerForMembers(selectedGroup.id);
  };

  setFormInput = (e, screen) => {
    const {
      store: {
        GroupListStore: { setIceBreakerScreensInfo },
      },
    } = this.props;

    const fieldToSet = e.target.getAttribute("data-field");
    setIceBreakerScreensInfo(fieldToSet, screen, e.target.value);
  };

  updateIceBreakerStatus = (value) => {
    const {
      store: {
        GroupListStore: { setIceBreakerStatus },
      },
    } = this.props;
    setIceBreakerStatus(value);
  };

  updateNewMemberBannerStatus = (value) => {
    const {
      store: {
        GroupListStore: { setNewMemberBanner },
      },
    } = this.props;
    setNewMemberBanner(value);
  };

  updateGroupIceBreaker = () => {
    const {
      store: {
        GroupListStore: { updateGroupIceBreaker, selectedGroup },
      },
    } = this.props;
    updateGroupIceBreaker(selectedGroup.id);
  };

  render() {
    const {
      store: {
        GroupListStore: {
          hasIceBreaker,
          checklist,
          newMemberBanner,
          updatingIceBreaker,
        },
      },
    } = this.props;

    return (
      <div className="iceBreaker-container">
        <IceBreakerScreen
          checklist={checklist}
          screen={1}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={2}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={3}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={4}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={5}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <div className="label-container">
          <span className="iceBreaker-label">IceBreaker On/Off</span>
          <Switch
            checked={hasIceBreaker}
            className="switch"
            onChange={this.updateIceBreakerStatus}
          />
        </div>
        <div className="label-container">
          <span className="iceBreaker-label">Display new member banner</span>
          <Switch
            checked={newMemberBanner}
            className="switch"
            onChange={this.updateNewMemberBannerStatus}
          />
        </div>
        <div className="button-container">
          <CustomButton
            type="primary"
            disabled={updatingIceBreaker}
            onClick={this.updateGroupIceBreaker}
            caption="Save"
            size="large"
          />
          <CustomButton
            type="primary"
            onClick={this.resetIceBreakerForAll}
            caption="Reset IceBreaker for all Members In Group"
            size="large"
          />
        </div>
      </div>
    );
  }
}

export default IceBreaker;
