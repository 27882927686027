import moment from "moment";
import _ from "lodash";
import PubNub from "pubnub";
import { types } from "mobx-state-tree";

import DM from "./DM";
import Group from "./Group";
import { user } from "../en.json";
import BroadcastStore from "../stores/BroadcastStore";
import EventStore from "../stores/EventStore";
import FlagNeedsStore from "../stores/FlagNeedsStore";
import GroupStore from "../stores/Groups";
import GroupListStore from "../stores/GroupListStore";
import MessagesStore from "../stores/MessagesStore";
import MemberListStore from "../stores/MemberListStore";
import NotificationStore from "../stores/NotificationStore";
import ProfileStore from "../stores/ProfileStore";
import SignupStore from "../stores/SignupStore";
import SiteStore from "../stores/SiteStore";
import ApiService from "../utils/ApiService";
import history from "../utils/history";
import { getBatchedArray } from "../utils/getBatchArray";
import { COMM, ENVIRONMENT, IS_ISLAND, ISLAND, NIDA } from "../utils/getEnvironment";
import { extractSecretKey } from "../utils/extractKeys";
import {
  createModeratorPubnubInstance,
  createUserPubnubInstance,
  subscribeModeratorChannels,
  getPubnubInstanceByUserType,
  subscribeUserChannels,
  setStateInChannel,
  subscribeSingleChannel,
  subscribeChannels,
  unsubscribeAll,
  setUUID,
  subscribeDMs,
  addListener,
} from "../utils/PubnubMethods";
import { setPubnubInstance } from "../utils/pubnubInstance";
import SecureLocalStorage from "../utils/SecureLS";
import { ALIKE_USER } from "../constants/UserRolesConstant";

var mixpanel = require("mixpanel-browser");
const pubnubInstance = new PubNub({
  publishKey: "",
  subscribeKey: "",
  authKey: "",
  useRandomIVs: IS_ISLAND ? true : false
});

const Auth = types
  .model("Auth", {
    usernameInput: "",
    passwordInput: "",
    emailInput: "",
    newPasswordInput: "",
    confirmPasswordInput: "",
    token: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    siteId: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    loading: false,
    allDmsLoading: false,
    isResolved: types.maybeNull(types.boolean),
    groups: types.array(Group),
    dms: types.array(DM),
    otherDms: types.array(DM),
    fullName: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    showNoChromeModal: false,
    showResetPwdPopup: false,
  })
  .actions((self) => ({
    reset() {
      self.token = null;
      self.username = null;
      self.siteId = null;
      self.email = null;
      self.userId = null;
      self.loading = false;
      self.isResolved = null;
      self.groups = [];
      self.dms = [];
      self.otherDms = [];
      self.type = null;
      self.fullName = "";
      self.phoneNumber = "";
      self.usernameInput = "";
      self.passwordInput = "";
      self.emailInput = "";
      self.newPasswordInput = "";
      self.confirmPasswordInput = "";
      self.showResetPwdPopup = false;
    },
    setShowChromeModal(value) {
      self.showNoChromeModal = value;
    },
    async login() {
      const params = {
        username: self.usernameInput,
        password: self.passwordInput,
        strategy: "local",
      };

      self.setLoading(true);
      const response = await ApiService.postRequest("authentication", params);
      if (response.success) {
        if (response.data.hasOwnProperty("keys")) {
          const { key, originalData } = extractSecretKey(response.data.keys);
          const keys = pubnubInstance.decrypt(originalData, key);
          SecureLocalStorage.setMultipleKeys(keys);
        }
        await self.setUserDetails(response.data);

        mixpanel.people.set({
          $email: self.email,
          $username: self.username,
        });
        mixpanel.identify(String(self.userId + "-" + ENVIRONMENT));
        mixpanel.track("Login Success", { from: "WEB" });
        if (self.type === "moderator" || self.type === "SA") {
          history.push("/members");
        } else if (self.type === "FD") {
          history.push("/alert");
        } else {
          history.push("/chat");
        }
      } else {
        NotificationStore.setNotification("error", user.invalidLogin);
      }
      self.setLoading(false);
    },
    async getUserEnv() {
      try {
        const params = {
          username: self.username,
        };
        const response = await ApiService.getRequest("get-user-env", params);
        if (response.success) {
          if (response.data.hasOwnProperty("keys")) {
            const { key, originalData } = extractSecretKey(response.data.keys);
            const keys = pubnubInstance.decrypt(originalData, key);
            SecureLocalStorage.setMultipleKeys(keys);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    addGroupToUserGroups(group) {
      if (self.shouldIncludeGroup(group)) {
        self.groups.push(group);
      }
    },
    addDmToUserDms(dm) {
      self.dms.push(dm);
    },
    removeGroupFromUserGroups(groupId) {
      self.groups = self.groups.filter((userGroup) => userGroup.id !== groupId);
      MessagesStore.pendUserAction(groupId);
    },
    removeDMFromUserDMs(dmIds) {
      self.dms = self.dms.filter((userDM) => {
        return !dmIds.includes(userDM.id.toString());
      });
      self.otherDms = self.otherDms.filter((userDM) => {
        return !dmIds.includes(userDM.id.toString());
      });
    },
    editGroupDetails(data) {
      const groupIndexToEdit = _.findIndex(self.groups, {
        id: data.id,
      });
      self.groups[groupIndexToEdit] = {
        ...self.groups[groupIndexToEdit],
        ...data,
      };
    },
    updateUserStatus(groupId, status) {
      const userGroups = _.cloneDeep(self.groups);

      let targetIndex = -1;
      userGroups.forEach((group, index) => {
        if (group.id == groupId) {
          targetIndex = index;
        }
      });
      if (targetIndex >= 0) {
        self.groups[targetIndex].user_groups.status = status;
        const isPaused = status === 3 ? true : false;
        MessagesStore.setSelectedGroupPause(isPaused);
        MessagesStore.setSelectedGroupStatus(status);
      }
    },
    // updateUserPaused(groupId, isPaused) {
    //   const userGroups = _.cloneDeep(self.groups);

    //   let targetIndex = -1;
    //   userGroups.forEach((group, index) => {
    //     if (group.id == groupId) {
    //       targetIndex = index;
    //     }
    //   });
    //   if (targetIndex >= 0) {
    //     self.groups[targetIndex].user_groups.isPaused = isPaused;
    //     MessagesStore.setSelectedGroupPause(isPaused);
    //   }
    // },
    updateAllPaused(groupId, statusId) {
      const userGroups = _.cloneDeep(self.groups);

      let targetIndex = -1;
      userGroups.forEach((group, index) => {
        if (group.id == groupId) {
          targetIndex = index;
        }
      });
      if (targetIndex >= 0) {
        if (_.includes(["user", "semi-moderator"], self.type)) {
          self.groups[targetIndex].user_groups.status = statusId;
          MessagesStore.setSelectedGroupStatus(statusId);
        }
      }
      if (self.type === "moderator" || self.type === "SA") {
        GroupListStore.updatePauseInSession(groupId, statusId);
      }
    },

    logout() {
      self.reset();
      mixpanel.reset();
      ProfileStore.reset();
      SignupStore.resetAll();
      MemberListStore.resetAll();
      MemberListStore.reset();
      SignupStore.resetAll();
      GroupStore.resetAll();
      SiteStore.resetAll();
      FlagNeedsStore.resetAll();
      BroadcastStore.resetSelections();
      unsubscribeAll();
      MessagesStore.resetSelectedGroup();
      mixpanel.track("Logout", { from: "WEB" });
      history.push("/login");
      MessagesStore.resetAll();
      MessagesStore.clearInterval();
      EventStore.resetAllEvents();
      try {
        SecureLocalStorage.removeAll();
      } catch {
        localStorage.clear();
      }
    },
    setUsernameInput(e) {
      self.usernameInput = e.target.value.trim();
    },
    setPasswordInput(e) {
      self.passwordInput = e.target.value;
    },
    setEmailInput(e) {
      self.emailInput = e.target.value.trim();
    },
    setNewPasswordInput(e) {
      self.newPasswordInput = e.target.value;
    },
    setConfirmPasswordInput(e) {
      self.confirmPasswordInput = e.target.value;
    },
    resetFormInputs() {
      self.usernameInput = "";
      self.emailInput = "";
      self.newPasswordInput = "";
      self.confirmPasswordInput = "";
    },
    setLoading(value) {
      self.loading = value;
    },
    setToken(token) {
      self.token = token;
    },
    setUsername(username) {
      self.username = username;
    },
    setUserId(userId) {
      self.userId = parseInt(userId, 10);
    },
    setUserType(type) {
      self.type = type;
    },
    setEmailId(email) {
      self.email = email;
    },
    async unResolveUser(userId) {
      const response = await ApiService.patchRequest("users", userId, {
        isResolved: false,
      });
      if (response.success) {
        self.setUserResolved(response.data.isResolved);
      }
    },
    async setUserDetails(apiResponse) {
      self.username = self.usernameInput;
      self.setToken(apiResponse.accessToken);
      SecureLocalStorage.set("TOKEN", apiResponse.accessToken);
      SecureLocalStorage.set("USERNAME", self.username);
      const params = {
        username: self.username,
      };
      const response = await ApiService.getRequest("users", params);
      try {
        const { id, type, siteId } = response.data[0];
        SecureLocalStorage.set("USERID", id);
        SecureLocalStorage.set("USER_TYPE", type);
        if (siteId) {
          SiteStore.setSelectedSite(siteId);
          SecureLocalStorage.set("SITE_ID", siteId);
        } else {
          SiteStore.setSelectedSite(0);
          SecureLocalStorage.set("SITE_ID", 0);
        }
        self.setUserData(response.data[0]);
        self.showResetPwdModal(response.data[0])
      } catch (error) {
        return;
      }
      return response.success;
    },
    setUserData(data) {
      self.type = data.type;
      self.siteId = data.siteId;
      self.userId = data.id;
      self.email = data.email;
      self.username = data.username;
      self.phoneNumber = data.phoneNumber;
      self.fullName = data.fullName;
      self.isResolved = data.isResolved;
    },
    showResetPwdModal(data) {
      if (data.lastPasswordUpdatedAt && moment(Date.now()).diff(moment(data.lastPasswordUpdatedAt), 'days') >= 90) {
        self.showResetPwdPopup = true;
      }
    },
    closeResetPwdModal() {
      self.showResetPwdPopup = false;
    },
    setSiteId(siteId) {
      self.siteId = siteId;
    },
    updateProfileData(data) {
      if (data.email) {
        self.email = data.email;
      }
      if (data.username) {
        self.username = data.username;
      }
      if (data.type) {
        self.type = data.type;
      }
      if (data.siteId) {
        self.siteId = data.siteId;
      }
      if (data.usResolved) {
        self.isResolved = data.isResolved;
      }
    },
    setType(value) {
      self.type = value;
    },
    loginCreatedUser(user) {
      SecureLocalStorage.set("TOKEN", user.accessToken);
      SecureLocalStorage.set("USERNAME", user.username);
      SecureLocalStorage.set("USERID", user.id);
      SecureLocalStorage.set("USER_TYPE", user.type);
      self.setToken(user.accessToken);
      self.setUsername(user.username);
      self.setUserId(user.id);
    },

    async getDMQuery() {
      const query = self.dms.reduce((obj, dm, index) => {
        const key = `$and[${index}][id][$ne]`;

        if (!obj.hasOwnProperty(key)) {
          obj[key] = dm.id;
        }
        return obj;
      }, {});

      return query;
    },

    async fetchAllDmsForSuperAdmins() {
      // const query = await this.getDMQuery();

      let skip = 0;
      let total = 1;
      self.setAllDmsLoading(true);
      // We will clear dms in state before calling the api
      self.setOtherDmsData([]);
      const searchVal = MessagesStore.searchedOthersDmQuery;
      const trimmedSearchVal = searchVal.trim();
      // If search value exists then we are calling the api to fetch dm channels
      if (trimmedSearchVal) {
        while (skip < total) {
          const params = {
            $skip: skip,
            $limit: 50,
            "$sort[createdAt]": -1,
            include: true,
            search: trimmedSearchVal
          };
          // We will call the api to fetch the dm channels according to the search value
          const dmResponse = await ApiService.getRequest("dm-channels", params);
          const { success, data, meta } = dmResponse;
          if (success && data.length) {
            skip = skip + 50;
            total = meta.total;
            // We need to create model of that DM in mobx state 
            const otherDmsData = data.map((dm) => {
              if (!self.dms.includes((selfDM) => selfDM.id === dm.id)) {
                return DM.create(dm);
              }
            });
            // Here We are combining the previous DMs and current DMs data
            const totalOtherDmsData = [...self.otherDms, ...otherDmsData];
            // Here we will set the total DMs data to the mobx state
            self.setOtherDmsData(totalOtherDmsData);
            // self.setSuperAdminDms(dmResponse.data);
          } else {
            self.setAllDmsLoading(false);
            // NotificationStore.setNotification("error", user.unableToFetch);
            return;
          }
        }
      }
      self.setAllDmsLoading(false);
    },
    // Here this function is used to set the other dms data to the mobx state data
    setOtherDmsData(arr = []) {
      self.otherDms = arr;
    },
    setAllDmsLoading(value) {
      self.allDmsLoading = value;
    },
    async fetchGroupsAndDms() {
      self.setLoading(true);
      const response = await ApiService.getRequest("users", {
        id: self.userId,
        include: true,
        includeOnly: "groups,user-consent,dm-channels",
        isSiteDataRequiredForGroups: true,
      });

      if (response.success && response.data[0]) {
        const userType = response.data[0].type;
        const userId = response.data[0].id;
        const siteId = response.data[0].siteId;
        if (siteId) {
          SecureLocalStorage.set("SITE_ID", siteId);
          SiteStore.setSelectedSite(siteId);
          self.setSiteId(siteId);
        } else {
          SiteStore.setSelectedSite(0);
          SecureLocalStorage.set("SITE_ID", 0);
        }
        self.setUserData(response.data[0]);
        setPubnubInstance({
          personal: createUserPubnubInstance({ userId }),
          moderator: ["moderator", "SA"].includes(userType)
            ? createModeratorPubnubInstance({ userId })
            : null,
        });
        SecureLocalStorage.set("USERID", userId);
        SecureLocalStorage.set("USER_TYPE", userType);

        self.setUserGroup(response.data[0].groups);
        self.setUserDms(response.data[0].channels);
        if (
          userType === "moderator" ||
          userType === "SA" ||
          userType === "NOA"
        ) {
          subscribeModeratorChannels();
        } else {
          subscribeUserChannels({ userId: userId });
        }

        // if (self.type === "SA") {
        //   this.fetchAllDmsForSuperAdmins();
        // }
        const pubnub = getPubnubInstanceByUserType(userType);
        addListener(pubnub);
        setUUID(pubnub, userId);
        subscribeChannels(pubnub, MessagesStore.subscribedChannels);
        self.setLoading(false);
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", user.unableToFetch);
        return;
      }
    },
    setFullName(name) {
      self.fullName = name;
    },
    setPhone(phoneNumber) {
      self.phoneNumber = phoneNumber;
    },
    changeUserOnlineStatus() {
      const pubnub = getPubnubInstanceByUserType(self.type);
      const batchedSubscribedChannels = getBatchedArray(
        MessagesStore.subscribedChannels,
        200
      );
      batchedSubscribedChannels.forEach((batch) => {
        setStateInChannel(pubnub, batch, {
          isOnline: false,
        });
      });
    },
    setUserResolved(isResolved) {
      self.isResolved = isResolved;
    },
    setUserGroup(groups) {
      const userGroups = [];

      groups.forEach((group) => {
        if (self.shouldIncludeGroup(group)) {
          // self.subscribeSemiModChannel(group.user_groups);
          MessagesStore.addSubscribedChannel(`GROUP_CHAT_${group.id}`);
          userGroups.push(Group.create(group));
        }
      });

      self.groups = userGroups;
    },
    shouldIncludeGroup(group) {
      if (![NIDA, COMM, ISLAND].includes(ENVIRONMENT)) {
        return true;
      }

      const isUser = ALIKE_USER.includes(self.type);
      const isResearchSite = [1, 3].includes(self.siteId);
      let includeGroup = true;

      if (isUser && isResearchSite) {
        includeGroup = group.sites.some(site => site.id === self.siteId);
      }

      return includeGroup;
    },
    subscribeSemiModChannel(userGroups) {
      if (userGroups && userGroups.type === "semi-moderator") {
        subscribeSingleChannel(`FLAG_MODERATOR_${userGroups.groupId}`);
      }
    },
    setSuperAdminDms(dmList) {
      let channels = [];
      const userType = self.type;
      const pubnub = getPubnubInstanceByUserType(userType);
      const dms = dmList.filter((dm) => {
        if (!self.dms.includes((selfDM) => selfDM.id === dm.id)) {
          const channel = `DIRECT_MESSAGE_${dm.id}`;
          channels.push(channel);
          return DM.create(dm);
        }
      });
      subscribeDMs(pubnub, channels);
      self.otherDms = [...self.otherDms, ...dms];
    },
    setUserDms(dmList) {
      let channels = [];
      const userType = self.type;
      const pubnub = getPubnubInstanceByUserType(userType);
      if (userType === "user") {
        const channelBot = {
          id: self.userId,
          name: "Marigold Support",
          createdAt: "2019-08-27T14:16:42.785Z",
          updatedAt: "2019-08-27T14:16:42.785Z",
          dm_channel_users: null,
          isBot: true,
        };
        dmList = [channelBot, ...dmList];
      }
      const dms = dmList.map((dm) => {
        const channel = `DIRECT_MESSAGE_${dm.id}`;
        channels.push(channel);
        return DM.create(dm);
      });
      subscribeDMs(pubnub, channels);
      self.dms = [...self.dms, ...dms];
    },
    addUserDm(dm) {
      const pubnub = getPubnubInstanceByUserType(self.type);
      subscribeSingleChannel(pubnub, `DIRECT_MESSAGE_${dm.id}`);
      const dmData = DM.create(dm);
      self.dms = [...self.dms, dmData];
    },
    sortGroups() {
      self.groups = self.groups
        .slice()
        .sort((item1, item2) =>
          self.sortListItems("GROUP_CHAT_" + item1.id, "GROUP_CHAT_" + item2.id)
        );
    },
    sortDms() {
      self.dms = self.dms
        .slice()
        .sort((item1, item2) =>
          self.sortListItems(
            "DIRECT_MESSAGE_" + item1.id,
            "DIRECT_MESSAGE_" + item2.id
          )
        );
    },
    sortListItems(channel1, channel2) {
      let index1 = _.findIndex(MessagesStore.latestMessages, {
        channel: channel1,
      });
      let index2 = _.findIndex(MessagesStore.latestMessages, {
        channel: channel2,
      });

      index1 = index1 === -1 ? Number.MAX_SAFE_INTEGER : index1;
      index2 = index2 === -1 ? Number.MAX_SAFE_INTEGER : index2;
      return index1 - index2;
    },
    async sendPasswordResetLink() {
      const response = await ApiService.postRequest("forgot-password", {
        email: self.emailInput,
      });
      if (response.success) {
        NotificationStore.setNotification("success", user.emailSent);
        history.push("/login");
      } else {
        NotificationStore.setNotification("error", response.data.message);
      }
    },
    async changePassword(token) {
      if (self.isPasswordValid) {
        const response = await ApiService.patchRequest(
          "forgot-password",
          null,
          {
            password: self.newPasswordInput,
            token,
          }
        );
        if (response.success) {
          NotificationStore.setNotification("success", user.loginSuccess);
          history.push("/login");
        } else {
          NotificationStore.setNotification("error", response?.data?.message || user.linkExpired);
        }
      }
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.usernameInput !== "" && self.passwordInput !== "";
    },
    get isEmailValid() {
      let regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return regEx.test(self.emailInput.toLowerCase());
    },
    get isPasswordFilled() {
      return (
        self.newPasswordInput.length > 0 && self.confirmPasswordInput.length > 0
      );
    },
    isUserSuspended(groupId) {
      const group = _.find(self.groups, { id: groupId });
      if (group && group.user_groups && group.user_groups.status === 2) {
        return true;
      } else {
        return false;
      }
    },
    isAlreadyAssigned(groupId) {
      const group = _.find(self.groups, { id: groupId });
      return group ? true : false;
    },
    get isPasswordValid() {
      const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

      if (self.newPasswordInput !== self.confirmPasswordInput) {
        NotificationStore.setNotification("error", user.passwordMismatch);
        return false;
      }

      if (!strongRegex.test(self.newPasswordInput)) {
        NotificationStore.setNotification("error", user.passwordError);
        return false;
      }
      return true;
    },
  }));

export default Auth;
