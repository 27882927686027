import React from "react";
import { inject, observer } from "mobx-react";
import { CustomButton } from "../UI/CustomButton";

@inject("store")
@observer
class IceBreakerScreen extends React.Component {
  render() {
    const { screen, setFormInput, checklist } = this.props;

    const index = screen - 1;

    const hasValue =
      checklist.length && checklist.length >= index ? checklist[index] : null;

    const title = hasValue && hasValue.title ? hasValue.title : "";
    const description =
      hasValue && hasValue.description ? hasValue.description : "";
    const buttonName =
      hasValue && hasValue.buttonName ? hasValue.buttonName : "";
    const imgUrl = hasValue && hasValue.imgUrl ? hasValue.imgUrl : "";

    return (
      <div>
        <div className="iceBreaker-screen-label">Screen {screen}</div>
        <div className="label-container">
          <div className="iceBreaker-label">Title :</div>
          <textarea
            className="iceBreaker-input"
            data-field="title"
            value={title}
            placeholder="Title"
            onChange={(e) => setFormInput(e, screen)}
          />
        </div>
        <div className="label-container">
          <div className="iceBreaker-label">Description :</div>
          <textarea
            className="iceBreaker-input"
            data-field="description"
            value={description}
            placeholder="Description"
            onChange={(e) => setFormInput(e, screen)}
          />
        </div>
        <div className="label-container">
          <div className="iceBreaker-label">Image Url :</div>
          <textarea
            className="iceBreaker-input"
            data-field="imgUrl"
            value={imgUrl}
            placeholder="Image Url"
            onChange={(e) => setFormInput(e, screen)}
          />
        </div>
        <div className="label-container">
          <div className="iceBreaker-label">Button Text :</div>
          <textarea
            className="iceBreaker-input"
            data-field="buttonName"
            value={buttonName}
            placeholder="Button Text"
            onChange={(e) => setFormInput(e, screen)}
          />
        </div>
      </div>
    );
  }
}

export default IceBreakerScreen;
