import ApiService from "../utils/ApiService";
import Group from "./Group";
import NotificationStore from "../stores/NotificationStore";
import _ from "lodash";
import { ImageUpload } from "../utils/uploadFile";
import { groups } from "../en.json";
import { toJS } from "mobx";
import { types } from "mobx-state-tree";
import SiteStore from "../stores/SiteStore";
import GroupStore from "../stores/Groups";
var mixpanel = require("mixpanel-browser");

const GroupList = types
  .model("GroupList", {
    groups: types.array(Group),
    loading: false,
    pagination: types.frozen({
      pageSize: 10,
      total: 0,
      current: 1,
    }),
    sortColumn: types.maybe(types.string),
    sortOrder: types.maybe(types.string),
    selectedGroup: types.maybeNull(Group),
    showGroupDetails: false,
    groupEditingLoading: false,
    showGroupFlags: false,
    groupName: types.maybe(types.string),
    groupDesc: types.maybe(types.string),
    groupCode: types.maybe(types.string),
    groupType: types.maybe(types.string),
    category: types.maybe(types.string),
    groupSites: types.array(types.number),
    newGroupSites: types.array(types.number),
    groupImage: "",
    sessionLoading: false,
    alertLoading: false,
    showIceBreaker: false,
    groupEdit: false,
    allPauseLoading: false,
    iceBreakerLoading: false,
    updatingIceBreaker: false,
    description: "",
    hasIceBreaker: false,
    newMemberBanner: false,
    onlineUsers: types.array(types.frozen({})),
    offlineUsers: types.array(types.frozen({})),
    groupSiteIds: types.array(types.frozen({})),
    newGroup: false,
    creatingGroup: false,
    siteGroupsLoading: false,
    showGroupSitesModal: false,
    checklist: types.array(types.frozen({})),
  })
  .actions((self) => ({
    setNewGroup(value) {
      self.newGroup = value;
    },
    async getGroupSites(groupId) {
      self.setSiteGroupsLoading(true);
      const params = {
        groupId,
      };
      const response = await ApiService.getRequest("site-groups", params);
      const siteArray = [];
      const siteIds = [];
      if (response.success) {
        response.data.forEach((group) => {
          if (group.siteId !== -1) {
            siteArray.push(group.siteId);
            siteIds.push(group);
          }
        });
        self.setGroupSites(siteArray);
        self.setGroupSitesObject(siteIds);
        self.setSiteGroupsLoading(false);
      } else {
        self.setSiteGroupsLoading(false);
      }
    },
    setSiteGroupsLoading(value) {
      self.siteGroupsLoading = value;
    },
    setShowGroupSitesModal(value) {
      self.showGroupSitesModal = value;
    },
    setGroupSitesObject(arr) {
      self.groupSiteIds = arr;
    },
    setMultipleSites(id) {
      self.groupSites = [...id];
    },
    updateMultipleSites(id) {
      self.newGroupSites = [...id];
    },
    setGroupSites(siteArray) {
      self.groupSites = siteArray;
    },
    setCreatingGroup(value) {
      self.creatingGroup = value;
    },
    setNewGroupDetails(type, value) {
      self[type] = value;
    },
    setNewCategoryDetails(type, value) {
      self[type] = value;
    },
    async createNewGroup(file) {
      let imageUploadRes = "";
      if (self.groupCode !== "MCS19") {
        NotificationStore.setNotification("error", groups.createCodeError);
        return;
      }
      self.setCreatingGroup(true);
      if (file["name"]) {
        imageUploadRes = await ImageUpload(file);
      }
      let params = {
        imageUrl: imageUploadRes,
        name: self.groupName,
        siteId: -1,
        description: self.groupDesc,
        code: self.groupCode,
        groupType: self.groupType ? self.groupType : "active", // send default as active
        category: self.category,
      };
      const response = await ApiService.postRequest("groups", params);
      if (response.success) {
        SiteStore.addGroupToSiteGroups(self.groupSites, response.data);
        GroupStore.addToSiteGroups(self.groupSites, response.data);
        self.addGroupToMultipleSites(response.data.id);
        self.resetPagination();
        self.fetchGroups();
        self.setCreatingGroup(false);
        self.setNewGroup(false);
        self.resetNewGroup();
        NotificationStore.setNotification("success", groups.createSuccess);
      } else {
        self.setCreatingGroup(false);
        NotificationStore.setNotification("error", response.errors[0].message);
        return;
      }
    },
    async addGroupToMultipleSites(groupId) {
      let promises = [];
      self.groupSites.forEach((siteId) => {
        const params = {
          siteId,
          groupId,
        };
        promises.push(ApiService.postRequest("site-groups", params));
      });
      Promise.all(promises)
        .then((res) => {
          self.setShowGroupSitesModal(false);
        })
        .catch((err) => {
          self.setShowGroupSitesModal(false);
          return;
        });
    },
    updateGroupSites(groupId) {
      const newIds = toJS(self.newGroupSites);
      const oldIds = toJS(self.groupSites);

      const addedIds = _.difference(newIds, oldIds);
      const removedIds = _.difference(oldIds, newIds);
      if (addedIds.length) {
        addedIds.forEach(async (id) => {
          const params = {
            siteId: id,
            groupId,
          };

          await ApiService.postRequest("site-groups", params);
        });
      }
      if (removedIds.length) {
        removedIds.forEach(async (id) => {
          const removedSite = self.groupSiteIds.filter((group) => {
            return group.siteId === id;
          });
          await ApiService.deleteRequest("site-groups", removedSite[0].id);
        });
      }
      self.setShowGroupSitesModal(false);
      self.setGroupSites([]);
    },
    async fetchGroups(params = {}) {
      params = {
        ...params,
        $skip: (self.pagination.current - 1) * self.pagination.pageSize,
        include: true,
        includeOnly: "users",
        "$sort[createdAt]": -1,
      };
      // if (self.sortColumn) {
      //   let order;
      //   switch (self.sortOrder) {
      //     case "ascend":
      //       order = 1;
      //       break;
      //     case "descend":
      //       order = -1;
      //       break;
      //     default:
      //       order = 0;
      //       break;
      //   }
      //   params = {
      //     ...params,
      //     [`$sort[${self.sortColumn}]`]: order,
      //   };
      // }
      self.setLoading(true);
      const response = await ApiService.getRequest("groups", params);
      if (response.success) {
        self.setGroups(response.data);
        self.setPaginationData(self.parseMetaData(response.meta));
      } else {
        self.setLoading(false);
        NotificationStore.setNotification("error", groups.groupFetchError);
        return;
      }
      self.setLoading(false);
    },
    setIceBreakerInfo(fieldToSet, value) {
      self[fieldToSet] = value;
    },
    setIceBreakerScreensInfo(fieldToSet, screen, value) {
      const index = screen - 1;
      let newCheck = self.checklist;
      newCheck[index] = {
        ...newCheck[index],
        [fieldToSet]: value,
      };
      self.checklist = newCheck;
    },
    resetIceBreakerInfo() {
      self.description = "";
      self.hasIceBreaker = false;
      self.newMemberBanner = false;
    },

    async parseChecklist(content) {
      try {
        return await JSON.parse(
          content.replace(/'/g, '"').replace(/\[newline\]/g, "\\n")
        );
      } catch (e) {
        throw e;
      }
    },
    async updateGroupIceBreaker(groupId) {
      const content = toJS(self.checklist);
      const checklist = await JSON.stringify(content)
        .replace(/"/g, "'")
        .replace(/\//g, "\\/")
        .replace(/\\n/g, "[newline]");
      const params = {
        hasIceBreaker: self.hasIceBreaker,
        checklist,
        displayNewMemberBanner: self.newMemberBanner,
      };
      self.setUpdatingIceBreaker(true);
      const updatedResponse = await ApiService.patchRequest(
        "groups",
        groupId,
        params
      );
      if (updatedResponse.success) {
        self.setUpdatingIceBreaker(false);
        self.setShowIceBreaker(false);
      } else {
        self.setUpdatingIceBreaker(false);
      }
    },
    async resetIceBreakerForMembers(groupId) {
      const params = {
        groupId,
        status: false,
      };
      const response = await ApiService.postRequest(
        "set-icebreaker-status",
        params
      );
      if (response.success) {
        NotificationStore.setNotification(
          "success",
          groups.resetIceBreakerSuccess
        );
      } else {
        NotificationStore.setNotification(
          "error",
          groups.resetIceBreakerFailure
        );
      }
      self.setShowIceBreaker(false);
    },
    setUpdatingIceBreaker(value) {
      self.updatingIceBreaker = value;
    },
    setNewMemberBanner(value) {
      self.newMemberBanner = value;
    },
    setIceBreakerStatus(value) {
      self.hasIceBreaker = value;
    },
    setShowIceBreaker(value) {
      self.showIceBreaker = value;
    },
    setGroupOnlineUsers(onlineUsers, groupId) {
      const channel = `GROUP_CHAT_${groupId}`;
      if (
        onlineUsers &&
        onlineUsers[channel] &&
        onlineUsers[channel].occupants
      ) {
        const users = onlineUsers[channel].occupants;
        self.setOnlineUserIds(users);
      }
    },
    setOnlineUserIds(users) {
      let uuids = [];
      users.forEach((user) => {
        if (user.state && user.state.isOnline) {
          if (!_.startsWith(user.uuid, "pn")) {
            const uuid = Number(user.uuid);
            uuids = [...uuids, uuid];
          }
        }
        self.selectOnlineUsers(uuids);
      });
    },
    selectOnlineUsers(userIds) {
      if (self.selectedGroup && self.selectedGroup.users) {
        let offlineUsers = [];
        let onlineUsers = [];
        const allUsers = _.cloneDeep(self.selectedGroup.users);
        allUsers.forEach((user) => {
          if (userIds.includes(user.userId)) {
            onlineUsers = [...onlineUsers, user];
          } else {
            offlineUsers = [...offlineUsers, user];
          }
        });
        self.onlineUsers = onlineUsers;
        self.offlineUsers = offlineUsers;
      }
    },
    updateOnlineStatus(isOnline, userId) {
      const uuid = Number(userId);
      if (isOnline) {
        if (
          self.selectedGroup &&
          self.selectedGroup.users &&
          self.offlineUsers.length
        ) {
          let offlineUsers = _.cloneDeep(self.offlineUsers);
          offlineUsers.forEach((user) => {
            if (user.userId === uuid) {
              const newOfflineUsers = self.offlineUsers.filter(
                (user) => user.userId !== uuid
              );
              self.offlineUsers = newOfflineUsers;
              self.onlineUsers = [...self.onlineUsers, user];
            }
          });
        }
      } else {
        if (
          self.selectedGroup &&
          self.selectedGroup.users &&
          self.onlineUsers.length
        ) {
          let onlineUsers = _.cloneDeep(self.onlineUsers);
          onlineUsers.forEach((user) => {
            if (user.userId === uuid) {
              const newOnlineUsers = self.onlineUsers.filter(
                (user) => user.userId !== uuid
              );
              self.onlineUsers = newOnlineUsers;
              self.offlineUsers = [...self.offlineUsers, user];
            }
          });
        }
      }
    },
    async fetchGroupSession(groupId) {
      const params = {
        include: true,
        includeOnly: "users",
        id: groupId,
      };
      self.setSessionLoading(true);
      const response = await ApiService.getRequest("groups", params);
      if (response.success) {
        self.setSessionGroup(response.data[0]);
      } else {
        self.setSessionLoading(false);
        NotificationStore.setNotification("error", groups.groupFetchError);
        return;
      }
      self.setSessionLoading(false);
    },
    setSessionLoading(value) {
      self.sessionLoading = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    async getGroupIceBreaker(groupId) {
      self.setIceBreakerLoading(true);
      const endPoint = `groups/${groupId}`;
      const groupResponse = await ApiService.getRequest(endPoint);
      if (groupResponse.success) {
        self.setGroupIceBreakerInfo(_.cloneDeep(groupResponse.data));
        if (groupResponse.data && groupResponse.data.checklist) {
          const check = await self.parseChecklist(groupResponse.data.checklist);
          self.setChecklist(check);
        } else {
          self.setChecklist([]);
        }

        self.setIceBreakerLoading(false);
      } else {
        self.setIceBreakerLoading(false);
      }
    },
    setIceBreakerLoading(value) {
      self.iceBreakerLoading = value;
    },
    setGroupIceBreakerInfo(data) {
      self.description = data.description;
      self.hasIceBreaker = data.hasIceBreaker;
      self.newMemberBanner = data.displayNewMemberBanner;
    },
    editGroupDetails(value) {
      self.groupEdit = value;
    },
    setgroupEditingLoading(value) {
      self.groupEditingLoading = value;
    },
    showFlags(value) {
      self.showGroupFlags = value;
    },
    changePreviousValue(prop, value) {
      self[prop] = value;
    },
    setSessionGroup(group) {
      let users = [];
      let pauseCount = 0;
      let unPauseCount = 0;
      group &&
        group.members &&
        _.forEach(group.members, (member) => {
          const userType = member.type;
          if (_.includes(["user", "semi-moderator"], userType)) {
            if (member.user_groups.status === 3) {
              pauseCount = pauseCount + 1;
            } else {
              unPauseCount = unPauseCount + 1;
            }
            users.push({
              username: member.username,
              userId: member.id,
              status: member.user_groups.status,
              addedById: member.user_groups.addedBy,
              createdAt: member.user_groups.createdAt,
            });
          }
        });
      group.pausedCount = pauseCount;
      group.unPausedCount = unPauseCount;
      group.memberCount = users.length;
      group.users = users;
      self.selectedGroup = { ...group };
    },
    updatePauseInSession(groupId, statusId) {
      try {
        if (self.selectedGroup && self.selectedGroup.id === groupId) {
          // const users = _.cloneDeep(self.selectedGroup.users);
          let offlineUsers = _.cloneDeep(self.offlineUsers);
          let onlineUsers = _.cloneDeep(self.onlineUsers);
          if (offlineUsers) {
            offlineUsers.forEach((user) => {
              user.status = statusId;
            });
          }
          if (onlineUsers) {
            onlineUsers.forEach((user) => {
              user.status = statusId;
            });
          }
          if (statusId === 3) {
            self.updatePauseUnPauseCount(self.selectedGroup.memberCount, 0);
          } else {
            self.updatePauseUnPauseCount(0, self.selectedGroup.memberCount);
          }
          self.onlineUsers = onlineUsers;
          self.offlineUsers = offlineUsers;
          // self.selectedGroup.users = users;
        }
      } catch (e) {
        mixpanel.track("updatePauseInSession", {
          from: "WEB",
          error: JSON.stringify(e),
        });
      }
    },

    setGroups(data) {
      let groupAlertsIds = [];
      self.groups = _.map(data, (group) => {
        groupAlertsIds.push(`GROUP_CHAT_${group.id}`);
        let moderators = [];
        let users = [];
        let semiMods = [];
        group.members &&
          _.forEach(group.members, (member) => {
            const userType = member.type;
            _.includes(["moderator"], userType)
              ? moderators.push({
                  userId: member.id,
                  username: member.username,
                  type: userType,
                })
              : _.includes(["semi-moderator"], userType)
              ? semiMods.push({
                  userId: member.id,
                  username: member.username,
                  type: userType,
                })
              : users.push({
                  username: member.username,
                  userId: member.id,
                  status: member.user_groups.status,
                  addedById: member.user_groups.addedBy,
                  createdAt: member.user_groups.createdAt,
                });
          });
        group.memberCount = users.length;
        self.offlineUsers = [...users];
        group.users = users;
        group.semiModerators = semiMods;
        group.moderators = moderators;
        return Group.create(group);
      });
      self.getGroupAlerts(groupAlertsIds);
    },
    setPaginationData(pagination) {
      self.pagination = pagination;
    },
    async getGroupAlerts(groupChannels) {
      self.setAlertLoading(true);
      const params = {
        channels: groupChannels.join(","),
      };
      const response = await ApiService.getRequest("reaction-count", params);
      if (response.success && response.data.length) {
        self.setGroupAlertCount(response.data);
        self.setAlertLoading(false);
      }
      self.setAlertLoading(false);
    },
    addGroupUserThroughSignal(userData) {
      if (self.selectedGroup && self.selectedGroup.id === userData.groupId) {
        let group = _.cloneDeep(self.selectedGroup);
        group.users.push({
          username: userData.username,
          userId: userData.userId,
          status: userData.status,
          addedById: userData.addedBy,
          createdAt: userData.createdAt,
        });
        if (userData.status === 3) {
          group.pausedCount = self.selectedGroup.pausedCount + 1;
        } else {
          group.unPausedCount = self.selectedGroup.unPausedCount + 1;
        }
        group.memberCount = self.selectedGroup.memberCount + 1;
        self.selectedGroup = { ...self.selectedGroup, ...group };
      }
    },
    removeGroupUserThroughSignal(userId, groupId, statusId) {
      if (self.selectedGroup && self.selectedGroup.id === groupId) {
        let group = _.cloneDeep(self.selectedGroup);
        group.users = group.users.filter((user) => {
          if (user.userId === userId) {
            if (statusId === 3) {
              group.pausedCount = self.selectedGroup.pausedCount - 1;
            } else {
              group.unPausedCount = self.selectedGroup.unPausedCount - 1;
            }
          }
          return user.userId !== userId;
        });
        group.memberCount = self.selectedGroup.memberCount - 1;
        self.selectedGroup = { ...self.selectedGroup, ...group };
      }
    },

    removeOnlineUser(userId, groupId) {
      let onlineUsers = _.cloneDeep(self.offlineUsers);
      const newOnlineUsers = onlineUsers.filter((user) => {
        return user.userId !== userId;
      });
      self.onlineUsers = newOnlineUsers;
    },
    removeOnlineUser(userId, groupId) {
      let offlineUsers = _.cloneDeep(self.onlineUsers);
      const newOfflineUsers = offlineUsers.filter((user) => {
        return user.userId !== userId;
      });
      self.offlineUsers = newOfflineUsers;
    },
    removeGroupUser(userId, groupId) {
      let group = _.cloneDeep(self.selectedGroup);
      group.users = group.users.filter((user) => {
        if (user.userId === userId) {
          if (user.status === 3) {
            group.pausedCount = self.selectedGroup.pausedCount - 1;
          } else {
            group.unPausedCount = self.selectedGroup.unPausedCount - 1;
          }
        }
        return user.userId !== userId;
      });
      group.memberCount = self.selectedGroup.memberCount - 1;
      self.selectedGroup = { ...self.selectedGroup, ...group };
      self.updateGroupMemberCount(userId, groupId);
    },
    changeStatusInGroup(userId, statusId) {
      const users = _.cloneDeep(self.selectedGroup.users);
      const userInSelectedGroup = _.find(users, { userId });
      if (userInSelectedGroup) {
        userInSelectedGroup.status = statusId;
      }
      const groups = _.cloneDeep(self.groups);
      const group = _.find(groups, { id: self.selectedGroup.id });
      if (group) {
        const user = _.find(group.users, { userId });
        if (user) {
          user.status = statusId;
        }
      }
      self.groups = groups;
      self.selectedGroup.users = users;
    },
    changeStatusOfOfflineUser(userId, statusId) {
      let offlineUsers = _.cloneDeep(self.offlineUsers);
      const offlineUser = _.find(offlineUsers, { userId });
      if (offlineUser) {
        offlineUser.status = statusId;
      }
      self.offlineUsers = offlineUsers;
    },
    resetPagination() {
      self.pagination = {
        pageSize: 10,
        current: 1,
        total: 0,
      };
    },
    changeStatusOfOnlineUser(userId, statusId) {
      let onlineUsers = _.cloneDeep(self.onlineUsers);
      const onlineUser = _.find(onlineUsers, { userId });
      if (onlineUser) {
        onlineUser.status = statusId;
      }
      self.onlineUsers = onlineUsers;
    },
    updateOnlineOfflineUsers(userId, statusId) {
      const offlineUsers = _.cloneDeep(self.offlineUsers);
      const onlineUsers = _.cloneDeep(self.onlineUsers);
      const offlineUserIndex = offlineUsers.findIndex(
        (user) => user.userId === userId
      );
      const onlineUserIndex = onlineUsers.findIndex(
        (user) => user.userId === userId
      );
      if (offlineUserIndex >= 0) {
        let offlineUser = offlineUsers[offlineUserIndex];
        offlineUser.status = statusId;
      }
      if (onlineUserIndex >= 0) {
        let onlineUser = onlineUsers[onlineUserIndex];
        onlineUser.status = statusId;
      }
      self.onlineUsers = onlineUsers;
      self.offlineUsers = offlineUsers;
    },
    changePauseInSessionThroughSignal(groupId, userId, statusId) {
      if (self.selectedGroup && self.selectedGroup.id === groupId) {
        self.updateOnlineOfflineUsers(userId, statusId);
        const users = _.cloneDeep(self.selectedGroup.users);
        const userInSelectedGroup = _.find(users, { userId });
        if (userInSelectedGroup) {
          if (statusId === 3) {
            self.updatePauseUnPauseCount(
              self.selectedGroup.pausedCount + 1,
              self.selectedGroup.unPausedCount - 1
            );
          } else {
            self.updatePauseUnPauseCount(
              self.selectedGroup.pausedCount - 1,
              self.selectedGroup.unPausedCount + 1
            );
          }
          userInSelectedGroup.status = statusId;
        }
        self.selectedGroup.users = users;
      }
    },
    async pauseAllUsersInGroup(groupId, status) {
      self.setAllPauseLoading(true);
      const params = {
        groupId,
        pause: status,
      };
      const response = await ApiService.postRequest("set-pause-status", params);
      if (response.success) {
        self.setAllPauseLoading(false);
      } else {
        self.setAllPauseLoading(false);
      }
    },
    updatePauseUnPauseCount(pauseCount, unPauseCount) {
      self.selectedGroup.pausedCount = pauseCount;
      self.selectedGroup.unPausedCount = unPauseCount;
    },
    setAllPauseLoading(value) {
      self.allPauseLoading = value;
    },
    editGroupInfo() {
      self.groupImage = self.selectedGroup.imageUrl;
      self.groupName = self.selectedGroup.name;
      self.groupDesc = self.selectedGroup.description;
      self.editGroupDetails(true);
    },
    resetNewGroup() {
      self.groupCode = "";
      self.groupDesc = "";
      self.groupImage = "";
      self.groupName = "";
      self.groupSites = [];
      self.category = "";
      self.groupType = "";
      self.newGroup = false;
    },
    async updateGroupDetails(file) {
      let imageUploadRes = "";
      self.setgroupEditingLoading(true);
      if (file["name"]) {
        imageUploadRes = await ImageUpload(file);
      }
      if (!self.groupName.trim().length) {
        self.setgroupEditingLoading(false);
        self.editGroupDetails(false);
        NotificationStore.setNotification("error", groups.emptyGroup);
        return;
      }
      let params = {};
      if (imageUploadRes && self.selectedGroup.imageUrl !== imageUploadRes) {
        params = {
          ...params,
          imageUrl: imageUploadRes,
        };
      }
      if (self.selectedGroup.name !== self.groupName) {
        params = {
          ...params,
          name: self.groupName,
        };
      }
      if (self.selectedGroup.description !== self.groupDesc) {
        params = {
          ...params,
          description: self.groupDesc,
        };
      }
      params = {
        ...params,
        oldGroupName: self.selectedGroup.name,
      };

      const response = await ApiService.patchRequest(
        "groups",
        self.selectedGroup.id,
        params
      );
      if (response.success) {
        self.updateSelectedGroup(response.data);
        self.updateGroup(response.data);
        self.setgroupEditingLoading(false);
        self.editGroupDetails(false);
        NotificationStore.setNotification("success", groups.updateSuccess);
      } else {
        self.setgroupEditingLoading(false);
        self.editGroupDetails(false);
        NotificationStore.setNotification("error", response.data.message);
      }
    },
    setAlertLoading(value) {
      self.alertLoading = value;
    },
    updateGroup(data) {
      self.groups = self.groups.map((element) => {
        if (element.id === data.id) {
          element = { ...toJS(element), ...data };
        }
        return element;
      });
    },
    setGroupAlertCount(alertCounts) {
      alertCounts.forEach((alertCount) => {
        const groupId = Number(alertCount.channel.split("_")[2]);
        const group = _.find(self.groups, { id: groupId });
        if (group) {
          group.alertCount = Number(alertCount.count);
        }
      });
    },
    updateGroupMemberCount(userId, groupId) {
      const group = _.find(self.groups, { id: groupId });
      if (group) {
        group.memberCount = group.memberCount - 1;
        _.remove(group.users, { userId });
      }
    },
    parseMetaData(meta) {
      return {
        pageSize: meta.limit,
        current: meta.skip / meta.limit + 1,
        total: meta.total,
      };
    },
    setSortParameters(column, order) {
      self.sortColumn = column;
      self.sortOrder = order;
    },
    setDetailsVisibility(value) {
      self.showGroupDetails = value;
    },
    async getUsernameFromIds(group) {
      if (group && group.users) {
        const userIds = group.users.reduce((obj, element) => {
          if (!element.addedByUsername) {
            if (
              element.addedById !== -1 &&
              !obj.hasOwnProperty(element.addedById)
            ) {
              obj[element.addedById] = true;
            }
            return obj;
          }
        }, {});

        if (userIds && Object.keys(userIds).length) {
          const params = {
            payload: Object.keys(userIds),
          };
          const response = await ApiService.postRequest(
            "username-by-id",
            params
          );

          const groupUsers = group.users.map((groupUser) => {
            response.data.forEach((user) => {
              if (groupUser.addedById === user.id) {
                groupUser = { ...groupUser, addedByUsername: user.username };
              }
            });
            return groupUser;
          });

          return groupUsers;
        } else {
          return;
        }
      }
    },
    async setSelectedGroup(group) {
      if (group && group.users && group.users.length) {
        group.users = await self.getUsernameFromIds(group);
      }

      self.setGroupDetails(group);
    },
    setChecklist(checklist) {
      self.checklist = checklist;
    },
    updateSelectedGroup(group) {
      self.selectedGroup = { ...self.selectedGroup, ...group };
    },
    setGroupDetails(group) {
      self.selectedGroup = group;
    },
    resetSelectedGroup() {
      self.setSelectedGroup(null);
      self.groupName = "";
      self.groupDesc = "";
    },
  }))
  .views((self) => ({
    getSortOrder(column) {
      return self.sortColumn === column ? self.sortOrder : false;
    },
    get isAllPaused() {
      return self.selectedGroup.memberCount === self.selectedGroup.pausedCount;
    },
    get canCreateGroup() {
      return !(self.groupName && self.groupCode && self.groupDesc && self.groupType);
    },
    get isAllUnPaused() {
      return (
        self.selectedGroup.memberCount === self.selectedGroup.unPausedCount
      );
    },
  }));

export default GroupList;
